import { useState, useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { CHLButton, CHLDialog } from "../../components"
import { useForm } from "react-hook-form";
import axiosInstance from "../../services/axios";
import { MCC_REDIRECT_URI }from "../../services/api";

function MockPage() {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { register, handleSubmit, watch, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });
    const classes = useStyles();
    const [preScreenRefIndicator, preScreenRefNumber] = watch(["preScreenRefIndicator", "preScreenRefNumber"]);
    useEffect(() => {
        if (preScreenRefIndicator === 'N') {
            clearErrors('preScreenRefNumber');
        }
    }, [preScreenRefIndicator, preScreenRefNumber]);

    const onSubmit = (data: any) => {
        setLoading(true);
        axiosInstance.post(MCC_REDIRECT_URI, data).then(res => {
            setLoading(false);
            window.location.href = res?.data as string;
        }).catch(err => {
            setLoading(false);
        })
    }

    const onCloseHandler = () => {
        setShowAlert(false);
    }
    return (
        <Box position="relative" margin="auto" maxWidth={800}>
            <Box my={8} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Partner ID</p>
                        <input className={classes.input} {...register('partnerID', { required: "Required", maxLength: { value: 15, message: "Length can't more than 15" } })} />
                        {errors.partnerID && <p className={classes.p}>{errors.partnerID?.message?.toString() || ''}</p>}

                    </label>
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Partner Customer ID</p>
                        <input className={classes.input} {...register('partnerCustID', { required: "Required", maxLength: { value: 50, message: "Length can't more than 50" } })} />
                    </label>
                    {errors.partnerCustID && <p className={classes.p}>{errors.partnerCustID?.message?.toString()}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Partner Datetime (Format: YYMMDDhhmmss)</p>
                        <input className={classes.input} {...register('partnerDateTime', { required: true, pattern: { value: /^\d{12}$/i, message: 'Invalid Datetime' } })} />
                    </label>
                    {errors.partnerDateTime && <p className={classes.p}>{errors.partnerDateTime?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Partner Transaction ID</p>
                        <input className={classes.input} {...register('partnerTrxID', { required: "Required", maxLength: { value: 15, message: "Length can't more than 15" } })} />
                    </label>
                    {errors.partnerTrxID && <p className={classes.p}>{errors.partnerTrxID?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Product Code</p>
                        <input className={classes.input} {...register('productCode', { required: true })} />
                    </label>
                    {errors.productCode && <p className={classes.p}>{errors.productCode?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Callback Url</p>
                        <input className={classes.input} {...register('callbackUrl', { required: true })} />
                    </label>
                    {errors.callbackUrl && <p className={classes.p}>{errors.callbackUrl?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>utoken</p>
                        <input className={classes.input} {...register('utoken', { required: true })} />
                    </label>
                    {errors.utoken && <p className={classes.p}>{errors.utoken?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>preScreenRefIndicator</p>
                        <select className={classes.input} {...register("preScreenRefIndicator", { required: true })}>
                            <option value="N">N</option>
                            <option value="Y">Y</option>
                        </select>
                    </label>
                    {errors.preScreenRefIndicator && <p className={classes.p}>{errors.preScreenRefIndicator?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p>{preScreenRefIndicator === 'Y' && <span style={{ color: 'red' }}>*</span>}preScreenRefNumber</p>
                        <input className={classes.input} {...register('preScreenRefNumber', { required: preScreenRefIndicator === 'Y' })} />
                    </label>
                    {errors.preScreenRefNumber && <p className={classes.p}>{errors.preScreenRefNumber?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Source</p>
                        <input defaultValue="mcc" className={classes.input} {...register('source', { required: true })} />
                    </label>
                    {errors.source && <p className={classes.p}>{errors.source?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p><span style={{ color: 'red' }}>*</span>Partner Code</p>
                        <input defaultValue="mcc" className={classes.input} {...register('partnerCode', { required: true })} />
                    </label>
                    {errors.partnerCode && <p className={classes.p}>{errors.partnerCode?.message?.toString() || 'Required'}</p>}
                </div>

                <div>
                    <label>
                        <p>reserve1</p>
                        <input defaultValue="" className={classes.input} {...register('reserve1', { required: false })} />
                    </label>
                </div>

                <div>
                    <label>
                        <p>reserve2</p>
                        <input defaultValue="" className={classes.input} {...register('reserve2', { required: false })} />
                    </label>
                </div>

                <div>
                    <label>
                        <p>reserve3</p>
                        <input defaultValue="" className={classes.input} {...register('reserve3', { required: false })} />
                    </label>
                </div>


                <Box mt={5} display="flex" justifyContent="center">
                    <CHLButton
                        title="Submit"
                        fullWidth
                        color="secondary"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                    />
                </Box>
            </form>
            <CHLDialog
                hideClose={true}
                open={showAlert}
                title="Success"
                onClose={() => onCloseHandler()}
                actions={[
                    {
                        title: "Ok",
                        color: "secondary",
                        onClick: () => onCloseHandler()
                    }]}>
                <Typography>Submit Success</Typography>
            </CHLDialog>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    note: {
        fontSize: "16px",
        lineHeight: "22px",
    },
    h4: {
        color: theme.palette.secondary.main,
        fontSize: "22px",
        lineHeight: "28px",
        fontWeight: 700,
        marginBottom: "10px",
    },
    span: {
        fontWeight: 400,
        fontSize: 16
    },
    input: {
        display: "block",
        boxSizing: "border-box",
        width: "100%",
        borderRadius: "4px",
        border: "1px solid #eeeeee",
        padding: "10px 15px",
        marginBottom: "5px",
        fontSize: "14px",
    },
    p: {
        marginTop: 0,
        color: "red",
        fontSize: 14,
    }
}));

export default MockPage;