import { Fragment, useState, useEffect} from "react"
import { Box, Typography, AppBar, Container, Toolbar } from "@material-ui/core"
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { BUTTON_LABEL } from "../../utilities/label"
import { ERROR_PAGE } from "../../utilities/error"
import { CHLButton, CHLCustomerService } from "../../components"
import { ReactComponent as OctopusFail } from "../../assets/images/oct_fail.svg";
import { ReactComponent as Fail } from "../../assets/images/fail.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const CIMB_HOME_URL = window._env.REACT_APP_CIMB_HOME

const ErrorPage = () =>{
  const { code } = useSelector((s: RootState) => s.error);

  const [header, setHeader] = useState<string>()
  const [message, setMessage] = useState<string>()

  useEffect(() => {
    if(code != null){
      setErrorPage(ERROR_PAGE[code])
    }
    window.sessionStorage.clear()
    window.localStorage.clear()
  }, [])

  const setErrorPage  = (error: {header: string, message: string}) =>{
    setHeader(error.header)
    setMessage(error.message)
  }

  const redirectToHome = () =>{
    window.location.href = CIMB_HOME_URL;
  }

  return (
    <Fragment>
      <AppBar elevation={0}>
        <Toolbar>
          <Box flex={1} />
          <Logo />
          <Box flex={1} />
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={10}>
          <Typography align="center" variant="h5" component="div">
            <Box fontSize="30px" fontWeight="bold" color="#FF0000">We’re Sorry!</Box>
            <Box display="flex" justifyContent="center" my={5}>
              <Fail />
            </Box>
          </Typography>
          <Typography align="center" variant="body2" component="div">
            <Box my={5} fontSize="16px" fontWeight="bold">
              {header? header : "Processing Error"}
            </Box>
            <Box fontSize="16px">{message ? message : ""}</Box>
          </Typography>
          <Box display="flex" justifyContent="center" my={3}>
            <OctopusFail />
          </Box>
          <Box display="flex" justifyContent="center">
            <CHLCustomerService/>
          </Box>
          <Box my={2} textAlign="center" fontSize="16px">
            <CHLButton
              title={BUTTON_LABEL.backToHome}
              color="secondary"
              onClick={() => redirectToHome()}
            />
          </Box>
        </Box>
      </Container>
    </Fragment>
  )
}

export default ErrorPage;
