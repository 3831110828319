import { FC, FormEvent, useRef, useState, useEffect } from "react";
import CHLTextField, {
  ICHLTextField,
} from "../../../../components/CHLTextField";
import { ReactComponent as RemoveIcon } from "../../../../assets/images/remove.svg";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { DocUploadRequest } from "../../../../services/requests/DocUploadRequest";
import { blobToBase64, byteConvert } from "../../../../utilities/helper";
import CHLDialog from "../../../../components/CHLDialog";
import { DocUploadActions } from "../../../../stores/reducers";
import { RootState } from "../../../../stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  BE_CODE_STATUS,
  SMART_FILL_FILE_SIZE,
  SMART_FILL_ACCEPT_FILE_FORMAT,
} from "../../../../utilities/constants";

interface Base64File {
  name: string;
  content: string;
  isValidSize: boolean;
  isValidFormat: boolean;
}

const UploadField: FC<ICHLTextField> = (props) => {
  const { onChange, value, code, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<File[] | null>();
  const [obsError, setObsError] = useState<boolean>(false);
  const [failFiles, setFailFiles] = useState<Array<any>>([]);
  const { fileIds } = useSelector((s: RootState) => s.docUpload);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      onChange && onChange(value as any);
      setFileList(value);
    }
  }, []);

  const onClickHandler = () => {
    inputRef.current?.click();
  };

  const onSingleRemoveHandler = (
    index: number,
    e: FormEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    let latestFiles: Array<File> = fileList
      ? fileList.filter((f, idx) => idx !== index)
      : [];
    let latestFileIds: { [key: string]: string } = {};
    const oldFileIds: { [key: string]: string } = fileIds;
    latestFiles.map((f) => {
      let key: string = `${code}/${f.name}`;
      latestFileIds[key] = oldFileIds[key];
    });
    if (latestFiles.length > 0) {
      dispatch(DocUploadActions.setRemainFileIds(latestFileIds));
      onChange && onChange(latestFiles as any);
    } else {
      dispatch(DocUploadActions.setFileIds({}));
      onChange && onChange(null as any);
    }
    setFileList(latestFiles);
    if (inputRef.current) inputRef.current.value = "";
  };

  // 文件累加、 NO-同名覆盖-NO
  // 判断文件大小 <= 5M
  // 存储obs call fileId：存储方式 code/fileName= fileId
  const onFileChangeHandler = async (e: FormEvent) => {
    const files = (e.target as HTMLInputElement).files;
    if (files && files.length) {
      dispatch(DocUploadActions.setUploadLoading(true));
      let { newFilesUpload, base64Lists } = await convertToBase64(files);
      let res: PromiseSettledResult<any>[] = await uploadToObs(base64Lists);
      let {
        failedFiles = [],
        passedFiles = [],
        newPassedFiles,
      } = setFileValues(base64Lists, res, newFilesUpload);
      setFailFiles(failedFiles);
      if (failedFiles.length > 0) {
        setObsError(true);
      }
      // 同名覆盖的处理
      // let newNames = passedFiles.map((i) => i.name);
      // let filterFiles = fileList?.filter((f) => !newNames.includes(f.name));
      // let newFileList = filterFiles
      //   ? filterFiles.concat(newPassedFiles)
      //   : newPassedFiles;
      // onChange &&
      //   onChange((newFileList.length > 0 ? newFileList : null) as any);
      // setFileList(newFileList);
      onChange && onChange((newPassedFiles.length > 0 ? newPassedFiles : null) as any);
      setFileList(newPassedFiles);
      dispatch(DocUploadActions.setUploadLoading(false));
      dispatch(DocUploadActions.setFileIds(mapCodeFileId(passedFiles)));
      if (inputRef.current) inputRef.current.value = "";
    }
  };

  const mapCodeFileId = (passedFiles: { name: any; id: any }[]) => {
    let mapFiles: { [key: string]: string } = {};
    passedFiles.map((item: { name: any; id: any }) => {
      mapFiles[`${code}/${item.name}`] = item.id;
    });
    return mapFiles;
  };

  const convertToBase64 = async (files: FileList) => {
    let newFilesUpload = [];
    let base64Lists: Base64File[] = [];
    for (let i = 0; i < files.length; ++i) {
      newFilesUpload.push(files[i]);
      let isValidSize = files[i].size <= SMART_FILL_FILE_SIZE;
      let isValidFormat = SMART_FILL_ACCEPT_FILE_FORMAT.includes(files[i]?.type);
      let content = "";
      if (isValidSize && isValidFormat) {
        content = await blobToBase64(files[i]);
      }
      base64Lists.push({
        name: files[i].name,
        content,
        isValidSize,
        isValidFormat,
      });
    }
    return {
      newFilesUpload,
      base64Lists,
    };
  };

  const setFileValues = (
    filesData: { name: any }[],
    res: PromiseSettledResult<any>[],
    files: Array<File>
  ) => {
    let passedFiles: Array<any> = [];
    let failedFiles: Array<any> = [];
    let newPassedFiles: Array<File> = [];
    res.map((r: PromiseSettledResult<any>, index: number) => {
      if (r.status === "rejected") {
        failedFiles.push({
          name: filesData[index].name,
          error:
            r.reason?.message === "oversize"
              ? "oversize_error"
              : r.reason?.message === "invalid_format"
              ? "format_error"
              : "service_error",
        });
      } else if (r.status === "fulfilled") {
        if (r.value.code === BE_CODE_STATUS.SUCCESS) {
          passedFiles.push({
            name: filesData[index].name,
            id: r.value?.data?.uploadedFiles?.[0]?.id,
            code,
          });
          newPassedFiles.push(files[index]);
        } else {
          failedFiles.push({
            name: filesData[index].name,
            error: "service_error",
          });
        }
      }
    });
    return {
      failedFiles,
      passedFiles,
      newPassedFiles,
    };
  };

  const uploadToObs = (filesData: Base64File[]) => {
    return Promise.allSettled(
      filesData.map(({name, content, isValidSize, isValidFormat}, index: number) => {
        if (content) {
          return DocUploadRequest.obsUploadFiles([
            { name, content },
          ]);
        } 
        if(!isValidFormat){
          return Promise.reject({message: "invalid_format"})
        }
        if(!isValidSize) {
          return Promise.reject({ message: "oversize" });
        }
      })
    );
  };

  return (
    <>
      <CHLTextField
        file
        inputProps={{ readOnly: true }}
        onClick={onClickHandler}
        {...rest}
      />
      <input
        ref={inputRef}
        type="file"
        multiple
        hidden
        onChange={onFileChangeHandler}
        accept=".png, .jpg, .jpeg, .pdf"
      />
      <div>
        {value &&
          value.map((v: File, index: number) => {
            return (
              <Box className={classes.itemWrapper} key={`${v.name}-${index}`}>
                <Typography
                  className={
                    v.size > SMART_FILL_FILE_SIZE
                      ? clsx(classes.name, classes.errorName)
                      : clsx(classes.name)
                  }
                >
                  {v.name}
                </Typography>

                <IconButton
                  className={classes.icon}
                  onClick={(e) => onSingleRemoveHandler(index, e)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            );
          })}
      </div>
      {obsError && (
        <CHLDialog
          hideClose={true}
          open={obsError}
          title="Oops..."
          onClose={() => setObsError(false)}
          actions={[
            {
              title: "OK",
              color: "secondary",
              onClick: () => setObsError(false),
            },
          ]}
        >
          <Typography>Something wrong in the process of uploading:</Typography>
          <Typography>
            <ul className={classes.ul}>
              {failFiles.map((i) => {
                return (
                  <li>
                    {
                      i.error === "oversize_error"
                      ? `${i.name}: File size not supported. Maximum file size permitted is ${byteConvert(SMART_FILL_FILE_SIZE)}.`
                      : i.error === "format_error" 
                      ? `${i.name}: File format not supported. Only PDF, JPG and PNG file formats are accepted.`
                      : `${i.name}: File upload unsuccessful. Please try again.`
                    }
                  </li>
                );
              })}
            </ul>
          </Typography>
        </CHLDialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    color: "#36B37E",
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  errorName: {
    color: "red",
  },
  icon: {
    marginLeft: "16px",
  },
  ul: {
    padding: "0 0 0 24px",
  },
}));
export default UploadField;
