import { useState, forwardRef, useEffect } from "react";
import { Box, FormControl, FormHelperText } from "@material-ui/core";
import CHLTextField, { ICHLTextField } from "../CHLTextField";
import CHLLabel from "../CHLLabel";
import InputMask from "react-input-mask";
import { CODE } from "../../utilities/constants"

interface ICHLEmploymentField extends ICHLTextField {
  label?: string;
  helperText?: string;
  description?: string;
  error: boolean;
  onChange: any;
  value?: string;
  objPlaceholder?: {
    [key: string]: string
  },
  autoPaddingZero: boolean,
}

let val: any = "";
const CHLEmploymentField = forwardRef<any, ICHLEmploymentField>((props, ref) => {
  const {
    label,
    bold,
    required,
    helperText,
    description,
    error,
    onChange,
    value,
    objPlaceholder,
    autoPaddingZero,
  } = props;

  let [month, setMonth] = useState<string>()
  let [year, setYear] = useState<string>()

  let m: string = "";
  let y: string = "";

  useEffect(() => {
    if (value?.includes("-")) {
      let strVal = value.split("-")
      y = strVal[0];
      m = strVal[1];
      setYear(y)
      setMonth(m)
    }
  }, [])

  useEffect(() => {
    if (!value) {
      setYear("")
      setMonth("")
    }
  }, [value])

  const onChangeHandler = (code: string) => (e: any) => {
    let newMonth: string;
    let newYear: string;
    const newValue: string = e.target.value;

    if (code === CODE.LENGTH_OF_EMPLOYMENT_YEAR) {
      newMonth = month ? month : "";
      newYear = newValue ? newValue : "";
      setYear(newValue)
    } else {
      newYear = year ? year : "";
      newMonth = newValue ? newValue : "";
      setMonth(newValue)
    }

    if (newYear == "" && newMonth == "") {
      val = "-"
    } else if (parseInt(newMonth) > 11) {
      val = newYear + "-"
    } else {
      if (newYear == "" && newMonth == "00") {
        if (month == "") {
          val = "-";
        }
      } else if (newYear == "00" && newMonth == "") {
        if (year == "") {
          val = "-";
        }
      } else {
        if (autoPaddingZero) {
          newYear = newYear.padStart(2, "0")
          newMonth = newMonth.padStart(2, "0")
        } else {
          newYear = newYear != "" ? newYear.padStart(2, "0") : newYear;
          newMonth = newMonth != "" ? newMonth.padStart(2, "0") : newMonth;
        }

        val = newYear + "-" + newMonth;
      }
    }

    if (val == "00-00") {
      val = "-"
    }

    onChange && onChange(val);

  };

  return (
    <Box mb={2}>
      <FormControl error={error} style={{ position: "relative" }}>
        <CHLLabel
          bold={bold}
          description={description}
          label={label}
          required={required}
        />

        <Box display="flex" alignItems="flex-end">
          <Box flex={1}>
            <InputMask
              mask="99"
              onChange={onChangeHandler(CODE.LENGTH_OF_EMPLOYMENT_YEAR)}
              // defaultValue={year}
              value={year}
              maskChar={null}
            >
              {(inputProps: any) => (
                <CHLTextField
                  bold={true}
                  placeholder={objPlaceholder ? objPlaceholder[CODE.LENGTH_OF_EMPLOYMENT_YEAR] : null}
                  {...inputProps}
                  error={error}
                  hideHelperText
                  InputProps={{ endAdornment: "years" }}
                />
              )}
            </InputMask>
          </Box>
          <Box mx={1} />
          <Box flex={1}>
            <InputMask
              mask="99"
              onChange={onChangeHandler(CODE.LENGTH_OF_EMPLOYMENT_MONTH)}
              // defaultValue={month}
              value={month}
              maskChar={null}
            >
              {(inputProps: any) => (
                <CHLTextField
                  bold={true}
                  placeholder={objPlaceholder ? objPlaceholder[CODE.LENGTH_OF_EMPLOYMENT_MONTH] : null}
                  {...inputProps}
                  error={error}
                  hideHelperText
                  InputProps={{ endAdornment: "months" }}
                />
              )}
            </InputMask>
          </Box>
        </Box>
        <FormHelperText error={true} style={{ marginTop: "-10px" }}>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
});

export default CHLEmploymentField;
