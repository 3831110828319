import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import CHLTextField, { ICHLTextField } from "../CHLTextField";
import DateIcon from "../../assets/images/date.svg"

const CHLDatePicker: FC<ICHLTextField> = (props) => {
  const { ...rest } = props;
  const classes = useStyles();
  return (
    <CHLTextField classes={{ root: classes.root }} inputProps={{max: "2099-12-31"}} type="date" {...rest} />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& ::-webkit-calendar-picker-indicator": {
      backgroundImage: `url(${DateIcon})`
    },
  },
}));

export default CHLDatePicker;
