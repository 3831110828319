import { FC, useState } from "react";
import { 
  Box, 
  Button, 
  CircularProgress, 
  makeStyles 
} from "@material-ui/core";
import { ReactComponent as GPSIcon } from "../../../../assets/images/gps.svg";
import { useDispatch } from "react-redux";
import { BUTTON_LABEL, LABEL } from "../../../../utilities/label";
import { RespAddress } from "../../../../types/response";
import { ReqOnemapSearchAddress } from "../../../../types/request";
import { AlertDialogActions } from "../../../../stores/reducers/alertDialog";
import { ApplicationPageRequest } from "../../../../services/requests/ApplicationPageRequest";
// import mockSearch from "../../../../mock/mock-response/onemap.json";

interface IButtonGeolocation {
  searchVal: string;
  onClick: (data: any) => void;
}

const ButtonGeolocation: FC<IButtonGeolocation> = (props) => {
  const { searchVal, onClick } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onClickHandler = async () => {
    if (searchVal) {
      if(!searchVal.includes(" ")){
        setIsLoading(true);
        const requestParams: ReqOnemapSearchAddress = { searchVal, isMcc: true }
        await getOnemapAddress(requestParams);
      }
    }
  }

  const showErrorMessage = (message: string) => {
    dispatch(AlertDialogActions.addAlert({
      title: LABEL.NOTICE,
      message,
      buttonText: BUTTON_LABEL.CLOSE
    }))
  };

  const getOnemapAddress = async (reqData: ReqOnemapSearchAddress) => {
    let result: any
    try {
      result = await ApplicationPageRequest.getOneMapAddress(reqData);

      if(result.data){
        const data:RespAddress = result.data.data
        // const data:RespAddress = mockSearch.data

        if(data){
          onClick && onClick(data);
        }else{
          const message = result.data.message
          showErrorMessage(message)
        }
      }
    } catch (err) {
      showErrorMessage("Failed to retrieve address. Please try again later.");
    }finally{
      setIsLoading(false)
    }
  };

  return (
    <Button
      tabIndex="-1"
      className={classes.button}
      variant="outlined"
      fullWidth
      onClick={onClickHandler}
      disabled={isLoading}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box display="flex" alignItems="center" py={1} fontWeight="100">
          <GPSIcon />
          <span>Get Address</span>
        </Box>
      )}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default ButtonGeolocation;
