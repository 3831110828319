export const REDIRECT_COUNT_DOWN = 20;

export const QUICK_LINK = [
  {
    title: "Product Details",
    url: window._env.REACT_APP_CIMB_PRODUCT_DETAILS,
  },
  {
    title: "Fees & Charges",
    url: window._env.REACT_APP_CIMB_FEES_CHARGES,
  },
  {
    title: "Eligibility",
    url: window._env.REACT_APP_CIMB_ELIGIBILITY,
  }
]

export const EWALLET_NOTE = "The maximum loan amount and the balance in your wallet must not be more than $4,999 as guided by your wallet provider and regulated by the Monetary Authority of Singapore."

export const FORM_TYPE = {
  DATAENTRY: "dataEntry",
  TABLE: "table",
  ATTACHMENT: "attachment"
};

export const DEFAULT_VALUE = {
  STP: "stp",
  NSTP: "nstp",
  COUNTRY_SINGAPORE: "SG",
  COUNTRY_MALAYSIAN: "MY",
  HASTIN_YES: "tiny",
  FATCA_RESIDENCE_YES: "fatcay",//to determine nstp flow if yes
  FATCA_RESIDENCE_NO: "fatcan",

  PAYNOW_NRIC: "NRIC",
  PAYNOW_MOBILE: "MSISDN",

  REJECT: "reject",
  APPROVE: "approve",
  IN_PROGRESS: 'inProgress',

  NODE_AIP: "inPrincipleApproval",
  NODE_CONTRACT: "contract",

  MYINFO: "myinfo",
  NON_MYINFO: "nonmyinfo",

  YES: 'yes',
  NO: 'no',
  INVALID: 'invalid',
  SUCCESS: 'success',
}

const prefix_addr = "regadd_"
export const REGADDR = { //Sequence must follow SG address format: Block - Street Name - #Floor + Unit - Building Name - Country - Postal Code
  BLOCK: `${prefix_addr}block`,
  STREET: `${prefix_addr}street`,
  FLOOR: `${prefix_addr}floor`,
  UNIT: `${prefix_addr}unit`,
  BUILDING: `${prefix_addr}building`,
  COUNTRY: `${prefix_addr}country`,
  POSTAL_CODE: `${prefix_addr}postal`,
}

export const CODE = {
  RISK_BASED_TEXT: 'riskBasedText',
  MALAYSIA_NRIC: "myNRIC",
  PASSPORT_NUMBER: "passportNumber",
  PASSPORT_EXPIRY_DATE: "passportExpiryDate",
  EMPLOYMENT_PASS_TYPE: "passtype",
  EMPLOYMENT_PASS_STATUS: "passstatus",
  EMPLOYMENT_PASS_EXPIRY_DATE: "passexpirydate",
  MCC_ADDRESS_DETAIL_CAP_NAME: "Address Details",
  PROG_CODE: "progcode",
  CRDE_VALUE: "crdeValue",
  PERSONAL_DETAILS: "Personal Details",
  MCC_NATIONALITY: "nationality",
  NATIONALITY: "nationality",
  MCC_SINGAPORPR: "awsmsingaporepr",

  MYINFO_CODE: "code",
  MYINFO_STATE: "state",
  MYINFO_ERROR: "error",
  PAYNOW_CHECK_PASSED: "paynowCheck",

  //Hidden Field Code
  HIDDEN_FIELD: "hidden",
  PROCESS_MODE: "processMode",
  CLIENT_IP: "clientIPv4",
  TAB_ID: "tabID",
  PARTY_CODE: "partyCode",
  PRODUCT_CODE: "productCode",
  UTOKEN: "utoken",
  MYINFO_MODE: "myinfomode",

  //Partner Page
  ENTITY_TYPE: "type",
  ORDERID: "orderid", //URL params
  APPLIC_AMOUNT: "applicAmount",
  APPLIC_TENURE: "applicLoanTerm",
  APPLIC_INCOME: "mincome",
  APPLIC_PRODUCT: "product",
  CUSTOMER: "receivingacc", //for ewallet
  CALLBACK_URL: "callbackUrl",
  UID: 'uid', // for mcc
  PARTNER_TRANSACTION_ID: 'trxId', // for mcc
  MCC_LANDING_URL: 'mccLandingUrl',
  MONTHLY_INSTALLMENT: "monthlyinstallmentamount",
  TOTAL_REPAYMENT: "trlamount",
  CIMB_INTEREST_RATE: "cimbInterestRate",

  APPLY_NO: "applyNo", //Resume Application URL Params
  TOKEN: "token", //Resume Application URL Params
  FACEV_SCORE: "score",
  FACEV_FLAG: "facevFlag",

  NRIC: "personIDNo",
  PRINCIPAL_NAME: "customerFullName",
  EMAIL: "personEmail",

  PAYNOW_ACCOUNT_NAME: "payNowCreditorName",

  ADDRESS: "regaddr",
  ...REGADDR,
  POSTAL_CODE: "regadd_postal",

  MCC_POST_CODE: "awsmregadd_postal",
  MCC_BLOCK: "regadd_block",
  MCC_BUILDING: "regadd_building",
  MCC_STREET: "regadd_street",
  ADDRESS_FLOOR: "regadd_floor",
  ADDRESS_UNIT: "regadd_unit",

  COMPANY_POST_CODE: "awsmemppostal",
  COMPANY_BLOCK: "awsmempblock",
  COMPANY_BUILDING: "awsmempbuilding",
  COMPANY_STREET: "awsmempstreet",

  LENGTH_OF_EMPLOYMENT: "lenofemp",
  LENGTH_OF_EMPLOYMENT_YEAR: "lenofempy",
  LENGTH_OF_EMPLOYMENT_MONTH: "lenofempm",

  MCC_LENGTH_OF_EMPLOYMENT: "awsmlenofemp",
  MCC_LENGTH_OF_EMPLOYMENT_YEAR: "awsmlenofempy",
  MCC_LENGTH_OF_EMPLOYMENT_MONTH: "awsmlenofempm",

  MCC_LENGTH_OF_RESIDENCE: "awsmLenOfResYM",
  MCC_LENGTH_OF_RESIDENCE_YEAR: "awsmLenOfResYMy",
  MCC_LENGTH_OF_RESIDENCE_MONTH: "awsmLenOfResYMm",

  MCC_SECOND_CONTACT_TYPE: "awsmscontype",
  MCC_SECOND_CONTACT_NUMBER: "awsm2ndMobileNbr",
  MCC_SECOND_CONTACT_AREA_CODE: "awsm2ndAreaCode",
  MCC_MOBILE_AREA_CODE: "awsmMobileAreaCode",
  MCC_MOBILE_NO: "awsmMobileNbr",
  MCC_MARITAL_STATUS: "awsmcustomermaritalstatus",
  MCC_FAKE_MOBILE: "fakeMobileField",
  MCC_FAKE_SECONDARY_CONTACT: "fakeSecondaryContactField",

  //PDPA
  PDPA_CONSENT: "Declaration#1",
  PDPA_PHONE: "pdpaphone",
  PDPA_SMS: "pdpasms",

  // MCC PDPA
  MCC_PDPA_CONSENT: "PDPA",
  MCC_PDPA_PHONE: "awsmpdpaphone",
  MCC_PDPA_SMS: "awsmpdpasms",

  //FATCA
  FATCA_RESIDENCE: "isfatcaresidence",//to determine nstp flow if yes
  FATCA_REASON: "fatcaresidencetype",

  //MCC FATCA
  MCC_FATCA_RESIDENCE: "awsmisfatcaresidence",//to determine nstp flow if yes
  MCC_FATCA_REASON: "awsmfatcaresidencetype",

  //CRS
  COUNTRY: "countrytaxresidence1",
  HASTIN: "crshastax",
  TIN: "crstaxnumber",

  SIGNATURE: "esign",
  MCC_SIGNATURE: "awsmesign",

  ACKNOWLEDGEMENT: "ackpdpa",
  ACKNOWLEDGEMENT_EMAIL: "ackemail",
  ACKNOWLEDGEMENT_EMAIL_OPTION: "acknowledgeemail",
  ACKNOWLEDGE_PDPA_OPTION: "acknowledgepdpa",

  MCC_ACKNOWLEDGEMENT: "awsmackpdpa",
  MCC_ACKNOWLEDGEMENT_EMAIL: "awsmackemail",
  MCC_ACKNOWLEDGEMENT_EMAIL_OPTION: "awsmackemailOption",
  MCC_ACKNOWLEDGE_PDPA_OPTION: "awsmackpdpaOption",

  ACKNOWLEDGE_CPF_NOA: "cpfnoastmt",
  ACKNOWLEDGE_CPF_NOA_OPTION: "cpfnoastatement",


  PR_STATUS: "prstatus",

  APPROVED_AMOUNT: "approvedAmount",

  // Paynow
  PAYNOW_ID_TYPE: "paynowtype",
  PAYNOW_ID: "paynowid",

  // Partner Type
  EWALLET: "ewallet",
  MERCHANT: "merchant",
  CORPORATE: "corporate",
  MCC: 'mcc',

  //CPF
  CPF_HISTORY: "cpfcontributions",
  NOA: "noahistory", //Notice of Assessment

  // AWSM MCC CPF tab name
  MCC_CPF_HISTORY: "CPF Contributions",
  MCC_NOA: "NOA History", //Notice of Assessment
  MCC_DOCUMENT_UPLOAD: 'Non-MyInfo Upload',

  MYINFO_PROFILE: "Myinfo Profile",
  ABOUT_ME: "About Me",
  DOCUMENT_UPLOAD: "Document Upload",
  DOC_UPLOAD: "Doc Upload",
  DOC_UPLOAD_CHECKBOX: "Doc Upload Checkbox",

  DECISION: "decision",
  REASON: "reason",

  ROAD_SHOW: 'isRoadShow',
  AGENT_CODE: 'agentCode',
  ALIASNAME: "aliasname",

  MCC_ALIASNAME: "awsmaliasname",
  MCC_FULLNAME: "awsmfullname",
  MCC_EMAIL_ADDRESS: "awsmemailaddress",
  MCC_MOBILE_NUMBER: "personMobileNo",
  MCC_GENDER: "personGenderD",
  MCC_DOB: "personBirthdate",
  DOB: "personBirthdate",
  MCC_HYPY_NAME: "hanyupinyinname",
  RACE: "race",
  BIRTH_COUNTRY: "birthcountry",

  MCC_ADDR_TYPE: "awsmAddressType",
  MCC_ADDR_TYPE_LOCAL: "local",
  MCC_ADDR_TYPE_FOREIGN: "foreign",


  MCC_ADDR_STREET: "awsmregadd_street",
  MCC_ADDR_BUILDING: "awsmregadd_building",
  MCC_ADDR_FLOOR: "regadd_floor",
  MCC_ADDR_UNIT: "regadd_unit",
  MCC_ADDR_COUNTRY: "regadd_country",
  MCC_ADDR_PROPERTY_TYPE: "awsmpropertytype",
  MCC_ADDR_RESIDENT_STATUS: "awsmresidentialstatus",

  MCC_FOREIGN_ADDR_LINE1: "awsmForeignAddLine1",
  MCC_FOREIGN_ADDR_LINE2: "awsmForeignAddLine2",
  MCC_EMPLOY_STATUS: "awsmempstatus",
  MCC_EMPLOY_COMPANY_NAME: "awsmemployment",
  MCC_EMPLOY_BUSINESS_SECTOR: "awsmemploymentsector",
  MCC_EMPLOY_OCCUPATION: "awsmoccupation",
  MCC_GROSS_ANNUAL_INCOM: "awsmgrossannualinc_v3",
  MCC_NO_OF_DEPENDENTS: "awsmnoofdependents",

  MCC_BY_PASS_DC: "mccbypassdc",
  PAYSLIP: "payslip",
  LETTER_OF_ACCEPTANCE: "letterOfAcceptance",
  EMPLOY_STATUS: "empstatus",
  IS_FATCA_RESIDENCE: "isfatcaresidence",

  CPF_STATEMENT: "past12MonthsCPF",
  LATEST_YEAR_NOA: "latestYearNOA",
  PREVIOUS_YEAR_NOA: "previousYearNOA",
  PASSPORT_COPY: "passportCopy",
  EMPLOYMENT_PASS_COPY: "empPassCopy",
  LATEST_BILLING_PROOF: "latestBillingProof",
  FEN_FORM: "fen",

  IS_NRIC_BOTH_SIDE: "hasNricCopyBothSide",
  NRIC_FRONT_AND_BACK: "nricCopy",
  NRIC_FRONT: "nricCopyF",
  NRIC_BACK: "nricCopyB",

  IS_MY_NRIC_BOTH_SIDE: "hasMYNricCopyBothSide",
  MY_NRIC_FRONT_AND_BACK: "MYNricCopy",
  MY_NRIC_FRONT: "MYNricCopyF",
  MY_NRIC_BACK: "MYNricCopyB",

  W8_W9_FORM: "w8w9",

  ENCRYPTED_PARAM_KEY: "queryParams",
};

export const EMPLOYMENT_PASS_STATUS_VALUE = {
  LIVE: "Live",
  APPROVED: "Approved",
}

export const NRIC_COPY_BOTH_SIDE_VALUE = {
  YES: "yes",
}

export const MY_NRIC_COPY_BOTH_SIDE_VALUE = {
  YES: "yes",
}

export const NATIONALITY_VALUE = {
  AMERICAN: "US",
  AMERICAN_SAMOA: "AS",
}

export const BIRTH_COUNTRY_VALUE = {
  AMERICAN_SAMOA: "AS",
  UNITED_STATES: "US",
  US_VIRGIN_ISLANDS: "VI",
}

export const EMPLOY_STATUS_VALUE = {
  EMPLOYED: "EM1",
  SELF_EMPLOYED: "EM4",
  COMMISSION_EARNER: "EM5",
}

export const PR_STATUS_VALUE = {
  YEAR0: "YR00",
  YEAR1: "YR01",
  YEAR2: "YR02",
  YEAR3: "YR03",
}
  
export const MCC_EMPLOY_STATUS_VALUE = {
  EMPLOYED: 'E',
  SELF_EMPLOYED: 'S',
  COMMISSIOM: "C",
  NSF: "N",
  HOUSE_WIFE:"H",
  STUDENT: "ST",
  RETIREE: "R",
};
export const MCC_EMPLOY_DEFAULT_VALUE = {
  BUSINESS_SECTOR: '080', //Government Bodies
  OCCUPATION: '64', // Armed Personnel
};

export const MAP_COMPANY_ADDR = {
  regadd_block: CODE.COMPANY_BLOCK,
  regadd_building: CODE.COMPANY_BUILDING,
  regadd_postal: CODE.COMPANY_POST_CODE,
  regadd_street: CODE.COMPANY_STREET,
};

export const MAP_MY_ADDR = {
  regadd_block: CODE.MCC_BLOCK,
  regadd_building: CODE.MCC_BUILDING,
  regadd_postal: CODE.MCC_POST_CODE,
  regadd_street: CODE.MCC_STREET,
}

//insert fieldCode to this array if need to bypass mandatory field validation and hide for manual flow
export const BYPASS_VALIDATION = [CODE.ADDRESS]

export const READ_ONLY_FIELD = Object.values(REGADDR)


export const CPF_HISTORY_TABLE = ["For Month", "Paid On", "Amount", "Employer Contibution"]

export const PDPA_CONSENT_CONTENT = {
  NOTE: [
    "Please indicate your consent to the above, and preference for the mode of communication, by checking in the applicable box(s) above.",
    "In the event that no indication is made on the above. CIMB reserves its right to send Marketing Messages to your Singapore telephone number registered with CIMB (“ Registered Number”) if you have an ongoing relationship with CIMB or if the Registered Number is not registered with the National Do Not Call Registry."
  ]
}

export const TAB_CAPTION = [ //based on sequence: sequence is based on activeIndex
  // ploan myinfo hidden step1: My Details from
  //{
  //  TITLE: `
  //  <div style="display: flex">
  //    <div>My Details from</div>
  //    <div style="position: relative; margin-left: 5px">
  //      <img src="/singpass.svg" alt="singpass" style="position: absolute; top: 10px" />
  //    </div>
  //  </div>`,
  //  SUBTITLE: "Verify your details retrieved from Myinfo",
  //}, 
  {
    TITLE: "Getting To Know You",
    SUBTITLE: "<span style='color: #FF0000'>*</span>This field is required",
  },
  {
    TITLE: "Supporting Information",
  },
  {
    TITLE: 'Document Upload',
    SUBTITLE: `
    <div>
      <div>Upload the document(s) that are required to continue your application process.</div>
      <ul>
        <li>Only PDF, JPG, JPEG and PNG file formats are accepted</li>
        <li>Maximum size per file is 5MB</li>
      </ul>
    </div>`,
  },
];

export const CAPTION: ({
  NAME: string;
  TITLE: string;
  SUBTITLE?: string;
})[] = [ //NAME need to sync with SLP, TITLE and SUBTILTLE hardcoded in H5
  {
    NAME: "About Me",
    TITLE: "Myinfo Profile",
  },
  {
    NAME: "cpfcontributions",
    TITLE: "CPF Contribution",
  },
  {
    NAME: "noahistory",
    TITLE: "Notice of Assessment",
  },
  {
    NAME: "Personal Details#1", //need to sync with SLP 'name' attribute
    TITLE: "My Disbursement Account",
    SUBTITLE: "Upon approval, we will disburse the loan to your bank account linked to your PayNow, please ensure the information provided is correct.",
  },
  {
    NAME: "Personal Details#2",
    TITLE: "About Me",
  },
  {
    NAME: "Personal Details#3",
    TITLE: "My Address Details",
    SUBTITLE: "Any paper correspondences for this product will be sent to your residential address"
  },
  {
    NAME: "Personal Details#4",
    TITLE: "My Employment Details",
  },
  {
    NAME: "Declaration#1",
    TITLE: "Personal Data Protection Act",
  },
  {
    NAME: "Declaration#2",
    TITLE: "Foreign Account Tax Compliance Act (FATCA)",
  },
  {
    NAME: "Declaration#3",
    TITLE: "Common Reporting Standard (CRS) Self-Certification",
  },
];

export const CAPTION_SUMMARY = [
  {
    NAME: "Application Info",
    TITLE: "My Estimated Repayment",
  },
  { //need to be removed
    // NAME: "Myinfo Profile",
    NAME: "About Me",
    TITLE: "Myinfo Profile",
  },
  ...CAPTION,
  {
    NAME: CODE.DOC_UPLOAD_CHECKBOX,
    TITLE: CODE.DOC_UPLOAD_CHECKBOX,
  },
  {
    NAME: CODE.DOC_UPLOAD,
    TITLE: CODE.DOC_UPLOAD,
  }
];

export const MCC_TAB_CAPTION = [ //based on sequence: sequence is based on activeIndex
  {
    TITLE: "Getting To Know You",
    SUBTITLE: "<span style='color: #FF0000'>*</span>This field is required",
  },
  {
    TITLE: 'Document Upload',
    SUBTITLE: `
    <div>
      <div>Upload the document(s) that are required to continue your application process.</div>
      <ul>
        <li>Only PDF, JPG, JPEG and PNG file formats are accepted</li>
        <li>Maximum size per file is 500KB</li>
      </ul>
    </div>`,
  },
  {
    TITLE: "Supporting Information",
  },
  {
    TITLE: "Application for EZ-Link Micro Credit",
  },
];

export const MCC_CAPTION: ({
  NAME: string;
  TITLE: string;
  SUBTITLE?: string;
})[] = [ //NAME need to sync with SLP, TITLE and SUBTILTLE hardcoded in H5
  {
    NAME: "About Me", // form response name fixme
    TITLE: "About Me",
  },
  {
    NAME: "Address Details",
    TITLE: "My Address Details",
    SUBTITLE: "Note: Your existing credit card(s) with the bank (if any) will be updated to this address upon application approval."
  },
  {
    NAME: "Employment Details",
    TITLE: "My Employment Details",
  },
  {
    NAME: "PDPA",
    TITLE: "Personal Data Protection Act",
  },
  {
    NAME: "FATCA",
    TITLE: "Foreign Account Tax Compliance Act (FATCA)",
  },
];

export const MCC_CAPTION_SUMMARY = [
  ...MCC_CAPTION,
  {
    NAME: "Non-MyInfo Upload",
    TITLE: "Document Upload",
  },
  {
    NAME: "CPF Contributions",
    TITLE: "CPF Contribution",
    SUBTITLE: "",
  },
  {
    NAME: "NOA History",
    TITLE: "Notice of Assessment",
  }
];

export const MCC_CAPTION_SUMMARY_MANUAL = [
  ...MCC_CAPTION,
  {
    NAME: "Non-MyInfo Upload",
    TITLE: "Document Upload",
  },
];

export const AIP_SECRET_KEY = "$ecr3tK3y";

export const UNAUTHORIZED = 404;
export const BE_CODE_STATUS = {
  SUCCESS: "000000",
  SYSTEM_ERROR: "999999",
  CONNECTIVITY_ERROR: "999998",
  UNAUTHORIZED: "999997",
  OFFER_EXPIRED: "999996",
  UNDER_MAINTENANCE: "999995",
  RETRIGGER_AIP: "999994",
  AIP_EXPIRED: "999993",
  AIP_INVALID_NRIC: "999992",
  PENDING_ORDER: "999991",
  ONE_MAP_ERROR: "999990",
  FAILED: "failed",
  ROAD_SHOW_INVALID: '900006',
  UPLOAD_FILES_INVALID: '900007',
  PAYNOW_SHOW_MESSAGE: '900008',
  DUPLICATE_SUBMISSION: '900009',
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  LENDELA_SERVICE_UNAVAILABLE: 500,
  SERVICE_UNAVAILABLE: 503
}

export const FACEV_RETRY_TIMES = "fv_retry_times"

export const SMART_FILL_FILE_SIZE = 5 * 1024 * 1024;
export const SMART_FILL_ACCEPT_FILE_FORMAT = [
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const RESUME_TYPES = {
  DOC_UPLOAD: "Smart_Fill",
  AIP: "IPA",
};