import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CODE, DEFAULT_VALUE, REDIRECT_COUNT_DOWN, BE_CODE_STATUS } from "../../../utilities/constants";
import { CHLOverlayLoading, CHLButton, CHLCustomerService } from "../../../components"
import { BUTTON_LABEL, ROAD_SHOW_LABLE } from "../../../utilities/label";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { ReactComponent as FailIcon } from "../../../assets/images/fail.svg";
import { ReactComponent as OctopusSuccess } from "../../../assets/images/oct_success.svg";
import { ReactComponent as OctopusFail } from "../../../assets/images/oct_fail.svg";
import { useLocation, useHistory } from "react-router-dom";
import { RoadShowRequest } from "../../../services/requests/RoadShowRequest";

function RoadShow() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);
    let [decision, setDecision] = useState<string>(DEFAULT_VALUE.NO);
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get(CODE.TOKEN);
    const callbackUrl = queryParams.get(CODE.CALLBACK_URL);


    useEffect(() => {
        if (token) {
            submitUserResponse(token);
        } else {
            history.push({
                pathname: "/403"
            });
        }
    }, []);

    const submitUserResponse = async (token: string) => {
        try {
            let res: any = await RoadShowRequest.submitResponse(token);
            if (res?.data?.customerDecision) {
                setDecision(res.data.customerDecision);
            } else if (res?.code === BE_CODE_STATUS.ROAD_SHOW_INVALID) {
                setDecision(DEFAULT_VALUE.INVALID);
            }
            setIsLoading(false);
            const countDownTimer = setInterval(() => {
                timer--;
                setTimer(timer)
                if (timer === 0) {
                    redirectToMerchant();
                }
            }, 1000)
            return () => {
                clearInterval(countDownTimer);
                setTimer(REDIRECT_COUNT_DOWN);
            }
        } catch (err) {
            history.push({
                pathname: "/403"
            });
        }
    }

    const redirectToMerchant = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = callbackUrl as string;
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" maxWidth={800}>
                <CHLOverlayLoading />
            </Box>
        );
    } else {
        return (
            <Box mt={8} maxWidth={800} margin="auto">
                <Box mt={10}>
                    <>
                        <Typography align="center" variant="h5" component="div">
                            <Box
                                fontWeight="bold"
                                color={
                                    decision === DEFAULT_VALUE.YES ? "#16C0B7" : "#FF0000"
                                }>
                                {ROAD_SHOW_LABLE[decision].title}
                            </Box>
                        </Typography>
                        <Box display="flex" justifyContent="center" my={3}>
                            {decision === DEFAULT_VALUE.YES ?
                                <SuccessIcon /> : <FailIcon />
                            }
                        </Box>
                        <Typography align="center" component="div">
                            <Box fontWeight="bold">
                                {ROAD_SHOW_LABLE[decision].header}
                            </Box>
                            <Box my={3}>
                                {ROAD_SHOW_LABLE[decision].message}
                            </Box>
                        </Typography>

                        <Box display="flex" justifyContent="center" my={5}>
                            {decision === DEFAULT_VALUE.YES ?
                                <OctopusSuccess /> : <OctopusFail />
                            }
                        </Box>
                        <Box my={5} textAlign="center">
                            <CHLCustomerService />
                        </Box>
                    </>
                    <Box my={2} display="flex" justifyContent="center">
                        <CHLButton
                            title={BUTTON_LABEL.backToHome + " (" + timer + "s)"}
                            color="secondary"
                            onClick={() => redirectToMerchant()}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default RoadShow;