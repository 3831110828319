import { FC, useContext, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RespAddress, RespField, RespAppField, RespAppOptionField } from "../../../../types/response";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { CHLCheckbox, CHLValueField } from "../../../../components";
import { getComponentAttributes, getApplicantType } from "../../../../utilities/helper";
import { ApplicationFormContext } from "./ApplicationForm";
import {
  CODE,
  DEFAULT_VALUE,
  PR_STATUS_VALUE,
  EMPLOY_STATUS_VALUE,
  NRIC_COPY_BOTH_SIDE_VALUE,
  MY_NRIC_COPY_BOTH_SIDE_VALUE,
} from "../../../../utilities/constants";
import { CODE_LABEL } from "../../../../utilities/label";
import { TOOLTIPS } from "../../../../utilities/tooltips";
import WatchedComponent from "./WatchedComponent";
import { Grid, InputAdornment, makeStyles } from "@material-ui/core";
import { ReactComponent as PaynowIcon } from "../../../../assets/images/paynow.svg";
import { ApplicationFormSummaryContext } from "./ApplicationFormSummary";
import { ApplicationSummaryContext } from "./ApplicationSummary";
import { RootState } from "../../../../stores/rootReducer";
import { ApplicationActions } from "../../../../stores/reducers/application";

type Field = Pick<RespAppField, "field">;
interface IApplicationController extends Field {
  length: number;
  required: boolean;
  name: string;
  controllerCode?: string;
  isSummary?: boolean;
  onCRSCountryChange?:(val: string, code: string, onChange: any, setValue: any) => void,
}

const ApplicationController: FC<IApplicationController> = (props) => {
  const store = useStore();
  const {
    isAmericanFlag,
    uploadRelated,
    parents,
    names,
    summaries,
    summariesNames,
    ploanParents,
    ploanNames,
    ploanNoUploadStepParents,
    ploanNoUploadStepNames,
    ploanSummaries,
    ploanSummariesNames,
    ploanNoUploadStepSummaries,
    ploanNoUploadStepSummariesNames,
  } = useSelector((s: RootState) => s.application);
  const { isManualFlow } = useSelector((s: RootState) => s.partner)
  const {
    isSGPR,
    isMalayForeigner,
    isOtherForeigner,
    empstatus,
    prstatus,
    employedMonths,
  } = uploadRelated;

  const { length, required, name, controllerCode, isSummary, field, onCRSCountryChange } = props;
  let fieldRequired = required;
  const { code, fieldType, render, dataType, maxLength } = field;
  let { options = [] } = field;
  const context = useContext(
    // isSummary ? ApplicationFormSummaryContext : ApplicationFormContext
    isSummary ? ApplicationSummaryContext : ApplicationFormContext
  );
  let { errors } = useFormState({ control: context?.control });
  const { activeIndex, defaultValues, getValues, setValue, placeholders, register } = context || {};
  const classes = useStyles();
  const dispatch = useDispatch();
  const myInfo = store.getState().myinfo.data;

  // useEffect(() => {

  //   const isPayNowTypeField = code === CODE.PAYNOW_ID_TYPE;  
  //   if (isPayNowTypeField) {
  //     console.log(context?.nric)
  //     // setValue(CODE.PAYNOW_ID, context?.nric)
  //     console.log(getValues(CODE.PAYNOW_ID))
  //     // onChangeHandler()(getValues(CODE.PAYNOW_ID_TYPE));
  //     // onChangeHandler()("NRIC");
  //   }
  // }, []);

  const v3 = useWatch({ control: context?.control, name: CODE.PAYNOW_ID_TYPE });
  let forcePaynowField = v3 === DEFAULT_VALUE.PAYNOW_NRIC ? true : false;

  let defaultValue: any;
  let error: any;
  const ids = controllerCode?.split(".");
  if (ids) {
    error = ids.reduce(
      (prevValue, newValue) => prevValue && prevValue[newValue],
      errors
    );
    defaultValue = ids.reduce(
      (prevValue, newValue) => prevValue && prevValue[newValue],
      defaultValues
    );
  } else {
    defaultValue = defaultValues[code];
    error = errors[code];
  }
  
  // hardCoded
  const isHasTinField = field && field.code === CODE.HASTIN;
  let hasTinDependName: any = "";
  let hidden: boolean = false;
  let disabled: boolean = false;

  if (isHasTinField && controllerCode) {
    const prefix = controllerCode.replace(/\.[^.]*$/, "");
    hasTinDependName = `${prefix}.${CODE.COUNTRY}`;
  }
  const v1 = useWatch({ control: context?.control, name: hasTinDependName });
  if (isHasTinField && controllerCode && v1 === DEFAULT_VALUE.COUNTRY_SINGAPORE)
    hidden = true;
  // ================================================ //
  const isTinField = field && field.code === CODE.TIN;
  let tinDependNames: any = "";
  let forceValueField = false;

  if (isTinField && controllerCode) {
    const prefix = controllerCode.replace(/\.[^.]*$/, "");
    tinDependNames = [`${prefix}.${CODE.COUNTRY}`, `${prefix}.${CODE.HASTIN}`];
  }
  const v2 = useWatch({ control: context?.control, name: tinDependNames });
  if (isTinField && controllerCode) {
    if (
      JSON.stringify(v2) ===
      JSON.stringify([DEFAULT_VALUE.COUNTRY_SINGAPORE, DEFAULT_VALUE.HASTIN_YES])
    )
      forceValueField = true;
    else forceValueField = false;
  }

  const isPayslipField = code === CODE.PAYSLIP;
  if (!isManualFlow && isPayslipField) {
    if (
      (empstatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
        empstatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
      (!isSGPR ||
        (isSGPR &&
          (prstatus === PR_STATUS_VALUE.YEAR1 ||
            prstatus === PR_STATUS_VALUE.YEAR2 ||
            employedMonths < 3)))
    ) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }
  if (isManualFlow && isPayslipField) {
    if (
      empstatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
      empstatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER
    ) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }

  const isLetterField = code === CODE.LETTER_OF_ACCEPTANCE;
  if (isLetterField) {
    if (
      (empstatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
        empstatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
      employedMonths < 3
    ) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }

  const isPRField = code === CODE.PR_STATUS;
  if(isPRField){
    const nationality = getValues(CODE.MCC_NATIONALITY) || myInfo.nationality;
    const nric = getValues(CODE.NRIC) || myInfo.personIDNo;
    if(nric && ["S", "s", "T", "t"].includes(nric[0])){
      fieldRequired = true;
      if(nationality === DEFAULT_VALUE.COUNTRY_SINGAPORE){
        disabled = true;
      }else{
        disabled = false;
        options = options.filter(o=> o.code !== PR_STATUS_VALUE.YEAR0);
      }
    }else{
      hidden = true;
    }
  }

  const isAmericanField = code === CODE.IS_FATCA_RESIDENCE;
  if(isAmericanFlag && isAmericanField && getValues(CODE.IS_FATCA_RESIDENCE) == DEFAULT_VALUE.FATCA_RESIDENCE_YES){
    disabled = true;
  }
  
  const isMalaysiaIDNoField = code === CODE.MALAYSIA_NRIC;
  const personIDNo = useWatch({ control: context?.control, name: CODE.NRIC });
  const nationality = useWatch({ control: context?.control, name: CODE.NATIONALITY });
  if(isMalaysiaIDNoField){
    let nric, nation;
    if(!isManualFlow){
      nric = myInfo.personIDNo;
      nation = myInfo.nationality;  
    }else{
      nric = personIDNo;
      nation = nationality;
    }
    if(nric && nation){
      const { isMalayForeigner } = getApplicantType(nric, nation);
      if(isMalayForeigner){
        hidden = false;
        fieldRequired = true;
      }else{
        hidden = true;
      }
    }else{
      hidden = true;
    }
  }

  const isPassportNoField = code === CODE.PASSPORT_NUMBER;
  const isPassportExpiryField = code === CODE.PASSPORT_EXPIRY_DATE;
  const isPassTypeField = code === CODE.EMPLOYMENT_PASS_TYPE;
  const isPassStatusField = code === CODE.EMPLOYMENT_PASS_STATUS;
  const isPassExpiryField = code === CODE.EMPLOYMENT_PASS_EXPIRY_DATE;
  if (
    isPassportNoField ||
    isPassportExpiryField ||
    isPassTypeField ||
    isPassStatusField ||
    isPassExpiryField
  ) {
    if(!isManualFlow){
      let nric, nation;
      if(!isManualFlow){
        nric = myInfo.personIDNo;
        nation = myInfo.nationality;  
      }else{
        nric = personIDNo;
        nation = nationality;
      }
      if(nric && nation){
        const { isSGPR } = getApplicantType(nric, nation);
        if(!isSGPR){
          hidden = false;
          fieldRequired = true;
        }else{
          hidden = true;
        }
      }else{
        hidden = true;
      }
    }
  }

  // const isCPFField = code === CODE.CPF_STATEMENT;
  // if (isCPFField) {
  //   if (
  //     !isManualFlow ||
  //     (isManualFlow && empstatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED)
  //   ) {
  //     hidden = true;
  //   }
  // }

  const isLatestNOAField = code === CODE.LATEST_YEAR_NOA;
  if (isLatestNOAField) {
    if (isManualFlow && empstatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED) {
      fieldRequired = true;
    }else{
      hidden = true;
    }
  }

  const isPrevNOAField = code === CODE.PREVIOUS_YEAR_NOA;
  if (isPrevNOAField) {
    if (isManualFlow && empstatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED) {
      fieldRequired = true;
    }else{
      hidden = true;
    }
  }

  const isPassportCopyField = code === CODE.PASSPORT_COPY;
  if (isPassportCopyField) {
    if (!isSGPR) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }

  const isEmploymentPassCopyField = code === CODE.EMPLOYMENT_PASS_COPY;
  if (isEmploymentPassCopyField) {
    if (isManualFlow && (isMalayForeigner || isOtherForeigner)) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }

  const isLatestBillingProofField = code === CODE.LATEST_BILLING_PROOF;
  if (isLatestBillingProofField) {
    if ((isManualFlow && isMalayForeigner) || isOtherForeigner) {
      fieldRequired = true;
    }
    if (isSGPR || (!isManualFlow && isMalayForeigner)) {
      hidden = true;
    }
  }

  const isFENField = code === CODE.FEN_FORM;
  if(isFENField){
    if (isMalayForeigner) {
      fieldRequired = true;
    } else {
      hidden = true;
    }
  }

const nricBothSideVal = useWatch({ control: context?.control, name: CODE.IS_NRIC_BOTH_SIDE });
const isNRICBothSideField = code === CODE.IS_NRIC_BOTH_SIDE;
const isNRICFrontAndBackField = code === CODE.NRIC_FRONT_AND_BACK;
const isNRICFrontField = code === CODE.NRIC_FRONT;
const isNRICBackField = code === CODE.NRIC_BACK;
if(isNRICBothSideField){
  if(!isManualFlow || !isSGPR){
    hidden = true;
  }
}
if(isNRICFrontAndBackField){
  if(nricBothSideVal === NRIC_COPY_BOTH_SIDE_VALUE.YES){
    fieldRequired = true;
  }else{
    hidden = true;
  }
}

if(isNRICFrontField || isNRICBackField){
  if(isManualFlow && isSGPR && nricBothSideVal !== NRIC_COPY_BOTH_SIDE_VALUE.YES){
    fieldRequired = true;
  }else{
    hidden = true;
  }
}

const myNRICBothSideVal = useWatch({ control: context?.control, name: CODE.IS_MY_NRIC_BOTH_SIDE });
const isMYNRICBothSideField = code === CODE.IS_MY_NRIC_BOTH_SIDE;
const isMYNRICFrontAndBackField = code === CODE.MY_NRIC_FRONT_AND_BACK;
const isMYNRICFrontField = code === CODE.MY_NRIC_FRONT;
const isMYNRICBackField = code === CODE.MY_NRIC_BACK;
if(isMYNRICBothSideField){
  if(!isMalayForeigner){
    hidden = true;
  }
}

if(isMYNRICFrontAndBackField){
  if(isMalayForeigner && myNRICBothSideVal === MY_NRIC_COPY_BOTH_SIDE_VALUE.YES){
    fieldRequired = true;
  }else{
    hidden = true;
  }
}

if(isMYNRICFrontField || isMYNRICBackField){
  if(isMalayForeigner && myNRICBothSideVal !== MY_NRIC_COPY_BOTH_SIDE_VALUE.YES){
    fieldRequired = true;
  }else{
    hidden = true;
  }
}

  
  // end hardCoded

  const getMonthsNum = (str: any) => {
    let num = -1;
    if (str && str !== "-") {
      const ymArr = str.split("-");
      num = Number(ymArr[0]) * 12 + Number(ymArr[1]);
    }
    return num;
  };

  const handleHiddenDocUploadStep = () => {
    dispatch(ApplicationActions.setIsPloanHasUploadStep(false));
    dispatch(ApplicationActions.setParents([...ploanNoUploadStepParents]));
    dispatch(ApplicationActions.setNames([...ploanNoUploadStepNames]));
    dispatch(ApplicationActions.setSummaries([...ploanNoUploadStepSummaries]));
    dispatch(
      ApplicationActions.setSummariesNames([...ploanNoUploadStepSummariesNames])
    );
  };

  const handleDisplayDocUploadStep = () => {
    dispatch(ApplicationActions.setIsPloanHasUploadStep(true));
    dispatch(ApplicationActions.setParents([...ploanParents]));
    dispatch(ApplicationActions.setNames([...ploanNames]));
    dispatch(ApplicationActions.setSummaries([...ploanSummaries]));
    dispatch(ApplicationActions.setSummariesNames([...ploanSummariesNames]));
  };

  // Method
  const onChangeHandler = (onChange?: any) => async(e?: any) => {
    const isCountryField = code === CODE.COUNTRY;
    const isPayNowTypeField = code === CODE.PAYNOW_ID_TYPE;
    const isEmployLengthField = code === CODE.LENGTH_OF_EMPLOYMENT;
    const isEmploymentStatusField = code === CODE.EMPLOY_STATUS;
    const isFATCAResidenceField = code === CODE.IS_FATCA_RESIDENCE;
    const isMYNRICBothSideField = code === CODE.IS_MY_NRIC_BOTH_SIDE;
    const isPRField = code === CODE.PR_STATUS;

    let newValue = e?.target?.value ?? e;

    if (isCountryField && controllerCode) {
      const prefix = controllerCode.replace(/\.[^.]*$/, "");
      if (newValue === DEFAULT_VALUE.COUNTRY_SINGAPORE) {
        setValue && setValue(`${prefix}.${CODE.HASTIN}`, DEFAULT_VALUE.HASTIN_YES);
        setValue && setValue(`${prefix}.${CODE.TIN}`, context?.nric);
      } else {
        const prevValue = getValues(controllerCode);
        if (prevValue === DEFAULT_VALUE.COUNTRY_SINGAPORE)
          setValue && setValue(`${prefix}.${CODE.TIN}`, null);
      }
      onCRSCountryChange && onCRSCountryChange(newValue, prefix , onChange, setValue)
    } 
    else if (isPayNowTypeField) {
      
      if(getValues(CODE.PAYNOW_ID) == null){
        setValue && setValue(CODE.PAYNOW_ID, context?.nric);
      }else{
        if(newValue === DEFAULT_VALUE.PAYNOW_MOBILE){
          const idValue = getValues(CODE.PAYNOW_ID) === context?.nric ? null :  getValues(CODE.PAYNOW_ID)
          setValue && setValue(CODE.PAYNOW_ID, idValue);
        }else{
          setValue && setValue(CODE.PAYNOW_ID, context?.nric);
        }
      }
    }

    if (!isManualFlow && isEmploymentStatusField) {
      const prevValue = getValues(CODE.EMPLOY_STATUS);
      const employmentStatus = newValue;

      if (employmentStatus !== prevValue) {
        const nric = myInfo.personIDNo;
        const nationality = myInfo.nationality;
        const { isSGPR } = getApplicantType(nric, nationality);

        const lengthOfEmployment = getMonthsNum(
          getValues(CODE.LENGTH_OF_EMPLOYMENT)
        );
        const prStatus = getValues(CODE.PR_STATUS);

        if (
          isSGPR &&
          (employmentStatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED ||
            ((employmentStatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
              employmentStatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
              lengthOfEmployment >= 3 &&
              (prStatus === PR_STATUS_VALUE.YEAR0 ||
                prStatus === PR_STATUS_VALUE.YEAR3)))
        ) {
          handleHiddenDocUploadStep();
        } else {
          handleDisplayDocUploadStep();
        }
      }
    }

    if (!isManualFlow && isEmployLengthField) {
      const prevValue = getValues(CODE.LENGTH_OF_EMPLOYMENT);
      const employedMonths = newValue;

      if (employedMonths !== prevValue) {
        const lengthOfEmployment = getMonthsNum(employedMonths);

        const nric = myInfo.personIDNo;
        const nationality = myInfo.nationality;
        const { isSGPR } = getApplicantType(nric, nationality);

        const employmentStatus = getValues(CODE.EMPLOY_STATUS);
        const prStatus = getValues(CODE.PR_STATUS);

        if (
          isSGPR &&
          (employmentStatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED ||
            ((employmentStatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
              employmentStatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
              lengthOfEmployment >= 3 &&
              (prStatus === PR_STATUS_VALUE.YEAR0 ||
                prStatus === PR_STATUS_VALUE.YEAR3)))
        ) {
          handleHiddenDocUploadStep();
        } else {
          handleDisplayDocUploadStep();
        }
      }
    }

    if (!isManualFlow && isPRField) {
      const prevValue = getValues(CODE.PR_STATUS);
      const prStatus = newValue;
      if (prStatus !== prevValue) {
        const nric = myInfo.personIDNo;
        const nationality = myInfo.nationality;
        const { isSGPR } = getApplicantType(nric, nationality);

        const lengthOfEmployment = getMonthsNum(
          getValues(CODE.LENGTH_OF_EMPLOYMENT)
        );
        const employmentStatus = getValues(CODE.EMPLOY_STATUS);

        if (
          isSGPR &&
          (employmentStatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED ||
            ((employmentStatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
              employmentStatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
              lengthOfEmployment >= 3 &&
              (prStatus === PR_STATUS_VALUE.YEAR0 ||
                prStatus === PR_STATUS_VALUE.YEAR3)))
        ) {
          handleHiddenDocUploadStep();
        } else {
          handleDisplayDocUploadStep();
        }
      }
    }
    
    if (isMYNRICBothSideField) {
      if (newValue === MY_NRIC_COPY_BOTH_SIDE_VALUE.YES) {
        setValue && setValue(CODE.MY_NRIC_FRONT, null);
        dispatch(ApplicationActions.removeObsFileMapByCode(CODE.MY_NRIC_FRONT));
        dispatch(ApplicationActions.removeDocumentByCode(CODE.MY_NRIC_FRONT));
        setValue && setValue(CODE.MY_NRIC_BACK, null);
        dispatch(ApplicationActions.removeObsFileMapByCode(CODE.MY_NRIC_BACK));
        dispatch(ApplicationActions.removeDocumentByCode(CODE.MY_NRIC_BACK));
      } else {
        setValue && setValue(CODE.MY_NRIC_FRONT_AND_BACK, null);
        dispatch(ApplicationActions.removeObsFileMapByCode(CODE.MY_NRIC_FRONT_AND_BACK));
        dispatch(ApplicationActions.removeDocumentByCode(CODE.MY_NRIC_FRONT_AND_BACK));
      }
    }

    if(isFATCAResidenceField && newValue === DEFAULT_VALUE.FATCA_RESIDENCE_NO){
      dispatch(ApplicationActions.removeObsFileMapByCode(CODE.W8_W9_FORM));
      dispatch(ApplicationActions.removeDocumentByCode(CODE.W8_W9_FORM));
      setValue && setValue(CODE.W8_W9_FORM, null);
    }

    if(!required){
      if(newValue == "") newValue = null
    }

    if(!isCountryField){
      onChange && onChange(newValue);
      setValue && setValue(code, newValue)
      
    } 
  };  


  // Template
  return (
    <Controller
      name={controllerCode ?? code}
      control={context?.control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState: { isDirty } }) => {


        const placeholder = placeholders ?
          code === CODE.LENGTH_OF_EMPLOYMENT ? {
            [CODE.LENGTH_OF_EMPLOYMENT_MONTH]: placeholders[CODE.LENGTH_OF_EMPLOYMENT_MONTH],
            [CODE.LENGTH_OF_EMPLOYMENT_YEAR]: placeholders[CODE.LENGTH_OF_EMPLOYMENT_YEAR]
          } :
          placeholders[code] : undefined

        const hasValue = !!value && !isDirty;
        const hasMyInfo = Object.keys(myInfo).some((k) => k === code); 
        const { component, componentOption, props } = getComponentAttributes({
          activeIndex,
          code,
          type: fieldType,
          options,
          hasValue,
          dataType,
          value,
          hasMyInfo,
          placeholder,
          maxLength,
        });

        // console.log(getValues(CODE.PAYNOW_NRIC))

        let Component: any
        const ComponentOption = componentOption;
        let componentProps: any
        if(code === CODE.PAYNOW_ID){
          Component = forcePaynowField ? CHLValueField : component;

          const adorment = forcePaynowField ? 
          {labelRight: <PaynowIcon />} : {InputProps: { endAdornment: <PaynowIcon />, startAdornment: (
            <InputAdornment position="start">+</InputAdornment>
          ), }}
          componentProps = { ...props, ...adorment, placeholder: 'e.g. 6512345678'} || {};
        }else{
          Component = forceValueField ? CHLValueField : component;
          componentProps = { ...props, description: TOOLTIPS[code] } || {};
        }
        if((isPRField || isAmericanField) && disabled){
          componentProps = {...componentProps, disabled: true }
        }

        // Grid settings
        let gridAuto: any = { xs: 12, sm: 6 };
        if (length <= 1) gridAuto = { xs: true };
        // if (code === CODE.POSTALCODE) gridAuto = { xs: 12, sm: 3 };
        if (Component === CHLCheckbox) gridAuto = { xs: 12 };

        return (
          <>
            <WatchedComponent
              ref={ref}
              control={context?.control}
              controllercode={controllerCode}
              render={render}
              field={field}
              isDirty={isDirty}
              currentValue={value}
              defaultValue={defaultValue}
              onChange={onChange}>
              <Grid
                item
                {...gridAuto}
                className={hidden ? classes.hidden : undefined}>
                <Component
                  {...register(code)}
                  ref={ref}
                  code={code}
                  label={CODE_LABEL[code] ?? name}
                  required={fieldRequired}
                  // items={options && options.map((option) => ({
                  //   text: option.name,
                  //   value: option.code,
                  // }))}
                  items={options && options}
                  value={value ?? ''}
                  error={error}
                  helperText={error?.message}
                  // helperText={errors[code]?.message}
                  onChange={onChangeHandler(onChange)}
                  onBlur={onBlur}
                  {...componentProps}
                >
                  {options && options.map((option, i) => (
                    <WatchedComponent
                      key={option.code}
                      control={context?.control}
                      controllercode={controllerCode}
                      // render={option.render}
                      text={CODE_LABEL[option.code] ?? option.name}
                      value={option.code}
                      component={ComponentOption}
                      index={i}
                    />
                  ))}
                </Component>
              </Grid>
            </WatchedComponent>

            {/* {(code === CODE.POSTALCODE && getValues(CODE.MAILING_ADDRESS) == CODE.MAILING_ADDRESS_YES) && (
              <>
                <Grid item xs={12} sm={3}>
                  <ButtonGeolocation
                    postalCode={getValues(CODE.POSTALCODE)}
                    onClick={setAddressInfo}
                  />
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={6} />
                </Hidden>
              </>
            )} */}
          </>
        );
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  hidden: {
    display: "none",
  },
}));

export default ApplicationController;
