import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  isLoading: false,
  myInfoError: false,
  errorType: '',
};

const myinfoSlice = createSlice({
  name: "myinfo",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setMyInfoError: (state, { payload }: PayloadAction<boolean>) => {
      state.myInfoError = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload
    },
    setErrorType: (state, { payload }) => {
      state.errorType = payload
    },
  },
});

const { actions, reducer } = myinfoSlice;

export const MyInfoActions = actions;
export default reducer;
