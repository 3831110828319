import CallbackPage from "../pages/Public/Callback";
import CallbackHomePage from "../pages/Public/Callback/CallbackHomePage";
import CallbackCompletePage from "../pages/Public/Callback/CallbackCompletePage";
import PartnerPage from "../pages/Public/PartnerPage";
import ResumeApplication from "../pages/Public/ResumeApplication";
import ResumeApplicationComplete from "../pages/Public/ResumeApplication/ResumeApplicationComplete";
import SingpassLogin from "../pages/Public/ResumeApplication/SingpassLogin";
import MASLanding from "../pages/Public/ResumeApplication/MASLanding";

import MCCPartnerPage from "../pages/Public/MCCPartner";
import MCCCallbackPage from "../pages/Public/MCCCallback";
import MCCCallbackHomePage from "../pages/Public/MCCCallback/CallbackHomePage";
import MCCCallbackCompletePage from "../pages/Public/MCCCallback/CallbackCompletePage";

import Forbidden from "../pages/Forbidden"
import MockPage from "../pages/MockRedirect";
import { IRoute } from "../types/common";
//import FaceVerification from "../pages/Public/FaceVerificationPage";
import RoadShow from "../pages/Public/RoadShow";
import UploadCheck from "../pages/Public/UploadDocument";
import UploadLanding from "../pages/Public/UploadDocument/UploadLanding";
import UploadComplete from "../pages/Public/UploadDocument/UploadComplete";
import LendelaComponent from "../pages/Public/Lendela";

const publicRoutes: IRoute[] = [
  {
    name: "forbidden",
    path: "/403",
    component: Forbidden,
  },
  // {
  //   name: "testAPI",
  //   path: "/testip",
  //   component: TestAPI,
  // },
  {
    name: "partner",
    path: "/partner/:id",
    component: PartnerPage,
  },
  {
    name: "callback",
    path: "/callback",
    exact: false,
    component: CallbackPage,
    children: [
      {
        name: "callback-home",
        path: "/callback",
        component: CallbackHomePage,
      },
      {
        name: "callback-complete",
        path: "/callback/complete",
        component: CallbackCompletePage,
      },

    ],
  },
  {
    name: "check",
    path: "/check",
    exact: false,
    component: ResumeApplication,
  },
  {
    name: "inPrincipalApprovalCheck",
    path: "/resume/check/:id?",
    exact: false,
    component: MASLanding,
  },
  {
    name: "resumeApplicationComplete",
    path: "/resume/complete",
    exact: false,
    component: ResumeApplicationComplete,
  },
  {
    name: "singpassLogin",
    path: "/qrLogin",
    exact: false,
    component: SingpassLogin,
  },
  {
    name: "roadShow",
    path: "/roadShow",
    exact: false,
    component: RoadShow,
  },
  {
    name: "mccCallback",
    path: "/mcc/callback",
    exact: false,
    component: MCCCallbackPage,
    children: [
      {
        name: "mccCallbackHome",
        path: "/mcc/callback",
        component: MCCCallbackHomePage,
      },
      {
        name: "mccCallbackComplete",
        path: "/mcc/callback/complete",
        component: MCCCallbackCompletePage,
      },
    ],
  },
  {
    name: "mccPartner",
    path: "/mcc/:id",
    exact: true,
    component: MCCPartnerPage,
  },
  {
    name: "Document Upload Landing",
    path: "/upload/check/:id?",
    exact: false,
    component: UploadCheck,
  },
  {
    name: "Document Upload",
    path: "/upload/document",
    exact: false,
    component: UploadLanding,
  },
  {
    name: "Document Upload Completed",
    path: "/upload/complete",
    exact: false,
    component: UploadComplete,
  },
  // {
  //   name: "faceVerification",
  //   path: "/faceV",
  //   exact: false,
  //   component: FaceVerification
  //},
  {
    name: "Lendela iframe page",
    path: "/partnerAIPResume",
    exact: false,
    component: LendelaComponent,
  },
  {
    name: "Lendela2",
    path: "/lendela/partnerAIPResume",
    exact: false,
    component: LendelaComponent,
  },
];

if(window._env.MCC_MOCK_PAGE_ENABLED === "true") {
  publicRoutes.push({
    name: "Mock Redirect",
    path: "/mock",
    exact: false,
    component: MockPage
  })
}

export default publicRoutes;
