import { FC, ReactNode, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, Typography, makeStyles } from "@material-ui/core";
import {CHLLabel, CHLSlider} from "../../../../../components";
import { ReactComponent as Pencil } from "../../../../../assets/images/red_pencil.svg";
import { formatRepaymentValue } from "../../../../../utilities/helper";


interface IValueSliderField {
  label?: string;
  value: any;
  error?: boolean;
  helperText?: string;
  description?: string;
  items?: { text: string; value: any }[];
  code: string;
  onChange: any;
  onChangeCommitted?:(tenure: string) => void,
}

const ValueSliderField: FC<IValueSliderField> = ({
  label,
  value,
  error,
  helperText,
  description,
  items,
  code,
  onChange,
  onChangeCommitted=()=>{},
})=> {

  const [showSlider, setShowSlider] = useState<boolean>(false);
  const classes = useStyles()

  const vfObj = Object.assign({}, value)
  const strValue = formatRepaymentValue(
    vfObj.fieldType,
    vfObj.dataType,
    vfObj.value,
    vfObj.tenorUnit, 
    vfObj.currency,
  )

  const defaultValues = {
    tenure: vfObj.value,
  }

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors},
  } = useForm({
    defaultValues,
  });

  const handleOnClick = () =>{
    setShowSlider(!showSlider)
  }

  const handleOnChangeCommitted = (event: any, val: any) =>{
    const strVal = val.toString()
    onChangeCommitted(strVal)
  }

  return (
    <Fragment>
      <FormControl error={error} fullWidth style={{marginTop: '10px'}}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleOnClick}
        style={{cursor: "pointer"}}
      > 
        <CHLLabel
          bold={true}
          description={description}
          label={label}
          required={false}
        />
        <Pencil/>
      </Box>
      {showSlider ?  
      // <Controller
      //   name="tenure"
      //   control={control}
      //   render={({ field: { value, onChange, name } }) => (
      //   <CHLSlider
      //       code={name}
      //       items={items}
      //       error={false}
      //       helperText=""
      //       value={value}
      //       onChange={onChange}
      //       onChangeCommitted={handleOnChangeCommitted}/>
      //   )}
      // />

      <CHLSlider
        code={code}
        onChange={onChange}
        onChangeCommitted={handleOnChangeCommitted}
        items={items}
        value={value.value}
      />
    : 
    (<Typography component="div">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        minHeight={32}
      >
        <Box color="#9A9A9A">
          {strValue}
        </Box>
      </Box>
    </Typography>)}
    </FormControl>
    </Fragment>
  );
};


const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    wordBreak: "normal",
    "& .MuiTableCell-head": {
      background: "#C4161C",
      color: "#fff",
      padding: "10px",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "bold",
    },
    "& .MuiTableCell-body":{
      background: "#F2F4FA",
      border: "1px solid #fff"
    }
  },
  noaContainer:{
    background: "#F2F4FA",
    padding: "10px",
  },
  header: {
    background: "#C4161C",
    color: "#fff",
    padding: "10px",
    fontSize: "16px",
    lineHeight: "22px",
  },
  label: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
  },
  value: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
  },
})

export default ValueSliderField;
