import { createContext, FC, forwardRef, useState, useEffect } from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  RadioGroup,
  RadioGroupProps,
  TextField,
  Box,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import CHLLabel from "../CHLLabel";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import CHLTextField from '../CHLTextField';
import InputMask from "react-input-mask";

interface IOption {
  code: string; 
  name: string;
  sequence?: number;
}
 
interface ICHLNumberWithAreaCodeField{
  label?: string;
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  description?: string;
  error?: boolean;
  onChange?: any;
  value?: string;
  objPlaceholder?: {
    [key: string]: string
  },
  options?: IOption[];
  isMobile?: boolean;
}
const CHLNumberWithAreaCodeField = forwardRef<any, ICHLNumberWithAreaCodeField>((props, ref) => {
// const CHLNumberWithAreaCodeField: FC<ICHLNumberWithAreaCodeField> = (props) => {
  const {
    label,
    required,
    bold,
    helperText = "",
    description,
    error,
    children,
    value,
    options = [],
    isMobile = false,
    onChange,
    ...rest
  } = props;
  const classes = useStyles();

  const [areaValue, setAreaValue] = useState<IOption | null>(null);
  const [areaCode, setAreaCode] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [mask, setMask] = useState<string>("999999999999999");

  let a: string = "";
  let n: string = "";
  
  useEffect(()=>{
    if(value?.includes("#")){
      let arr = value.split("#");
      a = arr[0];
      n = arr[1];
      const selectedArea = a ? options.find(option=>option.code === a) || null : null;
      setAreaCode(a);
      setNumber(n);
      setAreaValue(selectedArea);
      if(a === '65'){
        setMask("99999999");
      }
    }
  }, [])

  const getSelectedValue = (code: string) => {
    return options?.find(option => option.code === code);
  }

  const handleAreaCodeChange = (event: any, value: any) => {
    setAreaValue(value? value: null);
    const newAreaCode = value?.code;
    onChangeHandler('areaCode', newAreaCode);
  }

  const handleNumberChange = (event: any) => {
    const value = event.target.value || "";
    onChangeHandler('number', value);
  }

  const onChangeHandler = (key: string, value: any) => {
    let str_areaCode: string = areaCode || "";
    let str_number: string = number || "";

    if(key === 'areaCode'){
      str_areaCode = value || "";
      setAreaCode(str_areaCode);
      if(str_areaCode === '65'){
        if(str_number.length > 8){
          str_number = str_number.substring(0, 8);
          setNumber(str_number);
        }
        setMask("99999999");
      }else{
        setMask("999999999999999");
      }
    }
    if(key === 'number'){
      str_number = value || "";
      setNumber(str_number);
    }

    // if(str_areaCode && !str_number || !str_areaCode && str_number){
    //   setHelper("Please provide your Mobile Number, including area code and number");
    // }

    let finalVal = str_areaCode + '#' + str_number;
    
    onChange && onChange(finalVal);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: option => (option as IOption).name,
  });

  // let selectedAreaCodeOption;
  // if(areaCode){

  // selectedAreaCodeOption = (getSelectedValue(areaCode) as IOption).name;
  // console.log("selectedAreaCodeOption: ", selectedAreaCodeOption);
  // }


  return (
    <FormControl className={classes.root} error={error}>
      <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
      />
      <Box display="flex" flexDirection="row">
        <Autocomplete
          className={classes.dropdown}
          classes={{
            paper: classes.paper,
          }}
          ListboxProps={{
            style: {
            position: "absolute",
            width: 'calc(350% + 10px)',
            top: 0,
            left: 0,
            margin: 0,
            padding: "8px 0",
            overflow: "auto",
            listStyle: "none",
            maxHeight: "40vh",
            background: "#FFF",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "8px",
            color: "rgba(0, 0, 0, 0.87)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            } 
          }}
          forcePopupIcon={true}
          disableClearable
          filterOptions={filterOptions}
          options={options}
          value={areaValue}
          getOptionLabel={(option) => `+${(option as IOption).code}`}
          renderOption={(option) => (option as IOption).name}
          renderInput={(params) => <TextField {...params} placeholder='e.g. +65' />}
          popupIcon={<ArrowDownIcon />}
          onChange={handleAreaCodeChange}
        />
        <InputMask
          mask={mask}
          onChange={handleNumberChange}
          value={number}
          maskChar={null}
        >
          {(inputProps: any) => (
            <TextField
              inputRef={ref} 
              className={classes.input}
              value={number}
              placeholder='e.g. 12345678'
              {...inputProps}
            />
          )}
        </InputMask>
      </Box>
      {!isMobile || error ? (<FormHelperText error={true}>{helperText}</FormHelperText>) : (<FormHelperText error={false}>The mobile number is used for authentication to protect your account.</FormHelperText>)}
    </FormControl>
  );

})

const useStyles = makeStyles((theme) => ({
  root: {
    width: " 100%",
    position: 'relative',
  },
  dropdown: {
    flex: 2,
    marginRight: '10px',
  },
  input: {
    flex: 5,
  },
  groupRoot: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.shape.borderRadius,
    "& > label": {
      borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    },
    "& > label:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    "& > label:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderRight: "none",
    },
  },
  groupRootError: {
    border: "1px solid #F44336",
  },
  paper: {
      width: 'calc(350% + 10px)',
  },
}));

export default CHLNumberWithAreaCodeField;
