import axiosInstance from "../axios";
import { 
    FACE_VERIFICATION, 
    FACE_V_GET_TOKEN, 
    FACE_V_VALIDATE_TOKEN 
} from "../api";
import { 
    ReqFaceVerificationGetToken, 
    ReqFaceVerificationValidateToken 
} from "../../types/request";

export class FaceVerificationRequest {
    static getToken = async (data: ReqFaceVerificationGetToken)=> {
        return axiosInstance.get(`${FACE_VERIFICATION}/${data.userId}${FACE_V_GET_TOKEN}`);
    };

    static validateToken = async(userID: string, data: ReqFaceVerificationValidateToken)=> {
        return axiosInstance.get(`${FACE_VERIFICATION}/${userID}${FACE_V_VALIDATE_TOKEN}`, data);
    };
}