import axiosInstance from "../../../services/axios";

export class LendelaApplicationRequest {

  static validateApplyNo(applyNo: string) {
    const promise = axiosInstance.get(`/oc/partner/auth/${applyNo}`);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static fetchFormAndInfo(applyNo: string) {
    const promise = axiosInstance.get(`/oc/partner/${applyNo}/aipFetch`);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static paynowValidate(data: any) {
    const promise = axiosInstance.post(`/oc/partner/paynow`, data);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static submitApplication(applyNo: string, data: any) {
    const params = {
      decision: 'approve',
      fieldValues: data,
    };
    const promise = axiosInstance.post(`/oc/partner/aipSubmit/${applyNo}`, params);
    const result:any = promise.then((response) => response.data)
    return result;
  }
  
}
