import { FC, FormEvent, useRef, useState, useEffect } from "react";
import CHLTextField, { ICHLTextField } from "../CHLTextField";
import { ReactComponent as FileIcon } from "../../assets/images/file.svg";
import { ReactComponent as HasFileIcon } from "../../assets/images/hasFile.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/remove.svg";
import { Box, IconButton } from "@material-ui/core";

const CHLUploadField: FC<ICHLTextField> = (props) => {
  const { onChange, value, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>(value ? value : "");

  useEffect(() => {
    if(value){
      onChange && onChange(value as any);
      setName(value.name);
    }
    
  }, [])

  const onClickHandler = () => {
    inputRef.current?.click();
  };

  const onClickRemoveHandler = (e: FormEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChange && onChange(null as any);
    setName("");
    if (inputRef.current) inputRef.current.value = "";
  };

  const onFileChangeHandler = (e: FormEvent) => {
    const files = (e.target as HTMLInputElement).files;
    if (files && files.length) {
      onChange && onChange(files[0] as any);
      setName(files[0].name);
    }
  };

  return (
    <>
      <CHLTextField
        file
        InputProps={{
          // startAdornment: (
          //   <Box mr={1}>
          //     {name ? <HasFileIcon/> : <FileIcon />}
          //   </Box>
          // ),
          endAdornment: (
            <Box ml={1}>
              <IconButton onClick={onClickRemoveHandler}>
                <RemoveIcon />
              </IconButton>
            </Box>
          ),
        }}
        inputProps={{
          readOnly: true,
        }}
        onClick={onClickHandler}
        value={name}
        {...rest}
      />
      <input ref={inputRef} type="file" hidden onChange={onFileChangeHandler} />
    </>
  );
};

export default CHLUploadField;
