import { useLayoutEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import {
  REDIRECT_COUNT_DOWN,
  DEFAULT_VALUE,
  BE_CODE_STATUS,
} from "../../../utilities/constants";
import { BUTTON_LABEL, UPLOAD_FILES_LABEL } from "../../../utilities/label";
import { CHLButton, CHLCustomerService } from "../../../components";
import { ReactComponent as FailIcon } from "../../../assets/images/fail.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { ReactComponent as OctopusSuccess } from "../../../assets/images/oct_success.svg";
import { ReactComponent as OctopusFail } from "../../../assets/images/oct_fail.svg";

const UploadCompletePage = () => {
  const { state } = useLocation<{
    code: string;
    applyNo: string;
    callbackUrl?: string;
  }>();
  const { code, callbackUrl } = state;
  const history = useHistory();
  let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);
  let decision: string = DEFAULT_VALUE.SUCCESS;
  if (code === BE_CODE_STATUS.UPLOAD_FILES_INVALID) {
    decision = DEFAULT_VALUE.INVALID;
  }

  const labels = UPLOAD_FILES_LABEL[decision];

  const redirectToMerchant = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();

    if (callbackUrl) {
      if (callbackUrl != "") {
        window.location.href = callbackUrl;
      }
    }
  };

  useLayoutEffect(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (code) {
      const countDownTimer = setInterval(() => {
        timer--;
        setTimer(timer);
        if (timer === 0) {
          redirectToMerchant();
        }
      }, 1000);
      return () => {
        clearInterval(countDownTimer);
        setTimer(REDIRECT_COUNT_DOWN);
      };
    } else {
      history.push({
        pathname: "/403",
      });
    }
  }, []);

  return (
    <Box mt={10}>
      <Typography align="center" variant="h5" component="div">
        <Box
          fontWeight="bold"
          color={decision === DEFAULT_VALUE.SUCCESS ? "#16C0B7" : "#FF0000"}
        >
          {labels.title}
        </Box>
      </Typography>
      <Box display="flex" justifyContent="center" my={3}>
        {decision === DEFAULT_VALUE.SUCCESS ? <SuccessIcon /> : <FailIcon />}
      </Box>
      <Typography align="center" component="div">
        <Box fontWeight="bold">{labels.header}</Box>
      </Typography>

      <Typography align="center" component="div">
        <Box my={3}>{labels.message}</Box>
      </Typography>
      <Box display="flex" justifyContent="center" my={5}>
        {decision === DEFAULT_VALUE.SUCCESS ? (
          <OctopusSuccess />
        ) : (
          <OctopusFail />
        )}
      </Box>

      <Box my={5} textAlign="center">
        <CHLCustomerService />
      </Box>

      <Box my={3} display="flex" justifyContent="center">
        {callbackUrl && (
          <CHLButton
            title={BUTTON_LABEL.backToHome + " (" + timer + "s)"}
            color="secondary"
            onClick={() => redirectToMerchant()}
          />
        )}
      </Box>
    </Box>
  );
};

export default UploadCompletePage;
