import { FC, useEffect, useState, createContext } from 'react'
import { Grid, Box, Typography, Hidden, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { RespIPAMetaData, RespProduct, RespIPATemplate, RespAppOptionField} from "../../../../../types/response";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores/rootReducer";
import { CHLValueField, CHLHeaderBar, CHLButton, CHLOverlayLoading, CHLDialog, CHLCustomerService } from "../../../../../components";
import { formatRepaymentValue } from "../../../../../utilities/helper";
import ValueSliderField from "../ValueSliderField"
import RejectForm from "./RejectForm"
import { ResumeApplicationRequest } from "../../../../../services/requests/ResumeApplicationRequest";
import { DEFAULT_VALUE, CODE } from "../../../../../utilities/constants"

interface ipaProps{
    applyNo: string;
    loanProposal: RespIPAMetaData;
    repayment: RespIPAMetaData;
    product: RespProduct;
    onSubmit:(data:any, decisionObj: any, amount: string) => void;
}

interface IRejectFormContext {
    control: any;
    defaultValues: any;
  }
  

const CUSTOMER_SERVICE_URL = window._env.REACT_APP_CIMB_CUSTOMER_SERVICE

export const RejectFormContext = createContext<IRejectFormContext | null>(
null
);

const InPricipalApproval: FC<ipaProps> = ({
    applyNo, loanProposal, repayment, product, onSubmit
}) =>{
    const [loanMatrixLoading, setLoanRepaymentLoading] = useState<boolean>(false);
    const [rejectShow, setRejectShow] = useState<boolean>(false);
    const {ipaData, resumeApplicationForm} = useSelector((s: RootState) => s.resumeApplication);

    let [repayFormData, setRepayFormData] = useState<any>([]);
    
    const approvedAmount = ipaData?.approvedAmount[0][0]

    const currency = product.currency;
    const tenorUnit = product.tenorUnit;
    const tenors = product.tenors;

    let decisionObj: { [key: string]: any } = {
        comments: null,
        reason: '',
        decision: null
    }

    let defaultValues: { [key: string]: any } = {...decisionObj}


    const { handleSubmit, control, setValue, getValues} = useForm({defaultValues});

    // Start initialize - React Hook Forms
    const loanProposalTemplate = loanProposal.applicationTemplateTabFields
    const repaymentTemplate = repayment.applicationTemplateTabFields


    let data: { [key: string]: any } = {}
    data = Object.assign({}, ipaData)

    for(const key in data){
        const value = data[key]
        let valArr = value.map((v:any) =>{
            return v[0]
        })
        data[key] = valArr[0]
    }

    const loanProposalForm = loanProposalTemplate.map((f: RespIPATemplate)=>{
        return {...f, value: f.field.code === CODE.APPLY_NO ? applyNo :  data[f.field.code]}
    })

    loanProposalForm.map((f: RespIPATemplate)=>{
        if(!f.readOnly){
            defaultValues[f.field.code] = f.value
        }
    })

    const setRepaymentFormValue = (d: { [key: string]: any }) =>{
        const repaymentForm = repaymentTemplate.map((f)=>({
            ...f, 
            value: d[f.field.code]
        }))

        return repaymentForm
    }

    useEffect(() => {
        
        // const formData = setRepaymentFormValue(data)
        // setRepayFormData(formData)
        calculateRepayment(data[CODE.APPROVED_AMOUNT], data[CODE.APPLIC_TENURE])
        if(data[CODE.APPLIC_TENURE]){
            setValue(CODE.APPLIC_TENURE, data[CODE.APPLIC_TENURE])
        }
        
    }, [])

    const calculateRepayment = async (amount: number, tenure: string) => {
        const { income, profitSegment, riskSegment, progcode } = data;
        let result = await ResumeApplicationRequest.fetchLoanMatrix({amount, tenure, progcode, income, profitSegment, riskSegment});

        const formData = setRepaymentFormValue(result.data)
        setRepayFormData(formData)
        setLoanRepaymentLoading(false)
    };

    const handleOnChange = (code?: any) => (e?: any) =>{
        setValue(code, e)
    }

    const handleOnChangeCommitted = (tenure: string ) =>{
        setLoanRepaymentLoading(true)

        const amount = data[CODE.APPROVED_AMOUNT]
        const delayDebounceFn = setTimeout(() => {
            calculateRepayment(amount, tenure.toString())
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }

    const handleRejectLoan = () =>{
        let data = Object.assign({}, defaultValues)
        
        Object.keys(data).map((key)=>{ 
            let values = getValues(key) 
            if(Array.isArray(values)){
                data[key] = values[1]
            }else{
                if(typeof values === "object"){
                    data[key] = ""
                }else{
                    data[key] = values
                }
            }
            
            return {...data}
        })
        data.decision = DEFAULT_VALUE.REJECT
        // onSubmit(data, decisionObj)
        handleOnSubmit(data)
    }

    const setLoanTenureOptions = () =>{
        let tenorOptions: RespAppOptionField[] = tenors.map(t => {
          return {
            code: t.toString(),
            name: t.toString()
          }
        })
  
        return tenorOptions
    }

    const handleOnSubmit = (data: any) =>{

        if(data[CODE.APPLIC_TENURE]){
            data[CODE.APPLIC_TENURE] = data[CODE.APPLIC_TENURE].toString()
        }

        const current: { [key: string]: any } = {}

        repayFormData.map((item: RespIPATemplate) =>{
            const {code} = item.field
            const repayValue = item.value
            // return data[code] = item.field.group?.code === CODE.HIDDEN_FIELD ? null : repayValue
            if(item.field.group?.code !== CODE.HIDDEN_FIELD) {
                data[code] = repayValue;
            } 
            return null;
        })

        Object.keys(data).forEach(key => {
            if(key in decisionObj){
                const val = data[key]
                delete data[key]
                return current[key] = val
            }else{
                return null
            }
        })

        // console.log(data)
        
        onSubmit(data, current, approvedAmount)
    } 

    return (
    <Box 
        maxWidth={800} 
        margin="auto"
        position="relative"
    >
        {loanMatrixLoading && <CHLOverlayLoading/>}
       
        <Box p={2} style={{position: "relative"}}>
            <Box mb={3}>
                <Typography component="div">
                    <Box fontWeight="bold" fontSize="1.375rem">Loan Proposal</Box>
                </Typography>
                <Typography component="div">Please confirm all the details before accepting the loan</Typography>
            </Box>
            
            <form onSubmit={handleSubmit((d) =>handleOnSubmit(d))}>
            <Grid container>   
                {loanProposalForm.map((item: RespIPATemplate) =>{
                    const {code, fieldType, options, dataType} = item.field
                    const {name, readOnly, value, format} = item
                    return (
                    <Grid key={code} item xs={12} sm={6}>
                    <Box key={code} my={2} pr={2}>
                        {readOnly ? 
                        
                        <CHLValueField
                            key={code}
                            label={name}
                            required={false}
                            value={formatRepaymentValue(fieldType, dataType, value, tenorUnit, currency)}
                            error={false}
                            helperText={""}/>
                        : 
                        <Controller
                        key={code}
                        name={code}
                        control={control}
                        render={({ field: { value, onChange }}) => {
                            let formOptions:RespAppOptionField[] = setLoanTenureOptions()
                            // value = value ? value : defaultValues[code]
                        return (
                            <ValueSliderField
                            key={code}
                            code={code}
                            label={name}
                            error={false}
                            helperText={""}
                            items={formOptions && formOptions.map((option) => ({
                                text: option.name,
                                value: option.code,
                            }))}
                            value={{fieldType, dataType, value, tenorUnit, currency}}
                            onChange={handleOnChange(code)}
                            onChangeCommitted={handleOnChangeCommitted}
                        />)
                    }}/>}
                    </Box> 
                    </Grid>
                    )
                })}
                <Grid item xs={12} sm={12}>
                    <Box my={2}>
                        <CHLHeaderBar title="Revised Repayment Schedule" hasLink={false} />
                    </Box>
                </Grid>
                {repayFormData.map((item: RespIPATemplate) =>{


                    const {code, fieldType, dataType, group} = item.field
                    const {name, value, format} = item

                    if(group?.code == CODE.HIDDEN_FIELD) return null

                    return (
                        <Grid key={code} item xs={12} sm={6}>
                            <Box key={code} my={2}>
                                <CHLValueField
                                key={code}
                                label={name}
                                required={false}
                                value={formatRepaymentValue(fieldType, dataType, value, tenorUnit, currency)}
                                error={false}
                                helperText={""}/>
                            </Box>
                        </Grid>
                    )
                })}
                <Box my={2}>
                    <CHLCustomerService/>
                </Box>
                <Grid container item>
                    <Box clone order={{ xs: 3, sm: 1 }}>
                        <Grid item xs={12} sm={3}>
                            <Box my={2}>
                                <CHLButton
                                type="button"
                                title="Reject Loan"
                                color="default"
                                fullWidth
                                onClick={() => setRejectShow(true)}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Hidden xsDown>
                    <Box clone order={{ xs: 2, sm: 2 }}>
                        <Grid
                        item
                        sm={6}
                        />
                    </Box>
                    </Hidden>
                    <Box clone order={{ xs: 1, sm: 3 }}>
                        <Grid item xs={12} sm={3}>
                            <Box mt={2}>
                                <CHLButton
                                type="submit"
                                title="Accept Loan"
                                color="secondary"
                                fullWidth
                                onClick={()=> {
                                    setValue("decision", DEFAULT_VALUE.APPROVE)
                                    setValue("reason", null)
                                }}
                                />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <CHLDialog
                    title=""
                    open={rejectShow}
                    onClose={() => setRejectShow(false)}
                    actions={[
                    {
                        title: "Submit",
                        color: "secondary",
                        type: "submit",
                        form: "rejectForm",
                        onClick: handleRejectLoan,
                    },
                    ]}
                    >
                    <RejectFormContext.Provider
                        value={{
                            control,
                            defaultValues,
                        }}>
                        <RejectForm/>
                    </RejectFormContext.Provider>    
                </CHLDialog>
                </Grid>
            </form>
           
        </Box>
       
    </Box>
    )
}

export default InPricipalApproval;
