import { createContext, FC, Fragment, useState } from "react";
import {
  CheckboxProps,
  FormControl,
  FormGroup,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import CheckboxIcon from "../../assets/images/checkbox.svg";
import CHLLabel from "../CHLLabel";
import { CODE } from "../../utilities/constants";

interface ICHLCheckbox extends CheckboxProps {
  label: string;
  items?: { text: string; value: number | string }[];
  error?: boolean;
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  description?: string;
  dataType?: string;
  value?: string;
  onChange?: any;
}

interface ICheckboxContent {
  value: any;
  onChange?: any;
  onChangeHandler?: any;
}

let valueArray:string[] = []

export const CheckboxContext = createContext<ICheckboxContent | null>(null);
const CHLCheckbox: FC<ICHLCheckbox> = (props) => {
  const classes = useStyles();
  const {
    label,
    // items = [],
    items,
    error,
    required,
    bold,
    helperText,
    description,
    value,
    children,
    onChange,
    dataType,
    ...rest
  } = props;

  const onChangeHandler = (v: string, index: number) =>{
    let val: string | string[] 
    if(dataType == "TextList"){
      if(valueArray.length == 0){
        valueArray.push(v)
      }else{
        // if(v !== CODE.AGREE_EMAIL){
          let newValue = valueArray.includes(v) ? "" : v
          valueArray.splice(index, 1, newValue);
        // }
      }
      val = valueArray
    }else{
      val = value == v ? "" : v
    }
    onChange && onChange(val) //pass back to ApplicationController onChangeHandler
  }

  return (
    <FormControl className={label == "" ? classes.notFirstOption : ""} error={!!error}>
      {label != "" && <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
      />}
      <FormGroup row>
        <CheckboxContext.Provider value={{ value, onChange, onChangeHandler }}>
          {children}
        </CheckboxContext.Provider>
      </FormGroup>
      <FormHelperText error={true} className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  helperText: {
    marginLeft: 30,
    marginTop: 0,
  },
  icon: {
    backgroundColor: "#ECECEC",
    borderRadius: 4,
    marginLeft: 2,
    width: 20,
    height: 20,
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#ECECEC",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: `url(${CheckboxIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      content: '""',
    },
  },
  notFirstOption:{
    marginTop: "-25px",
  }
}));

export default CHLCheckbox;
