import { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CODE, DEFAULT_VALUE } from "../../../utilities/constants";
import { ReqResumeApplication, ReqDictionary } from "../../../types/request";
import { RespAppTemplate, RespIPAMetaData, RespRejectTemplate } from "../../../types/response";
import { RootState } from "../../../stores/rootReducer";
import { fetchMyInfo } from "../../../stores/thunks/myinfo.thunks";
import { resumeApplication } from "../../../stores/thunks/resumeApplication.thunks";
import { ResumeApplicationActions } from "../../../stores/reducers/resumeApplication";
import { ResumeApplicationRequest } from "../../../services/requests/ResumeApplicationRequest"
import InPricipalApproval from "./subcomponents/InPrincipalApproval"
import Supplement from "./subcomponents/Supplement"
import { CHLOverlayLoading } from "../../../components";
import { AuthActions } from "../../../stores/reducers/auth";

import { Box, Typography } from "@material-ui/core";

import { BE_CODE_STATUS } from "../../../utilities/constants"

const CIMB_HOME_URL = window._env.REACT_APP_CIMB_HOME

function ResumeApplication (){
    const location = useLocation();
    const { state } = useLocation<{ applyNo: string, myInfoCode?: string, myInfoError?: string | null }>();
    const { applyNo, myInfoCode, myInfoError } = { ...state };

    const history = useHistory();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const tempApplyNo = queryParams.get(CODE.APPLY_NO)
    const tempToken = queryParams.get(CODE.TOKEN)

    const { resumeApplicationForm, product, callbackUrl } = useSelector((s: RootState) => s.resumeApplication);
    const { repaymentData } = useSelector((s: RootState) => s.partnerRepayment);
    const { data: myInfoData } = useSelector((s: RootState) => s.myinfo);

    const [loading, setLoading] = useState<boolean>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [callbackURL, setCallbackURL] = useState<string>(callbackUrl);
    const [applicationNo, setApplicationNo] = useState<string>("");
    // const [resumeApplicationForm, setResumeApplicationForm] = useState<RespAppTemplate>({})



    useEffect(() => {
        if(applyNo && myInfoCode){
            const reqPreCheck: ReqResumeApplication = {
                applyNo,
                myInfo: {
                    code: myInfoCode,
                    state: btoa(applyNo)
                }
            }
            // // setLoading(true)
            setApplicationNo(applyNo)
            dispatch(resumeApplication(reqPreCheck));
        }
        else{
            if(myInfoError){
                if(myInfoError == "access_denied"){
                  window.location.href = CIMB_HOME_URL;
                }
            }else{
                if(applyNo){
                    setApplicationNo(applyNo)
                    setLoading(false)

                    if(repaymentData.callbackUrl){
                        if(repaymentData.callbackUrl != ''){
                            setCallbackURL(repaymentData.callbackUrl)
                        }
                    }
                }else{
                    history.push({
                        pathname: "/403"
                    });
                }
            }
        }
    }, [])

    

    // useEffect(() => {
    //     // if(Object.keys(urlParams).length == 0){
    //     //     history.replace("/404");
    //     // }else{
    //         const data: ReqResumeApplication = {
    //            applyNo: urlParams.applyNo,
    //            token: urlParams.token
    //         }
    //         dispatch(resumeApplication(data));
    //     // }

    //     const applyNo = urlParams.applyNo
    //     const token = urlParams.token

    //     // if(applyNo && token){
    //     //     fetchForms(applyNo, token)
    //     // }
    // }, []);
    
    const polishData = async (data: any) =>{
        await Promise.all(
            Object.keys(data).map((key)=>{
                let value = data[key]

                value = Array.isArray(value) ? value : [value]

                data[key] = [value]
                return {...data}
            })
        )
    }

    const submitApplication = async(payload: any, decision: string, amount: string) =>{
        
        console.log(payload, decision, amount, applyNo)
        
        const { data }: any = await ResumeApplicationRequest.submitApplication(applyNo, payload);

        if(data.code === BE_CODE_STATUS.SUCCESS){
            setTimeout(() => {
                history.replace({
                    pathname: "/resume/complete",
                    state: { 
                        code: data.code,
                        // code: "000000",
                        applyNo: applicationNo,
                        applicAmount: amount,
                        decision,
                        productCode: product.code,
                        currency: product.currency,
                        callbackURL: callbackURL
                    },
                });
            }, 1000)
        }
    }

    // const onSubmit = useCallback(
    //     async (app: any, decision: any, amount: string) => {
    //         console.log(decision, amount)
    //         console.log("onSubmit", callbackURL)

    //         setSubmitLoading(true)
    //         const d = decision.decision

    //         if(app) await polishData(app)
    //         console.log(app)
    //         const payload = {
    //             node: DEFAULT_VALUE.NODE_AIP,
    //             answers: app,
    //             ...decision
    //         }
    //         submitApplication(payload, d, amount)
    // },[]);

    const onSubmit =
        async (app: any, decision: any, amount: string) => {

            setSubmitLoading(true)
            const d = decision.decision

            if(app) await polishData(app)
            let payload = {
                node: DEFAULT_VALUE.NODE_AIP,
                // answers: app,
                ...decision
            }
            if(d === DEFAULT_VALUE.APPROVE) {
                payload.answers = app;
            }
            if(d === DEFAULT_VALUE.REJECT){
                payload.answers = {};
            }
            submitApplication(payload, d, amount)
    };

    if(!submitLoading && Object.keys(resumeApplicationForm).length > 0 ){
        setTimeout(()=>{
            setLoading(false)
            if(callbackUrl){
                setCallbackURL(callbackUrl)
            }
        }, 50)
    }

    if(loading || submitLoading){
        return (
        <Box display="flex" justifyContent="center" mt={6}>
            <CHLOverlayLoading/>
        </Box>
        );
    }

    return (
    <Box mt={8} style={{position: "relative"}}>
        <InPricipalApproval 
            applyNo={applicationNo}
            loanProposal={resumeApplicationForm.loanProposal}
            repayment={resumeApplicationForm.repaymentSchedule}
            product={product}
            onSubmit={onSubmit}
        />
    </Box>)
}

export default ResumeApplication;