import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { 
  ReqApplicationData, 
  ReqApplicationTabs, 
  ReqApplicationSubmission, 
  ReqValidatePayNowAccount,
  ReqDictionary,
  ReqInPrincipleApproval, 
  ReqMyInfoNames
} from "../../../types/request";
import { 
  RespAppOptionField, 
  RespAppMetaData,
  RespIPAMetaData,
  RespRejectTemplate,
} from "../../../types/response";
import { ApplicationPageRequest } from "../../../services/requests/ApplicationPageRequest";
import { ResumeApplicationRequest } from "../../../services/requests/ResumeApplicationRequest";
import { RootState } from "../../../stores/rootReducer";
import { fetchMyInfo } from "../../../stores/thunks/myinfo.thunks";
import { fetchForms } from "../../../stores/thunks/application.thunks";
import { selectParentsByIndex, selectMyProfileData } from "../../../stores/reducers/application";
import { MyInfoActions, PartnerActions } from "../../../stores/reducers";
import { ApplicationActions } from "../../../stores/reducers/application";
import { ResumeApplicationActions } from "../../../stores/reducers/resumeApplication";

import { blobToBase64, getApplicantType, setProcessMode } from "../../../utilities/helper";
import { PATH } from "../../../utilities/path";
import { MYINFO_ACCESS_DENIED } from "../../../utilities/error";
import { 
  BE_CODE_STATUS, 
  CODE, 
  FORM_TYPE, 
  DEFAULT_VALUE,
  REGADDR,
  NATIONALITY_VALUE,
  BIRTH_COUNTRY_VALUE,
  RESUME_TYPES,
  EMPLOY_STATUS_VALUE
} from "../../../utilities/constants";

import { CHLOverlayLoading, CHLDialog } from "../../../components";
import ApplicationSummary from "./subcomponents/ApplicationSummary";
import FaceVerification from "../FaceVerificationPage"
import { ErrorAction } from "../../../stores/reducers";
// import { mockPayNowIDValidation } from "../../../mock";

const ApplicationForm = lazy(() => import("./subcomponents/ApplicationForm"));
const ApplicationHeader = lazy(() => import("./subcomponents/ApplicationHeader"));

const AIP_RETRY_INTERVAL = window._env.AIP_RETRY_INTERVAL
const AIP_RETRY_TIMEOUT = window._env.AIP_RETRY_TIMEOUT
const CIMB_HOME_URL = window._env.REACT_APP_CIMB_HOME
const HAS_FACEV = window._env.HAS_FACEV === 'true'

let applicationSubmissionData: ReqApplicationSubmission = {}
const applicationTabs: ReqApplicationTabs[] = [];
function CallbackApplication() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  let [payNowValidationCount, setPayNowValidationCount] = useState<number>(0);
  let [backLoading, setBackLoading] = useState<boolean>(false);
  let [isPayNowError, setIsPayNowError] = useState<boolean>(false);
  let [payNowErrorMsg, setPayNowErrorMsg] = useState<string>('');
  let [isPayNowEmpty, setIsPayNowEmpty] = useState<boolean>(false);
  const [fieldOptions, setFieldOptions] = useState<RespAppOptionField[]>();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [tableTypeData, setTableTypeData] = useState<any>(null);
  const [applyNo, setApplyNo] = useState<string>();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useStore();
  const { data: oriData, parents, originalParents, isLoading: isLoadingForm, isSTP, isPayNowIDValidateFailed, faceVScore, faceVFlag, obsFileMap, isPloanHasUploadStep } = useSelector(
    (s: RootState) => s.application
  );
  const { isLoading: isLoadingMyInfo, data: myInfoData, myInfoError: myInfoError, errorType} = useSelector(
    (s: RootState) => s.myinfo
  );
  const { productData, isManualFlow, crdeValue, progcode, partnerVerifyData } = useSelector((s: RootState) => s.partner);
  const { type: partyType } = partnerVerifyData;
  const { repaymentData } = useSelector(
    (s: RootState) => s.partnerRepayment
  );
  const currentTab = useSelector(selectParentsByIndex(activeIndex));
  const myProfileTab = useSelector(selectMyProfileData(activeIndex));
  const params = new URLSearchParams(location.search);
  const code = params.get(CODE.MYINFO_CODE);
  const state = params.get(CODE.MYINFO_STATE);
  const error = params.get(CODE.MYINFO_ERROR);

  const callbackURL = repaymentData.callbackUrl;
  let employmentStatus = "";
  let isSGPRFlag = false;

  useEffect(() => {
    if(isAIP()){
      if(state){
        const strState = atob(state)
        const applyNo = strState.split(`${CODE.APPLY_NO}=`)[1]
        const pathname = window.sessionStorage.getItem("RESUME_TYPE") == RESUME_TYPES.DOC_UPLOAD ?  PATH.UPLOAD_DOCS :  PATH.CHECK
        history.replace({
          pathname,
          state: { 
            applyNo: applyNo,
            myInfoCode: code,
            myInfoError: error
          }
        });
      }
    }else{
      try{
        const productCode = repaymentData.productCode

        const templateID = productData.templateIdIn
        const strTemplateID = btoa(templateID.join(":"))

        const entityType = repaymentData.entityType

        if(entityType && entityType === CODE.CORPORATE){
          // if(processMode != DEFAULT_VALUE.STP){//local unit test workaround
          if(isSTP == DEFAULT_VALUE.STP){ //if faceV hit not NSTP
            dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.STP ))//change to STP on 28/2
          }
        }

        if (code && state) {
          dispatch(
            fetchForms({
            productCode,
            isManualFlow: isManualFlow,
            tids: strTemplateID
            })
          )
          if(!myInfoData || Object.keys(myInfoData).length == 0){
            dispatch(
              fetchMyInfo({code, state: state})
            )
          } 
        }else{
          if(error){
            if(error == MYINFO_ACCESS_DENIED){
              window.location.href = CIMB_HOME_URL;
            }
          }
          dispatch(
            fetchForms({
            productCode,
            isManualFlow: isManualFlow,
            tids: strTemplateID
            })
          )
        }
      }catch{
        dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.SYSTEM_ERROR))
      }
    }

    window.history.pushState(null, "", window.location.href);
    // }
  }, []);

  const onBackButtonEvent = useCallback((e:any) =>{
    e.preventDefault();

    window.history.pushState(null, "", window.location.href);
    if(activeIndex > 0){ 
      if(activeIndex == parents.length - 1){
        dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.SYSTEM_ERROR))
      }else{
        onBack()
      }
    }

  }, [activeIndex, parents.length])

  // Methods
  function extractURLParams() {
    const params = new URLSearchParams(location.search);
    const state = params.get("state");

    if(state){
      let strState = atob(state)
      if(strState.includes("applyNo=")){
        return{
          applyNo: params.get("applyNo")
        }
      }else{
        return {};
      }
    }else{
      return {};
    }
  }

  window.addEventListener('popstate', onBackButtonEvent);

  // function isAIP(){
  //   let isaip = false;
  //   const params = new URLSearchParams(location.search);
  //   const state = params.get("state");
  function isAIP(){
    let isaip = false;

    if(state){
      let strState = atob(state)
      if(strState.includes("applyNo=")){
        isaip = true
      }
    }
    return isaip
  }

  const verifyAddress = () =>{
    const concatAddress = `${applicationSubmissionData[REGADDR.BLOCK][0][0]} ${applicationSubmissionData[REGADDR.STREET][0][0]}, #${applicationSubmissionData[REGADDR.FLOOR][0][0]}-${applicationSubmissionData[REGADDR.UNIT][0][0]}, ${ applicationSubmissionData[REGADDR.COUNTRY][0][0]}, ${applicationSubmissionData[REGADDR.POSTAL_CODE][0][0]} `
    const regAddr = applicationSubmissionData[CODE.ADDRESS][0][0]

    if(concatAddress !== regAddr){
      dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.NSTP))
    }
  }

  const triggerGTMEvent = (activeIndex: number) => {
    if(activeIndex === 0){
      window.dataLayer.push({'event': 'gtm.personaldetails'});
    }else if(activeIndex === 1){
      window.dataLayer.push({'event': 'gtm.declaration'});
    }else if(activeIndex === 2 && !isPloanHasUploadStep){
      window.dataLayer.push({'event': 'gtm.applicationsummary'});
    }else if(activeIndex === 3 && !isPloanHasUploadStep){
      window.dataLayer.push({'event': 'gtm.formcomplete'});
    }else if(activeIndex === 3 && isPloanHasUploadStep){
      window.dataLayer.push({'event': 'gtm.applicationsummary'});
    }else if(activeIndex === 4 && isPloanHasUploadStep){
      window.dataLayer.push({'event': 'gtm.formcomplete'});
    }
  }

  // Methods
  const onSubmit = useCallback(
    async (app?: any) => {
      triggerGTMEvent(activeIndex);

      let proceed = true
      let payNowResult: any = {}
      let errorMsg: any

      if(app[CODE.PAYNOW_ID_TYPE]){
        const type = app[CODE.PAYNOW_ID_TYPE]
        const id = app[CODE.PAYNOW_ID]

        if(!id || id ===""){
          setIsPayNowEmpty(true)
          proceed = false
        }else{
          payNowResult = await validatePayNowAccount(id, type)
          proceed =  payNowResult.validate
          errorMsg = payNowResult.errorMsg
        }
        
        if(proceed){
          let result: { [key: string]: any } = payNowResult.result
            Object.entries(result).map(
              ([key, value]:any) => {
                app[key] = value 
              }
            )
        }else{
          setIsPayNowError(true);
          setPayNowErrorMsg(errorMsg);
        }
      }

      if(activeIndex === 0){
        const loanInfoSummaryData = [{
          id: "loanInfo",
          name: "Application Info",
          type: FORM_TYPE.DATAENTRY,
          fields: repaymentData.repayment
        }]
        dispatch(ApplicationActions.setSummaryData(loanInfoSummaryData))

        let res: any = {};
        const myInfo: { [key: string]: any } = store.getState().myinfo.data;
        myProfileTab.map((t) => {
          if (t?.type === FORM_TYPE.TABLE) {
            if (!res[t.id]) res[t.id] = [];
            t.applicationTemplateTabFields.map((f) => {
              res[t.id].push({[f?.field?.code]:null})
            })
          } else if (t?.type === FORM_TYPE.DATAENTRY) {
            t?.applicationTemplateTabFields.map((f) => {
              res[f?.field?.code] = myInfo[f?.field?.code] || null;
            })
          }
        })
        app = app || {};
        Object.assign(app, res);
      }

      if(activeIndex === 0){
        let isAmericanFlag = false;
        if([NATIONALITY_VALUE.AMERICAN, NATIONALITY_VALUE.AMERICAN_SAMOA].includes(app[CODE.NATIONALITY]) || [BIRTH_COUNTRY_VALUE.AMERICAN_SAMOA, BIRTH_COUNTRY_VALUE.UNITED_STATES, BIRTH_COUNTRY_VALUE.US_VIRGIN_ISLANDS].includes(app[CODE.BIRTH_COUNTRY])){
          isAmericanFlag = true;
        }
        dispatch(ApplicationActions.setIsAmericanFlag(isAmericanFlag));
        // personIDNo / nationality / lenofemp 均为required字段
        const { isSGPR, isMalayForeigner, isOtherForeigner } = getApplicantType(app.personIDNo, app.nationality);
        const lenofemp = app[CODE.LENGTH_OF_EMPLOYMENT];
        let employedMonths = -1;
        if(lenofemp && lenofemp !== "-"){
          const ymArr = lenofemp.split("-");
          employedMonths = Number(ymArr[0])*12 + Number(ymArr[1]);
        }
        let docUploadRelatedData = {
          isSGPR,
          isMalayForeigner,
          isOtherForeigner,
          lenofemp,
          employedMonths,
          [CODE.EMPLOY_STATUS]: app[CODE.EMPLOY_STATUS],
          [CODE.PR_STATUS]: app[CODE.PR_STATUS],
        };
        employmentStatus = app[CODE.EMPLOY_STATUS];
        isSGPRFlag = isSGPR;
        dispatch(ApplicationActions.setUploadRelated(docUploadRelatedData));
      }

      
      // polish data to submit
      if (app) await polishSubmissionData(app);

      if(activeIndex < parents.length - 1)
      if (app) setSummaryData(app);
      // if (app) await polishData(app);

      // if(activeIndex == 1) verifyAddress()

      if (activeIndex < parents.length - 1) {
        window.scrollTo(0, 0);
        if(proceed) setActiveIndex((prev) => ++prev);
      } else {
        setSubmitLoading(true)
        submitAppication();
      }
    },
    [activeIndex, parents.length]
  );

  const removeSalutation = (name: string) => {
    let nameArr = name.split(' ')
    const salutations: string[] = window._env.PAYNOW_WHITELIST_SALUT.split('|')

    if(nameArr.length > 1) {
      let first = nameArr.splice(0, 1)[0].toUpperCase()
      nameArr = [first, ...nameArr]
    }

    nameArr = nameArr.filter((word) => salutations.findIndex((salutation) => salutation === word) === -1)

    return nameArr.join(' ')
  }

  const validatePayNowAccount = async(id: string, type: "NRIC" | "MSISDN" | "UEN") =>{
    let validate: boolean = true
    let nameValidate: any;
    let result: any
    let errorMsg: any
      try {
        let myInfo: { [key: string]: any } = store.getState().myinfo.data;
        // hidden myinfo step1， applicationSubmissionData has no value
        const myInfoNames: ReqMyInfoNames = {
          customerFullName: applicationSubmissionData[CODE.PRINCIPAL_NAME]?.[0]?.[0] || myInfo[CODE.PRINCIPAL_NAME],
          aliasname: applicationSubmissionData.aliasname?.[0]?.[0] || myInfo.aliasname,
          hanyupinyinname: applicationSubmissionData.hanyupinyinname?.[0]?.[0] || myInfo.hanyupinyinname,
          hanyupinyinaliasname: applicationSubmissionData.hanyupinyinaliasname?.[0]?.[0] ||  myInfo.hanyupinyinaliasname,
        };

        const payload: ReqValidatePayNowAccount = { id, type, myInfoNames };
        result = await ApplicationPageRequest.validatePayNowAccount(payload);
        const {code, data, message} = result.data;
        if(code != BE_CODE_STATUS.SUCCESS){
          validate = false
          errorMsg = message || '';
        }else{
          const { nameValidationPassed } = data;
          nameValidate = nameValidationPassed; 
          validate = true
        } 
      } catch (err) {
        validate = false
      }
    dispatch(ApplicationActions.setIsPayNowIDValidateFailed(nameValidate));
    return {
      validate,
      result: result.data?.data,
      errorMsg,
    }
  }

  const setSummaryFieldValue = (
    value?: string,
    options?: RespAppOptionField[]
    ) =>{
      let selectedValue: string | number | null | undefined
      if(options?.length){
        const selectedOptions = options.filter(item => item.code === value)
        selectedOptions.length && (selectedValue = selectedOptions[0].name)
      }else{
        selectedValue = value && value
      }
    return selectedValue
  }

  const setSummaryData = (data: any) =>{
    let parentData = Object.values(parents[activeIndex])[0];
    let myInfoTabData = activeIndex === 0 ? Object.values(originalParents[0] || {})[0] : [];
    parentData = [...myInfoTabData, ...parentData];
    let summaryData = parentData.map(item =>{
      let fields: any = []
      if (item.type === FORM_TYPE.ATTACHMENT) {
        fields = item.applicationTemplateTabFields.map(f => {
          if (f.field.group?.code === CODE.HIDDEN_FIELD) return
          return {
            label: f.name,
            code: f.field.code,
          }
        })
      } else if(item.type === FORM_TYPE.DATAENTRY){
        fields = item.applicationTemplateTabFields.map(f =>{

          if(f.field.group?.code === CODE.HIDDEN_FIELD) return 
          if(f.field.fieldType === "ImageFile"){
            return {
              label: f.name,
              code: f.field.code,
            }
          }
          let value = ""
          if(f.field.options){
            const selectedOptions = f.field.options.filter(o => o.code ===  data[f.field.code])
            selectedOptions.length && (value = selectedOptions[0].name)
          }else{
            value = data[f.field.code]
          }
          return{
            label: f.name,
            code: f.field.code,
            value: setSummaryFieldValue(data[f.field.code], f.field.options)
          }
        })
      } else{
        const tableData:any[] = data[item.id]
        let tableFields:any = []
        tableData.map((d, i) =>{
          let obj = item.applicationTemplateTabFields.map((f) =>{
            return {
              label: f.name,
              value: setSummaryFieldValue(tableData[i][f.field.code], f.field.options)
            }
          })
          tableFields.push(obj)
        })
        fields = tableFields
      }
      
      return {
        id: item.id,
        name: item.originalName,
        type: item.type,
        fields: fields
      }
    })
    dispatch(ApplicationActions.setSummaryData(summaryData))
  }

  const polishSubmissionData = async (data:any) =>{
    let curTabs = activeIndex === 0 ? [...currentTab, ...myProfileTab] : [...currentTab]
    await Promise.all(
      curTabs.map(async (tab) =>{
        let sData:{ [key: string]: any[] } = {}
        const { applicationTemplateTabFields, id, type, originalName } = tab;
        if(type === FORM_TYPE.TABLE){
          if(originalName != CODE.CPF_HISTORY && originalName != CODE.NOA){
            applicationTemplateTabFields.map(async (field) =>{
              const {code, dataType} = field.field
              const tableData = data[id]
  
              setTableTypeData(tableData)
  
              let td = tableData.map((d: any)=>{
                return d[code] ? [d[code]] : [null]
              });
              sData[code] = td
            })
            Object.assign(applicationSubmissionData, sData)
          }else{
            const myInfo = store.getState().myinfo.data
            if(Object.keys(myInfo).length == 0){
              applicationTemplateTabFields.map(async (field) =>{
                const {code} = field.field
                const tableData = data[id]
                
                let td = tableData.map((d: any)=>{
                  return d[code] ? [d[code]] : [null]
                });
                sData[code] = td
              })
              Object.assign(applicationSubmissionData, sData)
            }else{
              const mData:{ [key: string]: any[] } = myInfo
              const mdata = mData[originalName]
            
              const res = applicationTemplateTabFields.reduce((acc, el) => {
                const {code} = el.field
                
                let values:any = [[null]]
                if(code.includes("_")){
                  const key = code.split("_")[1]
                  if(mdata){
                    values = mdata.map((item) => {
                      return [item[key]]
                    })
                  }
                }
                return { ...acc, [code]: values }
              }, {})
              Object.assign(applicationSubmissionData, res)
            }
          }
          
        }else{
          const fieldV = await applicationTemplateTabFields.reduce(
            async (prevValue, newValue) => {

              const { code } = newValue.field;


              let value = data[code];

              if(code === CODE.FATCA_RESIDENCE && value === DEFAULT_VALUE.FATCA_RESIDENCE_YES) dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.NSTP))
              if (value instanceof Blob || value instanceof File) {
                if (value instanceof File) {
                  dispatch(ApplicationActions.setDocumentUpload({
                    [code]: value.name
                  }))
                }
                value = await blobToBase64(value);    
              }
              
              if (value == " ") value = ""

              // if(code.includes("_desc")){
              // // if(code.includes("personFirstName")){
              //   // const ddCode = "tittle"
              //   const ddCode = code.split("_")[0]
              //   const ddField = applicationTemplateTabFields.filter((f):any=> f.field.code == ddCode)
              //   if(ddField.length > 0){
              //     const options = ddField[0].field.options

              //     const ddDesc = options.filter((o):any => o.code == data[ddCode])
              //     value = ddDesc[0].name
              //   }
              // }

              value = [[value]];

              const prev = await prevValue;
              return { ...prev, [code]: value };
            },
            Promise.resolve({})
          );
          Object.assign(applicationSubmissionData, fieldV)
        }
      })
    )
  }

  const pushToApplicationTabs = async (tabs: RespAppMetaData[], data: any) => {
    await Promise.all(
      tabs.map(async (tab) => {
        const { applicationTemplateTabFields, id, type } = tab;
        let fields: { code: string; value: any }[];
        let fieldValues: { [key: string]: any[] };

        if (type === FORM_TYPE.TABLE) {
          const arrayValues: any[] = data[id] || [];
          const array: any = [];
          arrayValues.map((v) => {
            Object.entries(v).forEach(([key, value]) => {
              const obj = array.find((o: any) => o.code === key);
              if (obj) {
                obj.value.push(value);
              } else array.push({ code: key, value: [value] });
            });
          });

          fields = [...array];
          fieldValues = arrayValues.reduce((prevV, newV) => {
            const obj = Object.keys(newV).reduce((prevKey, newKey) => {
              const prevValue = prevV[newKey];
              let newValue = newV[newKey];
              newValue = Array.isArray(newValue) ? newValue : [newValue];

              if (prevValue && Array.isArray(prevValue)) {
                prevValue.push(newValue);
                return { ...prevKey, [newKey]: prevValue };
              } else return { ...prevKey, [newKey]: [newValue] };
            }, {});

            return { ...obj };
          }, {});
        } else {
          fields = await Promise.all(
            applicationTemplateTabFields.map(async ({ field: { code } }) => {
              let value = data[code];
              if(code === CODE.FATCA_RESIDENCE && value === DEFAULT_VALUE.FATCA_RESIDENCE_YES) dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.NSTP))
              if (value instanceof Blob) value = await blobToBase64(value);
              return { code, value };
            })
          );
          fieldValues = await applicationTemplateTabFields.reduce(
            async (prevValue, newValue) => {
              const { code } = newValue.field;
              let value = data[code];
              if (value instanceof Blob) value = await blobToBase64(value);
              value = Array.isArray(value) ? value : [value];

              const prev = await prevValue;
              return { ...prev, [code]: [value] };
            },
            Promise.resolve({})
          );
        }


        const index = applicationTabs.findIndex((tab) => tab.id === id);
        // if doesn't exist then push to application tabs
        if (index < 0) applicationTabs.push({ id, fields, fieldValues });
        // else if overwrite the value in application tabs
        else applicationTabs.splice(index, 1, { id, fields, fieldValues });
      })
    );
  };



  const submitAppication = async () => {
    if(submitLoading) return;
    applicationSubmissionData[CODE.PROCESS_MODE] = [[isSTP]]
    applicationSubmissionData[CODE.PAYNOW_CHECK_PASSED] = [[isPayNowIDValidateFailed.toString()]]
    applicationSubmissionData[CODE.ENTITY_TYPE] = [[repaymentData.entityType]]
    applicationSubmissionData[CODE.CALLBACK_URL] = [[repaymentData.callbackUrl]]
    if(partyType === CODE.CORPORATE){
      applicationSubmissionData[CODE.ROAD_SHOW] = [[sessionStorage.getItem(CODE.ROAD_SHOW)]]
      applicationSubmissionData[CODE.AGENT_CODE] = [[sessionStorage.getItem(CODE.AGENT_CODE)]]
    }
    applicationSubmissionData[CODE.MYINFO_MODE] = [[isManualFlow ? DEFAULT_VALUE.NON_MYINFO : DEFAULT_VALUE.MYINFO]]
    applicationSubmissionData[CODE.PROG_CODE] = [[progcode]]
    applicationSubmissionData[CODE.CRDE_VALUE] = [[crdeValue]]

    if(HAS_FACEV){
      applicationSubmissionData[CODE.FACEV_SCORE] = [[faceVScore]]
      applicationSubmissionData[CODE.FACEV_FLAG] = [[faceVFlag]]
    }

    let repaymentArr:any[] =  repaymentData.repayment

    repaymentArr.map((r):any =>{
      applicationSubmissionData[r.code] = [[r.answer]]
    })

    let uploadObsFields: any = [];
    if (Object.entries(obsFileMap).length) {
      Object.entries(obsFileMap).map(([code, fileId]) => {
        applicationSubmissionData[code] = [[fileId]];
        uploadObsFields.push(code);
      });
    }

    const objApplicAmount = repaymentArr.find((r) => r.code === CODE.APPLIC_AMOUNT)
    const applicAmount = objApplicAmount.value

    // try {

    const payload: ReqApplicationData = {
      answers: applicationSubmissionData,
      uploadedFileCodes: uploadObsFields,
    }

    const { submitApplication } = ApplicationPageRequest;
    const { data }: any = await submitApplication(payload);
    if(data.code == BE_CODE_STATUS.DUPLICATE_SUBMISSION){
      return;
    }

    if (data.code == BE_CODE_STATUS.SUCCESS){
      const applyNo = data.data?.applyNo
      proceedToAIP(applyNo, applicAmount)
    }
  };

  const proceedToAIP = (applyNo: string, applicAmount: string) =>{

    const reqAIP: ReqInPrincipleApproval = {
      applyNo
    }

    let getAIPResponse = setInterval(async ()=>{    
      try{
        const checkResult = await ResumeApplicationRequest.fetchResumeApplication(reqAIP)

        if(checkResult.code === BE_CODE_STATUS.SUCCESS){
          clearInterval(getAIPResponse)
          clearTimeout(redirectComplete)
          const {data}:any = checkResult
          if(data){
            let {decision, approvedAmount, node} = data;
            if(typeof approvedAmount === "string"){
              approvedAmount = parseFloat(approvedAmount);
            }
            let decisionFlag = (decision === DEFAULT_VALUE.APPROVE && node === 'end' && !!approvedAmount) || decision === DEFAULT_VALUE.REJECT;
            if(decision){
              setTimeout(() => {
                history.replace({
                    pathname: "/callback/complete",
                    state: { 
                      code: checkResult.code,
                      applyNo: applyNo,
                      applicAmount,
                      approvedAmount: approvedAmount ? productData.currency + ' ' + approvedAmount.toLocaleString(undefined, {minimumFractionDigits: 2}): undefined,
                      decision: decisionFlag ? decision : undefined,
                    },
                });
              }, 1000) 
            }else{
              dispatch(ResumeApplicationActions.setResumeApplication(data));
        
              if(data.payload.reject){
                const reject:RespIPAMetaData = data.payload.reject
                const fields: RespRejectTemplate[] = reject.applicationTemplateTabFields
                const dictionaryField = fields.filter((item: RespRejectTemplate) => item.field.fieldType === "Dictionary")[0]


                const book = dictionaryField.field.dictCode
                const label = dictionaryField.field.dictLabel

                if(book && label){
                  const dictData: ReqDictionary = {book, label}
                  dispatch(ResumeApplicationActions.setDictionaryRoot(label));

                  const dictionaryResult = await ResumeApplicationRequest.fetchDictionary(dictData);
                  dispatch(ResumeApplicationActions.setDictionary(dictionaryResult.data));
                }
              }

              setTimeout(() => {
                history.replace({
                    pathname: "/check",
                    state: { 
                      applyNo: applyNo
                    },
                });
              }, 1000) 
            }
          } 
        }else{
          if(checkResult.code != BE_CODE_STATUS.RETRIGGER_AIP){
            clearInterval(getAIPResponse)
            clearTimeout(redirectComplete)
            
            history.replace({
              pathname: "/callback/complete",
              state: { 
                code: BE_CODE_STATUS.SUCCESS,
                applyNo: applyNo,
                applicAmount,
              },
            });
          }
        }
      }catch(err){
        clearInterval(getAIPResponse)
        clearTimeout(redirectComplete)

        history.replace({
          pathname: "/callback/complete",
          state: { 
            code: BE_CODE_STATUS.SUCCESS,
            applyNo: applyNo,
            applicAmount,
          },
        });
      }
    }, parseInt(AIP_RETRY_INTERVAL))

    let redirectComplete = setTimeout(()=>{
      clearInterval(getAIPResponse)
      history.replace({
          pathname: "/callback/complete",
          state: { 
            code: BE_CODE_STATUS.SUCCESS,
            applyNo: applyNo,
            applicAmount,
          },
      });
    }, parseInt(AIP_RETRY_TIMEOUT))
  }

  const onBack = () => {
    const s = applicationTabs.slice(1);
    
    setActiveIndex(activeIndex - 1);
    // setPreviousData(s);
  };

  const payNowErrorOnClickHandler = () =>{
    payNowValidationCount < 2 ? 
    setIsPayNowError(false) :
    window.location.href = callbackURL
  }

  const goToHomePage = () => {
    dispatch(PartnerActions.setIsManualFlow(true));
    dispatch(MyInfoActions.setMyInfoError(false))
    dispatch(MyInfoActions.setErrorType(''));
    window.location.href = CIMB_HOME_URL;
  }

  // Template
  const condition = (code ? isLoadingForm || isLoadingMyInfo : isLoadingForm) || submitLoading;
  if (condition){
    return (
      <Box display="flex" justifyContent="center" mt={6}>
        <CHLOverlayLoading/>
      </Box>
    );
  }

  if(HAS_FACEV){
    if(faceVScore == undefined && Object.keys(myInfoData).length > 0){
      let myInfo: { [key: string]: any } = {}
      myInfo = Object.assign({}, myInfoData)

      const bearer = state ?? ''

      const userID:string = myInfo[CODE.NRIC]
      return (
        <FaceVerification
          userID={userID}
          state={bearer}
        />
      )
    }
  }

  if (myInfoError) {
    return (
      <CHLDialog
        hideClose={true}
        open={true}
        title="Notice"
        onClose={() => {}}
        actions={[
          {
            title: "OK",
            color: "secondary",
            onClick: goToHomePage,
          },
        ]}
      >
        <Typography>
          {errorType === "foreigner_passtype_invalid" && "Sorry. The Employment Pass Type you are holding is not eligible for this product."}
          {errorType === "foreigner_passstatus_invalid" && "Sorry. Your Employment Pass Status must be Live to be eligible for this product."}
          {errorType === "foreigner_passexpirydate_invalid" && "Sorry. Your Employment Pass must have at least 6 months validity."}
        </Typography>
      </CHLDialog>
    );
  }

  return (
    <Box position="relative">
      {backLoading && <CHLOverlayLoading />}
      <Suspense fallback={<h1 />}>
        <ApplicationHeader activeIndex={activeIndex} />
        {activeIndex !== parents.length - 2 ? (
          <>
            <ApplicationForm
              key={activeIndex}
              activeIndex={activeIndex}
              tableTypeData={tableTypeData}
              // values={applicationTabs.flatMap((value) => value.fields)}
              values={applicationSubmissionData}
              onSubmit={onSubmit}
              onBack={onBack}
            />
            {isPayNowError && (
              <CHLDialog
                hideClose={true}
                open={isPayNowError}
                title="Validation Unsuccessful"
                onClose={() => setIsPayNowError(false)}
                actions={[
                  {
                    title: "Close",
                    color: "secondary",
                    onClick: () => setIsPayNowError(false),
                  },
                ]}
              >
                <Typography>{payNowErrorMsg}</Typography>
              </CHLDialog>
            )}
            {isPayNowEmpty && (
              <CHLDialog
                hideClose={true}
                open={isPayNowEmpty}
                title="Notice"
                onClose={() => setIsPayNowEmpty(false)}
                actions={[
                  {
                    title: "Close",
                    color: "secondary",
                    onClick: () => setIsPayNowEmpty(false),
                  },
                ]}
              >
                <Typography>
                  Paynow ID is empty. Kindly re-confirm again.
                </Typography>
              </CHLDialog>
            )}
          </>
        ) : (
          <ApplicationSummary
            activeIndex={activeIndex}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
      </Suspense>
    </Box>
  );
}

const CallbackHomePage = () => {
  return <CallbackApplication />;
};

export default CallbackHomePage;
