import { FC } from "react";
import {
  Box,
  Slider,
  withStyles,
  SliderProps,
  FormControl,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import CHLTextField from "../CHLTextField";
import CHLLabel from "../CHLLabel";
import { CODE } from "../../utilities/constants";
import { CODE_LABEL, CODE_SUB_LABEL } from "../../utilities/label";

interface ICHLSlider extends SliderProps {
  label?: string;
  onChange: any;
  error?: boolean;
  helperText?: string;
  items?: { text: string; value: any }[];
  code?: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
  partnerType?: string;
}

const CHLSlider: FC<ICHLSlider> = (props) => {
  const {
    label,
    helperText = " ",
    error,
    value,
    onChange,
    items = [],
    code,
    disabled,
    description,
    required,
    partnerType,
    ...rest
  } = props;
  const dynamicProps = items.length
    ? {
        min: parseFloat(items[0].value),
        max: parseFloat(items[items.length - 1].value),
      }
    : {};
  const classes = useStyles();

  // methods
  const onChangeHandler = (_: any, newValue: number | number[]) => {
    onChange && onChange(newValue);
  };

  const textFieldValue = code === CODE.APPLIC_AMOUNT ? typeof value === "string" ? 
  parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2}) : 
  value?.toLocaleString(undefined, {minimumFractionDigits: 2}) : value

  let stepProp = null;
  if(partnerType === CODE.CORPORATE && code === CODE.APPLIC_AMOUNT){
    stepProp = 1000.00
  }
  if(partnerType === CODE.EWALLET && code === CODE.APPLIC_AMOUNT){
    stepProp = 100.00
  }

  // template
  return (
    <FormControl classes={{ root: classes.root }} error={error}>
      {description && <CHLLabel
        label={code && CODE_LABEL[code]}
        required={required}
        description={description}
      />}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize="1rem"
      >
       {code && <span className={classes.spanLabel}>{CODE_SUB_LABEL[code] ?? label}</span>}
        <Box display="flex" alignItems="center" maxWidth={175}>
          <Box fontWeight="bold" mr={1} visibility={code === CODE.APPLIC_TENURE ? "hidden" : "visible"}>
            <span className={classes.spanLabel}>SGD</span>
          </Box>
          <CHLTextField
            value={textFieldValue}
            variant={"outlined" as any}
            inputProps={{ style: { textAlign: "center"}, readOnly: true }}
            helperText=""
          />
          <Box fontWeight="bold" ml={1} display={code === CODE.APPLIC_AMOUNT ? "none" : "block"}>
            <span className={classes.spanLabel}>months</span>
          </Box>
        </Box>
      </Box>

      <PrettoSlider
        {...rest}
        {...dynamicProps}
        
        marks={items.map(({ text, value }) => ({
          label: code === CODE.APPLIC_AMOUNT ? parseFloat(text).toLocaleString(undefined, {minimumFractionDigits: 2}) : text,
          value: value,
        }))}
        value={value}
        step={stepProp}
        ThumbComponent="div"
        onChange={onChangeHandler}
        disabled={disabled && disabled}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hidden: {
    visibility: "hidden",
  },
  spanLabel:{
    color: theme.palette.secondary.main
  }
}));

const PrettoSlider = withStyles((theme) => ({
  root: {
    height: 8,
    "& > .MuiSlider-markLabel": {
      color: theme.palette.grey[900],
      transform: "translateX(0)",
    },
    "& > .MuiSlider-markLabel ~ .MuiSlider-markLabel": {
      transform: "translateX(-50%)",
    },
    "& > span:last-of-type": {
      transform: "translateX(-100%) !important",
    },
    "& .MuiSlider-thumb.Mui-disabled":{
      height: 24,
      width: 24,
      border: `2px solid ${theme.palette.grey[900]}`,
      marginTop: -8,
      marginLeft: -8,
    },
    "&.Mui-disabled > .MuiSlider-track":{
      background: "#bdbdbd"
    }
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `2px solid ${theme.palette.primary.main}`,
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    background: theme.palette.gradient?.light,
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

export default CHLSlider;
