import { makeStyles, Box, Link, colors } from "@material-ui/core";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron_right.svg";
import { FC } from "react";

const CHLHeaderBar: FC<{ title: string, hasLink: boolean, redirectLink?: string }> = (props) => {
    const { title, hasLink, redirectLink } = props;
    const classes = useStyles();

    const style = hasLink ? {'cursor': 'pointer'} : {'cursor': 'normal'}

    const onClickHandler = () =>{
        window.open(redirectLink, "_blank")
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.barContainer}
            style={style}
            {...(hasLink && {onClick: onClickHandler})}
        >
        <span className={classes.title}>
          {title}
        </span>
        {hasLink && <ChevronRight/>}
      </Box>
    )
}

const useStyles =  makeStyles((theme) => ({
    barContainer: {
        background: '#F3F4F9',
        borderRadius: '6px',
        padding: '10px'
    },
    title: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '27px',
      color: theme.palette.secondary.main
    }
}))

export default CHLHeaderBar;

