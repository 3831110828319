import { createSlice } from "@reduxjs/toolkit";
import { CODE } from "../../utilities/constants"

const initialState = {
  token: null,
  aipToken: null,
  slpToken: null,
  authCode: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      const { payload } = action;
      state.token = payload;
      sessionStorage.setItem(CODE.TOKEN, payload)
    },
    setAIPToken(state, action) {
      const { payload } = action;
      state.aipToken = payload;
    },
    setLendelaSLpToken(state, action){
      const { payload } = action;
      state.slpToken = payload;
    },
    setLendelaAuthCode(state, action){
      const { payload } = action;
      state.authCode = payload;
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = authSlice;

export const AuthActions = actions;
export default reducer;
