import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useLayoutEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as ErrorLogo } from "../../assets/images/404.svg";

function NoMatch() {
  const isWidthGreater500 = useMediaQuery("(min-width: 500px)");
  useLayoutEffect(() => {
    document.title = "Not Found";
  }, []);

  return (
    <>
      <AppBar elevation={0} position="relative">
        <Toolbar>
          <Box flex={1} />
          <Logo />
          <Box flex={1} />
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={5}>
          <Typography component="div">Error Page</Typography>
        </Box>
        <Typography variant="h4" component="div" color="primary">
            <Box fontWeight="bold">404</Box>
        </Typography>
        <Box my={2}>
          <Typography>
            Oops! We're sorry. The page you're looking for is no longer available.
          </Typography>
        </Box>
        <Box mt={-2} textAlign="center">
          <ErrorLogo width={!isWidthGreater500 ? "100%" : undefined} />
        </Box>
      </Container>
    </>
  );
}

export default NoMatch;
