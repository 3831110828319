import { FC, ReactNode, forwardRef } from "react";
import { Box, FormControl, Typography } from "@material-ui/core";
import CHLLabel from "../CHLLabel";

interface ICHLValueField {
  label?: string;
  labelRight?: ReactNode | string;
  required?: boolean;
  bold?: boolean;
  value?: any;
  error?: boolean;
  helperText?: string;
  description?: string;
}

// const CHLValueField: FC<ICHLValueField> = (props) => {
  const CHLValueField = forwardRef<any, ICHLValueField>((props, ref) => {
  const {
    label,
    labelRight,
    required,
    bold = true,
    value,
    error,
    // helperText = " ",
    description,
  } = props;
  return (
    <FormControl error={error} fullWidth>
      <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
      />

      <Typography component="div">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={32}
        >
          <Box style={{"wordBreak": "break-word"}} color="#9A9A9A">
            {value}
            {/* {typeof value === "string" ? ReactHtmlParser(value) : value} */}
          </Box>
          <Box>{labelRight}</Box>
        </Box>
      </Typography>
      {/* <FormHelperText>{helperText}</FormHelperText> */}
    </FormControl>
  );
});

export default CHLValueField;
