
import { CODE, DEFAULT_VALUE } from "./constants"

export const BUTTON_LABEL = {
    backToHome: "Back To Home",
    back: "Back",
    backToMerchant: "Back To Merchant",
    BACK_TO_CIMB_WEBSITE: "Back to CIMB Website",
    CLOSE: "Close",
    REJECT_LOAN: "Reject Loan",
    ACCEPT_LOAN: "Accept Loan",
    SUBMIT: "Submit",
}

export const SUMMARY_CODE_LABEL = {
    [CODE.APPLIC_AMOUNT]: "Loan Amount",
    [CODE.APPLIC_TENURE]: "Loan Tenure",
}

export const CODE_LABEL = {
    [CODE.CIMB_INTEREST_RATE]: "Interest Rate (p.a.)",
    [CODE.APPLIC_AMOUNT]: "Requested Loan Amount",
    [CODE.CPF_HISTORY]: "CPF Contribution History",
    [CODE.NOA]: "Notice of Assessment",
    [CODE.MCC_CPF_HISTORY]: "CPF Contribution History",
    [CODE.MCC_NOA]: "Notice of Assessment",
    [CODE.PDPA_PHONE]: "",
    [CODE.PDPA_SMS]: "",
    [CODE.MCC_PDPA_PHONE]: "",
    [CODE.MCC_PDPA_SMS]: "",
    [CODE.ACKNOWLEDGEMENT]: "",
    [CODE.ACKNOWLEDGEMENT_EMAIL]: "Notice And Consent In Respect Of Products And/Or Services Offered By CIMB Bank Berhad",
    [CODE.ACKNOWLEDGEMENT_EMAIL_OPTION]: "I agree to receive my loan monthly statement via the email provided in this application (which may not be the email in the Bank's records). I also acknowledge that a loan repayment account will be opened for every loan approved pursuant to this application and there will not be transaction alerts enabled for the loan repayment account.",
    [CODE.ACKNOWLEDGE_PDPA_OPTION]: `I have read and agree to the terms of the 
        <a target="blank" href="https://www.cimb.com.sg/content/dam/cimbsg/personal/document/loans/others/declaration-personal-loan-applicant.pdf">Customer Declaration and Confirmation</a>, 
        <a target="blank" href="https://www.cimb.com.sg/en/personal/help-support/regulations-policies/personal-data-protection-act.html">Terms and Conditions Governing Personal Data Protection Act 2012</a> and 
        <a target="blank" href="https://www.cimb.com.sg/content/dam/cimbsg/personal/document/loans/terms-and-conditions/tnc-personal-loan.pdf">General Terms and Conditions Governing Personal Loans</a> 
        (including applicable <a target="blank" href="https://www.cimb.com.sg/content/dam/cimbsg/personal/document/loans/others/personal-loan-product-terms.pdf">Product Terms</a>).`,
    [CODE.MCC_ACKNOWLEDGEMENT]: "Notice And Consent In Respect Of Products And/Or Services Offered By CIMB Bank Berhad",
    [CODE.MCC_ACKNOWLEDGE_PDPA_OPTION]: "I have read and agree to the terms of the Customer Declaration and Confirmation, Terms and Conditions Governing Personal Data Protection Act 2012, CIMB Credit Cards Cardmember’s Agreement, and Terms and Conditions governing CIMB Micro Credit Card.",
    [CODE.ACKNOWLEDGE_CPF_NOA]: "",
    [CODE.ACKNOWLEDGE_CPF_NOA_OPTION]: "I acknowledge that I have submitted my latest 24 months CPF/NOA Contribution History Statement(s) via MyInfo.",
};

export const CODE_SUB_LABEL = {
    [CODE.APPLIC_AMOUNT]: "I want to borrow"
}

export const APP_COMPLETION = {
    [DEFAULT_VALUE.APPROVE]: {
        title: "Congratulations!",
        header: "Thank you for your CIMB Personal Loan application. Your application has been approved.",
        message: "A copy of the Loan Approval Letter has been sent to your e-mail. To access, please enter password with your last 4 digit of NRIC@Month & Year of your date of birth. Example: 656F@Jan2021"
    },
    [DEFAULT_VALUE.REJECT]: {
        title: "We're Sorry!",
        header: "Application Rejected",
        message: "We regret to inform you that your CIMB Personal Loan application is unsuccessful.",
        message_AIP: "We're sorry to see that you have decided to not proceed with your CIMB Personal Loan application. We look forward to serving you again in the near future."
    },
    AIP: {
        title: "Thank You for Your Application!",
        header: "Application Submitted",
        message: "Thank you for applying for our CIMB Personal Loan. We have received your application and will notify you via e-mail and/or SMS regarding the status of your application."
    }
}

export const BNPL_APP_COMPLETION = {
    [DEFAULT_VALUE.APPROVE]: {
        title: "Congratulations!",
        header: "Thank you for your CIMB Buy-Now-Pay-Later application. Your application has been approved.",
        message: "A copy of the Approval Letter has been sent to your e-mail. To access, please enter password with your last 4 digit of NRIC@Month & Year of your date of birth. Example: 656F@Jan2021"
    },
    [DEFAULT_VALUE.REJECT]: {
        title: "We're Sorry!",
        header: "Application Rejected",
        message: "We regret to inform you that your CIMB Buy-Now-Pay-Later application is unsuccessful.",
    },
    AIP: {
        title: "Thank You for Your Application!",
        header: "Application Submitted",
        message: "Thank you for applying for our CIMB Buy-Now-Pay-Later. We have received your application and will notify you via e-mail and/or SMS regarding the status of your application."
    }
}

export const ML_APP_COMPLETION = {
    [DEFAULT_VALUE.APPROVE]: {
        title: "Congratulations!",
        header: "Thank you for your CIMB Micro Loan application. Your application has been approved.",
        message: "A copy of the Approval Letter has been sent to your e-mail. To access, please enter password with your last 4 digit of NRIC@Month & Year of your date of birth. Example: 656F@Jan2021"
    },
    [DEFAULT_VALUE.REJECT]: {
        title: "We're Sorry!",
        header: "Application Rejected",
        message: "We regret to inform you that your CIMB Micro Loan application is unsuccessful.",
        message_AIP: "We're sorry to see that you have decided to not proceed with your CIMB Micro Loan application. We look forward to serving you again in the near future.",
    },
    AIP: {
        title: "Thank You for Your Application!",
        header: "Application Submitted",
        message: "Thank you for applying for our CIMB Micro Loan. We have received your application and will notify you via e-mail and/or SMS regarding the status of your application."
    }
}

export const MCC_APP_COMPLETION = {
    [DEFAULT_VALUE.APPROVE]: {
        title: "Congratulations!",
        header: "Thank you for your EZ-Link Micro Credit application. Your application has been approved.",
    },
    [DEFAULT_VALUE.REJECT]: {
        title: "We're Sorry!",
        header: "Application Rejected",
        message: "We regret to inform you that your application to EZ-Link Micro Credit is unsuccessful.",
    },
    [DEFAULT_VALUE.IN_PROGRESS]: {
        title: "Thank You for Your Application!",
        header: "Your Application is currently in progress.",
        message: "Your application reference number is"
    }
};

export const LABEL = {
    APPLY_NO: "Application reference number:",
    LOAN_AMOUNT: " loan amount:",
    APP_COMPLETE_NOTE: "Note: Your applied loan amount is subjected to the Bank's discretion. The final loan amount may differ from your requested amount.",
    NOTICE: "Notice",
}

export const ROAD_SHOW_LABLE = {
    [DEFAULT_VALUE.YES]: {
        title: "Thank You For Your Consent!",
        header: "Application In Progress",
        message: "We have received your application and will notify you via e-mail and/or SMS regarding the status of your application."
    },
    [DEFAULT_VALUE.NO]: {
        title: "We're Sorry!",
        header: "Application Terminated",
        message: "We’re sorry to see that you have decided not to proceed with our CIMB Personal Loan. We would love to have you back should you require our CIMB Personal Loan services in the future.",
    },
    [DEFAULT_VALUE.INVALID]: {
        title: "We're Sorry!",
        header: "Consent is invalid",
        message: "We're sorry, it seems that the consent is invalid.",
    }
}

export const UPLOAD_FILES_LABEL = {
    [DEFAULT_VALUE.SUCCESS]: {
        title: "Thank You for Your Submission!",
        header: "Application in Progress",
        message: "We have received your documents and will notify you via email and/or SMS regarding the status of your application."
    },  
    [DEFAULT_VALUE.INVALID]: {
        title: "We're Sorry!",
        header: "Submission Unsuccessful",
        message: "We're sorry, it seems that the submission was unsuccessful. Please try again.",
    }
}