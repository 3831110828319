import {useEffect, useState} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import useScript from './hooks/useScripts';
import {Box} from "@material-ui/core";
import {CHLOverlayLoading} from "../../../components"

const ndiScript = window._env.REACT_APP_SINGPASS_QRLOGIN_SCRIPT
const clientID = window._env.REACT_APP_MYINFO_APP_CLIENT_ID
const redirectUri = window._env.REACT_APP_MYINFO_APP_REDIRECT_URI

const SingpassLogin = () =>{
    // useScript(false, ndiScript);
    const innerScript = `
    const authParamsSupplier = async () => {
            return { state: "Q0lNQlNHMDAxOlAyMDIwMTEwOTAwMDAwMTo0", nonce: "Q0lNQlNHMDAxOlAyMDIwMTEwOTAwMDAwMTo0"};
        };

        const onError = (errorId, message) => {
            console.log(errorId, "message: ",  message);
        };

        const initAuthSessionResponse = window.NDI.initAuthSession(
            'ndi-qr',
            {
                clientId: '${clientID}',                               
                redirectUri: '${redirectUri}',  
                scope: 'openid',
                responseType: 'code'
            },
            authParamsSupplier,
            onError,
        );

        console.log('initAuthSession: ', initAuthSessionResponse);
    `
    // useScript(true, innerScript)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        // const authoriseUrl =
        // window._env.REACT_APP_MYINFO_API_AUTHORISE +
        // "?client_id=" +
        // window._env.REACT_APP_MYINFO_APP_CLIENT_ID +
        // "&attributes=uinfin,name," +
        // "&purpose=Resume Application" +
        // "&state=" +
        // btoa(queryParams.toString()) +
        // "&redirect_uri=" +
        // window._env.REACT_APP_MYINFO_APP_REDIRECT_URI;
        // // + window.location.host 
        // // + window._env.REACT_APP_MYINFO_APP_REDIRECT_URI 

        // console.log(authoriseUrl)

        // window.location.href = authoriseUrl;
    }, [])
    return (
        <div id="ndi-qr"></div>
        // <Box display="flex" justifyContent="center" mt={6}>
        //     <CHLOverlayLoading/>
        // </Box>
    )
}
export default SingpassLogin;