import { forwardRef, ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { RespAppField, RespRender } from "../../../../types/response";
import { CODE } from "../../../../utilities/constants"

type Field = Pick<RespAppField, "field">;
interface IWatchField extends Partial<Field> {
  control: any;
  render?: RespRender;
  onChange?: any;
  isDirty?: boolean;
  currentValue?: any;
  defaultValue?: any;
  controllercode?: string;

  // for field
  children?: ReactNode;

  // for option
  text?: string;
  value?: string | number;
  component?: any;
  index?: number
}

const WatchedComponent = forwardRef<any, IWatchField>((props, ref) => {
  let { control, render } = props;
  const { field, controllercode } = props;
  const { isDirty, currentValue, defaultValue, onChange } = props;
  const { children, text, value, component, ...rest } = props;

  let name: string[] = [];
  let dependValues: (string | number)[] = [];

  const hasRenderCondition = render && render.fields && render.fields.length;
  if (render && hasRenderCondition) {
    name = render.fields.map((field) => {
      // for table type
      if (controllercode)
        return controllercode.replace(/\.[^.]*$/, `.${field.code}`);
      // for data entry type
      else return field.code;
    });
    dependValues = render.fields.map((field) => field.value);
  }

  const values = useWatch({ control, name })


  // let dependentName = ""
  // if(field?.code === CODE.PAYNOW_ID){
  //   dependentName = CODE.PAYNOW_ID_TYPE
  // }

  // const v3 = useWatch({ control: control, name: dependentName });
  // if(field?.code === CODE.PAYNOW_NRIC){
    // if(v3 === CODE.NRIC && !isDirty){
      // setTimeout(() => onChange && onChange(currentValue), 100);
    // }
  // }

  const isAllEqual = (a: any[], b: any[]) =>
    JSON.stringify(a) === JSON.stringify(b);

  // Todo [Eric]
  // const isAnyEqual = (a: any[], b: any[]) =>
  //   a.some((value, index) => value === b[index]);

  

  if (isAllEqual(values, dependValues)) {
    if (!children) {
      const Component = component;
      return (
        <Component ref={ref} value={value} {...rest}>
          {text}
        </Component>
      );
    } else {
      // if (field) setDefaultValue({ field }, defaultValue, isDirty, onChange);
      return <>{children}</>;
    }
  } else {
    if(!(field?.code == CODE.PAYNOW_ID && currentValue)){
      // if (currentValue !== defaultValue) setTimeout(() => onChange && onChange(defaultValue), 100);
      setTimeout(() => onChange && onChange(null), 100);
    }



    // else{
    //   if (currentValue !== defaultValue)
    //   setTimeout(() => onChange && onChange(defaultValue), 100);
    // }
    return null;
    
    ;
  }
});



function setDefaultValue(
  { field }: Field,
  defaultValue: any,
  isDirty?: boolean,
  onChange?: any
) {
  if (defaultValue) return;

  const { dataType, fieldType, options } = field;
  if (fieldType === "RadioButton" && options && options.length && !isDirty) {
    let value: any;
    if (dataType === "Integer" || dataType === "Float")
      value = parseFloat(options[0].code);
    else value = options[0].code;
    
    setTimeout(() => onChange && onChange(value), 100);
  }
}

export default WatchedComponent;
