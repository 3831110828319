import {
  createTheme,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core";

const method =
  window._env.NODE_ENV === "development"
    ? unstable_createMuiStrictModeTheme
    : createTheme;
export const theme = method({
  palette: {
    primary: {
      main: "#FF0000",
      light: "#C4161C",
      dark: "#780000",
    },
    secondary: {
      main: "#333739",
    },
    grey: {
      "100": "#F2F4FA",
      "900": "#9A9A9A",
    },
    gradient: {
      main: "linear-gradient(111.03deg, #E200BC, #FF0000, #F70032)",
      light: "linear-gradient(105.77deg, #E200BC, #F70032 100%, #FF0000)",
      contrastText: "#fff",
    },
    action: {
      disabled: "#F5F5F5",
    },
  },
  typography: {
    body1:{
      color: "#333739",
    },
    h6: {
      fontSize: "1.125rem",
    },
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
    button: {
      fontWeight: 700,
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
          height: "100vh",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "15.5px 14px",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1rem",
        marginBottom: 8,
        color: "#333739 !important",
        lineHeight: "22px",
      },
    },
    MuiSlider: {
      rail: {
        opacity: 1,
        backgroundColor: "#EEEEEE",
      },
      marked: {
        marginBottom: 36,
      },
      mark: {
        opacity: 0,
      },
      markLabel: {
        top: 35,
      },
    },
  },
});
