import { Box, Grid, Typography } from "@material-ui/core";
import { FC, Fragment, useContext, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ReactComponent as AddIcon } from "../../../../assets/images/icon_add.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/images/icon_remove.svg";
import { ApplicationFormContext } from "./ApplicationForm";
import { ApplicationSummaryContext } from "./ApplicationSummary";
import ApplicationController from "./ApplicationController";
import { CHLDialog } from "../../../../components";
import {  } from "../../../../utilities/constants";

interface IFormTable {
  id: string;
  isSummary?: boolean;
}

const MAX_CRS_NUMBER = 3

const FormTable: FC<IFormTable> = (props) => {
  const { id, isSummary } = props;
  const context = useContext(
    // isSummary ? ApplicationFormSummaryContext : ApplicationFormContext
    isSummary ? ApplicationSummaryContext : ApplicationFormContext
  );
  const { fields, append, remove } = useFieldArray({
    control: context?.control,
    name: id,
  });
  const [crsCountry, setCRSCountry] = useState<any[]>(new Array(MAX_CRS_NUMBER))
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onCRSCountryChange = (val: string, prefix: string, onChange: any, setValue: any) =>{
    const index = parseInt(prefix.split(".")[1])
    let value = null
    if(crsCountry.includes(val)){
      // alert(val)
      // onChange(value)
      setIsOpen(true)
    }else{
      value = val
      // onChange(val)
    }

    onChange(value)

    crsCountry[index] = value
    setCRSCountry(crsCountry);
    
  }

  if (!context) return null;

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Fragment key={index}>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Tax Residence #{index + 1}</Typography>
                {index !== 0 && (
                  <RemoveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => remove(index)}
                  />
                )}
              </Box>
            </Grid>

            {Object.keys(field).map((key) => {
              const f = context.fields.find((f) => f.field.code === key);
              if (!f) return null;

              return (
                <ApplicationController
                  key={`${field.id}.${key}`}
                  length={fields.length * (Object.keys(field).length - 1)}
                  required={f.required}
                  name={f.name}
                  controllerCode={`${id}.${index}.${key}`}
                  isSummary={isSummary}
                  field={f.field}
                  onCRSCountryChange={onCRSCountryChange}
                />
              );
            })}
          </Fragment>
        );
      })}

      {fields.length < MAX_CRS_NUMBER && (
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{ cursor: "pointer" }}
            onClick={() =>
              append({ ...Object.assign({}, ...context.defaultValues[id]) })
            }
          >
            <Box mr={1}>Add Tax Residence</Box>
            <AddIcon />
          </Box>
        </Grid>
      )}
      {isOpen && 
        <CHLDialog
          hideClose={true}
          open={isOpen}
          title="Notice"
          onClose={() => setIsOpen(false)}
          actions={[
            {
              title: "Close",
              color: "secondary",
              onClick: () => setIsOpen(false)
            }]}
          >
          <Typography>This Country/Jurisdiction of Tax Residence has been selected.</Typography>
        </CHLDialog>}
    </>
  );
};

export default FormTable;
