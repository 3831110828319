import { FC, useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
} from "@material-ui/core";
import { CheckboxContext } from ".";
import clsx from "clsx";
import parse from "html-react-parser";
import CheckboxIcon from "../../assets/images/checkbox.svg";
import { CODE } from "../../utilities/constants";

interface ICHLCheckboxButton extends FormControlLabelProps{
  index?: number
}

const CHLCheckboxButton: FC<ICHLCheckboxButton> = (props) => {
  const { children, value, index, ...rest } = props;
  const classes = useStyles();
  const context = useContext(CheckboxContext);

  let isChecked = false
  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    if(typeof context?.value == "string"){
      // if(value === CODE.AGREE_EMAIL){
      //   context?.onChangeHandler(value, index)
      //   isChecked = true //hardcoded to checked if value is agreeEmail, user cannot uncheck
      // }else{
        isChecked = context?.value === value;
      // }
    }else{
      isChecked = context?.value.includes(value)
    }
    setChecked(isChecked)
  }, [])

  const onChangeHandler = (v: any) =>{
    // if(v.target.value !== CODE.AGREE_EMAIL){
      setChecked(!checked)
      context?.onChangeHandler(v.target.value, index)
    // }
  }

  return (
    <Box>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            classes={{
              root: classes.root,
            }}
            onChange={onChangeHandler}
            // onChange={(v) => {
            //   context?.onChangeHandler(v.target.value, index)
            //   // if (checked) context?.onChange("");
            //   // else context?.onChange(v);
              
            // }}
            value={value}
            checked={checked}
            color="primary"
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            disableRipple
            icon={<span className={classes.icon} />}
          />
        }
        label={
          typeof children === "string" ? parse(children) : children
        }
      ></FormControlLabel>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  label: {
    alignItems: "baseline",
    marginRight: 0,
  },
  labelBold: {
    fontWeight: 700,
  },
  helperText: {
    marginLeft: 30,
    marginTop: 0,
  },
  icon: {
    backgroundColor: "#ECECEC",
    borderRadius: 4,
    marginLeft: 2,
    width: 20,
    height: 20,
    transform: 'translateY(6px)',
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#ECECEC",
    transform: 'translateY(6px)',
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: `url(${CheckboxIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      content: '""',
    },
  },
  required: {
    color: theme.palette.primary.main,
  },
}));

export default CHLCheckboxButton;
