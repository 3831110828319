import { createAsyncThunk } from "@reduxjs/toolkit";
import MD5 from "crypto-js/md5"
import { AppDispatch } from "..";
import { store } from "../../stores";
import { CODE, AIP_SECRET_KEY } from "../../utilities/constants"
import { polishMyInfoData } from "../../utilities/helper"
import { MyInfoActions } from "../reducers/myinfo";
import { ApplicationPageRequest } from "../../services/requests/ApplicationPageRequest";
import { ResumeApplicationRequest } from "../../services/requests/ResumeApplicationRequest";
import { ReqResumeApplication, ReqInPrincipleApproval, ReqDictionary } from "../../types/request";
import { RespIPAMetaData, RespRejectTemplate, RespIPATemplate } from "../../types/response";
import { ResumeApplicationActions } from "../reducers/resumeApplication";

export const resumeApplication = createAsyncThunk<
any,
ReqResumeApplication,
{ dispatch: AppDispatch } // ThunkApi Field Type
>("resumeApplication/fetchForms", async (data, { dispatch }) => {
  try {
    dispatch(ResumeApplicationActions.setIsLoading(true));

    let reqParams: ReqInPrincipleApproval;
    const applyNo = data.applyNo

    // if(data.myInfo){

      const { myinfo } = store.getState()
      let myInfoData: {[key: string]: any}  = myinfo.data

      if(Object.keys(myInfoData).length == 0){
        const myInfoResult = await ApplicationPageRequest.fetchMyInfoPerson(data.myInfo)
        myInfoData = myInfoResult.data

        dispatch(
          MyInfoActions.setData(
            polishMyInfoData(myInfoData)
          )
        );
      }
      
      const nric = myInfoData[CODE.NRIC]
      const hToken = MD5(data.applyNo + nric + AIP_SECRET_KEY).toString()

      reqParams = {
        applyNo,
        hToken, 
        nric
      }
    // }else{
    //   reqParams = {
    //     applyNo,
    //     token: data.token
    //   }
    // }

    // const results = await ResumeApplicationRequest.fetchResumeAppForm(data);
    const results = await ResumeApplicationRequest.fetchResumeApplication(reqParams);
    const resData = results.data

    if(resData){
      dispatch(ResumeApplicationActions.setResumeApplication(resData));
      
      if(resData.payload.reject){
        const reject:RespIPAMetaData = resData.payload.reject
        const fields: RespRejectTemplate[] = reject.applicationTemplateTabFields
        const dictionaryField = fields.filter((item: RespRejectTemplate) => item.field.fieldType === "Dictionary")[0]


        const book = dictionaryField.field.dictCode
        const label = dictionaryField.field.dictLabel

        if(book && label){
          const dictData: ReqDictionary = {book, label}
          dispatch(ResumeApplicationActions.setDictionaryRoot(label));

          const dictionaryResult = await ResumeApplicationRequest.fetchDictionary(dictData);
          dispatch(ResumeApplicationActions.setDictionary(dictionaryResult.data));
        }
      }
    }else{

    }
  } catch (err) {
    Promise.resolve(err)
    throw err;
  } finally {
    dispatch(ResumeApplicationActions.setIsLoading(false));
  }
});
