import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RespAppTemplate, RespResumeApplication, RespProduct, RespDictionary } from "../../types/response";

const initialState = {
  node: "",
  resumeApplicationForm: <RespAppTemplate>{},
  ipaData: <any>{},
  product: <RespProduct>{},
  dictionary: <RespDictionary>{},
  dictionaryRoot: "",
  isLoading: false,
  authCode: "",
  callbackUrl: <any>"", //temporary: getting callbackURL from API for external AIP url
}

const resumeApplicationSlice = createSlice({
  name: "resumeApplication",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setResumeApplication: (state, { payload }: PayloadAction<RespResumeApplication>) => {
      state.authCode = payload.authCode
      state.node = payload.node;
      state.resumeApplicationForm = payload.payload
      state.product = payload.product
      
      state.ipaData = payload.answers
      state.callbackUrl = payload.callbackUrl
    },
    setDictionaryRoot: (state, { payload }: PayloadAction<string>) => {
      state.dictionaryRoot = payload
    },
    setDictionary: (state, { payload }: PayloadAction<RespDictionary>) => {
      state.dictionary = payload
    }
  },
});

const { actions, reducer } = resumeApplicationSlice;

export const ResumeApplicationActions = actions;
export default reducer;
