import { useLayoutEffect, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import { useLocation, useHistory } from "react-router-dom";
import { 
  BE_CODE_STATUS, 
  REDIRECT_COUNT_DOWN, 
  DEFAULT_VALUE 
} from "../../../utilities/constants";
import { 
  BUTTON_LABEL, 
  LABEL, 
  ML_APP_COMPLETION,
  APP_COMPLETION 
} from "../../../utilities/label";
import { CHLButton, CHLCustomerService } from "../../../components"
import { ReactComponent as FailIcon } from "../../../assets/images/fail.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { ReactComponent as OctopusSuccess } from "../../../assets/images/oct_success.svg";
import { ReactComponent as OctopusFail } from "../../../assets/images/oct_fail.svg";


const ResumeCompletePage = () => {
  const { state } = useLocation<{ 
    code: string, 
    applyNo: string, 
    applicAmount: string | number, 
    decision: string, 
    productCode?: string,
    currency?: string,
    callbackURL?: string 
  }>();
  const { 
    code, 
    applyNo, 
    applicAmount, 
    decision, 
    productCode,
    currency = 'SGD',
    callbackURL 
  } = { ...state };
  const history = useHistory();

  let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);

  const amount = currency + ' ' + (
    typeof applicAmount === "string" 
    ? parseFloat(applicAmount).toLocaleString(undefined, {minimumFractionDigits: 2})
    : typeof applicAmount === "number"
    ? applicAmount.toLocaleString(undefined, {minimumFractionDigits: 2})
    : applicAmount
  );
  const RESUME_COMPLETION = productCode === "P00002" ? ML_APP_COMPLETION : APP_COMPLETION;

  const redirectToMerchant = () =>{
    window.localStorage.clear()
    window.sessionStorage.clear()

    if(callbackURL){
      if(callbackURL != ''){
        window.location.href = callbackURL;
      }
    }
  }

  useLayoutEffect(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()

    if(code){
      if(code === BE_CODE_STATUS.SUCCESS){
        const countDownTimer = setInterval(() => {
          timer--;
          setTimer(timer)
          if(timer === 0){
            redirectToMerchant()
          }
        }, 1000)
        return () => {
          clearInterval(countDownTimer)
          setTimer(REDIRECT_COUNT_DOWN)
        }
      }
    }else{
      history.push({
        pathname: "/403"
      });
    }
  }, [])

  return (
    <Box mt={10}>
      <>
        <Typography align="center" variant="h5" component="div">
          <Box 
            fontWeight="bold" 
            color={
              decision === DEFAULT_VALUE.REJECT ? "#FF0000" : "#16C0B7"
              }>
              {RESUME_COMPLETION[decision].title}
          </Box>
        </Typography>
        <Box display="flex" justifyContent="center" my={3}>
          {decision === DEFAULT_VALUE.REJECT ? 
            <FailIcon/> : <SuccessIcon/>
          }
        </Box>
        <Typography align="center" component="div">
          <Box fontWeight="bold">
            {RESUME_COMPLETION[decision].header}
          </Box>
          <Box my={3}>
            {RESUME_COMPLETION[decision].message_AIP}
          </Box>
          {decision !== DEFAULT_VALUE.REJECT && <>
          <Box my={3}>
          <Box fontWeight="bold">{LABEL.APPLY_NO}</Box>
            <Box>{applyNo}</Box>
          </Box>
            <Box fontWeight="bold" mt={3}>{
              `Approved${LABEL.LOAN_AMOUNT}` 
              }
            </Box>
            <Box>{amount}</Box>
          </>}
        </Typography>
        <Box display="flex" justifyContent="center" my={5}>
          {decision === DEFAULT_VALUE.REJECT ? 
            <OctopusFail/> : <OctopusSuccess/>
          }
        </Box>
        {decision === DEFAULT_VALUE.REJECT && <Box my={5} textAlign="center">
          <CHLCustomerService/>
        </Box>
        }
      </>
      <Box my={3} display="flex" justifyContent="center">
        {callbackURL && <CHLButton
          title={BUTTON_LABEL.back + " (" + timer + "s)"}
          color="secondary"
          onClick={() => redirectToMerchant()}
          />
        }
      </Box>
    </Box>
  );
};

export default ResumeCompletePage;
