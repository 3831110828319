import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { ReqApplicationPage } from "../../types/request";
import { ApplicationPageRequest } from "../../services/requests/ApplicationPageRequest";
import { ApplicationActions } from "../reducers/application";
import { RespAppMetaData } from "../../types/response";
import { BE_CODE_STATUS } from "../../utilities/constants"

export const fetchForms = createAsyncThunk<
  any, // Return Type
  ReqApplicationPage, // Passing Value Type
  { dispatch: AppDispatch } // ThunkApi Field Type
>("application/fetchForms", async (data, { dispatch }) => {
  try {
    const isManualFlow = data.isManualFlow
    dispatch(ApplicationActions.setLoading(true));
    const result = await ApplicationPageRequest.fetchForms(data);
    let res: RespAppMetaData[] = []

    
    if(result.code === BE_CODE_STATUS.SUCCESS){
      const dataPayload = result.data
      res = Object.values(dataPayload).map((v):any =>{
        return v
      })
    }else{
      res = []
    }
    dispatch(ApplicationActions.setApplicationStatus(result));
    dispatch(ApplicationActions.setData({ res, isManualFlow }));
  } catch (err) {
    throw err;
  } finally {
    dispatch(ApplicationActions.setLoading(false));
  }
});
