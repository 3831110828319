import { createContext, FC, Fragment, useState } from "react"
import { useSelector } from "react-redux";
import {
    Box,
    Collapse,
    Grid,
    Hidden,
    Theme,
    Typography,
    useMediaQuery,
    makeStyles,
  } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { createYupSchema } from "../../../../utilities/helper";
import CollapseHeader from "./CollapseHeader";
import { RootState } from "../../../../stores/rootReducer";
import { MCC_CAPTION_SUMMARY, MCC_CAPTION_SUMMARY_MANUAL, CODE, FORM_TYPE } from "../../../../utilities/constants";
import { CODE_LABEL, SUMMARY_CODE_LABEL} from "../../../../utilities/label";
import { CHLButton, CHLValueField, CHLCustomerService, CHLNumberWithAreaCodeField } from "../../../../components";
import { selectParentsByIndex } from "../../../../stores/reducers/application";
import { RespAppField, RespPlaceholder } from "../../../../types/response";
import FormDataEntry from "./FormDataEntry";
import ClickableValueField  from "./ClickableValueField"

interface IApplicationFormSummary {
    activeIndex: number;
    onSubmit: () => void;
    onBack: () => void;
}
interface IApplicationSummaryContext {
    register: any;
    control: any;
    defaultValues: any;
    fields: RespAppField[];
    activeIndex: number;
    isManualFlow: boolean;
    setValue?: any;
    getValues?: any;
    setFocus?: any;
    nric?: string;
    placeholders?: RespPlaceholder;
}

export const ApplicationSummaryContext = createContext<IApplicationSummaryContext | null>(null);

const ApplicationSummary: FC<IApplicationFormSummary> = (props) =>{
    const classes = useStyles();
    const { activeIndex, onSubmit, onBack } = props;
    const [expands, setExpands] = useState<boolean[]>([]);
    const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

    const { summaryData, isManualFlow, documentUpload } = useSelector((s: RootState) => s.application);
    const currentParent = useSelector(selectParentsByIndex(activeIndex));
    const template = currentParent.reduce<RespAppField[]>((prev, current) => {
        let tabFields = current.applicationTemplateTabFields;
        return prev.concat(tabFields);
    }, []);
    const tabs = isManualFlow ? MCC_CAPTION_SUMMARY_MANUAL : MCC_CAPTION_SUMMARY;
    const form = template.map(({ field: { code, value } }: any) => ({
        [code]: value ?? null,
    }));

    const defaultValues = Object.assign({}, ...form);
    const { control, handleSubmit, register } = useForm({
        defaultValues,
        resolver: createYupSchema(template, isManualFlow),
    });

    const setLengthOfEmploymentValue = (val: string) =>{
        const v = val.split("-")
        return v[0] + " Years " + v[1] + " Months"
    }


    return (
        <>
            {summaryData.map((d, i) => {
                const {fields, type, name} = d
                const caption = tabs.find((c) => c.NAME === d.name);
                const isCaptionExisted = caption?.TITLE;
                const isAddressDetailTab = caption?.NAME === CODE.MCC_ADDRESS_DETAIL_CAP_NAME;
                if(!fields) return null;
                // name is originalName
                // if(name === CODE.MCC_DOCUMENT_UPLOAD) return null;

                return(
                    <Fragment key={"summaryHeader"+i}>
                        <CollapseHeader
                            key={"collapseHeader"+i}
                            isSummary={true}
                            name={d.name}
                            expand={!!expands[i]}
                            onClick={() => {
                                expands[i] = !expands[i];
                                setExpands([...expands]);
                            }}
                        />
                        <Collapse in={isCaptionExisted ? expands[i] : true}>
                            {isAddressDetailTab && <p className={classes.darkText}>{caption?.SUBTITLE || ""}</p>}
                            <Box key={"Box"+i} mt={2}/>
                            <Grid key={"Grid"+i} container spacing={smAndUp ? 4 : 2}>
                                {type === FORM_TYPE.DATAENTRY && (
                                    fields.map((f, idx) => {
                                        if(f){
                                            if(f.code === CODE.ADDRESS) return null;
                                            if(f.value){
                                                if(f.code === CODE.MCC_FAKE_MOBILE || f.code === CODE.MCC_FAKE_SECONDARY_CONTACT){
                                                    return (
                                                        <Grid item xs={12} sm={6} key={idx}>
                                                        <CHLValueField
                                                            key={"CHLNumberWithAreaCodeField" + idx}
                                                            label={f.label}
                                                            value={f.value.replace("#","")}
                                                        />
                                                    </Grid> 
                                                    )
                                                }
                                                return (
                                                <Grid item xs={12} sm={6} key={idx}>
                                                    <CHLValueField
                                                        key={"CHLVal"+idx}
                                                        label={SUMMARY_CODE_LABEL[f.code] ?? f.label}
                                                        value={[CODE.LENGTH_OF_EMPLOYMENT, CODE.MCC_LENGTH_OF_EMPLOYMENT, CODE.MCC_LENGTH_OF_RESIDENCE].includes(f.code) ? setLengthOfEmploymentValue(f.value) : f.value }
                                                    />
                                                </Grid>)
                                            }
                                        }
                                    }) 
                                )}
                                {type === FORM_TYPE.TABLE && (
                                    name === CODE.MCC_NOA || name === CODE.MCC_CPF_HISTORY ? 
                                    <Box key={i} p={2} style={{width: "100%"}}>
                                        <ClickableValueField 
                                            key={"CHLClickVal"+i}
                                            code={name}
                                            label={CODE_LABEL[name]}
                                        /> 
                                    </Box>
                                    :   
                                    fields.map((f, idx) => {
                                        return(
                                            <Grid item xs={12} sm={6} key={idx}>
                                                <Typography key={idx} color={"primary"}>Tax Residence #{idx + 1}</Typography>
                                                {
                                                    f.map((t:any, j: number) =>{
                                                        if(t.value){
                                                            return (
                                                                <Box my={2}>
                                                                    <CHLValueField
                                                                        key={j}
                                                                        label={t.label}
                                                                        value={t.value}/>
                                                                </Box>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Grid>
                                        )
                                    })
                                )}
                                {type === FORM_TYPE.ATTACHMENT && (
                                    fields.map((f, idx) => {
                                        if(f){
                                            const documentName = documentUpload[f.code]
                                            if(documentName || f.value){
                                                return (
                                                <Grid item xs={12} sm={6} key={idx}>
                                                    <CHLValueField
                                                        key={"CHLVal"+idx}
                                                        label={SUMMARY_CODE_LABEL[f.code] ?? f.label}
                                                        value={documentName ? documentName : f.value}
                                                    />
                                                </Grid>
                                                )
                                            }
                                        }
                                        
                                    }) 
                                )}
                            </Grid>
                        </Collapse>
                    </Fragment>
                )
            })}
            <Box borderTop={1} my={4} borderColor="#BDBDBD" width="100%">
                <Box my={4} fontWeight={900} fontSize="18px">Acknowledgement</Box>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                {currentParent.map((parent, index) =>{
                    const { applicationTemplateTabFields } = parent;
                    return(
                    <Grid key={index} container>
                        <Grid key={"n"+index} container spacing={smAndUp ? 4 : 2}>
                            <ApplicationSummaryContext.Provider
                                value={{
                                register,
                                activeIndex,
                                control,
                                defaultValues,
                                fields: applicationTemplateTabFields,
                                isManualFlow
                                }}>
                                <FormDataEntry isSummary={true} />
                            </ApplicationSummaryContext.Provider>
                        </Grid>
                    </Grid>
                    )
                })}
                <Box mt={4} display="flex" justifyContent="start">
                    <Typography component="div">
                        For any enquiries regarding your application, please contact CIMB Credit Card Call Centre anytime at (65) 6333 6666.
                    </Typography>
                </Box>

                <Grid container item>
                    {activeIndex != 0 && (
                    <Box clone order={{ xs: 3, sm: 1 }}>
                        <Grid item xs={12} sm={3}>
                        <Box my={2}>
                            <CHLButton
                            type="button"
                            title="Back"
                            color="default"
                            fullWidth
                            onClick={onBack}
                            />
                        </Box>
                        </Grid>
                    </Box>
                    )}
                    <Hidden xsDown>
                        <Box clone order={{ xs: 2, sm: 2 }}>
                            <Grid item sm={activeIndex == 0 ? 9 : 6} />
                        </Box>
                    </Hidden>
                    <Box clone order={{ xs: 1, sm: 3 }}>
                        <Grid item xs={12} sm={3}>
                            <Box mt={2}>
                            <CHLButton
                                type="submit"
                                title="Agree and Proceed"
                                color="secondary"
                                fullWidth
                            />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    darkText:{
      color: theme.palette.secondary.main,
      fontSize: "16px"
    },
  })
)

export default ApplicationSummary;