import { forwardRef, ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { RespRejectTemplate, RespRender } from "../../../../types/response";
import { CODE } from "../../../../utilities/constants"

type Field = Pick<RespRejectTemplate, "field">;
interface IWatchField extends Partial<Field> {
  control: any;
  render?: RespRender;
  onChange?: any;
  isDirty?: boolean;
  currentValue?: any;
  defaultValue?: any;
  controllercode?: string;

  // for field
  children?: ReactNode;

  // for option
  text?: string;
  value?: string | number;
  component?: any;
  index?: number
}

const WatchedComponent = forwardRef<any, IWatchField>((props, ref) => {
  let { control, render } = props;
  const { field, controllercode } = props;
  const { isDirty, currentValue, defaultValue, onChange } = props;
  const { children, text, value, component, ...rest } = props;

  let name: string[] = [];
  let dependValues: (string | number)[] = [];

  const hasRenderCondition = render && render.fields && render.fields.length;
  if (render && hasRenderCondition) {
    name = render.fields.map((field) => {
      // for table type
      if (controllercode)
        return controllercode.replace(/\.[^.]*$/, `.${field.code}`);
      // for data entry type
      else return field.code;
    });
    dependValues = render.fields.map((field) => field.value);
  }

  let values = useWatch({ control, name })

  if(hasRenderCondition){
    const dValues = dependValues[0]
    if(values instanceof Array){
      const arrValues: any[] = values[0]

      if(arrValues.length > 0){
        values = arrValues.filter((v) =>{
          return v == dValues
        })
      }
    }
  }


  const isAllEqual = (a: any[], b: any[]) => JSON.stringify(a) === JSON.stringify(b);

  if (isAllEqual(values, dependValues)) {
    if (!children) {
      const Component = component;
      return (
        <Component ref={ref} value={value} {...rest}>
          {text}
        </Component>
      );
    } else {
      return <>{children}</>;
    }
  } else {
    if(!(field?.code == CODE.PAYNOW_ID && currentValue)){
      setTimeout(() => onChange && onChange(null), 100);
    }
    return null;
    
    ;
  }
});

export default WatchedComponent;
