import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RespVerification, RespAppMetaData, RespMyInfo, RespProduct, RespPlaceholder} from "../../types/response";
import {ReqPartnerVerifyData} from "../../types/request"
import { IState } from "../../types/state";

const initialState = {
  callbackURL: undefined,
  authenticatedData: <RespVerification>{},
  partnerForm: <RespAppMetaData>{},
  repaymentForm: <RespAppMetaData>{},
  myInfoData: <RespMyInfo>{},
  productData: <RespProduct>{},
  placeholderData: <RespPlaceholder>{},
  isLoading: false,
  partnerVerifyData: <ReqPartnerVerifyData>{},
  isManualFlow: false,
  partyType: undefined,
  uid: '',
  landingUrl: '',
  crdeValue: undefined,
  progcode: undefined,
}

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setCallbackURL: (state, { payload }) => {
      state.callbackURL = payload
    },
    setPartyType: (state, { payload }) => {
      state.partyType = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthenticatedData: (state, { payload }) => {
      const data = payload.data
      state.authenticatedData = data;
      state.partnerForm = data.payload?.loanCalculator
      state.repaymentForm = data.payload?.repaymentSchedule
      state.myInfoData = data.myInfo
      state.productData = data.product
      state.placeholderData = data.placeholder
    },
    setPartnerVerifyData: (state, { payload }) => {
      state.partnerVerifyData = payload
    },
    setIsManualFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isManualFlow = payload;
    },
    setUid: (state, { payload }) => {
      state.uid = payload;
    },
    setLandingUrl:(state, { payload }) => {
      state.landingUrl = payload;
    },
    setCrdeValue: (state, { payload }) => {
      state.crdeValue = payload;
    },
    setProgcode:  (state, { payload }) => {
      state.progcode = payload;
    },
  },
});

const { actions, reducer } = partnerSlice;

export const PartnerActions = actions;
export default reducer;
