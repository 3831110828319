import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import publicRoutes from "../../../routes/publicRoutes";

const ROUTE_NAME = "mccCallback";
const CallbackPage = () => {
  const parent = publicRoutes.find((route) => route.name === ROUTE_NAME);

  return (
    <Box mt={8} maxWidth={800} margin="auto">
      <Switch>
        {parent?.children?.map((route) => {
          const Component = route.component;
          return (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact ?? true}
              component={Component}
            />
          );
        })}
      </Switch>
    </Box>
  );
};

export default CallbackPage;
