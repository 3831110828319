import { useEffect, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import { useLocation, useHistory } from "react-router-dom";
import { RootState } from "../../../stores/rootReducer";
import { useSelector } from "react-redux";
import {
  DEFAULT_VALUE,
  REDIRECT_COUNT_DOWN,
} from "../../../utilities/constants";
import {
  BUTTON_LABEL,
  MCC_APP_COMPLETION,
  LABEL
} from "../../../utilities/label";
import { CHLButton, CHLCustomerService } from "../../../components"
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { ReactComponent as FailIcon } from "../../../assets/images/fail.svg";
import { ReactComponent as OctopusSuccess } from "../../../assets/images/oct_success.svg";
import { ReactComponent as OctopusFail } from "../../../assets/images/oct_fail.svg";

const MCCCallbackCompletePage = () => {
  let { state } = useLocation<{
    code: string,
    applyNo: string,
    applicAmount: string,
    decision?: string
  }>();
  let {
    code,
    applyNo,
    applicAmount,
    decision
  } = { ...state };
  const desc = decision ? decision : DEFAULT_VALUE.IN_PROGRESS;
  const history = useHistory();

  const { callbackURL }: any = useSelector((s: RootState) => s.partner);

  let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);

  const redirectToMerchant = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.href = callbackURL;
  }

  useEffect(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()

    if (code) {
      const countDownTimer = setInterval(() => {
        timer--;
        setTimer(timer)
        if (timer === 0) {
          redirectToMerchant()
        }
      }, 1000)
      return () => {
        clearInterval(countDownTimer)
        setTimer(REDIRECT_COUNT_DOWN)
      }
    } else {
      history.push({
        pathname: "/403"
      });
    }
  }, [])

  return (
    <Box mt={10}>
      <>
        <Typography align="center" variant="h5" component="div">
          <Box
            fontWeight="bold"
            color={
              desc === DEFAULT_VALUE.REJECT ? "#FF0000" : "#16C0B7"
            }>
            {MCC_APP_COMPLETION[desc].title}
          </Box>
        </Typography>
        <Box display="flex" justifyContent="center" my={3}>
          {desc === DEFAULT_VALUE.REJECT ?
            <FailIcon /> : <SuccessIcon />
          }
        </Box>
        <Typography align="center" component="div">
          <Box fontWeight="bold">
            {MCC_APP_COMPLETION[desc].header}
          </Box>
          {
            (desc === DEFAULT_VALUE.IN_PROGRESS && !applyNo) ? null : <Box my={3}>
              {MCC_APP_COMPLETION[desc].message} {desc === DEFAULT_VALUE.IN_PROGRESS && applyNo}
            </Box>
          }
          {desc === DEFAULT_VALUE.APPROVE &&
            <Box my={3}>
              <Box fontWeight="bold">{LABEL.APPLY_NO}</Box>
              <Box>{applyNo}</Box>
            </Box>
          }
        </Typography>
        <Box display="flex" justifyContent="center" my={5}>
          {desc === DEFAULT_VALUE.REJECT ?
            <OctopusFail /> : <OctopusSuccess />
          }
        </Box>
        {desc !== DEFAULT_VALUE.APPROVE && <Box my={5} textAlign="center">
          <CHLCustomerService />
        </Box>
        }
      </>
      <Box my={2} display="flex" justifyContent="center">
        <CHLButton
          title={BUTTON_LABEL.backToMerchant + " (" + timer + "s)"}
          color="secondary"
          onClick={() => redirectToMerchant()}
        />
      </Box>
    </Box>
  );
};

export default MCCCallbackCompletePage;
