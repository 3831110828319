import { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CHLDialog, CHLOverlayLoading } from "../../../components";
import UploadForm from "./subcomponents/UploadForm";
import parse from "html-react-parser";
import { BE_CODE_STATUS } from "../../../utilities/constants";
import { docUploadApplication } from "../../../stores/thunks/docUpload.thunks";
import { DocUploadRequest } from "../../../services/requests/DocUploadRequest";
import { ReqResumeApplication } from "../../../types/request";

const CIMB_HOME_URL = window._env.REACT_APP_CIMB_HOME;

function UploadLanding() {
  const { state } = useLocation<{
    applyNo: string;
    myInfoCode?: string;
    myInfoError?: string | null;
  }>();
  const { applyNo, myInfoCode, myInfoError } = { ...state };
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [applicationNo, setApplicationNo] = useState<string>("");
  const classes = useStyles();
  const { docUploadForm, callbackUrl, pageLoading, uploadLoading, fileIds } =
    useSelector((s: RootState) => s.docUpload);
  const { applicationTemplateTabFields: fields = [] } = docUploadForm;

  useEffect(() => {
    if (applyNo && myInfoCode) {
      const reqPreCheck: ReqResumeApplication = {
        applyNo,
        myInfo: {
          code: myInfoCode,
          state: btoa(applyNo),
        },
      };
      setApplicationNo(applyNo);
      dispatch(docUploadApplication(reqPreCheck));
    } else {
      if (myInfoError) {
        if (myInfoError == "access_denied") {
          window.location.href = CIMB_HOME_URL;
        }
      } else {
        if (applyNo) {
          setApplicationNo(applyNo);
        } else {
          history.push({
            pathname: "/403",
          });
        }
      }
    }
  }, []);

  const onSubmit = async (data: any) => {
    let formData = await processSubmitData(data);
    submitFormData(formData);
  };

  const submitFormData = async (lastData: any) => {
    try {
      setSubmitLoading(true);
      let results = await DocUploadRequest.submitDocuments(
        applicationNo,
        lastData
      );
      const code = results?.code;
      if (
        code === BE_CODE_STATUS.SUCCESS ||
        code === BE_CODE_STATUS.UPLOAD_FILES_INVALID
      ) {
        setTimeout(() => {
          history.replace({
            pathname: "/upload/complete",
            state: {
              code,
              applyNo: applicationNo,
              callbackUrl: callbackUrl || CIMB_HOME_URL,
            },
          });
        }, 1000);
      }
    } catch (err) {
      history.push({
        pathname: "/403",
      });
    }
  };

  const processSubmitData = async (data: any) => {
    let formData: any = [];
    let copyFileIds: { [key: string]: string } = fileIds;
    await Promise.all(
      fields.map(async (f) => {
        const { code } = f.field;
        let value = data[code];
        if (value instanceof FileList || value instanceof Array) {
          // file类型不能作为map的遍历
          let ids = [];
          for (let i = 0; i < value.length; ++i) {
            let key: string = code + "/" + value[i].name;
            let fileId = copyFileIds[key];
            ids.push(fileId);
          }
          formData.push({
            code,
            values: ids,
          });
        }
      })
    );
    return formData;
  };

  const tab = {
    TITLE: "Document Upload",
    SUBTITLE: `
    <div>
      <div>Upload the document(s) that are required to continue your application process.</div>
      <ul>
        <li>Only PDF, JPG, JPEG and PNG file formats are accepted</li>
        <li>Maximum size per file is 5MB</li>
      </ul>
    </div>`,
  };

  if (submitLoading || pageLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={6}>
        <CHLOverlayLoading />
      </Box>
    );
  }

  return (
    <Box mt={8} maxWidth={800} margin="auto" position="relative">
      {uploadLoading && <CHLOverlayLoading />}
      <Box>
        <Typography component="div">
          <Box
            mb={2}
            fontWeight="bold"
            fontSize="1.375rem"
            className={classes.darkText}
          >
            Document Upload
          </Box>
        </Typography>

        <Typography component="div">
          <Box className={classes.darkText}>
            {parse(tab.SUBTITLE as string)}
          </Box>
        </Typography>

        <Box mt={3} position="relative">
          <UploadForm
            formFields={fields}
            applyNo={applicationNo}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 16,
    },
  },
  darkText: {
    color: theme.palette.secondary.main,
  },
  name: {
    color: theme.palette.secondary.main,
    textTransform: "uppercase",
  },
  progressRoot: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#E5E5E5",
  },
  barColorPrimary: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#DA001A",
  },
}));

export default UploadLanding;
