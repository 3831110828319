import * as yup from "yup";
import { FieldType, DataType, RespAppField, RespAppOptionField } from "../types/response";
import { SchemaType } from "../types/common";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CODE,
  DEFAULT_VALUE,
  BYPASS_VALIDATION,
  READ_ONLY_FIELD,
  PR_STATUS_VALUE,
  EMPLOY_STATUS_VALUE,
  RESUME_TYPES,
  SMART_FILL_FILE_SIZE,
  EMPLOYMENT_PASS_STATUS_VALUE,
  NRIC_COPY_BOTH_SIDE_VALUE,
  MY_NRIC_COPY_BOTH_SIDE_VALUE,
} from "./constants";
import keys from "lodash/keys";
import pick from "lodash/pick";
import assign from "lodash/assign";
import {
  CHLCheckbox,
  CHLCheckboxButton,
  CHLDatePicker,
  CHLEmploymentField,
  CHLRadio,
  CHLRadioButton,
  CHLSignatureField,
  CHLTextField,
  CHLUploadField,
  CHLUploadObsField,
  CHLValueField,
  CHLAutocomplete,
  CHLResidenceField,
  CHLNumberWithAreaCodeField
} from "../components";
import { InputAdornment } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import moment from "moment";

interface TableField {
  field: {
    code: string;
    value: [];
    details: RespAppField[];
    name: string;
  };
}
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maxLength?: number;
}

const partyType = sessionStorage.getItem(CODE.PARTY_CODE);

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, maxLength, ...other } = props;
  const maxLen = maxLength ? (maxLength + Math.ceil(maxLength / 3) - 1) : 15;
  return (
    <NumberFormat
      {...other}
      maxLength={maxLen}
      getInputRef={inputRef}
      onValueChange={(values: { value: any; }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export const getAttachmentConfig = (code: string) =>{
  const MS_ATTACHMENT_CONFIG = window._env.MS_ATTACHMENT_CONFIG
  const objAttachmentConfig = JSON.parse(MS_ATTACHMENT_CONFIG)

  let attachmentConfig

  if(code in objAttachmentConfig){
    attachmentConfig = objAttachmentConfig[code]
  }else{
    attachmentConfig = objAttachmentConfig.default
  }
  return attachmentConfig
}

export const createYupSchema = (data?: RespAppField[] | TableField[], isManualFlow?: boolean, uploadRelated?: any, myInfoData?: any) => {
  let finalSchema: any = {};

  if (data && data.length) {
    data.forEach((d) => {
      const { value, code, name, details } = (d as TableField).field;
      let schema: any;


      if (value) {
        // table type forms hard code
        //if(name != CODE.NOA && name != CODE.CPF_HISTORY &){
        if(![CODE.NOA, CODE.CPF_HISTORY, CODE.MCC_NOA, CODE.MCC_CPF_HISTORY].includes(name)) {
          const arraySchema = details
            ? details.map((detail) => ({
                [detail.field.code]: createSchema(detail, isManualFlow, uploadRelated, myInfoData),
              }))
            : [];
          const innerschema = Object.assign({}, ...arraySchema);
          schema = yup.array().of(yup.object().shape(innerschema));
        }
       
      } 
      else{
        schema = createSchema(d as RespAppField, isManualFlow, uploadRelated, myInfoData);
      }
      finalSchema[code] = schema;
    });
  }
  
  return yupResolver(yup.object().shape(finalSchema));
};

// between [21, 70]
export const isValidAgeRange = (dob: string) => {
  const startDate = moment().subtract(71, "years").add(1, 'days').format("YYYY-MM-DD");
  const endDate = moment().subtract(21, "years").format("YYYY-MM-DD");
  const startTime = new Date(startDate + "T00:00:00").getTime();
  const endTime = new Date(endDate + "T00:00:00").getTime();
  const dobTime = new Date(dob + 'T00:00:00').getTime();
  if (dobTime < startTime || dobTime > endTime) {
    return false;
  }
  return true;
}

export const isValidNRIC = (nric: string) => {
  const nricRegex = new RegExp("^(S|T)\\d{7}[A-Z]$");
  return nricRegex.test(nric);
}

export const isValidDOB = (dob: string) => {
  const dateRegex = /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/;
  return dateRegex.test(dob);
}

export const isNRICMatchWithDOB = (nric: string, dob: string) => {
  const dobYear = dob?.substring(0, 4);
  const nricYear = nric?.substring(0, 3)?.replace('S', '19')?.replace('T', '20');
  return nricYear === dobYear;
}

export const getApplicantType = (nric: string, nationality: string) => {
  let isSGPR = false;
  let isMalayForeigner = false;
  let isOtherForeigner = false;
  if (nric && ["S", "s", "T", "t"].includes(nric[0])) {
    isSGPR = true;
  } else if (nationality == DEFAULT_VALUE.COUNTRY_MALAYSIAN) {
    isMalayForeigner = true;
  } else {
    isOtherForeigner = true;
  }
  return {
    isSGPR,
    isMalayForeigner,
    isOtherForeigner,
  };
};

export const isExpiryDateValid = (expiryDate: string) => {
  const expiryTime = new Date(expiryDate + "T00:00:00").getTime();
  const criticalDate = moment().add(6, "months").format("YYYY-MM-DD");
  const criticalTime = new Date(criticalDate + "T00:00:00").getTime();
  return expiryTime > criticalTime;
};

export const isPassTypeValid = (type: string) => {
  const eligibleTypes = [
    "RPass",
    "SPass",
    "P1Pass",
    "P2Pass",
    "QPass",
    "PEP",
    "TEP",
    "Entre",
    "OVE",
    "LOC",
  ];
  return eligibleTypes.includes(type);
};

export const isPassStatusValid = (status: string) => {
  return status == EMPLOYMENT_PASS_STATUS_VALUE.LIVE;
};

export const byteConvert = function (bytes: any) {
  if (isNaN(bytes)) {
    return "";
  }
  var symbols = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var exp = Math.floor(Math.log(bytes) / Math.log(2));
  if (exp < 1) {
    exp = 0;
  }
  var i = Math.floor(exp / 10);
  bytes = bytes / Math.pow(2, 10 * i);
  if (bytes.toString().length > bytes.toFixed(2).toString().length) {
    bytes = bytes.toFixed(2);
  }
  return bytes + "" + symbols[i];
};

// for NRIC / FIN
const getDigitWeight = (index: number) => {
  let weightArr = [2, 7, 6, 5, 4, 3, 2];
  return weightArr[index] || 0;
}

// for NRIC / FIN
const ST_MAP = ['A', 'B', 'C', 'D', 'E', 'F', "G", "H", "I", "Z", "J"];
const GF_MAP = ['K', 'L', 'M', 'N', 'P', 'Q', "R", "T", "U", "W", "X"];
const M_MAP = ['K', 'L', 'J', 'N', 'P', 'Q', "R", "T", "U", "W", "X"];
const getLastLetter = (firstLetter: string, checkDigit: number) => {
  switch (firstLetter) {
    case 'S':
    case 'T':
      return ST_MAP[checkDigit];

    case 'G':
    case 'F':
      return GF_MAP[checkDigit];

    case 'M':
      return M_MAP[checkDigit];
  }
  return "";
}

export const isAlgoCheckPass = (nric: string) => {
  const reg = new RegExp("^[STFGM]\\d{7}[A-Z]$");
  if (reg.test(nric)) {
    let num = nric.replace(/\D/g, "");// 留下纯数字部分
    let numArr = num.split("");
    let sum = 0;
    sum = numArr.reduce((prev: number, cur: string, index: number) => {
      return prev += (+cur) * getDigitWeight(index);
    }, sum);
    if (nric.startsWith('G') || nric.startsWith("T")) {
      sum += 4;
    }
    let checkDigit = 11 - (sum % 11 + 1); 
    let lastLetter = getLastLetter(nric[0], checkDigit);
    return nric.endsWith(lastLetter);
  }
  return false;
}

export const isEmpty = (val: any) => {
  if(val == undefined || val == "" || val == null){
    return true
  }else{
    return false
  }
}

const createSchema = (d: RespAppField, isManualFlow?: boolean, uploadRelated?: any, myInfoData?: any) => {
  const {
    fieldType,
    dataType,
    min,
    max,
    maxLength,
    regex,
    validationMessage,
    render,
    group,
    code,
    // status,
  } = d.field;
  const label = d.name
  const isHidden = group?.code
  if (isHidden == "hidden") return; // If the field is hide, no need to create validation
  if (partyType === CODE.MCC && [CODE.APPLIC_AMOUNT, CODE.ADDRESS].includes(code)) return; // as up

  let type: SchemaType = getType(dataType);
  const schemaYup: any = yup[type]().nullable(); // This nullable is for the fields that not visible
  let schema = schemaYup;

  if (d.required){
    if(!BYPASS_VALIDATION.includes(code))
    schema = schema.concat(schemaYup.required(validationMessage));
  }

  if(code === CODE.PR_STATUS){
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "prstatus_required",
          validationMessage || "Please select the number of years that you have obtained PR status.",
          function (prstatus) {
            const nric = this.parent[CODE.NRIC] || myInfoData[CODE.NRIC];
            if (!prstatus && nric && ["S", "s", "T", "t"].includes(nric[0])) {
              return false;
            } else {
              return true;
            }
          }
        )
    );
  }

  if(code === CODE.MALAYSIA_NRIC){
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "MYNric_required",
          validationMessage || "Please provide your Malaysia IC Number.",
          function (myNric) {
            const nric = this.parent[CODE.NRIC] || myInfoData[CODE.NRIC];
            const nationality = this.parent[CODE.NATIONALITY] || myInfoData[CODE.NATIONALITY];
            if (nric && nationality) {
              const { isMalayForeigner } = getApplicantType(nric, nationality);
              if (!myNric && isMalayForeigner) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        )
        .test(
          "MYNric_notmatch_DOB",
          validationMessage || "Please provide your Malaysia IC Number.",
          function (myNric) {
            const dob = this.parent[CODE.DOB] || myInfoData[CODE.DOB];
            if (myNric && dob) {
              const dobStr = dob.replaceAll("-", "").substring(2);
              const nricStr = myNric.substring(0, 6);
              if (dobStr !== nricStr) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        )
    );
  }

  if (
    [
      CODE.PASSPORT_NUMBER,
      CODE.PASSPORT_EXPIRY_DATE,
      CODE.EMPLOYMENT_PASS_TYPE,
      CODE.EMPLOYMENT_PASS_STATUS,
      CODE.EMPLOYMENT_PASS_EXPIRY_DATE,
    ].includes(code)
  ) {
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "field_required",
          validationMessage || '', 
          function (value) {
            const nric = this.parent[CODE.NRIC] || myInfoData[CODE.NRIC];
            const nationality = this.parent[CODE.NATIONALITY] || myInfoData[CODE.NATIONALITY];
            if (nric && nationality) {
              const { isSGPR } = getApplicantType(nric, nationality);
              if (!value && !isSGPR) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        )
    );
  }

  if (
    code === CODE.PASSPORT_EXPIRY_DATE ||
    code === CODE.EMPLOYMENT_PASS_EXPIRY_DATE
  ) {
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "expiry_at_least_6months",
          code === CODE.PASSPORT_EXPIRY_DATE
            ? "Your passport must have at least 6 months validity."
            : "Your Employment Pass must have at least 6 months validity.",
          function (expiryDate){
            if(expiryDate && !isExpiryDateValid(expiryDate)){
              return false;
            }else{
              return true;
            }
          }
        )
    );
  }

  if (code === CODE.EMPLOYMENT_PASS_TYPE) {
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "passType_not_eligible",
          "Selected pass type is not eligible for this product",
          function (passType) {
            if (passType && !isPassTypeValid(passType)) {
              return false;
            } else {
              return true;
            }
          }
        )
    );
  }

  if (code === CODE.EMPLOYMENT_PASS_STATUS) {
    schema = schema.concat(
      yup
        .string()
        .nullable()
        .test(
          "status_not_live",
          "Your Employment Pass Status must be Live",
          function (passStatus) {
            if (passStatus && !isPassStatusValid(passStatus)) {
              return false;
            } else {
              return true;
            }
          }
        )
    );
  }

  if(code === CODE.FATCA_REASON){
    schema = schema.concat(
      yup
      .string()
      .when(CODE.FATCA_RESIDENCE, {
        is: (hasFatca:any) => hasFatca == DEFAULT_VALUE.FATCA_RESIDENCE_YES,
        then: yup.string().nullable().required(validationMessage)
      })
    )
  }

  //fixme
  if(code === CODE.MCC_FATCA_REASON){
    schema = schema.concat(
      yup
      .string()
      .when(CODE.MCC_FATCA_RESIDENCE, {
        is: (hasFatca:any) => hasFatca == DEFAULT_VALUE.FATCA_RESIDENCE_YES,
        then: yup.string().nullable().required(validationMessage)
      })
    )
  }

  if(isManualFlow && code === CODE.MCC_POST_CODE){
    schema = schema.concat(
      yup.string().nullable().test(
        'manual_local_postal_required',
        validationMessage ? validationMessage : "Please provide your Postal Code", 
        function(postal){
          const addressType = this.parent[CODE.MCC_ADDR_TYPE];
          if(addressType === CODE.MCC_ADDR_TYPE_LOCAL && (!postal || (postal && postal.length !== 6)) ){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && code === CODE.MCC_BLOCK){
    schema = schema.concat(
      yup.string().nullable().test(
        'manual_local_block_required',
        validationMessage ? validationMessage : "Please provide your Block / House Number", 
        function(block){
          const addressType = this.parent[CODE.MCC_ADDR_TYPE];
          if(addressType === CODE.MCC_ADDR_TYPE_LOCAL && !block){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && code === CODE.MCC_ADDR_STREET){
    schema = schema.concat(
      yup.string().nullable().test(
        'manual_local_street_required',
        validationMessage ? validationMessage : "Please provide your Block / House Number", 
        function(street){
          const addressType = this.parent[CODE.MCC_ADDR_TYPE];
          if(addressType === CODE.MCC_ADDR_TYPE_LOCAL && !street){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && partyType === CODE.MCC &&  code === CODE.MCC_ADDR_FLOOR){
    schema = schema.concat(
      yup.string().nullable().test(
        'restrict_floor_and_unit_both_have_value_or_not',
        "Please provide your Floor/Storey", 
        function(floor){
          const unit = this.parent[CODE.MCC_ADDR_UNIT];
          if(isEmpty(floor) && !isEmpty(unit)){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && partyType === CODE.MCC &&  code === CODE.MCC_ADDR_UNIT){
    schema = schema.concat(
      yup.string().nullable().test(
        'restrict_floor_and_unit_both_have_value_or_not',
        "Please provide your Unit", 
        function(unit){
          const floor = this.parent[CODE.MCC_ADDR_FLOOR];
          if(isEmpty(unit) && !isEmpty(floor)){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && code === CODE.MCC_ADDR_COUNTRY){
    schema = schema.concat(
      yup.string().nullable().test(
        'manual_foreign_country_required',
        validationMessage ? validationMessage : "Please select your Country", 
        function(country){
          const addressType = this.parent[CODE.MCC_ADDR_TYPE];
          if(addressType === CODE.MCC_ADDR_TYPE_FOREIGN && !country){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(isManualFlow && code === CODE.MCC_FOREIGN_ADDR_LINE1){
    schema = schema.concat(
      yup.string().nullable().test(
        'manual_foreign_line1_required',
        validationMessage ? validationMessage : "Please provide your Address Line 1", 
        function(line1){
          const addressType = this.parent[CODE.MCC_ADDR_TYPE];
          if(addressType === CODE.MCC_ADDR_TYPE_FOREIGN && !line1){
            return false
          }else{
            return true
          }
        }
      )
    )
  }

  if(code === CODE.MCC_FAKE_MOBILE){
    schema = schema.concat(
      yup.string().nullable().test(
        'mobile_number_empty',
        validationMessage || 'Please provide your Mobile Number/area code', 
        function(mobileNo){
          let areaCode = '';
          let number = '';
          if(mobileNo && mobileNo.includes("#")){
            const arr = mobileNo.split("#");
            areaCode = arr[0];
            number = arr[1];
          }
          if(!mobileNo || !areaCode || !number){
            return false
          } else{
            return true
          }
        }
      ).test(
        'mobileNo_is_8_digist_when_areacode_equal_65',
        'Mobile Number should be only 8 digits',
        function(mobileNo){
          let areaCode = '';
          let number = '';
          if(mobileNo && mobileNo.includes("#")){
            const arr = mobileNo.split("#");
            areaCode = arr[0];
            number = arr[1];
          }
          const regex = /^\d{8}$/;
          if(areaCode === '65' && number && !regex.test(number)){
            return false;
          }else{
            return true;
          }
        }
      )
    )
  }


  if(code === CODE.MCC_FAKE_SECONDARY_CONTACT){
    schema = schema.concat(
      yup.string().nullable().test(
        'second_contact_number_empty',
        validationMessage || 'Please provide your Secondary Contact Number',
        function(secondaryContactNumber){
          const secondaryContactType = this.parent[CODE.MCC_SECOND_CONTACT_TYPE];
          let areaCode = '';
          let number = '';
          if(secondaryContactNumber && secondaryContactNumber.includes("#")){
            const arr = secondaryContactNumber.split("#");
            areaCode = arr[0];
            number = arr[1];
          }
          if(secondaryContactType && (!secondaryContactNumber || !areaCode || !number)){
            return false
          } else{
            return true
          }
        }
      ).test(
        'scn_equal_mobile', 
        'Secondary Contact Number should not same with the Mobile Number', 
        function(secondaryContactNumber){
          const mobile = this.parent[CODE.MCC_FAKE_MOBILE];
          if(mobile === secondaryContactNumber){
            return false;
          }else{
            return true;
          }
        }
      ).test(
        'scn_isnot_8_digist_when_scareacode_equal_65',
        'Secondary Contact Number should be only 8 digits',
        function(secondaryContactNumber){
          let areaCode = '';
          let number = '';
          if(secondaryContactNumber && secondaryContactNumber.includes("#")){
            const arr = secondaryContactNumber.split("#");
            areaCode = arr[0];
            number = arr[1];
          }
          const regex = /^\d{8}$/;
          if(areaCode === '65' && number && !regex.test(number)){
            return false;
          }else{
            return true;
          }
        }
      )
    )
  }

  if (code === CODE.NRIC && isManualFlow && partyType === CODE.MCC) {
    schema = schema.concat(
      yup.string().nullable().test(
        'nric_invalid',
        'Please enter a valid NRIC.',
        function (nric) {
          const nricRegex = new RegExp("^(S|T)\\d{7}[A-Z]$");
          const dob = this.parent[CODE.MCC_DOB];
          let dobYear = dob?.substring(0, 4);
          const nricYear = nric?.substring(0, 3)?.replace('S', '19')?.replace('T', '20');
          if (nric && (!nricRegex.test(nric) || dobYear && nricYear !== dobYear)) {
            return false;
          } else {
            return true;
          }
        }
      ).test(
        'algo_check',
        'Please enter a valid NRIC.',
        function (nric) {
          return isAlgoCheckPass(nric as string);
        }
      )
    )
  }

  if (code === CODE.MCC_DOB && isManualFlow) {
    schema = schema.concat(
      yup.string().nullable().test(
        'dob_not_match_with_nric',
        'Please enter a valid date of birth.',
        function (dob) {
          const nric = this.parent[CODE.NRIC];
          let dobYear = dob?.substring(0, 4);
          const nricYear = nric?.substring(0, 3)?.replace('S', '19')?.replace('T', '20');
          if (dobYear && nricYear && nricYear !== dobYear) {
            return false;
          } else {
            return true;
          }
        }
      ).test(
        'dob_invalid', 
        'You do not meet the minimum age of this product', 
        function (dob) {
        const startDate = moment().subtract(71, "years").add(1, 'days').format("YYYY-MM-DD");
        const endDate = moment().subtract(21, "years").format("YYYY-MM-DD");
        const startTime = new Date(startDate + "T00:00:00").getTime();
        const endTime = new Date(endDate + "T00:00:00").getTime();
        const dobTime = new Date(dob + 'T00:00:00').getTime();
        if (dobTime < startTime || dobTime > endTime) {
          return false;
        }
        return true;
      })
    )
  }

  
  if (type === "string") {
    if(code === CODE.PAYNOW_ID){
      const paynowRegex =  new RegExp("^[0-9]{10}$");
      schema = schema.concat(
        yup
        .string()
        .when(CODE.PAYNOW_ID_TYPE, {
          is: (paynowtype:any) => paynowtype == DEFAULT_VALUE.PAYNOW_MOBILE,
          then: yup.string().nullable().matches(paynowRegex, validationMessage)
        })
      )
    }
    
    if (fieldType === "Email")
      schema = schema.concat(
        yup.string().email("Please enter a valid email address.")
      );
    
    //null is acceptable, but if the field cannot accept null, need to have this 'required()' method
    //perform validation when field is not required (required=false) but regex is available
    if (regex) {
      const r = new RegExp(regex);
      schema = schema.concat(
        yup.string().matches(r, validationMessage)
      );
    }

    if(maxLength) {
      schema = schema.concat(
        yup.string().max(maxLength as number, `${label} exceeded max length ${maxLength}`)
      );
    }


  }else {
    if (fieldType === "ImageFile") {
      const resumeType = window.sessionStorage.getItem("RESUME_TYPE");
      const isPloan = window.sessionStorage.getItem("productType") === "ploan";
      const isResume =
        resumeType == RESUME_TYPES.AIP || resumeType == RESUME_TYPES.DOC_UPLOAD;
      if(code === CODE.SIGNATURE || code === CODE.MCC_SIGNATURE){
          schema = schema.concat(
          yup
            .mixed()
            .test(
              "fileType",
              validationMessage ? validationMessage : "Please provide your signature.",
              (value) => value != ""
            )
          )
      }else{
        if(!isResume && isPloan){
          const { empstatus, isSGPR, isMalayForeigner, isOtherForeigner, employedMonths, prstatus } = uploadRelated;
          if(code === CODE.PAYSLIP){
            schema = schema.concat(
              yup.mixed().test(
                'payslip_image_required',
                validationMessage ? validationMessage : "Please upload your Payslip.",
                function (payslip) {
                  if (
                    !payslip &&
                    (empstatus === EMPLOY_STATUS_VALUE.EMPLOYED ||
                      empstatus === EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
                    (!isSGPR ||
                      (isSGPR &&
                        (prstatus === PR_STATUS_VALUE.YEAR1 ||
                          prstatus === PR_STATUS_VALUE.YEAR2 || 
                          employedMonths < 3)))
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                }
              )
            )
          }
          if(code === CODE.LETTER_OF_ACCEPTANCE){
            schema = schema.concat(
              yup.mixed().test(
                'letter_image_required',
                validationMessage ? validationMessage : "Please upload your Employment Offer Letter.",
                function (letter) {
                  if (
                    !letter &&
                    (empstatus == EMPLOY_STATUS_VALUE.EMPLOYED ||
                      empstatus == EMPLOY_STATUS_VALUE.COMMISSION_EARNER) &&
                    employedMonths < 3
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                }
              )
            )
          }
          if(code === CODE.NRIC_FRONT_AND_BACK){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "NricCopy_required",
                  validationMessage || "Please upload your NRIC Copy (Front & Back)",
                  function (NricCopy) {
                    const hasNricCopyBothSide =
                      this.parent[CODE.IS_NRIC_BOTH_SIDE];
                    if (
                      !NricCopy &&
                      isSGPR &&
                      isManualFlow &&
                      hasNricCopyBothSide === NRIC_COPY_BOTH_SIDE_VALUE.YES
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.NRIC_FRONT || code === CODE.NRIC_BACK){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "singleNricCopy_required",
                  validationMessage
                    ? validationMessage
                    : code === CODE.NRIC_FRONT
                    ? "Please upload your NRIC Copy (Front)"
                    : "Please upload your NRIC Copy (Back)",
                  function (singleNricCopy) {
                    const hasNricCopyBothSide = this.parent[CODE.IS_NRIC_BOTH_SIDE];
                    if (
                      !singleNricCopy &&
                      isSGPR &&
                      isManualFlow &&
                      hasNricCopyBothSide !== NRIC_COPY_BOTH_SIDE_VALUE.YES
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.MY_NRIC_FRONT_AND_BACK){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "MYNricCopy_required",
                  validationMessage || "Please upload your Malaysian NRIC Copy (Front & Back)",
                  function (MYNricCopy) {
                    const hasMYNricCopyBothSide = this.parent[CODE.IS_MY_NRIC_BOTH_SIDE];
                    if (
                      !MYNricCopy &&
                      isMalayForeigner &&
                      hasMYNricCopyBothSide === MY_NRIC_COPY_BOTH_SIDE_VALUE.YES
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.MY_NRIC_FRONT || code === CODE.MY_NRIC_BACK){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "singleMYNricCopy_required",
                  validationMessage
                    ? validationMessage
                    : code === CODE.MY_NRIC_FRONT
                    ? "Please upload your Malaysian NRIC Copy (Front)"
                    : "Please upload your Malaysian NRIC Copy (Back)",
                  function (singleNricCopy) {
                    const hasMYNricCopyBothSide = this.parent[CODE.IS_MY_NRIC_BOTH_SIDE];
                    if (
                      !singleNricCopy &&
                      isMalayForeigner &&
                      hasMYNricCopyBothSide !== MY_NRIC_COPY_BOTH_SIDE_VALUE.YES
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.LATEST_YEAR_NOA || code === CODE.PREVIOUS_YEAR_NOA){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "NOA_required",
                  validationMessage
                    ? validationMessage
                    : code === CODE.LATEST_YEAR_NOA
                    ? "Please provide your Latest Year NOA"
                    : "Please provide your Previous Year NOA",
                  function (noa) {
                    if (
                      !noa &&
                      isManualFlow &&
                      empstatus === EMPLOY_STATUS_VALUE.SELF_EMPLOYED
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.PASSPORT_COPY){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "passportCopy_required",
                  validationMessage || "Please upload your Passport Copy (at least 6 months validity).",
                  function (passportCopy) {
                    if (!isSGPR && !passportCopy) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if (code === CODE.EMPLOYMENT_PASS_COPY) {
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "empPassCopy_required",
                  validationMessage || "Please upload your Employment Pass.",
                  function (doc) {
                    if (
                      isManualFlow &&
                      (isMalayForeigner || isOtherForeigner) &&
                      !doc
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.LATEST_BILLING_PROOF){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "latestBillingProof_required",
                  validationMessage || "Please upload your Latest Proof of Residence.",
                  function (proof) {
                    if (
                      ((isManualFlow && isMalayForeigner) || isOtherForeigner) &&
                      !proof
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
          if(code === CODE.FEN_FORM){
            schema = schema.concat(
              yup
                .mixed()
                .test(
                  "fen_required",
                  validationMessage || "Please upload your Foreign Exchange Notice Declaration Form.",
                  function (fen) {
                    if (isMalayForeigner && !fen) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
            );
          }
        }
        
        const objAttachmentConfig = getAttachmentConfig(code)
        const SUPPORTED_FILE_TYPE = objAttachmentConfig.fileType
        const isSmartFillDocUpload = window.sessionStorage.getItem("RESUME_TYPE") === RESUME_TYPES.DOC_UPLOAD
        const MAX_FILE_SIZE = isSmartFillDocUpload ? SMART_FILL_FILE_SIZE : parseInt(objAttachmentConfig.fileSize)
        schema = schema.nullable().concat(
          yup
            .mixed()
            .test(
              "fileType_not_support",
              "File format not supported. Only PDF, JPG and PNG file formats are accepted.",
              (value) => { 
                if(value == null) return true;
                if(value && (value instanceof FileList || value instanceof Array) ) {
                  for(let i = 0; i < value.length; ++i) {
                    if(!(value[i] && SUPPORTED_FILE_TYPE.includes(value[i].type))) {
                      return false;
                    }
                  }
                  return true;
                } else {
                  return value && SUPPORTED_FILE_TYPE.includes(value.type);
                }
              }
            )
            .test(
              "fileSize_not_support",
              `File size not supported. Maximum file size permitted is ${byteConvert(MAX_FILE_SIZE)}.`,
              (value) => {
                if(value == null) return true;
                if(value && (value instanceof FileList || value instanceof Array) ) {
                  for(let i = 0; i < value.length; ++i) {
                    if(!(value[i] && value[i].size <= MAX_FILE_SIZE)) {
                      return false;
                    }
                  }
                  return true;
                } else {
                  return value && value.size <= MAX_FILE_SIZE;
                }
              }
            )
        );
      }
    }
  }

  schema = schema.concat(schema.typeError(`Please enter valid ${type}.`));
  schema = schema.concat(schema.nullable()); // Accept null but still need to pass required validation

  // conditional validation
  if (render && render.fields && render.fields.length) {
    const dependsField = render.fields.map((r) => r.code)

    if(!dependsField.includes(CODE.MYINFO_MODE)){
      schema = schemaYup.when(
        dependsField,
        {
          is: (...value: any) => { //is: depends dependentValue for conditional rendering. If matched then refer schema
            const v = render.fields.map((r) => r.value);
            return JSON.stringify([...value]) === JSON.stringify(v);
          },
          then: schema,
        }
      );
    }
  }

  return schema;
};

const getType = (dataType: string): "number" | "string" | "mixed" => {
  switch (dataType) {
    case "Integer":
    case "Float":
      return "string";
    case "Text":
    case "Alphabetic":
    case "ID":
      return "string";
    default:
      return "mixed";
  }
};

export const dataURIToBlob = (dataURI: string) => {
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const assignTargetProps = (target: any, source: any) =>
  assign(target, pick(source, keys(target)));


export const getComponentAttributes = (obj: {
  activeIndex?: number;
  code: string;
  type: FieldType;
  options: [] | RespAppOptionField[];
  hasValue: boolean;
  dataType?: string;
  value?: any;
  hasMyInfo?: boolean;
  placeholder?: string | Object;
  maxLength?: number;
  isManualFlow?: boolean;
}) => {
  const { 
    activeIndex, 
    code, 
    type, 
    options = [], 
    hasValue, 
    dataType, 
    value, 
    hasMyInfo, 
    placeholder, 
    maxLength, 
    isManualFlow 
  } = obj;
  const myInfoEditableFields = [
    "awsmtittle", // (About Me) Title  -- type: Dropdown
    "awsmmothermaidenname", // (About Me) Mother Maiden's Name
    "awsmnoofdependents", // (About Me) No. of Dependents
    //"personEmail", // (About Me) Email Address
    "awsmemailaddress", // (About Me) Email Address
    "personMobileNo", // (About Me) Mobile Number
    "awsmscontype", // (About Me) Secondary Contact Type  -- type: Dropdown
    "awsmsconnumber", // (About Me) Secondary Contact Number
    "awsmcustomermaritalstatus", // (About Me) Marital Status  -- type: Dropdown
    "edulevel", // (About Me) Highest Qualifications  -- type: Dropdown
    "awsmpropertytype", // (My Address Details) Property Type  -- type: Dropdown
    "awsmresidentialstatus", // (My Address Details) Residence status  -- type: Dropdown
    "awsmlresidence", // (My Address Details) Length of Residence (Year)
    "awsmempstatus", // (Employment) Employment Status  -- type: Dropdown
    "awsmemployment", // (Employment) Company Name
    "awsmemploymentsector", // (Employment) Nature of Business -- type: Dropdown
    "awsmoccupation", // (Employment) Occupation -- type: Dropdown
    "awsmemppostal", // (Employment) Postal Code
    "awsmempblock", // (Employment) Block / House Number
    "awsmempstreet", // (Employment) Street Name
    "awsmempbuilding", // (Employment) Building Name
    "awsmempfloor", // (Employment) Storey
    "awsmempunit", // (Employment) Unit Number
    "awsmgrossannualinc", // (Employment) Gross Annual Income
    CODE.MCC_LENGTH_OF_EMPLOYMENT, // (Employment) Length of Employment
    CODE.MCC_FAKE_MOBILE,
  ];
  // Hardcoded scenario (normally doesn't have default value)
  if(!isManualFlow && hasValue && [CODE.EMPLOYMENT_PASS_TYPE, CODE.EMPLOYMENT_PASS_STATUS].includes(code)){
    const valueDesc = options.find(o => o.code == value);
    return { 
      component: CHLValueField,
      props:{
        value: valueDesc && valueDesc.name
      }
    };
  }
  if(!isManualFlow && code === CODE.EMPLOYMENT_PASS_EXPIRY_DATE && hasValue){
    return { 
      component: CHLValueField,
      props:{
        value,
      } 
    };
  }
  if(code === CODE.MCC_EMPLOY_BUSINESS_SECTOR || code === CODE.MCC_EMPLOY_OCCUPATION){
    const valueDesc = options.find(o => o.code == value);
    return {
      component: CHLAutocomplete,
      props: {
        value: valueDesc ? valueDesc : null
      }
    };
  }
  if(code === CODE.MCC_FAKE_MOBILE || code === CODE.MCC_FAKE_SECONDARY_CONTACT){
    return {
      component: CHLNumberWithAreaCodeField,
      props: {
        options: options,
        isMobile: code === CODE.MCC_FAKE_MOBILE,
      }
    }
  }
  if (code === CODE.LENGTH_OF_EMPLOYMENT || code === CODE.MCC_LENGTH_OF_EMPLOYMENT) {
    return { 
      component: CHLEmploymentField,
      props:{
        objPlaceholder: placeholder,
        label: "Length of Employment",
        autoPaddingZero: code === CODE.LENGTH_OF_EMPLOYMENT
      }
    };  
  } else if(code === CODE.MCC_LENGTH_OF_RESIDENCE){
    return { 
      component: CHLEmploymentField,
      props:{
        objPlaceholder: placeholder,
        label: "Length of Residence",
        autoPaddingZero: false,
      }
    };  
  } else if (READ_ONLY_FIELD.includes(code) && !isManualFlow) { //[TO-DO] disable field editing
    // myinfo 
    if(type === "Dropdown"){
      const valueDesc = options.find(o => o.code == value)
      return { 
        component: CHLValueField,
        props:{
          value: valueDesc && valueDesc.name
        }
      };
    }else{
      return { component: CHLValueField };
    }
  } 
  else if (code === CODE.SIGNATURE || code === CODE.MCC_SIGNATURE) return { component: CHLSignatureField };
  else if (hasValue && activeIndex === 0 && !isManualFlow &&  partyType === CODE.MCC) { // ploan hidden step1 如果是第一页，有value值 且 是myinfo-flow 的时候
    // value 就是 myInfo中返回的 或者 编辑过的 field数据  
    const valueDesc = options.find(o => o.code == value);
    // 如果是Dropdown
    if(type === "Dropdown"){
      if(myInfoEditableFields.includes(code) && partyType === CODE.MCC){
        return {
          component: CHLAutocomplete,
          props: {
            value: valueDesc ? valueDesc : null
          }
        };
      }
      return {
        component: CHLValueField,
          props:{
            value: valueDesc && valueDesc.name
          }
      }
    }
    // ploan中第一页没有RadioButton
    if(type === "RadioButton"){
      // myInfoEditableFields中没有RadioButton类型的field
      return{
        component: CHLValueField,
        props:{
          value: valueDesc && valueDesc.name
        }
      }
    }
    // Dropdown & RadioButton之外的其他类型
    // editable fields
    if((myInfoEditableFields.includes(code) && partyType === CODE.MCC) || !hasMyInfo){
      return { component: CHLTextField };
    }
    // only review value fields
    return { component: CHLValueField };
  }

  if(code === CODE.MCC_ALIASNAME && !isManualFlow && partyType === CODE.MCC){
    return { component: CHLValueField };
  }

  if(code === CODE.NRIC && partyType === CODE.MCC){
    return { 
      component: CHLTextField,
      props: {
        upperFormat: true,
        maxLength: maxLength,
        placeholder,
      }
    };
  }

  if(code === CODE.MALAYSIA_NRIC){
    return { 
      component: CHLTextField,
      props: {
        isMYIC: true,
        maxLength: maxLength,
        placeholder,
      }
    };
  }

  if(code === CODE.MCC_NO_OF_DEPENDENTS){
    return { 
      component: CHLTextField,
      props: {
        numberOnly: true,
        maxLength: maxLength,
        placeholder,
      }
    };
  }
  if(code === CODE.MCC_POST_CODE){
    return { 
      component: CHLTextField,
      props: {
        numberOnly: true,
        placeholder,
      }
    };
  }
  if(code === CODE.ADDRESS_FLOOR || code === CODE.ADDRESS_UNIT || code === CODE.MCC_BLOCK){
    return { 
      component: CHLTextField,
      props: {
        alphanumericFormat: true,
        maxLength,
        placeholder,
      }
    };
  }

  // Normal scenario
  switch (type) {
    case "InputField":
    case "Address":
    case "Email":
      if(type === "InputField" && dataType === "Text"){
        return { 
          component: CHLTextField,
          props:{
            maxLength: maxLength,
            placeholder,
            spaceFormat: true,
          } 
        };
      }
      return { 
        component: CHLTextField,
        props:{
          maxLength: maxLength,
          placeholder,
        } 
      };
    case "TextArea":
      return {
        component: CHLTextField,
        props: {
          maxLength: maxLength,
          placeholder,
          multiline: true,
          minRows: 1,
          maxRows: 6,
        },
      };
    case "Phone":
      return {
        component: CHLTextField,
        props: {
          maxLength,
          placeholder,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">+</InputAdornment>
            ),
          },
        },
      };
    case "Amount":
      return {
        component: CHLTextField,
        props: {
          maxLength,
          placeholder,
          InputProps: {
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },
        },
      };
    case "DatePicker":
      return {
        component: CHLDatePicker,
        props: { placeholder: "yyyy-mm-dd" },
      };
    case "Dropdown":
      const selectedValue = options.find(o => o.code == value)
      return {
        component: CHLAutocomplete,
        props: {
          value: selectedValue ? selectedValue : null
        }
      };
    case "ImageFile":
      if(partyType !== CODE.MCC && !isManualFlow){
        return {
          component: CHLUploadObsField,
          props: { placeholder: "Tap here to upload file" },
        };
      }
      return {
        component: CHLUploadField,
        props: { placeholder: "Tap here to upload file" },
      };
    case "RadioButton":
      return {
        component: CHLRadio,
        componentOption: CHLRadioButton,
        props: { bold: false, toggle: options.length === 2 },
      };
    case "Checkbox":
      return {
        component: CHLCheckbox,
        componentOption: CHLCheckboxButton,
        props: {
          dataType: dataType,
        },
      };
    default:
      return { component: CHLTextField };
  }
};

export const formatRepaymentValue = (
  fieldType: FieldType, 
  dataType: DataType, 
  value: string | number, 
  tenorUnit: string, 
  currency: string) =>{
  
  if(fieldType === "Dropdown"){
    return value + ' ' + tenorUnit 
  }else if(dataType === "Float"){
    if(fieldType === "Amount"){
      if(value){
        value = typeof value === "string" ? parseFloat(value.replace(/,/g, "")) : value
        return currency + ' ' + value.toLocaleString(undefined, {minimumFractionDigits: 2})
      }else if(value == 0){
        return currency + ' ' + value.toLocaleString(undefined, {minimumFractionDigits: 2})
      }else return value
      
    }else if(fieldType === "InputField"){
      return value + '%'
    }
  }else return value
}

export const formatGroupSelect = (
  dictionaryRoot: string,
  dictionary: any
) =>{
  let optionGroup:any = []
  const rootKey = dictionary[dictionaryRoot]
  const rootObj =  (typeof rootKey === "string") && dictionary[rootKey]

  Object.entries(rootObj).filter(([key, value]) => {
      const childObj = dictionary[key]
      const children = Object.entries(childObj)
      .map(([k, v]) => ({value: k, text:v}));

      let obj = {
          value: key,
          text: value,
          children: children
      }
      optionGroup.push(obj)
  })
  return optionGroup
}

export const polishMyInfoData = (data: any) =>{
  Object.keys(data).forEach(k=>{

    if(typeof data[k] === 'string'){
      if(k == CODE.EMAIL){
        data[k] = data[k].trim()
      }
    }

    if(!Array.isArray(data[k]) && typeof data[k] === 'object'){
      Object.keys(data[k]).forEach(ck =>{
        data[k+ "_" + ck] = data[k][ck]
        if(k === 'regadd' && (ck === 'street' || ck === 'building')){
          data["awsm" + k + "_" + ck] = data[k][ck]; // awsmregadd_street / awsmregadd_building
        }
      })
    }
    
    if(partyType === CODE.MCC){
      if(k == CODE.CPF_HISTORY){
        data["CPF Contributions"] = data[k];
      }
      if(k == CODE.NOA){
        data["NOA History"] = data[k];
      }
    }
    
  })
  return data
}

export const setProcessMode = (score: number, partyType: string) => {
  const strFaceVRate = partyType === CODE.MCC ? window._env.MCC_FACEV_PASSING_RATE : window._env.FACEV_PASSING_RATE;
  const faceVRate:[] = JSON.parse(strFaceVRate).tiering
  console.log(faceVRate)

  let action = DEFAULT_VALUE.REJECT

  faceVRate.forEach((rate:any) =>{
    console.log(rate)
    const min = parseFloat(rate.min)
    const max = parseFloat(rate.max)
   
    if(score >= min && score <= max){
      action = rate.action 
      return
    }
  })
  return action
}

export const setMyInfoRedirectURL = (state: string) =>{
  const redirectUrl =
  window._env.REACT_APP_MYINFO_API_AUTHORISE +
  "?client_id=" +
  window._env.REACT_APP_MYINFO_APP_CLIENT_ID +
  "&attributes=" +
  window._env.REACT_APP_MYINFO_APP_ATTRIBUTES +
  "&purpose=" +
  window._env.REACT_APP_MYINFO_APP_PURPOSE +
  "&state=" + state + 
  "&redirect_uri=" +
  window._env.REACT_APP_MYINFO_APP_REDIRECT_URI; // mcc / ploan is different FIXME
  return redirectUrl
}

export const setMccMyinfoRedirectURL =  (state: string) =>{
  const redirectUrl =
  window._env.MCC_REACT_APP_MYINFO_API_AUTHORISE +
  "?client_id=" +
  window._env.MCC_REACT_APP_MYINFO_APP_CLIENT_ID +
  "&attributes=" +
  window._env.MCC_REACT_APP_MYINFO_APP_ATTRIBUTES +
  "&purpose=" +
  window._env.MCC_REACT_APP_MYINFO_APP_PURPOSE +
  "&state=" + state + 
  "&redirect_uri=" +
  window._env.MCC_REACT_APP_MYINFO_APP_REDIRECT_URI;
  //"http://localhost:3001/mcc/callback";
  return redirectUrl;
}


export const isInIframe = () => {
  
  console.log("window.frameElement: ", window.frameElement);
  console.log("window.frameElement.tagName === 'IFRAME': ", window.frameElement && window.frameElement.tagName === 'IFRAME');
  console.log("window !== window.top: ", window !== window.top);
  console.log("window.parent !== window: ", window.parent !== window);
  
  if(window.frameElement && window.frameElement.tagName === 'IFRAME'){
    return true;
  }
  if(window !== window.parent || window !== window.top){
    return true;
  }
  return false;
}
