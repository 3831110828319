import { useSelector } from "react-redux";
import { Controller, useForm, useWatch  } from "react-hook-form";
import { RootState } from "../../../../../stores/rootReducer";
import { CHLSelectGroup, CHLTextField } from "../../../../../components"
import {formatGroupSelect} from "../../../../../utilities/helper"
import {FieldType} from "../../../../../types/response"
import WatchComponent from "../WatchComponent"
import { Box } from "@material-ui/core";
import { RejectFormContext } from "./index"
import { useContext } from "react";

const RejectForm = () =>{
    const context = useContext(RejectFormContext);
    const {dictionaryRoot ,dictionary, resumeApplicationForm} = useSelector((s: RootState) => s.resumeApplication);
    const reject = resumeApplicationForm.reject
    const template = reject.applicationTemplateTabFields
    const form = template.map(({ field: { code, value } }: any) => {
        return  {[code]: value ?? ''}
    })
    const control = context?.control
    const defaultValues = context?.defaultValues

    const optionGroup = formatGroupSelect(dictionaryRoot, dictionary)

    const getComponentAttributes = (obj: { type: FieldType;}) => {
        const { type } = obj;
  
        switch (type) {
            case "TextArea":
            return {
                component: CHLTextField,
                props: {
                    value: null,
                    multiline: true,
                    // minRows: 4,
                    maxRows: 6,
                },
            };
            case "Dictionary":
            return {
                component: CHLSelectGroup,
                props: { optionGroups: optionGroup }
            };
            default:
            return { component: CHLTextField };
        }
    };

    return (
        <div>
            {template.map((f) =>{
                const { required, name, field } = f
                const { code, fieldType, render } = field;
                return (
                    <Controller
                key={code}
                name={code}
                control={control}
                render={({ field: { value, onChange }, fieldState: { isDirty } }) => {
                    const hasValue = !!value && !isDirty;
                    const { component, props } = getComponentAttributes({
                        type: fieldType,
                    });
            
                    const Component:any = component;
                    const componentProps = { ...props} || {};

                    return (
                        <>
                        <WatchComponent
                            control={control}
                            render={render}
                            field={field}
                            isDirty={isDirty}
                            currentValue={value}
                            defaultValue={defaultValues}
                            onChange={onChange}
                            >
                            <Box my={2}>
                            <Component
                                code={code}
                                label={name}
                                required={required}
                                value={value ?? ''}
                                error={false}
                                helperText={""}
                                onChange={onChange}
                                {...componentProps}
                                >
                            </Component>
                            </Box>
                        </WatchComponent>
                        </>
                    )
                }}
            />
                )
            })}
        </div>
    )
}

export default RejectForm