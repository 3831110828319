import { FC, FormEvent, useRef, useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import CHLTextField, { ICHLTextField } from "../CHLTextField";
import CHLDialog from "../CHLDialog";
import { ReactComponent as FileIcon } from "../../assets/images/file.svg";
import { ReactComponent as HasFileIcon } from "../../assets/images/hasFile.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/remove.svg";
import { Box, IconButton } from "@material-ui/core";
import { BE_CODE_STATUS, CODE } from "../../utilities/constants";
import { ApplicationActions } from "../../stores/reducers/application";
import { useDispatch } from "react-redux";
import { getAttachmentConfig, blobToBase64 } from "../../utilities/helper";
import { ApplicationPageRequest } from "../../services/requests/ApplicationPageRequest";
import loadingGif from "../../assets/images/flipball.gif";

const CHLUploadObsField: FC<ICHLTextField> = (props) => {
  const { onChange, value, helperText, ...rest } = props;
  const { code } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>(value ? value : "");
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const objAttachmentConfig = getAttachmentConfig(code || "default");
  const SUPPORTED_FILE_TYPE = objAttachmentConfig.fileType;
  const MAX_FILE_SIZE = parseInt(objAttachmentConfig.fileSize);
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      onChange && onChange(value as any);
      setName(value.name);
    }
  }, []);

  useEffect(()=>{
    if(!value){
      setName("");
      if (inputRef.current) inputRef.current.value = "";
    }
  }, [value])

  const onClickHandler = () => {
    !uploadLoading && inputRef.current?.click();
  };

  const onClickRemoveHandler = (e: FormEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(ApplicationActions.removeObsFileMapByCode(code));
    dispatch(ApplicationActions.removeDocumentByCode(code));
    onChange && onChange(null as any);
    setName("");
    if (inputRef.current) inputRef.current.value = "";
  };

  const onFileChangeHandler = async (e: FormEvent) => {
    const files = (e.target as HTMLInputElement).files;
    if (files && files.length) {
      setUploadLoading(true);
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      if (currentFile.size > MAX_FILE_SIZE || !SUPPORTED_FILE_TYPE.includes(currentFileType)) {
        onChange && onChange(currentFile as any);
        setName(currentFile.name);
      } else {
        const base64Content = await blobToBase64(currentFile);
        const uploadObsParam = [{
          name: currentFile.name,
          content: base64Content,
        }];
        const uploadRes = await ApplicationPageRequest.uploadToObs(
          uploadObsParam
        );
        if (
          uploadRes.code === BE_CODE_STATUS.SUCCESS &&
          uploadRes?.data?.uploadedFiles?.[0]?.id
        ) {
          dispatch(
            ApplicationActions.setObsFileMap({
              [code || currentFile.name]:
                uploadRes?.data?.uploadedFiles?.[0]?.id,
            })
          );
          onChange && onChange(currentFile as any);
          setName(currentFile.name);
        } else {
          setError("File upload unsuccessful. Please try again.");
        }
      }
      setUploadLoading(false);
    }
  };

  const handleDownloadW8Template = () => {
    window.open("https://www.cimb.com.sg/content/dam/cimbsg/personal/document/forms/regulations-policies/foreign-account-tax-compliance-act/fatca-w8ben.pdf", "_blank");
  }

  const handleDownloadW9Template =  () => {
    window.open("https://www.cimb.com.sg/content/dam/cimbsg/personal/document/forms/regulations-policies/foreign-account-tax-compliance-act/fatca-w9.pdf", "_blank");
  }

  const handleDownloadFENTemplate = () => {
    window.open("https://www.cimb.com.sg/content/dam/cimbsg/personal/document/forms/banking-with-us/foreign-exchange-notice-declaration-form.pdf");
  }

  let tips;
  if(code === CODE.W8_W9_FORM){
    tips = (
      <div>
        <Typography style={{display: 'inline'}}> Please click on the applicable form to download the template</Typography>
        <Typography style={{display: 'inline'}}>&nbsp;(&nbsp;<a style={{cursor: 'pointer'}} onClick={handleDownloadW8Template}>W-8BEN</a>&nbsp;or</Typography>
        <Typography style={{display: 'inline'}}>&nbsp;<a style={{cursor: 'pointer'}} onClick={handleDownloadW9Template}>W-9 Form</a>&nbsp;)</Typography>
      </div>
    )
  }
  if(code === CODE.FEN_FORM){
    tips = (
      <div>
        <Typography style={{display: 'inline'}}> Please click on the</Typography>
        <Typography style={{display: 'inline'}}>&nbsp;<a style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={handleDownloadFENTemplate}>link</a>&nbsp;</Typography>
        <Typography style={{display: 'inline'}}>to download the template</Typography>
      </div>
    )
  }
  return (
    <>
      <CHLTextField
        file
        InputProps={{
          endAdornment: (
            <Box ml={1}>
              {uploadLoading ? (
                <IconButton>
                  <img
                    className={classes.loading}
                    src={loadingGif}
                    alt="loading..."
                  />
                </IconButton>
              ) : (
                <IconButton onClick={onClickRemoveHandler}>
                  <RemoveIcon />
                </IconButton>
              )}
            </Box>
          ),
        }}
        inputProps={{
          readOnly: true,
        }}
        onClick={onClickHandler}
        value={name}
        helperText={helperText}
        tips={tips}
        {...rest}
      />
      <input
        ref={inputRef}
        type="file"
        accept={SUPPORTED_FILE_TYPE}
        hidden
        onChange={onFileChangeHandler}
      />
      {error && (
        <CHLDialog
          hideClose={true}
          open={true}
          title="Oops..."
          onClose={() => setError("")}
          actions={[
            {
              title: "OK",
              color: "secondary",
              onClick: () => setError(""),
            },
          ]}
        >
          <Typography>{error}</Typography>
        </CHLDialog>
      )}
    </>
  );
};

const useStyles = makeStyles({
  loading: {
    width: "20px",
    height: "20px",
    zIndex: 10,
  },
});

export default CHLUploadObsField;
