import axiosInstance from "../axios";
import { 
  APPLICATION_URL, 
  RESUME_APPLICATION, 
  IDENTITY_AIP,
  APPLICATION_AIP,
  DICTIONARY_URL, 
  DICTIONARY_ELEMENT, 
  LOAN_CALCULATOR
} from "../api";
import { ReqResumeApplication, ReqInPrincipleApproval, ReqFetchLoanMatrix, ReqDictionary, ReqSubmitInPrincipleApproval } from "../../types/request";
import { mockDictionaryData, mockRepaymentFormData, mockIPAData } from "../../mock";

export class ResumeApplicationRequest {
  static fetchResumeAppForm(data: ReqInPrincipleApproval) {

    // const endpoint = data.hToken ? APPLICATION_AIP : IDENTITY_AIP

    //Temporary endpoint for AIP via external URL
    const promise = axiosInstance.get(`${APPLICATION_AIP}`, data);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static validateApplyNo(data: ReqResumeApplication) {
    const reqData = {
      applyNo: data.applyNo
    }
    const promise = axiosInstance.get(`${IDENTITY_AIP}`, reqData);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static fetchLoanMatrix(data?: ReqFetchLoanMatrix) {
    // return new Promise<any>((resolve) => {
    //   setTimeout(() => {
    //     resolve(mockRepaymentFormData);
    //   }, 2000);
    // });

    const promise = axiosInstance.get(`${LOAN_CALCULATOR}`, data);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static fetchDictionary(data?: ReqDictionary) {
    // return new Promise<any>((resolve) => {
    //   setTimeout(() => {
    //     resolve(mockDictionaryData);
    //   }, 2000);
    // });

    const promise = axiosInstance.get(`${DICTIONARY_URL + '/' + data?.book + DICTIONARY_ELEMENT + data?.label}`);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static submitApplication(applyNo: string, data: ReqSubmitInPrincipleApproval) {
    // return new Promise<any>((resolve) => {  
    //   setTimeout(() => {
    //     resolve({data: {code: "000000"}});
    //   }, 2000);
    // });
    // const promise =  axiosInstance.patch(`${APPLICATION_URL + '/' + applyNo}`, data);

    // const result:any = promise.then((response) => response.data)
  
    return axiosInstance.patch(`${APPLICATION_URL + '/' + applyNo}`, data);
  }

  static fetchResumeApplication(data: ReqInPrincipleApproval) {
    const { applyNo, hToken, nric } = data
    let masAipReqParam = {}
    if(hToken && nric){
      masAipReqParam = {
        hToken, nric
      }
    }
    const promise = axiosInstance.get(`${APPLICATION_URL + '/' + applyNo + RESUME_APPLICATION}`, masAipReqParam);
    const result:any = promise.then((response) => response.data)
    return result

  }
}
