import { createContext, FC, Fragment, memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Typography,
  Box,
  Collapse,
  Grid,
  Hidden,
  Theme,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import {
  dataURIToBlob,
  createYupSchema,
  isEmpty,
} from "../../../../utilities/helper";
import { selectParentsByIndex } from "../../../../stores/reducers/application";
import { 
  MCC_CAPTION,
  CODE, 
  FORM_TYPE, 
  PDPA_CONSENT_CONTENT
} from "../../../../utilities/constants";
import CollapseHeader from "./CollapseHeader";
import { RootState } from "../../../../stores/rootReducer";
import { RespAppField, RespPlaceholder } from "../../../../types/response";
import FormDataEntry from "./FormDataEntry";
import FormTable from "./FormTable";
import PdpaConsentField from "./PdpaConsentField"
import ApplicationButton from "./ApplicationButton";
import { CHLButton } from "../../../../components";
import _ from "lodash"
import { isNRICMatchWithDOB, isValidNRIC, isValidAgeRange, isAlgoCheckPass } from "../../../../utilities/helper";

interface IApplicationForm {
  activeIndex: number;
  tableTypeData?: any[];
  values: {[key: string]: any};
  onSubmit: () => void;
  onBack?: () => void;
}

interface IApplicationFormContext {
  register: any;
  control: any;
  defaultValues: any;
  fields: RespAppField[];
  activeIndex: number;
  isManualFlow: boolean;
  setValue?: any;
  getValues?: any;
  setFocus?: any;
  nric?: string;
  placeholders?: RespPlaceholder;
}

const REACT_APP_CIMB_CPF_NOA = window._env.REACT_APP_CIMB_CPF_NOA
export const ApplicationFormContext = createContext<IApplicationFormContext | null>(null);

const ApplicationForm: FC<IApplicationForm> = (props) => {
  const classes = useStyles();
  const { 
    activeIndex, 
    tableTypeData, 
    values, 
    onSubmit, 
    onBack 
  } = props;
  
  const nric = values[CODE.NRIC] && values[CODE.NRIC][0][0]

  const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const [expands, setExpands] = useState<boolean[]>([true]);

  const { 
    parents: { length }, 
    documentUpload, 
    parents, 
    isManualFlow 
  } = useSelector((s: RootState) => s.application);
  

  // Retrieve data from state
  const { data: myinfo } = useSelector((s: RootState) => s.myinfo);
  const { placeholderData: placeholders  } = useSelector((s: RootState) => s.partner);
  const currentParent = useSelector(selectParentsByIndex(activeIndex));
  const template = currentParent.reduce<RespAppField[]>((prev, current) => {
    let tabFields:any = current.applicationTemplateTabFields;

    let fields = null;
    if (current.type === FORM_TYPE.TABLE) {
      fields = tabFields.reduce((prevValue:any, newValue:any) => {
        const fieldType = newValue.field.fieldType 
        const hasRender = newValue.field.render

        let defValue = null
        
        if(fieldType == "RadioButton"){
          if(!hasRender){
            defValue = newValue.field.options[0].code
          }
        }
        return { ...prevValue, [newValue.field.code]: defValue };
      }, {});

      tabFields = [
        {
          field: { code: `${current.id}`, name: `${current.originalName}`, value: [fields], details: tabFields },
        },
      ] as any;
    }
    return prev.concat(tabFields);
  }, []);

  let myInfo: { [key: string]: any } = {}
  myInfo = Object.assign({}, myinfo)

  let tableForm = template.map((t) => {
    let { code, value, details, fieldType, options, render } = t.field;
    if(details){ //istabletype
      if(tableTypeData){
        value = tableTypeData
      }
    }else{//isdataentryc or attachment
      if(fieldType == "RadioButton"){
        if(!render){ 
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
            if(!value){
              value = myInfo[code] || options[0].code
            }
          }else{
            value = myInfo[code] || options[0].code
          } 
        }else{
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
          }
        }
      }else{
        if(fieldType === "ImageFile"){
          if(values && Object.keys(values).length > 0){
            const src = values[code] && values[code][0][0]

            if(src){
              const blob = dataURIToBlob(src);
              value = new File([blob], documentUpload[code], { type: blob.type });
            }
          }
        }else{
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
    
            if(!value){
              value = myInfo[code]
            }
          }else{
            value = myInfo[code]
          }
        }
      }
    }
    return  {[code]: value ?? null}
  })

  const formValues = Object.assign({}, ...tableForm);
  const defaultValues = formValues

  const { 
    register, 
    control, 
    handleSubmit, 
    setValue, 
    getValues, 
    clearErrors,
    watch,
    setFocus,
    trigger,
    formState
  } = useForm({
    mode: "onChange",
    defaultValues,
    shouldFocusError: true,
    resolver: createYupSchema(template, isManualFlow),
  });

  let { errors } = formState
  let objErr:{ [key: string] : any} = {}

  // hardcode
  const [nricVal, dobVal, block, street, floor, unit] = watch([CODE.NRIC, CODE.MCC_DOB, CODE.MCC_BLOCK, CODE.MCC_ADDR_STREET, CODE.MCC_ADDR_FLOOR, CODE.MCC_ADDR_UNIT]);
  useEffect(() => {
    if (nricVal && dobVal && isNRICMatchWithDOB(nricVal, dobVal) && isManualFlow) {
      const isValidNric = isValidNRIC(nricVal) && isAlgoCheckPass(nricVal);
      const isValidDob = isValidAgeRange(dobVal);
      if (isValidNric && isValidDob) {
        clearErrors(CODE.NRIC);
        clearErrors(CODE.MCC_DOB);
      } else if (isValidNric && errors[CODE.NRIC]) {
        clearErrors(CODE.NRIC);
      } else if (isValidDob && errors[CODE.MCC_DOB]) {
        clearErrors(CODE.MCC_DOB);
      }
    }
    if(block){
      trigger(CODE.MCC_BLOCK);
    }
    if(street){
      trigger(CODE.MCC_ADDR_STREET);
    }
    if(!isEmpty(unit) && isEmpty(floor)){
      trigger(CODE.MCC_ADDR_FLOOR);
    }
    if(!isEmpty(floor) && isEmpty(unit)){
      trigger(CODE.MCC_ADDR_UNIT);
    } 
    if((!isEmpty(floor) && !isEmpty(unit)) || (isEmpty(floor) && isEmpty(unit))){
      clearErrors(CODE.MCC_ADDR_FLOOR);
      clearErrors(CODE.MCC_ADDR_UNIT);
    }
  }, [nricVal, dobVal, block, street, floor, unit]);

  if(errors.hasOwnProperty(CODE.FATCA_REASON)){ //force validation on FATCA Reason Field
    Object.keys(errors).map(k =>{ 
      if(k === CODE.FATCA_REASON){
        objErr[k] = errors[k]
      }
    })
  }else{
    objErr = errors
  }

  useEffect(() => {
    const firstError = Object.keys(objErr).reduce((field: any, a) => { 
      return !!objErr[field] ? field : a;
    }, null);

    const fe = currentParent.filter(k =>{
      if(k.id === firstError){ //TableType
        return k
      }else{
        return k.applicationTemplateTabFields.some(e => e.field.code === firstError);
      }
    })

    const collapseIndex = currentParent.findIndex(arr =>JSON.stringify(arr)  === JSON.stringify(fe[0]))

    if(collapseIndex > -1){
      expands[collapseIndex] = true
      setExpands([...expands])
    }
  }, [errors, setFocus]);


  // Template
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {currentParent.map((parent, index) => {
        const { applicationTemplateTabFields, originalName } = parent;
        const caption = MCC_CAPTION.find((c) => c.NAME === originalName);
        const isCaptionExisted = caption?.TITLE;
        return (
          <Fragment key={parent.id}>
            <CollapseHeader
              name={originalName}
              expand={expands[index]}
              onClick={() => {
                expands[index] = !expands[index];
                setExpands([...expands]);
              }}
            />

            <Collapse in={isCaptionExisted ? expands[index] : true}>
              <Box mt={2} />
              {caption?.SUBTITLE && <p className={classes.darkText}>{caption?.SUBTITLE}</p>}
              {/* hardcode MCC_PDPA_CONSENT*/}
              {originalName == CODE.MCC_PDPA_CONSENT && <PdpaConsentField/>}

              {/* fixme upload tab name */}
              {/*{originalName == `${CODE.MCC_DOCUMENT_UPLOAD}`&& <Box>
                <Box fontWeight={900} fontSize="18px">CPF/NOA Statement(s)</Box>
                <Box mb={5}>
                  <p className={classes.darkText}>
                    Please <a target="blank" href={REACT_APP_CIMB_CPF_NOA}>click here</a> to submit your CPF/NOA Statement(s)
                  </p>
                </Box>
              </Box>}*/}

              <Grid container spacing={smAndUp ? 4 : 2}>
                <ApplicationFormContext.Provider
                  value={{
                    register,
                    control,
                    defaultValues,
                    fields: applicationTemplateTabFields,
                    activeIndex,
                    isManualFlow,
                    setValue,
                    getValues,
                    nric,
                    placeholders, 
                  }}>
                  {parent.type === FORM_TYPE.TABLE ? (
                    originalName === CODE.MCC_CPF_HISTORY || originalName === CODE.MCC_NOA ? null
                    :
                    <FormTable id={`${parent.id}`} />
                  ) : (
                    <FormDataEntry />
                  )}    
                </ApplicationFormContext.Provider>
              </Grid>
            </Collapse>
          </Fragment>
        );
      })}

      <Grid container item>
        {activeIndex != parents.length - 1 && (
          <Box clone order={{ xs: 3, sm: 1 }}>
            <Grid item xs={12} sm={3}>
              <Box my={2}>
                <CHLButton
                  type="button"
                  title="Back"
                  color="default"
                  fullWidth
                  onClick={onBack}
                />
              </Box>
            </Grid>
          </Box>
        )}
        <Hidden xsDown>
          <Box clone order={{ xs: 2, sm: 2 }}>
            <Grid
              item
              sm={activeIndex == parents.length - 1 ? 9 : 6}
            />
          </Box>
        </Hidden>
        <Box clone order={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={3}>
            <Box mt={2}>
              <ApplicationButton
                activeIndex={activeIndex}
                length={length}
                control={control}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  darkText:{
    color: theme.palette.secondary.main,
    fontSize: "16px"
  },
}))

export default memo(ApplicationForm);
