import { createContext, FC, forwardRef } from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";
import CHLLabel from "../CHLLabel";

interface ICHLRadio extends RadioGroupProps {
  label?: string;
  items?: { text: string; value: number | string }[];
  required?: boolean;
  bold?: boolean;
  disabled?: boolean;
  helperText?: string;
  description?: string;
  error?: boolean;
  toggle?: boolean;
}

interface IRadioContext {
  disabled: boolean;
  value: string | number;
  toggle?: boolean;
}

export const RadioContext = createContext<IRadioContext | null>(null);
// const CHLRadio: FC<ICHLRadio> = (props) => {
  const CHLRadio = forwardRef<any, ICHLRadio>((props, ref) => {
  const {
    label,
    required,
    bold,
    helperText = " ",
    description,
    error,
    children,
    value,
    disabled,
    toggle,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.root} error={error}>
      <CHLLabel
        bold={true}
        description={description}
        label={label}
        required={required}
      />

      <RadioGroup
        innerRef={ref}
        {...rest}
        value={value}
        row
        classes={{
          root: toggle
            ? clsx(classes.groupRoot, error && classes.groupRootError)
            : undefined,
        }}
      >
        <RadioContext.Provider value={{ value, disabled: !!disabled, toggle }}>
          {children}
        </RadioContext.Provider>
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: " 100%",
  },
  groupRoot: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.shape.borderRadius,
    "& > label": {
      borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    },
    "& > label:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    "& > label:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderRight: "none",
    },
  },
  groupRootError: {
    border: "1px solid #F44336",
  },
}));

export default CHLRadio;
