import { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Typography, Link, withStyles } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { v4 as uuid } from "uuid"
import { ReqPartnerVerifyData, ReqValidateUTokenV2 } from "../../../types/request";
import { PartnerPageRequest } from "../../../services/requests/PartnerPageRequest";
import { setMccMyinfoRedirectURL } from "../../../utilities/helper";
import { CODE, BE_CODE_STATUS } from "../../../utilities/constants";
import { CHLOverlayLoading, CHLButton } from "../../../components"
import { RootState } from "../../../stores/rootReducer";
import { PartnerActions, PartnerRepaymentActions, ErrorAction, MyInfoActions, ApplicationActions } from "../../../stores/reducers";
import { catPartner } from "./../../../utilities/logConfig";
import { ReactComponent as PointIcon } from "../../../assets/images/icon_point.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: '4px',
        marginBottom: '16px',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            marginBottom: '16px',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#F3F4F9',
        marginBottom: -1,
        minHeight: 54,
        '&$expanded': {
            minHeight: 54,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginBottom: 0,
    },
}))(MuiAccordionDetails);

function MCCPartnerPage() {
    const match = useRouteMatch<{ id: string }>("/mcc/:id");
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { authenticatedData, placeholderData } = useSelector((s: RootState) => s.partner);
    const token = window.sessionStorage.getItem(CODE.TOKEN);
    const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const queryParams = new URLSearchParams(location.search);
    const callbackUrl = queryParams.get(CODE.CALLBACK_URL);
    const partnerTrxID = queryParams.get(CODE.PARTNER_TRANSACTION_ID);
    const uid = queryParams.get(CODE.UID);
    const productCode = queryParams.get(CODE.PRODUCT_CODE);
    const partnerType = queryParams.get(CODE.ENTITY_TYPE);

    useEffect(() => {
        catPartner.info("MCC Partner Page");
        if (match) {
            const partyCode = match.params.id;
            if (partyCode && productCode && partnerType && partnerTrxID && uid && callbackUrl) {

                const requestUToken: ReqValidateUTokenV2 = { partnerTrxID, uid, callbackUrl, productCode, type: partnerType };

                const getSavedForms = async (reqUtoken: ReqValidateUTokenV2) => {
                    setIsLoading(true);
                    if (await validateUToken(reqUtoken)) {
                        const product = authenticatedData.product;
                        if (!product) {
                            fetchForms(partyCode, productCode, partnerType, requestUToken);
                        }
                    }
                    setIsLoading(false);
                }

                if (token && token != "undefined") {
                    if (verifyAuthCode(partyCode, productCode, partnerType)) {
                        const ssTabID = sessionStorage.getItem(CODE.TAB_ID);
                        if (ssTabID) {
                            getSavedForms(requestUToken);
                        } else {
                            sessionStorage.setItem(CODE.TAB_ID, uuid()); //generate tab ID and save to session storage
                            fetchForms(partyCode, productCode, partnerType, requestUToken);
                        }
                    } else {
                        sessionStorage.setItem(CODE.TAB_ID, uuid());
                        fetchForms(partyCode, productCode, partnerType, requestUToken);
                    }
                } else {
                    sessionStorage.setItem(CODE.TAB_ID, uuid());
                    fetchForms(partyCode, productCode, partnerType, requestUToken);
                }
            } else {
                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED));
            }
        } else {
            dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED));
        }
        dispatch(MyInfoActions.setData({})) //clear
        dispatch(MyInfoActions.setMyInfoError(false)) //clear
        dispatch(ApplicationActions.setPartyType(undefined)) //clear
        dispatch(ApplicationActions.setFaceVScore(undefined)) //clear Face Verification
        dispatch(ApplicationActions.setFaceVFlag(undefined)) //clear Face Verification
    }, []);

    const validateUToken = async (requestUToken: ReqValidateUTokenV2) => {
        let validate = false;
        const result = await PartnerPageRequest.validateUTokenV2(requestUToken);
        if (result) {
            validate = result.code === BE_CODE_STATUS.SUCCESS;
            sessionStorage.setItem(CODE.MCC_LANDING_URL, window.location.href);
            dispatch(PartnerActions.setCallbackURL(callbackUrl));
            dispatch(PartnerActions.setPartyType(CODE.MCC));
        }
        return validate;
    }

    const verifyAuthCode = (
        partyCodes: string,
        productCode: string,
        partnerType: string,
    ) => {
        let validate = true;
        const ssPartyCode = sessionStorage.getItem(CODE.PARTY_CODE);
        const ssProductCode = sessionStorage.getItem(CODE.PRODUCT_CODE);
        const ssType = sessionStorage.getItem(CODE.ENTITY_TYPE);

        if (ssPartyCode && ssProductCode && ssType) {
            if (ssPartyCode !== partyCodes) validate = false;
            else if (ssProductCode !== productCode) validate = false;
            else if (ssType !== partnerType) validate = false;
        }

        return validate;
    }

    const fetchForms = async (
        partyCode: string,
        productCode: string,
        type: string,
        requestUToken: ReqValidateUTokenV2,
    ) => {
        const authReqPayload: ReqPartnerVerifyData = {
            partyCode,
            productCode,
            type,
            isMcc: true,
        };
        setIsLoading(true);

        if (await validateUToken(requestUToken)) {
            const result = await PartnerPageRequest.verifyPartnerId(authReqPayload);
            if (result) {
                if (result.code === BE_CODE_STATUS.SUCCESS) {
                    dispatch(PartnerActions.setPartnerVerifyData(authReqPayload));
                    const currentUrl = window.location.href;
                    dispatch(PartnerActions.setLandingUrl(currentUrl))
                    //set partner data to session storage
                    sessionStorage.setItem(CODE.PARTY_CODE, partyCode);
                    sessionStorage.setItem(CODE.PRODUCT_CODE, productCode);
                    sessionStorage.setItem(CODE.ENTITY_TYPE, type);
                    dispatch(PartnerActions.setAuthenticatedData(result));
                }
            }
        }
        setIsLoading(false);
    }

    const setSingpassRedirectURL = () => {
        const state = token ?? ''
        const authoriseUrl = setMccMyinfoRedirectURL(state);
        return authoriseUrl;
    }

    const setLoanRepaymentData = () => {
        const objRepayment = {
            //repayment: loanInfo,
            entityType: partnerType,
            productCode,
            callbackUrl,
            placeholder: placeholderData,
        };
        dispatch(PartnerRepaymentActions.setRepaymentData(objRepayment));
    }

    const onClickHandler = async (isManualFlow: boolean) => {
        setRedirectLoading(true);
        let redirectUrl = "";
        if (!isManualFlow) {
            //redirectUrl = "http://localhost:3001/mcc/callback?code=122&state=234343";
            redirectUrl = setSingpassRedirectURL();
            dispatch(PartnerRepaymentActions.setPartnerRedirectURL(redirectUrl));
        } else {
            redirectUrl = window._env.MCC_REACT_APP_MYINFO_APP_REDIRECT_URI; //"http://localhost:3001/mcc/callback";
        }
        console.log("FE -- redirectUrl: ", redirectUrl);
        setLoanRepaymentData();
        dispatch(PartnerActions.setIsManualFlow(isManualFlow));
        dispatch(PartnerActions.setUid(uid));
        window.location.href = redirectUrl;
    };

    const onClickQuickLink = (type: string) => {
        let linkUrl;
        switch (type) {
            case 'howItWorks': linkUrl = window._env.MCC_REACT_APP_CIMB_HOW_IT_WORKS; break;
            case 'feesAndCharges': linkUrl = window._env.MCC_REACT_APP_CIMB_FEES_CHARGES; break;
            case 'termsAndConditions': linkUrl = window._env.MCC_REACT_APP_CIMB_TERMS_CONDITIONS; break;
            case 'agreement': linkUrl = window._env.MCC_REACT_APP_CIMB_AGREEMENT; break;
            case "FAQs": linkUrl = window._env.MCC_REACT_APP_CIMB_FAQS; break;
            default: break;
        }
        console.log("linkUrl", linkUrl);
        window.open(linkUrl || window._env.REACT_APP_CIMB_HOME, "_blank");
    }

    if (isLoading || redirectLoading) {
        return (
            <Box display="flex" justifyContent="center" mt={6}>
                <CHLOverlayLoading />
            </Box>
        );
    } else {
        return (
            (partnerType && Object.keys(authenticatedData).length > 0) ? (
                <Box className={classes.root}>
                    <Typography align="center" variant="h5" component="div">
                        <Box letterSpacing="-0.5px" fontSize="30px" fontWeight="bold">Apply for EZ-Link Micro Credit</Box>
                    </Typography>
                    <Box textAlign="center" my={2}>
                        <Typography variant="subtitle1">Manage topups to your</Typography>
                        <Typography variant="subtitle1">EZ-Link wallet with</Typography>
                        <Typography variant="subtitle1"><Box fontWeight="600">3-month 0% Interest Instalment</Box></Typography>
                    </Box>
                    <Box className={classes.dataList}>
                        <PointIcon width="10px" height="10px" />
                        <Box textAlign="left" flex={1} ml={2} mt={2}>
                            <Typography variant="subtitle1"><Box fontWeight="600">Instant approval</Box></Typography>
                            <Typography variant="subtitle1"><Box color="#2075AD">No more waiting and wondering</Box></Typography>
                        </Box>
                    </Box>
                    <Box className={classes.dataList}>
                        <PointIcon width="10px" height="10px" />
                        <Box textAlign="left" flex={1} ml={2} mt={2}>
                            <Typography variant="subtitle1"><Box fontWeight="600">No interest Charges</Box></Typography>
                            <Typography variant="subtitle1"><Box color="#2075AD">No gimmicks, only S$10 late payment fee if you miss a payment</Box></Typography>
                        </Box>
                    </Box>
                    <Box className={classes.dataList}>
                        <PointIcon width="10px" height="10px" />
                        <Box textAlign="left" flex={1} ml={2} mt={2}>
                            <Typography variant="subtitle1"><Box fontWeight="600">Get it now</Box></Typography>
                            <Typography variant="subtitle1"><Box color="#2075AD">Don’t miss out</Box></Typography>
                        </Box>
                    </Box>

                    <Box className={classes.head} my={2}>Quick Links</Box>

                    <Box>
                        <Accordion>
                            <AccordionSummary
                                className={classes.heading}
                                expandIcon={<ExpandMoreIcon className={classes.svgIcon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>How It Works</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Box fontSize="16px" fontWeight="600" mb={1}>
                                    1 Select EZ-Link Micro Credit
                                </Box>
                                <Box fontSize="16px" fontWeight="400" mb={1} ml={2}>
                                    At the EZ-Link Top Up Screen, top up your EZ-Link wallet in multiples of S$100.
                                </Box>
                                <Link onClick={() => onClickQuickLink('howItWorks')} className={classes.quickLinks}>View More</Link>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                className={classes.heading}
                                expandIcon={<ExpandMoreIcon className={classes.svgIcon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Fees & Charges</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Box display="flex" alignItems="flex-start" mb={1}>
                                    <Box className={classes.circleBox}></Box>
                                    <Box fontSize="16px" fontWeight="600" display="flex" flex={1}>
                                        Late fee:<Typography>&nbsp;S$10</Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="flex-start" mb={1}>
                                    <Box className={classes.circleBox}></Box>
                                    <Box fontSize="16px" fontWeight="600" display="inline" flex={1}>
                                        Monthly payment:<Typography variant="subtitle1" display="inline">&nbsp;Full payment must be made by the payment due date to avoid incurring a late fee of S$10.</Typography>
                                    </Box>
                                </Box>

                                <Link onClick={() => onClickQuickLink("feesAndCharges")} className={classes.quickLinks}>View Product Highlight Sheet</Link>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                className={classes.heading}
                                expandIcon={<ExpandMoreIcon className={classes.svgIcon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Terms & Conditions</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Link onClick={() => onClickQuickLink("termsAndConditions")} className={classes.quickLinks}>View Terms & Conditions Governing CIMB Micro Credit Card</Link>
                                <Link onClick={() => onClickQuickLink("agreement")} className={classes.quickLinks}>View CIMB Credit Cards Cardmember’s Agreement</Link>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                className={classes.heading}
                                expandIcon={<ExpandMoreIcon className={classes.svgIcon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>FAQs</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Box fontSize="16px" fontWeight="600" mb={1}>Can I apply ?</Box>
                                <Box fontSize="16px" fontWeight="600" mb={1}>To be eligible, you must be:</Box>
                                <Box display="flex">
                                    <Box className={classes.circleBox}></Box>
                                    <Box flex={1} fontSize="16px">between 21 to 70 years old</Box>
                                </Box>
                                <Box display="flex" mb={1}>
                                    <Box className={classes.circleBox}></Box>
                                    <Box flex={1} fontSize="16px">a Singapore Citizen / Singapore Permanent Resident</Box>
                                </Box>
                                <Link onClick={() => onClickQuickLink("FAQs")} className={classes.quickLinks}>View all FAQs</Link>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box className={classes.bottomWrapper}>
                        <CHLButton
                            title="Retrieve MyInfo with Singpass"
                            fullWidth
                            color="primary"
                            onClick={() => onClickHandler(false)}
                        />
                        <Box mt={2}>
                            <Typography variant="body1">
                                <span><b>No Singpass account?</b> Don't worry, apply via online application form </span>
                                <Link className={classes.linkText} onClick={() => onClickHandler(true)}>here</Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : <div></div>
        )
    }

}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        margin: '60px auto 0',
        display: 'flex',
        flexDirection: 'column',
    },
    bottomWrapper: {
        padding: "8px",
    },
    note: {
        marginRight: '2px',
    },
    linkText: {
        cursor: "pointer",
    },
    quickLinks: {
        fontSize: '13px',
        cursor: "pointer",
        marginTop: '8px',
    },
    dataList: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "baseline"
    },
    head: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
        color: "#333739",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700,
        color: "#333739",
    },
    details: {
        display: "flex",
        flexDirection: 'column'
    },
    circleBox: {
        width: "6px",
        height: "6px",
        background: "black",
        marginRight: "8px",
        borderRadius: "50%",
        marginTop: '10px',
    },
    svgIcon: {
        color: '#EC1D23',
    }
}));

export default MCCPartnerPage;