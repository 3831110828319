import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RespAppMetaData, RespDocUpload } from "../../types/response";

const initialState = {
  docUploadForm: <RespAppMetaData>{},
  pageLoading: true,
  uploadLoading: false,
  fileIds: {},
  callbackUrl: <any>"",
};

const docUploadSlice = createSlice({
  name: "docUpload",
  initialState,
  reducers: {
    setPageLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.pageLoading = payload;
    },
    setUploadLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.uploadLoading = payload;
    },
    setDocUploadApplication: (
      state,
      { payload }: PayloadAction<RespDocUpload>
    ) => {
      state.docUploadForm = payload.payload;
      state.callbackUrl = payload.callbackUrl;
    },
    setFileIds: (state, { payload }) => {
      const uploadFileIds = state.fileIds;
      state.fileIds = { ...uploadFileIds, ...payload };
    },
    setRemainFileIds: (state, { payload }) => {
      state.fileIds = { ...payload };
    },
  },
});

const { actions, reducer } = docUploadSlice;

export const DocUploadActions = actions;
export default reducer;
