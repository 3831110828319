import axiosInstance from "../axios";
import {
  APPLICATION_URL,
  OBS_UPLOAD,
  UPLOAD_FORM,
  IDENTITY_UPLOAD_FILES,
  UPLOAD_FILES,
} from "../api";
import { ReqInPrincipleApproval } from "../../types/request";
// import {
//   mockCheckUploadFiles,
//   mockFetchUploadForm,
//   mockObsUpload,
//   mockConfirmationUploadFiles,
// } from "../../mock/index";

export class DocUploadRequest {
  static validateApplyNo(applyNo: string) {
    const promise = axiosInstance.get(`${IDENTITY_UPLOAD_FILES}/${applyNo}`);
    const result: any = promise.then((response) => response.data);

    // const promise = new Promise((resolve) => {
    //   resolve(mockCheckUploadFiles);
    // });
    // const result: any = promise.then((res) => res);

    return result;
  }

  //   fixme
  static fetchDocUploadApplication(data: ReqInPrincipleApproval) {
    const { applyNo, hToken, nric } = data;
    let masAipReqParam = {};
    if (hToken && nric) {
      masAipReqParam = {
        hToken,
        nric,
      };
    }
    const promise = axiosInstance.get(
      `${APPLICATION_URL + "/" + applyNo + UPLOAD_FORM}`,
      masAipReqParam
    );
    const result: any = promise.then((response) => response.data);
    return result;

    // return new Promise<any>((resolve) => {
    //   setTimeout(() => {
    //     resolve(mockFetchUploadForm);
    //   }, 10);
    // });
  }

  static obsUploadFiles = async (data: any) => {
    const promise = axiosInstance.post(`${OBS_UPLOAD}`, data);
    const result: any = promise.then((response) => response.data);

    // const promise = new Promise((resolve) => {
    //   resolve(mockObsUpload);
    // });
    // const result: any = promise.then((res) => res);

    return result;
  };

  // static obsUploadFilesFail = async (data: any) => {
  //   return Promise.reject(-1);
  // };

  // 提交补录的document
  static submitDocuments = async (applyNo: string, data: any) => {
    const promise = axiosInstance.post(`${UPLOAD_FILES}/${applyNo}`, data);
    const result: any = promise.then((response) => response.data);
    return result;
  };
}
