import {
  // applyMiddleware,
  // compose,
  configureStore,
  // createStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import { PAUSE } from "redux-persist/es/constants";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

 
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "partnerRepayment", "partner", "myinfo"],
};


const persistedReducer = persistReducer(persistConfig, rootReducer);
// const t =
//   (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//   (window as any).__REDUX_DEVTOOLS_EXTENSION__();

// const store = createStore(persistedReducer, compose(applyMiddleware(thunk), t));
// const persistor = persistStore(store);
// export { store, persistor };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});



export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
