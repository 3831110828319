import { FC, useContext } from "react";
import { useStore } from "react-redux";
import { RespAddress, RespAppField } from "../../../../types/response";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { CHLCheckbox, CHLValueField } from "../../../../components";
import { getComponentAttributes } from "../../../../utilities/helper";
import { ApplicationFormContext } from "./ApplicationForm";
import { CODE, DEFAULT_VALUE, MCC_EMPLOY_STATUS_VALUE } from "../../../../utilities/constants";
import { CODE_LABEL } from "../../../../utilities/label";
import { TOOLTIPS } from "../../../../utilities/tooltips";
import WatchedComponent from "./WatchedComponent";
import { Grid, InputAdornment, makeStyles, Hidden } from "@material-ui/core";
import { ReactComponent as PaynowIcon } from "../../../../assets/images/paynow.svg";
import { ApplicationSummaryContext } from "./ApplicationSummary";
import ButtonGeolocation from "./ButtonGeolocation"

type Field = Pick<RespAppField, "field">;
interface IApplicationController extends Field {
  length: number;
  required: boolean;
  name: string;
  controllerCode?: string;
  isSummary?: boolean;
  onCRSCountryChange?: (val: string, code: string, onChange: any, setValue: any) => void,
}

interface IAddr {
  [key: string]: string;
}

const ApplicationController: FC<IApplicationController> = (props) => {
  const store = useStore();

  const { length, required, name, controllerCode, isSummary, field, onCRSCountryChange } = props;
  const { code, fieldType, render, dataType, maxLength } = field;
  let { options } = field;
  let fieldRequired = required;
  const context = useContext(
    isSummary ? ApplicationSummaryContext : ApplicationFormContext
  );
  let { errors } = useFormState({ control: context?.control });
  const { activeIndex, defaultValues, getValues, setValue, placeholders, register, isManualFlow } = context || {};
  const classes = useStyles();

  const myInfo = store.getState().myinfo.data

  const v3 = useWatch({ control: context?.control, name: CODE.PAYNOW_ID_TYPE });
  let forcePaynowField = v3 === DEFAULT_VALUE.PAYNOW_NRIC ? true : false;

  let defaultValue: any;
  let error: any;
  const ids = controllerCode?.split(".");

  if (ids) {
    error = ids.reduce(
      (prevValue, newValue) => prevValue && prevValue[newValue],
      errors
    );
    defaultValue = ids.reduce(
      (prevValue, newValue) => prevValue && prevValue[newValue],
      defaultValues
    );
  } else {
    defaultValue = defaultValues[code];
    error = errors[code];
  }


  // hardCoded
  const isHasTinField = field && field.code === CODE.HASTIN;
  let hasTinDependName: any = "";
  let hidden: boolean = false;

  if (isHasTinField && controllerCode) {
    const prefix = controllerCode.replace(/\.[^.]*$/, "");
    hasTinDependName = `${prefix}.${CODE.COUNTRY}`;
  }
  const v1 = useWatch({ control: context?.control, name: hasTinDependName });
  if (isHasTinField && controllerCode && v1 === DEFAULT_VALUE.COUNTRY_SINGAPORE)
    hidden = true;
  // ================================================ //
  const isTinField = field && field.code === CODE.TIN;
  let tinDependNames: any = "";
  let forceValueField = false;

  if (isTinField && controllerCode) {
    const prefix = controllerCode.replace(/\.[^.]*$/, "");
    tinDependNames = [`${prefix}.${CODE.COUNTRY}`, `${prefix}.${CODE.HASTIN}`];
  }
  const v2 = useWatch({ control: context?.control, name: tinDependNames });
  if (isTinField && controllerCode) {
    if (
      JSON.stringify(v2) ===
      JSON.stringify([DEFAULT_VALUE.COUNTRY_SINGAPORE, DEFAULT_VALUE.HASTIN_YES])
    )
      forceValueField = true;
    else forceValueField = false;
  }
  // "Are you a Singapore PR" should be hidden when Nationality is Singapore Citizen
  const isSingaporeprField = code === CODE.MCC_SINGAPORPR;
  const nationalityValue = useWatch({ control: context?.control, name: CODE.MCC_NATIONALITY});
  if(isSingaporeprField && nationalityValue === DEFAULT_VALUE.COUNTRY_SINGAPORE){
    hidden = true;
  }
  // only when Secondary Contact Type has value render Secondary Contact Number
  const isSecondContactField = code === CODE.MCC_FAKE_SECONDARY_CONTACT;
  const secondContactTypeVal = useWatch({ control: context?.control, name: CODE.MCC_SECOND_CONTACT_TYPE });
    if(isSecondContactField && secondContactTypeVal){
    fieldRequired = true;
  }
    if(isSecondContactField && !secondContactTypeVal){
    hidden = true;
  }

  // Mobile Number --> areaCode + number
  let mobileNumberMaxLength = 16;
  const isMobileNoField = code === CODE.MCC_MOBILE_NO;
  const mobileAreaCode = useWatch({ control: context?.control, name: CODE.MCC_MOBILE_AREA_CODE });
  if(isMobileNoField && mobileAreaCode === '65'){
    mobileNumberMaxLength = 8;
    const curMobileNoValue = getValues(CODE.MCC_MOBILE_NO);
    if(curMobileNoValue && curMobileNoValue.length > 8){
        setValue && setValue(CODE.MCC_MOBILE_NO, curMobileNoValue.substr(0,8));
    } 
  }

  // Foreign Address or Local Address
  const addressType = useWatch({ control: context?.control, name: CODE.MCC_ADDR_TYPE });
  const isMccAddrCountryField = code === CODE.MCC_ADDR_COUNTRY;
  const isMccPostalCodeField = code === CODE.MCC_POST_CODE;
  let isShowGetAddressButton = true;
  let mccPostalMinLength = 6;
  let mccPostalMaxLength = 6;
  // manual + local
  if(isManualFlow && (!addressType || addressType === CODE.MCC_ADDR_TYPE_LOCAL)){
    mccPostalMinLength = 6;
    mccPostalMaxLength = 6;
    const shouldRequiredFields = [
      CODE.MCC_POST_CODE,
      CODE.MCC_BLOCK,
      CODE.MCC_ADDR_STREET
    ];
    const shouldHiddenFields = [
      CODE.MCC_ADDR_COUNTRY,
      CODE.MCC_FOREIGN_ADDR_LINE1, 
      CODE.MCC_FOREIGN_ADDR_LINE2
    ];
    if(shouldHiddenFields.includes(code)){
      hidden = true;
    }
    if(shouldRequiredFields.includes(code)){
      fieldRequired = true;
    }
  }
  // manual + foreign
  if(isManualFlow && addressType === CODE.MCC_ADDR_TYPE_FOREIGN){
    mccPostalMinLength = 0;
    mccPostalMaxLength = 9;
    isShowGetAddressButton = false;
    const shouldRequiredFields = [
      CODE.MCC_ADDR_COUNTRY,
      CODE.MCC_FOREIGN_ADDR_LINE1
    ];
    const shouldHiddenFields = [
      CODE.MCC_BLOCK,
      CODE.MCC_ADDR_STREET,
      CODE.MCC_ADDR_BUILDING,
      CODE.MCC_ADDR_FLOOR,
      CODE.MCC_ADDR_UNIT
    ];
    if(shouldHiddenFields.includes(code)){
      hidden = true;
    }
    if(shouldRequiredFields.includes(code)){
      fieldRequired = true;
    }
  }
  // myinfo-type
  const myinfoAddrType = myInfo?.regadd?.type;
  // myinfo + SG [Local]
  if(!isManualFlow && myinfoAddrType === 'SG'){
    const shouldHiddenFields = [
      CODE.MCC_ADDR_TYPE,
      CODE.MCC_POST_CODE,
      CODE.MCC_BLOCK,
      CODE.MCC_ADDR_STREET,
      CODE.MCC_ADDR_BUILDING,
      CODE.MCC_ADDR_FLOOR,
      CODE.MCC_ADDR_UNIT,
      CODE.MCC_ADDR_COUNTRY,
      CODE.MCC_FOREIGN_ADDR_LINE1, 
      CODE.MCC_FOREIGN_ADDR_LINE2
    ];
    if(shouldHiddenFields.includes(code)){
      hidden = true;
    }
  }
  // myinfo + foreign
  if(!isManualFlow && myinfoAddrType === 'UNFORMATTED'){
    const allAddressFields = [
      CODE.MCC_ADDR_TYPE,
      CODE.MCC_POST_CODE,
      CODE.MCC_BLOCK,
      CODE.MCC_ADDR_STREET,
      CODE.MCC_ADDR_BUILDING,
      CODE.MCC_ADDR_FLOOR,
      CODE.MCC_ADDR_UNIT,
      CODE.MCC_ADDR_COUNTRY,
      CODE.MCC_FOREIGN_ADDR_LINE1, 
      CODE.MCC_FOREIGN_ADDR_LINE2
    ];
    if(allAddressFields.includes(code)){
      hidden = true;
    }
  }

  // Employment Status enhancement
  const employStatus = useWatch({ control: context?.control, name: CODE.MCC_EMPLOY_STATUS });
  const allEmployDetailFields = [
    CODE.MCC_EMPLOY_COMPANY_NAME,
    CODE.MCC_EMPLOY_BUSINESS_SECTOR,
    CODE.MCC_EMPLOY_OCCUPATION,
    CODE.MCC_GROSS_ANNUAL_INCOM,
    CODE.MCC_LENGTH_OF_EMPLOYMENT
  ];
  const notWorkStatusValues = [
    MCC_EMPLOY_STATUS_VALUE.HOUSE_WIFE,
    MCC_EMPLOY_STATUS_VALUE.STUDENT,
    MCC_EMPLOY_STATUS_VALUE.RETIREE
  ];
  const nsfHiddenFields = allEmployDetailFields.slice(1, allEmployDetailFields.length);
  if(employStatus === MCC_EMPLOY_STATUS_VALUE.NSF && nsfHiddenFields.includes(code)){
    hidden = true;
  }
  if(notWorkStatusValues.includes(employStatus) && allEmployDetailFields.includes(code)){
    hidden = true;
  } 

  // end hardCoded

  // Method
  const onChangeHandler = (onChange?: any) => async (e?: any) => {
    const isCountryField = code === CODE.COUNTRY;
    const isPayNowTypeField = code === CODE.PAYNOW_ID_TYPE;
    const isAddressTypeField = code === CODE.MCC_ADDR_TYPE;
    const isEmployStatusField = code === CODE.MCC_EMPLOY_STATUS;

    let newValue = e?.target?.value ?? e;

    if (isCountryField && controllerCode) {
      const prefix = controllerCode.replace(/\.[^.]*$/, "");
      if (newValue === DEFAULT_VALUE.COUNTRY_SINGAPORE) {
        setValue && setValue(`${prefix}.${CODE.HASTIN}`, DEFAULT_VALUE.HASTIN_YES);
        setValue && setValue(`${prefix}.${CODE.TIN}`, context?.nric);
      } else {
        const prevValue = getValues(controllerCode);
        if (prevValue === DEFAULT_VALUE.COUNTRY_SINGAPORE)
          setValue && setValue(`${prefix}.${CODE.TIN}`, null);
      }
      onCRSCountryChange && onCRSCountryChange(newValue, prefix, onChange, setValue)
    }
    else if (isPayNowTypeField) {

      if (getValues(CODE.PAYNOW_ID) == null) {
        setValue && setValue(CODE.PAYNOW_ID, context?.nric);
      } else {
        if (newValue === DEFAULT_VALUE.PAYNOW_MOBILE) {
          const idValue = getValues(CODE.PAYNOW_ID) === context?.nric ? null : getValues(CODE.PAYNOW_ID)
          setValue && setValue(CODE.PAYNOW_ID, idValue);
        } else {
          setValue && setValue(CODE.PAYNOW_ID, context?.nric);
        }
      }
    }else if(isAddressTypeField){
      const localAddressFields = [
        CODE.MCC_POST_CODE,
        CODE.MCC_BLOCK,
        CODE.MCC_ADDR_STREET,
        CODE.MCC_ADDR_BUILDING,
        CODE.MCC_ADDR_FLOOR,
        CODE.MCC_ADDR_UNIT,
        CODE.MCC_ADDR_PROPERTY_TYPE,
        CODE.MCC_ADDR_RESIDENT_STATUS,
        CODE.MCC_LENGTH_OF_RESIDENCE
      ];
      const  foreignAddressFields = [
        CODE.MCC_POST_CODE,
        CODE.MCC_ADDR_COUNTRY,
        CODE.MCC_FOREIGN_ADDR_LINE1, 
        CODE.MCC_FOREIGN_ADDR_LINE2,
        CODE.MCC_ADDR_PROPERTY_TYPE,
        CODE.MCC_ADDR_RESIDENT_STATUS,
        CODE.MCC_LENGTH_OF_RESIDENCE
      ];
      if(newValue === CODE.MCC_ADDR_TYPE_LOCAL){
        foreignAddressFields.map(field => setValue && setValue(field, null))
        setValue && setValue(CODE.MCC_ADDR_COUNTRY, DEFAULT_VALUE.COUNTRY_SINGAPORE)
      }else{
        localAddressFields.map(field => setValue && setValue(field, null))
        setValue && setValue(CODE.MCC_ADDR_COUNTRY, null)
      }
    }else if(isEmployStatusField){
      const preValue = getValues(CODE.MCC_EMPLOY_STATUS);
      const employedStatus = [
        MCC_EMPLOY_STATUS_VALUE.EMPLOYED,
        MCC_EMPLOY_STATUS_VALUE.SELF_EMPLOYED,
        MCC_EMPLOY_STATUS_VALUE.COMMISSIOM
      ];
      const companyName = getValues(CODE.MCC_EMPLOY_COMPANY_NAME);
      if((employedStatus.includes(preValue) && employedStatus.includes(newValue))){
        // 不做修改
      }else if(!employedStatus.includes(newValue)){
        if(newValue === MCC_EMPLOY_STATUS_VALUE.NSF ){
          if(!employedStatus.includes(preValue)){
            setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, null);
          }
          setValue && setValue(CODE.MCC_EMPLOY_BUSINESS_SECTOR, "080"); //Government Bodies
          setValue && setValue(CODE.MCC_EMPLOY_OCCUPATION, "64"); //Armed Forces(Police/Army/Navy)
          setValue && setValue(CODE.MCC_GROSS_ANNUAL_INCOM, "0");
          setValue && setValue(CODE.MCC_LENGTH_OF_EMPLOYMENT, "01-00");
        }
        if(newValue === MCC_EMPLOY_STATUS_VALUE.HOUSE_WIFE){
          setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, "Not Applicable");
          setValue && setValue(CODE.MCC_EMPLOY_BUSINESS_SECTOR, "270"); //Home Maker
          setValue && setValue(CODE.MCC_EMPLOY_OCCUPATION, "10"); //Homemaker
          setValue && setValue(CODE.MCC_GROSS_ANNUAL_INCOM, "0");
          setValue && setValue(CODE.MCC_LENGTH_OF_EMPLOYMENT, "01-00");
        }
        if(newValue === MCC_EMPLOY_STATUS_VALUE.STUDENT){
          setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, "Not Applicable");
          setValue && setValue(CODE.MCC_EMPLOY_BUSINESS_SECTOR, "280"); //Student
          setValue && setValue(CODE.MCC_EMPLOY_OCCUPATION, "11"); //Student
          setValue && setValue(CODE.MCC_GROSS_ANNUAL_INCOM, "0");
          setValue && setValue(CODE.MCC_LENGTH_OF_EMPLOYMENT, "01-00");
        }
        if(newValue === MCC_EMPLOY_STATUS_VALUE.RETIREE){
          setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, "Not Applicable");
          setValue && setValue(CODE.MCC_EMPLOY_BUSINESS_SECTOR, "260"); //Retired
          setValue && setValue(CODE.MCC_EMPLOY_OCCUPATION, "91"); //Retired/Pensioner
          setValue && setValue(CODE.MCC_GROSS_ANNUAL_INCOM, "0");
          setValue && setValue(CODE.MCC_LENGTH_OF_EMPLOYMENT, "01-00");
        }
      }else{
        if(preValue === MCC_EMPLOY_STATUS_VALUE.NSF && companyName !== "Not Applicable"){
          setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, companyName);
        }else{
          setValue && setValue(CODE.MCC_EMPLOY_COMPANY_NAME, null);
        }
        setValue && setValue(CODE.MCC_EMPLOY_BUSINESS_SECTOR, null);
        setValue && setValue(CODE.MCC_EMPLOY_OCCUPATION, null);
        setValue && setValue(CODE.MCC_GROSS_ANNUAL_INCOM, null);
        setValue && setValue(CODE.MCC_LENGTH_OF_EMPLOYMENT, null);
      }
    }

    if (!required) {
      if (newValue == "") newValue = null
    }

    if (!isCountryField) {
      onChange && onChange(newValue);
      setValue && setValue(code, newValue)
    }
  };

  //MCC only address detail has oneMap 
  function autoPopulateOneMapAddress(address: RespAddress) {
    Object.entries(address).map(([key, value]: any) => {
      setValue && setValue(key, value);
    })
  }

  const getLabel = (code: any, name: any) => {
    if (code === "awsmackpdpa") {
      return CODE_LABEL[CODE.MCC_ACKNOWLEDGE_PDPA_OPTION]
    }
    return CODE_LABEL[code] ?? name;
  }


  // Template
  return (
    <Controller
      name={controllerCode ?? code}
      control={context?.control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState: { isDirty } }) => {
        // hardcode 
        let placeholder;
        if(placeholders){
          if(code === CODE.MCC_LENGTH_OF_EMPLOYMENT){
            placeholder = {
              [CODE.LENGTH_OF_EMPLOYMENT_MONTH]: placeholders[CODE.MCC_LENGTH_OF_EMPLOYMENT_MONTH],
              [CODE.LENGTH_OF_EMPLOYMENT_YEAR]: placeholders[CODE.MCC_LENGTH_OF_EMPLOYMENT_YEAR]
            }
          }else if(code === CODE.MCC_LENGTH_OF_RESIDENCE){
            placeholder = {
              [CODE.LENGTH_OF_EMPLOYMENT_YEAR]: placeholders[CODE.MCC_LENGTH_OF_RESIDENCE_YEAR],
              [CODE.LENGTH_OF_EMPLOYMENT_MONTH]: placeholders[CODE.MCC_LENGTH_OF_RESIDENCE_MONTH],
            }
          }else{
            placeholder = placeholders[code] || undefined;
          }
        }
        
        const hasValue = !!value && !isDirty;
        const hasMyInfo = Object.keys(myInfo).some((k) => k === code);
        const { component, componentOption, props } = getComponentAttributes({
          activeIndex,
          code,
          type: fieldType,
          options,
          hasValue,
          dataType,
          value,
          hasMyInfo,
          placeholder,
          maxLength,
          isManualFlow,
        });

        let Component: any
        const ComponentOption = componentOption;
        let componentProps: any
        if (code === CODE.PAYNOW_ID) {
          Component = forcePaynowField ? CHLValueField : component;

          const adorment = forcePaynowField ?
            { labelRight: <PaynowIcon /> } : {
              InputProps: {
                endAdornment: <PaynowIcon />, startAdornment: (
                  <InputAdornment position="start">+</InputAdornment>
                ),
              }
            }
          componentProps = { ...props, ...adorment } || {};
        } else {
          Component = forceValueField ? CHLValueField : component;
          componentProps = { ...props, description: TOOLTIPS[code] } || {};
        }
        if(isMobileNoField){
          componentProps = {...componentProps, maxLength: mobileNumberMaxLength }
        }
        if(isManualFlow && isMccPostalCodeField){
          componentProps = {...componentProps, maxLength: mccPostalMaxLength, minLength: mccPostalMinLength}
        }
        // CIMBSG-3351
        // if(isDropdownWithDefaultValue){
        //   Component = CHLValueField;
        //   componentProps = { ...componentProps, value: dropdownDefaultValue }
        // }

        // Grid settings
        let gridAuto: any = { xs: 12, sm: 6 };
        if (length <= 1) gridAuto = { xs: true };
        // if (code === CODE.POSTALCODE) gridAuto = { xs: 12, sm: 3 };
        if (Component === CHLCheckbox) gridAuto = { xs: 12 };
        if(isMccAddrCountryField && addressType === CODE.MCC_ADDR_TYPE_FOREIGN && isManualFlow){
          options = options.filter(op=>op.code !== 'SG')
        }
        return (
          <>
            <WatchedComponent
              ref={ref}
              control={context?.control}
              controllercode={controllerCode}
              render={render}
              field={field}
              isDirty={isDirty}
              currentValue={value}
              defaultValue={defaultValue}
              onChange={onChange}
              isManualFlow={context?.isManualFlow}>
              <Grid
                item
                {...gridAuto}
                className={hidden ? classes.hidden : undefined}>
                <Component
                  {...register(code)}
                  ref={ref}
                  code={code}
                  label={CODE_LABEL[code] ?? name}
                  required={fieldRequired}
                  items={options && options}
                  value={value ?? ''}
                  error={error}
                  helperText={error?.message}
                  onChange={onChangeHandler(onChange)}
                  onBlur={onBlur}
                  {...componentProps}
                >
                  {options && options.map((option, i) => (
                    <WatchedComponent
                      key={option.code}
                      control={context?.control}
                      controllercode={controllerCode}
                      // render={option.render}
                      //text={CODE_LABEL[option.code] ?? option.name}
                      text={getLabel(option.code, option.name)}
                      value={option.code}
                      component={ComponentOption}
                      index={i}
                    />
                  ))}
                </Component>
              </Grid>
            </WatchedComponent>
            {(isManualFlow && code === CODE.MCC_POST_CODE && isShowGetAddressButton) && (
              <>
                <Grid item xs={12} sm={3}>
                  <ButtonGeolocation
                    searchVal={!error ? getValues(code) : ""}
                    onClick={autoPopulateOneMapAddress}
                  />
                </Grid>
                <Hidden xsUp>
                  <Grid item sm={6} />
                </Hidden>
              </>
            )}
          </>
        );
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  hidden: {
    display: "none",
  },
}));

export default ApplicationController;
