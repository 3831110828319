import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { CODE } from "../../../../utilities/constants";
import { CODE_LABEL } from "../../../../utilities/label";
import { TOOLTIPS } from "../../../../utilities/tooltips";
import {
  FieldType,
  RespAppOptionField,
  RespAppField,
} from "../../../../types/response";
import { Box, Grid, Theme, useMediaQuery, makeStyles } from "@material-ui/core";
import WatchedComponent from "../../Callback/subcomponents/WatchedComponent";
import {
  CHLCheckbox,
  CHLCheckboxButton,
  CHLDatePicker,
  CHLRadio,
  CHLRadioButton,
  CHLTextField,
  CHLAutocomplete,
  CHLButton,
} from "../../../../components";
import UploadField from "./UploadField";
import { InputAdornment } from "@material-ui/core";
import { createYupSchema } from "../../../../utilities/helper";

interface IPorps {
  applyNo: string;
  formFields: RespAppField[];
  onSubmit: (data: any) => void;
}

const UploadForm: FC<IPorps> = ({ applyNo, formFields, onSubmit }) => {
  const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const classes = useStyles();
  const form = formFields.map(({ field: { code, value } }: any) => {
    return { [code]: value ?? null };
  });
  const defaultValues = Object.assign({}, ...form);

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: createYupSchema(formFields),
  });

  const onChangeHandler = (code: string, onChange?: any) => async (e?: any) => {
    let newValue = e?.target?.value ?? e;
    setValue(code, newValue);
    onChange && onChange(newValue);
    trigger(code);
  };

  const onSubmitHandler = async (data: any) => {
    onSubmit && onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={smAndUp ? 4 : 2}>
        {formFields &&
          formFields.map(({ field, name, required }) => {
            const { code, fieldType, maxLength, options, group } = field;
            if (group?.code == CODE.HIDDEN_FIELD) return null;
            return (
              <Controller
                key={code}
                name={code}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { isDirty },
                }) => {
                  const placeholder = "";
                  const hasValue = !!value && !isDirty;
                  const { component, componentOption, props } =
                    getComponentAttributes({
                      code,
                      type: fieldType,
                      options,
                      hasValue,
                      maxLength,
                      placeholder,
                    });

                  const Component: any = component;
                  const ComponentOption: any = componentOption;
                  const componentProps =
                    {
                      ...props,
                      description: TOOLTIPS[code],
                    } || {};
                  let gridAuto: any = { xs: 12, sm: 6 };
                  if (formFields.length <= 1) gridAuto = { xs: true };
                  if (Component === CHLCheckbox) gridAuto = { xs: 12 };
                  return (
                    <Grid item {...gridAuto}>
                      <Component
                        code={code}
                        label={name}
                        required={required}
                        items={options}
                        value={value ?? ""}
                        error={errors[code]}
                        helperText={errors[code]?.message}
                        onChange={onChangeHandler(code, onChange)}
                        {...componentProps}
                      >
                        {options &&
                          options.map((option, i) => (
                            <WatchedComponent
                              key={option.code}
                              control={control}
                              text={CODE_LABEL[option.code] ?? option.name}
                              value={option.code}
                              component={ComponentOption}
                              index={i}
                            />
                          ))}
                      </Component>
                    </Grid>
                  );
                }}
              />
            );
          })}
      </Grid>
      <Box mt={3} className={classes.justifyContent}>
        <CHLButton title="Submit" type="submit" color="secondary" />
      </Box>
    </form>
  );
};

export default UploadForm;

const getComponentAttributes = (obj: {
  code: string;
  type: FieldType;
  options: [] | RespAppOptionField[];
  hasValue: boolean;
  dataType?: string;
  value?: any;
  placeholder?: string | Object;
  maxLength?: number;
}) => {
  const {
    code,
    type,
    options,
    value,
    dataType,
    hasValue,
    maxLength,
    placeholder,
  } = obj;

  switch (type) {
    case "InputField":
    case "Address":
    case "Email":
      return {
        component: CHLTextField,
        props: {
          maxLength,
          placeholder,
        },
      };
    case "TextArea":
      return {
        component: CHLTextField,
        props: {
          maxLength,
          placeholder,
          multiline: true,
          minRows: 1,
          maxRows: 6,
        },
      };
    case "Phone":
      return {
        component: CHLTextField,
        props: {
          minRows: 1,
          placeholder,
          InputProps: {
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          },
        },
      };
    case "Amount":
      return {
        component: CHLTextField,
        props: {
          placeholder,
          maxLength,
          InputProps: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },
        },
      };
    case "DatePicker":
      return {
        component: CHLDatePicker,
        props: { placeholder: "yyyy-mm-dd" },
      };
    case "Dropdown":
      const selectedValue = options.find((o) => o.code == value);
      return {
        component: CHLAutocomplete,
        props: {
          value: selectedValue ? selectedValue : null,
        },
      };
    case "ImageFile":
      return {
        component: UploadField,
        props: { placeholder: "Tap here to upload file" },
      };
    case "RadioButton":
      return {
        component: CHLRadio,
        componentOption: CHLRadioButton,
        props: { bold: false, toggle: options?.length === 2 },
      };
    case "Checkbox":
      return {
        component: CHLCheckbox,
        componentOption: CHLCheckboxButton,
        props: {
          dataType,
        },
      };
    default:
      return {
        component: CHLTextField,
        props: {
          placeholder,
        },
      };
  }
};

const useStyles = makeStyles(() => ({
  justifyContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
