import { FC } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  PropTypes,
  Typography,
} from "@material-ui/core";
import CHLButton, { ButtonColorProps } from "../CHLButton";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

interface ICHLDialog extends DialogProps {
  title: any;
  actions?: { 
    title: string; 
    color?: ButtonColorProps; 
    type?: "button" | "submit" | "reset";
    form?: string;
    onClick?: () => void 
  }[];
  onClose: () => void;
  hideClose?: boolean;
  titleAlignment?: PropTypes.Alignment;
}

const CHLDialog: FC<ICHLDialog> = (props) => {
  const {
    actions = [{ title: "Close" }],
    title,
    children,
    onClose,
    hideClose,
    titleAlignment,
    ...rest
  } = props;
  const classes = useStyles();


  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{ square: true }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle disableTypography>
        <Typography
          align={titleAlignment ? titleAlignment : "center"}
          component="div"
          classes={{ root: classes.title }}
        >
          <Box fontWeight="bold">{title}</Box>
          {!hideClose && 
            <Box position="absolute" top="0" right="0">
              <IconButton
                size="small"
                disableRipple
                classes={{ sizeSmall: classes.sizeSmall }}
                onClick={onClose}
              >
                <CloseIcon className={classes.iconButton} />
              </IconButton>
            </Box>
          }
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <CHLButton
          
            key={index}
            title={action.title}
            fullWidth
            color={action.color}
            onClick={action.onClick ?? onClose}
            type={action.type ? action.type : "button"} 
            form={action.form}
            style={{margin: "10px"}}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    // wordBreak: "",
  },
  title: {
    position: "relative",
  },
  iconButton: {
    width: 15,
    height: 15,
  },
  sizeSmall: {
    padding: 5,
  },
}));

export default CHLDialog;
