import { useEffect, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import { useLocation, useHistory } from "react-router-dom";
import { RootState } from "../../../stores/rootReducer";
import { useSelector } from "react-redux";
import { 
  CODE,
  DEFAULT_VALUE, 
  REDIRECT_COUNT_DOWN
} from "../../../utilities/constants";
import { 
  BUTTON_LABEL, 
  APP_COMPLETION, 
  ML_APP_COMPLETION,
  BNPL_APP_COMPLETION,
  LABEL 
} from "../../../utilities/label";
import { CHLButton, CHLCustomerService } from "../../../components"
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { ReactComponent as FailIcon } from "../../../assets/images/fail.svg";
import { ReactComponent as OctopusSuccess } from "../../../assets/images/oct_success.svg";
import { ReactComponent as OctopusFail } from "../../../assets/images/oct_fail.svg";



const CallbackCompletePage = () => {
  let { state } = useLocation<{ 
    code: string, 
    applyNo: string, 
    applicAmount: string, 
    approvedAmount?: string,
    decision?: string 
  }>();
  let { 
    code, 
    applyNo, 
    applicAmount, 
    approvedAmount,
    decision 
  } = { ...state };
  const desc = decision ? decision : "AIP"
  const history = useHistory();
  
  const { callbackURL, partnerVerifyData }:any = useSelector((s: RootState) => s.partner);
  const partnerType = partnerVerifyData?.type;

  let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);

  const redirectToMerchant = () =>{
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.href = callbackURL;
  }

  useEffect(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()

    if(code){
        const countDownTimer = setInterval(() => {
          timer--;
          setTimer(timer)
          if(timer === 0){
            redirectToMerchant()
          }
        }, 1000)
        return () => {
          clearInterval(countDownTimer)
          setTimer(REDIRECT_COUNT_DOWN)
        }
    }else{
      history.push({
        pathname: "/403"
      });
    }
  }, [])

  const COMPLETE_LABEL =
    partnerType === CODE.EWALLET
      ? ML_APP_COMPLETION
      : partnerType === CODE.MERCHANT
      ? BNPL_APP_COMPLETION
      : APP_COMPLETION;

  const showNotes = decision == undefined && partnerType !== CODE.MERCHANT;
  return (
    <Box mt={10}>
      <>
        <Typography align="center" variant="h5" component="div">
          <Box 
            fontWeight="bold" 
            color={
              desc === DEFAULT_VALUE.REJECT ? "#FF0000" : "#16C0B7"
              }>
              {COMPLETE_LABEL[desc].title}
          </Box>
        </Typography>
        <Box display="flex" justifyContent="center" my={3}>
          {desc === DEFAULT_VALUE.REJECT ? 
            <FailIcon/> : <SuccessIcon/>
          }
        </Box>
        <Typography align="center" component="div">
          <Box fontWeight="bold">
            {COMPLETE_LABEL[desc].header}
          </Box>
          <Box my={3}>
            {COMPLETE_LABEL[desc].message}
          </Box>
          {desc !== DEFAULT_VALUE.REJECT && <>
          <Box my={3}>
          <Box fontWeight="bold">{LABEL.APPLY_NO}</Box>
            <Box>{applyNo}</Box>
          </Box>
            <Box fontWeight="bold" mt={3}>{
              `${decision ? "Approved" : "Applied"}${LABEL.LOAN_AMOUNT}` 
              }
            </Box>
            <Box>{decision? approvedAmount: applicAmount}</Box>
            {showNotes && <Box my={3}>{LABEL.APP_COMPLETE_NOTE}</Box>}
          </>}
        </Typography>
        <Box display="flex" justifyContent="center" my={5}>
          {desc === DEFAULT_VALUE.REJECT ? 
            <OctopusFail/> : <OctopusSuccess/>
          }
        </Box>
        {desc === DEFAULT_VALUE.REJECT && <Box my={5} textAlign="center">
          <CHLCustomerService/>
        </Box>
        }
      </>
      <Box my={2} display="flex" justifyContent="center">
        <CHLButton
          title={ BUTTON_LABEL.back + " (" + timer + "s)"}
          color="secondary"
          onClick={() => redirectToMerchant()}
        />
      </Box>
    </Box>
  );
};

export default CallbackCompletePage;
