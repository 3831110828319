import { useState, useEffect, useCallback, useRef, FC } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";

import { RootState } from "../../../stores/rootReducer";
import { AuthActions } from "../../../stores/reducers";
import { ApplicationActions, selectParentsByIndex } from "../../../stores/reducers/application";

import { FORM_TYPE, CODE, DEFAULT_VALUE, BE_CODE_STATUS } from "../../../utilities/constants";
import { blobToBase64, isInIframe } from "../../../utilities/helper";
import { lendelaDecrypt } from "../../../utilities/encryption";

import { CHLOverlayLoading, CHLDialog } from "../../../components";
import ApplicationForm from "../Callback/subcomponents/ApplicationForm";
import { LendelaApplicationRequest } from "./LendelaApplicationRequest";
import { POST_MESSAGE, POPUP_MESSAGE } from "./constants";
import { RespAppMetaData } from "../../../types/response";
import { catPartner } from "../../../utilities/logConfig";

const postMessage = (msg: string) => {
  window.parent.postMessage(msg, '*');
}

const Title = () => {
  const classes = useStyles();
  return <Typography className={classes.titleLabel}>Oops, connection error!</Typography>
}  

function LendelaAIP() {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [slpData, setSlpData] = useState<any>(null);
  const [nric, setNric] = useState<any>(null);
  const [applyNo, setApplyNo] = useState<string>('');
  const [isShowError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const location = useLocation();
  const dispatch = useDispatch();
 
  useEffect(() => {
    catPartner.info("Start Lendela page")
    dispatch(
      ApplicationActions.setData({
        res: [],
        isManualFlow: false,
        isLendela: true,
      })
    );
    init();
  }, []);

  const init = async () => {
    // if(isInIframe()){
    //   setIsLoading(false);
    //   return;
    // }
    console.log("if open in iframe: ", isInIframe());
    
    const queryParams = new URLSearchParams(location.search);
    console.log("queryParams: ", queryParams);
    const encryptParam: string = queryParams.get("param") || "";
    console.log("encryptParam: ", encryptParam);
    let paramObj;
    try{
      paramObj = lendelaDecrypt(encryptParam);
      console.log("paramObj after decrypt: ", paramObj);
    }catch(e){
      // URL decryption failed
      postMessage(POST_MESSAGE.D1);
      console.log("URL decryption failed, postMessage: D1");
    }

    const applyNo = paramObj?.applyNo;
    const slpToken = paramObj?.slpToken;

    if(applyNo && slpToken){

      dispatch(AuthActions.setLendelaSLpToken(slpToken));
      setApplyNo(applyNo);

      const validateRes = await LendelaApplicationRequest.validateApplyNo(applyNo);
      console.log("called auth API");

      if (validateRes.code === BE_CODE_STATUS.SUCCESS) {
        console.log("validateApplyNo success");
        await handleFetchAIP(applyNo);
      }else{
        console.log("validateApplyNo failed");
        // Auth API failed due to validation 999997
        if(validateRes.code === BE_CODE_STATUS.UNAUTHORIZED){
          postMessage(POST_MESSAGE.A1);
          console.log("Auth API failed due to validation 999997, postMessage: A1");
        }
        // Auth API failed due to system error 999999
        if(validateRes.code === BE_CODE_STATUS.SYSTEM_ERROR){
          postMessage(POST_MESSAGE.A2);
          console.log("Auth API failed due to system error 999999, postMessage: A2");
          setShowError(true);
          setErrorMsg(POPUP_MESSAGE.AUTH_ERROR);
        }
      }
    }  
    setIsLoading(false);
  };

  const handleFetchAIP = async (applyNo: string) => {
    const templateInfo = await LendelaApplicationRequest.fetchFormAndInfo(applyNo);
    console.log("called aipFetch API");
    if (templateInfo.code === BE_CODE_STATUS.SUCCESS) {
      console.log("fetchFormAndInfo success");
      console.log("templateInfo: ", templateInfo);
      const { template, slpData } = templateInfo.data;
      const { personIDNo } = slpData;
      let res: RespAppMetaData[] = [];
      res = Object.values(template).map((v): any => {
        return v;
      });
      dispatch(
        ApplicationActions.setData({
          res,
          isManualFlow: true,
          isLendela: true,
        })
      );
      setSlpData(slpData);
      setNric({ personIDNo: [[personIDNo]] });
    }else{
      console.log("fetchFormAndInfo failed");
      // Fetch API failed due to ext token/auth token validation 999997
      if(templateInfo.code === BE_CODE_STATUS.UNAUTHORIZED){
        postMessage(POST_MESSAGE.A3);
        console.log("Fetch API failed due to ext token/auth token validation 999997, postMessage: A3");
      }
      // Fetch API failed due to order validation 999993
      if(templateInfo.code === BE_CODE_STATUS.AIP_EXPIRED){
        postMessage(POST_MESSAGE.A4);
        console.log("Fetch API failed due to order validation 999993, postMessage: A4");
      }
      // Fetch API failed due to system error 999999
      if(templateInfo.code === BE_CODE_STATUS.SYSTEM_ERROR){
        postMessage(POST_MESSAGE.A5);
        console.log("Fetch API failed due to system error 999999, postMessage: A5");
        setShowError(true);
        setErrorMsg(POPUP_MESSAGE.AIP_ERROR);
      }
    }
  }

  const handleRetry = async () => {
    setShowError(false);
    setIsLoading(true);
    if(errorMsg == POPUP_MESSAGE.AUTH_ERROR){
      console.log("re-load from beginning....");
      await init();
    }
    if(errorMsg == POPUP_MESSAGE.AIP_ERROR){
      console.log("only re-load from call fetchAIP...");
      await handleFetchAIP(applyNo);
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={6}>
        <CHLOverlayLoading />
      </Box>
    );
  }
  if(isShowError){
    return (
      <CHLDialog
        titleAlignment="left"
        hideClose={true}
        open={isShowError}
        title={<Title />}
        onClose={() => setShowError(false)}
        actions={[
          {
            title: "Ok",
            color: "secondary",
            onClick: () => handleRetry(),
          },
        ]}
      >
        <Typography>Please try again.</Typography>
      </CHLDialog>
    )
  }
  return (
    <Box>
      {slpData && (
        <LendelaForm
          nricValObj={nric}
          slpData={slpData}
          activeIndex={activeIndex}
          applyNo={applyNo}
        />
      )}
    </Box>
  );
}

interface ILendelaFormProp {
  nricValObj: {[key: string]: any} | null;
  activeIndex: number;
  slpData: {[key: string]: any};
  applyNo: string;
}

const LendelaForm: FC<ILendelaFormProp> = (props: any) => {

  const { nricValObj, activeIndex, slpData, applyNo } = props;
  const [isShowError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isNotMatched, setIsNotMatched] = useState<boolean>(false);
  const styles = useStyles();

  const store = useStore();
  const dispatch = useDispatch();
  const { parents } = useSelector((s: RootState) => s.application);
  const currentTab = useSelector(selectParentsByIndex(activeIndex));

  let submissionData: { [key: string]: any[] } = {};
  let reSubmitFlag = useRef(true);
  let tempPaynowData = useRef<{ [key: string]: any }>();
  let tableDataTemp = [];

  const polishSubmissionData = async (data: any) => {
    await Promise.all(
      currentTab.map(async (tab) => {
        let sData: { [key: string]: any[] } = {};
        const { applicationTemplateTabFields, id, type, originalName } = tab;
        if (type === FORM_TYPE.TABLE) {
          applicationTemplateTabFields.map(async (field) => {
            const { code, dataType } = field.field;
            const tableData = data[id];
            tableDataTemp = tableData;
            let td = tableData.map((d: any) => {
              return d[code] ? [d[code]] : [null];
            });
            sData[code] = td;
          });
          Object.assign(submissionData, sData);
        } else {
          const fieldV = await applicationTemplateTabFields.reduce(
            async (prevValue, newValue) => {
              const { code } = newValue.field;
              let value = data[code];

              if (
                code === CODE.FATCA_RESIDENCE &&
                value === DEFAULT_VALUE.FATCA_RESIDENCE_YES
              )
                dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.NSTP));

              if (value instanceof File) {
                dispatch(
                  ApplicationActions.setDocumentUpload({
                    [code]: value.name,
                  })
                );
                value = await blobToBase64(value);
              }

              if (value == " ") value = "";

              value = [[value]];

              const prev = await prevValue;
              return { ...prev, [code]: value };
            },
            Promise.resolve({})
          );
          Object.assign(submissionData, fieldV);
        }
      })
    );
  };

  const onSubmit = useCallback(
    async (app?: any) => {

      if (reSubmitFlag.current) {

        reSubmitFlag.current = false;

        try{
          await polishSubmissionData(app);

          let obsFileMap = store.getState().application.obsFileMap;

          if (app[CODE.W8_W9_FORM]) {
            submissionData[CODE.W8_W9_FORM] = [[obsFileMap[CODE.W8_W9_FORM]]];
          }

          const getCallPaynowFlag = (savedPaynowData: any, app: any) => {
            if(!savedPaynowData){
              return true;
            }
            if(savedPaynowData && (savedPaynowData.id !== app.paynowid ||  savedPaynowData.type !== app.paynowtype)){
              return true;
            }
            return false;
          }

          const  needCallPaynowValidation = getCallPaynowFlag(tempPaynowData.current, app);

          if(needCallPaynowValidation){

            const paynowParams = {
              id: app.paynowid,
              type: app.paynowtype,
              myInfoNames: {
                customerFullName: slpData.customerFullName,
                aliasname: slpData.aliasname,
                hanyupinyinname: slpData.hanyupinyinname,
                hanyupinyinaliasname: slpData.hanyupinyinaliasname,
              },
            };
            const paynowValidateRes = await LendelaApplicationRequest.paynowValidate(paynowParams);
            
            if(paynowValidateRes.code === BE_CODE_STATUS.SUCCESS){
              // 遍历paynowValidateRes返回的字段，加进submissionData
              const paynowData: { [key: string]: any } = paynowValidateRes.data;
              // 暂存最近一次paynow校验成功的数据
              tempPaynowData.current = {
                ...paynowData,
                ...paynowParams,
              };
             
              await submitAIP(paynowData, submissionData, app);
              
            }else{
              // Paynow API success but result is AB, 900008
              if (paynowValidateRes.code === BE_CODE_STATUS.PAYNOW_SHOW_MESSAGE) {
                postMessage(POST_MESSAGE.P1);
                console.log("Paynow API success but result is AB, 900008, postMessage: P1");
                setShowError(true);
                setErrorMsg(POPUP_MESSAGE.PAYNOW_VALIDATION_NOT_MATCH);
                setIsNotMatched(true);
              }
              // Paynow API failed due to ext token/auth token validation 999997
              if(paynowValidateRes.code === BE_CODE_STATUS.UNAUTHORIZED){
                postMessage(POST_MESSAGE.P4);
                console.log("Paynow API failed due to ext token/auth token validation 999997, postMessage: P4");
              }
              // Paynow API failed due to system error 999999
              if(paynowValidateRes.code === BE_CODE_STATUS.SYSTEM_ERROR){
                postMessage(POST_MESSAGE.P5);
                console.log("Paynow API failed due to system error 999999, postMessage: P5");
                setShowError(true);
                setErrorMsg(POPUP_MESSAGE.PAYNOW_ERROR);
              }
            }
          }else{
            await submitAIP(tempPaynowData.current, submissionData, app);
          }
          reSubmitFlag.current = true;

        }catch(e){
          // 捕获 可能的 代码处理错误
          setShowError(true);
          setErrorMsg('Unknow Error');
          postMessage(POST_MESSAGE.U1);
          console.log("Unknow Error");
          reSubmitFlag.current = true;
        }
        
      }
    },
    [parents.length, slpData]
  );

  const submitAIP = async (paynowData: any, submissionData: any, app: any) => {
    if(Object.entries(paynowData).length > 0){
      Object.entries(paynowData).map(
        ([key, value]:any) => {
          submissionData[key] = [[value]] 
        }
      )
    }
    // 提交AIP
    const submitRes = await LendelaApplicationRequest.submitApplication(applyNo, submissionData);
    const { code } = submitRes;
    if(code === BE_CODE_STATUS.SUCCESS){
      console.log("Submit API success");
      // Submit API success
      postMessage(`${POST_MESSAGE.S1},${paynowData.nameValidationPassed ? POST_MESSAGE.P3 : POST_MESSAGE.P2},${app[CODE.FATCA_RESIDENCE] === DEFAULT_VALUE.FATCA_RESIDENCE_YES ? POST_MESSAGE.F2 : POST_MESSAGE.F1},${tableDataTemp.length === 1 ? POST_MESSAGE.C1 : POST_MESSAGE.C2}`);
      console.log("postMessage" + `
        ${POST_MESSAGE.S1},
        ${paynowData.nameValidationPassed ? POST_MESSAGE.P3 : POST_MESSAGE.P2},
        ${app[CODE.FATCA_RESIDENCE] === DEFAULT_VALUE.FATCA_RESIDENCE_YES ? POST_MESSAGE.F2 : POST_MESSAGE.F1},
        ${tableDataTemp.length === 1 ? POST_MESSAGE.C1 : POST_MESSAGE.C2}
      `);
    }else{
      console.log("Submit API failed");
      if(code === BE_CODE_STATUS.UNAUTHORIZED || code === BE_CODE_STATUS.AIP_EXPIRED){
        // Submit API failed due to ext token/auth token validation 999997 || 999993
        postMessage(POST_MESSAGE.S3);
        console.log("Submit API failed due to ext token/auth token validation 999997, postMessage: S3");
        }
      // Submit API failed due to system error 999999
      if(code === BE_CODE_STATUS.SYSTEM_ERROR){
        postMessage(POST_MESSAGE.S2);
        console.log("Submit API failed due to system error 999999, postMessage: S2");
        setShowError(true);
        // setErrorMsg('Submit API failed due to system error');
        setErrorMsg(POPUP_MESSAGE.SUBMIT_ERROR);
      }
    }
  }

  const closeFormPopup = () => {
    setShowError(false)
    setIsNotMatched(false);
  }

  return (
    <Box position="relative" pt={6}>
      <Box>
        <Typography component="span" className={styles.requireMark}>
          *
        </Typography>
        <Typography component="span">This field is required.</Typography>
      </Box>
      <ApplicationForm
        activeIndex={activeIndex}
        values={nricValObj}
        onSubmit={onSubmit}
      />
      {isShowError && (
        <CHLDialog
          titleAlignment="left"
          hideClose={true}
          open={isShowError}
          title={<Title />}
          onClose={closeFormPopup}
          actions={[
            {
              title: "Ok",
              color: "secondary",
              onClick: closeFormPopup,
            },
          ]}
        >
          {
            isNotMatched ? (
              <Typography>We're sorry but we're unable to process your request. You must register your mobile number or NRIC with PayNow first to receive the loan. Please note to include the country code without any spacing for your mobile number eg. 6512345678.</Typography>
            ) : (
              <Typography>Please click "Next" again to proceed.</Typography> 
            )
          }
        </CHLDialog>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  note: {
    fontSize: "16px",
    lineHeight: "22px",
  },
  h4: {
    color: theme.palette.secondary.main,
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: 700,
    marginBottom: "10px",
  },
  requireMark: {
    color: theme.palette.primary.main,
    fontSize: "16px",
  },
  titleLabel: {
    fontWeight: 700,
    fontSize: "18px",
  }
}));

export default LendelaAIP;
