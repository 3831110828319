import { FormLabel, makeStyles, Tooltip, Box } from "@material-ui/core";
import { FC } from "react";
import InfoIcon from "@material-ui/icons/Info";
import parse from "html-react-parser";

interface ICHLLabel {
  bold?: boolean;
  label?: string;
  required?: boolean;
  description?: string;
  component?: any;
}

const CHLLabel: FC<ICHLLabel> = (props) => {
  const {
    bold = true,
    label,
    required,
    description,
    component = "label",
  } = props;
  const classes = useStyles();

  return !!label ? (
    <FormLabel
      component={component}
      className={bold ? classes.labelBold : undefined}
    >
      {parse(label)}
      {!!required && <span className={classes.required}>*</span>}
      {!!description && (
        <Tooltip title={description} placement="top" enterTouchDelay={0}>
          <InfoIcon fontSize="small" className={classes.icon} />
        </Tooltip>
      )}
    </FormLabel>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  labelBold: {
    fontWeight: 700,
  },
  required: {
    color: theme.palette.primary.main,
  },
  icon: {
    cursor: "pointer",
    margin: "-5px 2px",
  },
}));

export default CHLLabel;
