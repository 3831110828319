import { combineReducers, PayloadAction, Reducer, AnyAction, createAction } from "@reduxjs/toolkit";
import errorReducer from "./reducers/error";
import authReducer from "./reducers/auth";
import alertReducer from "./reducers/alert";
import alertDialogReducer from "./reducers/alertDialog";
import applicationReducer from "./reducers/application";
import myinfoReducer from "./reducers/myinfo";
import partnerReducer from "./reducers/partner"
import resumeApplicationReducer from "./reducers/resumeApplication"
import partnerRepaymentReducer from "./reducers/partnerRepayment"
import preCheckReducer from "./reducers/precheck"
import DocUploadReducer from "./reducers/docUpload";

const rootReducer = combineReducers({
  error:errorReducer,
  auth: authReducer,
  alert: alertReducer,
  application: applicationReducer,
  myinfo: myinfoReducer,
  partner: partnerReducer,
  resumeApplication: resumeApplicationReducer,
  partnerRepayment: partnerRepaymentReducer,
  preCheck: preCheckReducer,
  alertDialog: alertDialogReducer,
  docUpload: DocUploadReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;