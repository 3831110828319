import { FC, useRef } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import { dataURIToBlob } from "../../utilities/helper";
import { ReactComponent as ResetIcon } from "../../assets/images/reset.svg";

interface ICHLSignatureField {
  label?: string;
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  error?: boolean;
  onChange: (e: any) => void;
}

const CHLSignatureField: FC<ICHLSignatureField> = (props) => {
  const {
    label,
    required,
    bold = true,
    helperText = " ",
    error,
    onChange,
  } = props;
  const classes = useStyles();
  const canvasRef = useRef<SignatureCanvas | null>(null);

  const onResetClickedHandler = () => {
    canvasRef.current?.clear();
    onChange && onChange(undefined);
  };

  const onEndHandler = () => {
    const src = canvasRef.current?.getTrimmedCanvas().toDataURL("image/png");
    if (!src) return;

    const blob = dataURIToBlob(src);
    const signature = new File([blob], "signature.png", { type: blob.type });
    onChange && onChange(signature);
  };

  return (
    <FormControl className={classes.root} error={error}>
      <Box display="flex" alignItems="center" mb={1}>
        {!!label && (
          <FormLabel
            component="label"
            className={bold ? classes.labelBold : undefined}
          >
            {label}
            {!!required && <span className={classes.required}>*</span>}
          </FormLabel>
        )}
        <Box flex={1} />

        <Box
          px={0.5}
          onClick={onResetClickedHandler}
          className={classes.clearBtn}
        >
          <ResetIcon width="15" height="15" />
          <Box mr={0.5} />
          <Box>Clear</Box>
        </Box>
      </Box>

      <SignatureCanvas
        penColor="black"
        backgroundColor="#ECFCFF"
        canvasProps={{ style: { width: "100%", height: 250 } }}
        ref={(ref) => (canvasRef.current = ref)}
        onEnd={onEndHandler}
      ></SignatureCanvas>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  labelBold: {
    fontWeight: 700,
  },
  required: {
    color: theme.palette.primary.main,
  },
  clearBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontSize: "16px"
    // borderBottom: "1px solid #999",
  },
}));

export default CHLSignatureField;
