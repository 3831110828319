import { AppBar, Box, Container, makeStyles, Toolbar } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import publicRoutes from "../../routes/publicRoutes";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

function Public() {
  const classes = useStyles();

  return (
    <Switch>
      {publicRoutes.map((publicRoute) => {
        const Component = publicRoute.component;
        return (
          <Route
            key={publicRoute.name}
            path={publicRoute.path}
            exact={publicRoute.exact ?? true}
            render={() => (
              <>
                {publicRoute.headerShown !== false && (
                  <AppBar elevation={0}>
                    <Toolbar>
                      <Box flex={1} />
                      <Logo />
                      <Box flex={1} />
                    </Toolbar>
                  </AppBar>
                )}

                <Container
                  className={
                    publicRoute.headerShown !== false
                      ? classes.container
                      : undefined
                  }
                >
                  <Component />
                </Container>
              </>
            )}
          />
        );
      })}
    </Switch>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 56,
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
    [theme.breakpoints.up("xs")]: {
      padding: 20,
    },
  },
}));

export default Public;
