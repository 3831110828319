import { FC, useContext } from "react";
import { ApplicationFormContext } from "./ApplicationForm";
import { ApplicationFormSummaryContext } from "./ApplicationFormSummary";
import { ApplicationSummaryContext } from "./ApplicationSummary";
import ApplicationController from "./ApplicationController";
import { CODE } from "../../../../utilities/constants";

interface IFormDataEntry {
  isSummary?: boolean;
}

const FormDataEntry: FC<IFormDataEntry> = (props) => {
  const { isSummary } = props;
  const context = useContext(
    isSummary ? ApplicationSummaryContext : ApplicationFormContext
  );
  const fields = context?.fields;
  return (
    <>
      {fields &&
        fields.map(({ field, name, required }) => {
          const { code, status, group } = field;
          if (group?.code === CODE.HIDDEN_FIELD) return null;
          if([CODE.AGENT_CODE, CODE.ROAD_SHOW].includes(code)) return null;

          // if(code === "regadd") return null;

          return (
              <ApplicationController
                key={code}
                isSummary={isSummary}
                length={fields.length}
                field={field}
                required={required}
                name={name}
              />
          );
        })}
    </>
  );
};

export default FormDataEntry;
