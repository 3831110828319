import moment from "moment"
import axios, { AxiosRequestConfig } from "axios"
import { dataEncryption, dataDecryption } from "../utilities/encryption";
import { BE_CODE_STATUS, HTTP_STATUS, CODE } from "../utilities/constants";
import { store } from "../stores";
import { ErrorAction } from "../stores/reducers/error";
import { AuthActions } from "../stores/reducers/auth";
import { PAYNOW_VALIDATION, RESUME_APPLICATION, AUTHENTICATION, VALIDATE_UTOKEN, ROAD_SHOW_RESPONSE, GET_ADDRESS, MCC_REDIRECT_URI, OBS_UPLOAD, UPLOAD_FILES } from "./api"

export const baseURL = process.env.NODE_ENV == "production" ? "" : process.env.REACT_APP_BASE_URL
const ENCRYPTION_ENABLED = window._env.MS_ENCRYPTION_ENABLED === "true"
const X_SAT_TIMESTAMP = moment().valueOf()

const config: AxiosRequestConfig = {
  baseURL: `${baseURL}/ms/v1`,
  timeout: parseInt(window._env.AXIOS_TIMEOUT) ?? 300000,
  headers: {
    "X-SAT-TIMESTAMP": X_SAT_TIMESTAMP,
    "Content-Type": "application/json",
  },
  // sourceId: "mcc",
};

const axiosInstance = axios.create(config);
axiosInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const { data } = config;

    if(data){
      if(ENCRYPTION_ENABLED && config.url != MCC_REDIRECT_URI){
        const encData = dataEncryption(data, X_SAT_TIMESTAMP);
        config.data = encData
      }
    }

    const isLendelaAPI = config.url?.includes("/oc/partner/");
    if(isLendelaAPI){

      const slpToken = store.getState().auth.slpToken;
      config.headers["X-OC-EXT-TOKEN"] = slpToken;

      const isLendelaAuthAPI = config.url?.includes("/oc/partner/auth/");
      if(!isLendelaAuthAPI){
        const authCode = store.getState().auth.authCode;
        config.headers["X-OC-AUTH-CODE"] = authCode;
      }
    }

    const token = sessionStorage.getItem(CODE.TOKEN)

    if(config.url != AUTHENTICATION && !isLendelaAPI){
      if (token){
        config.headers["Authorization"] = `Bearer ${token}`;
      } 
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    const url = response.config.url
    if(ENCRYPTION_ENABLED && url != MCC_REDIRECT_URI){
      const responseData = dataDecryption(response.data, X_SAT_TIMESTAMP)
      response.data = responseData
    }
    const authCode = response.data.data?.authCode
    
    if(authCode){
      store.dispatch(AuthActions.setToken(authCode)) 
    }

    const isLendelaAuthAPI = url?.includes("/oc/partner/auth/");
    if(isLendelaAuthAPI){
      store.dispatch(AuthActions.setLendelaAuthCode(authCode)) 
    }

    const isLendelaAPI = url?.includes("/oc/partner/");
    if(isLendelaAPI){
      return response;
    }

    const resCode = response.data.code

    if(url?.includes(GET_ADDRESS)){
      store.dispatch(
        ErrorAction.setErrorCode(null)
      )
    }
    else if(url?.includes(RESUME_APPLICATION)){ //FOR 5-mins loading
      store.dispatch(
        ErrorAction.setErrorCode(
          resCode == BE_CODE_STATUS.AIP_INVALID_NRIC ? resCode : null
        )
      )
    } else if(url?.includes(ROAD_SHOW_RESPONSE) && resCode === BE_CODE_STATUS.ROAD_SHOW_INVALID) {
      return response;
    } else if(url?.includes(UPLOAD_FILES) && resCode === BE_CODE_STATUS.UPLOAD_FILES_INVALID) {
      return response;
    }else if(url?.includes(PAYNOW_VALIDATION) && resCode === BE_CODE_STATUS.PAYNOW_SHOW_MESSAGE){
      return response;
    }else if(url === OBS_UPLOAD && resCode === BE_CODE_STATUS.CONNECTIVITY_ERROR) {
      return response;
    }else if(url?.endsWith("/application") && resCode === BE_CODE_STATUS.DUPLICATE_SUBMISSION){
      store.dispatch(
        ErrorAction.setErrorCode(null)
      )
    }else{
      store.dispatch(
        ErrorAction.setErrorCode(
          resCode != BE_CODE_STATUS.SUCCESS ? resCode : null
        )
      )
    }
    return response 
  },
  function (error) {
    if (error.response) {
      
      const { status } = error.response;
      const url = error.response.config.url

      const isLendelaAPI = url?.includes("/oc/partner/");
      if(isLendelaAPI && status == HTTP_STATUS.UNAUTHORIZED){
        error.response.data = {
          status,
          code: BE_CODE_STATUS.UNAUTHORIZED
        }
        return error.response;
      }
      if(isLendelaAPI && (status == HTTP_STATUS.SERVICE_UNAVAILABLE || status == HTTP_STATUS.LENDELA_SERVICE_UNAVAILABLE )){
        error.response.data = {
          status,
          code: BE_CODE_STATUS.SYSTEM_ERROR
        }
        return error.response;
      }

      if (status === HTTP_STATUS.UNAUTHORIZED) {
        store.dispatch(
          ErrorAction.setErrorCode(BE_CODE_STATUS.UNAUTHORIZED)
        )
      }else if (status === HTTP_STATUS.SERVICE_UNAVAILABLE) {
        store.dispatch(
          ErrorAction.setErrorCode(BE_CODE_STATUS.UNDER_MAINTENANCE)
        )
      }else{
        
        // const url = error.response.config.url
        if(url.includes(RESUME_APPLICATION)){ //ignore /check API and always keep loading until timeout
          store.dispatch(
            ErrorAction.setErrorCode(null)
          )
        }else if(url === OBS_UPLOAD) {
          // ignore obs error
          store.dispatch(
            ErrorAction.setErrorCode(null)
          )
        }else{
          store.dispatch(
            ErrorAction.setErrorCode(BE_CODE_STATUS.SYSTEM_ERROR)
          )
        }
      }
    }else{
      store.dispatch(
        ErrorAction.setErrorCode(BE_CODE_STATUS.FAILED)
      )
    }
    return error
  }
);

export default {
  get: <T>(url: string, data?: object) =>
    axiosInstance.get<T>(url, { params: data }),
  post: <T>(url: string, data?: object, options?: AxiosRequestConfig) =>
    axiosInstance.post<T>(url, data, options),
  patch: <T>(url: string, data?: object, options?: AxiosRequestConfig) =>
    axiosInstance.patch<T>(url, data, options),
  setHeader: (options: object) => {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...options,
    };
  }
};
