import { useState, useEffect } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  CHLButton,
  CHLOverlayLoading,
  CHLTextField,
} from "../../../components";
import { RESUME_TYPES, BE_CODE_STATUS, CODE } from "../../../utilities/constants";
import { DocUploadRequest } from "../../../services/requests/DocUploadRequest";
import { MyInfoActions } from "../../../stores/reducers/myinfo";
import { ERROR_MESSAGE } from "../../../utilities/error";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type iLandingForm = {
  applyNo: string;
};

const schema = yup
  .object()
  .shape({
    [CODE.APPLY_NO]: yup.string().required(ERROR_MESSAGE[CODE.APPLY_NO]),
  })
  .required();

function UploadCheck() {
  const match = useRouteMatch<{ id: string }>("/upload/check/:id?");
  const classes = useStyles();
  const dispatch = useDispatch();
  const applyNo = match ? match.params.id : null;
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues = {
    [CODE.APPLY_NO]: match ? match.params.id : null,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<iLandingForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(MyInfoActions.setData({}));
  }, []);

  const setRedirectUrl = (applyNo: string) => {
    const state = btoa("applyNo=" + applyNo);
    const redirectUrl =
      window._env.REACT_APP_MYINFO_API_AUTHORISE +
      "?client_id=" +
      window._env.REACT_APP_MYINFO_APP_CLIENT_ID +
      "&attributes=" +
      window._env.REACT_APP_MYINFO_APP_ATTRIBUTES +
      "&purpose=" +
      window._env.REACT_APP_MYINFO_APP_PURPOSE +
      "&state=" +
      state +
      "&redirect_uri=" +
      window._env.REACT_APP_MYINFO_APP_REDIRECT_URI;
    return redirectUrl;
  };

  const onSubmit = async (app: any) => {
    setLoading(true);
    let results = await DocUploadRequest.validateApplyNo(app.applyNo);
    if (results?.code === BE_CODE_STATUS.SUCCESS) {
      window.sessionStorage.setItem("RESUME_TYPE", RESUME_TYPES.DOC_UPLOAD);
      window.location.href = setRedirectUrl(app.applyNo);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={6}>
        <CHLOverlayLoading />
      </Box>
    );
  }

  return (
    <Container>
      <Box mt={8} style={{ position: "relative" }}>
        <Box mb={3}>
          <Typography component="div">
            <Box fontWeight="bold" fontSize="1.375rem">
              Please fill the infomation{" "}
            </Box>
          </Typography>
        </Box>
        <form
          className={classes.justifyContent}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            key={CODE.APPLY_NO}
            name="applyNo"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CHLTextField
                label="Application reference number"
                required={true}
                value={value ?? ""}
                helperText={errors.applyNo?.message}
                onChange={onChange}
              />
            )}
          />
          <CHLButton title="Submit" type="submit" color="secondary" />
        </form>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles(() => ({
  justifyContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "75vh",
  },
}));

export default UploadCheck;
