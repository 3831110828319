import axiosInstance, { baseURL } from "../axios";
import { 
  AUTHENTICATION,
  LOAN_CALCULATOR, 
  VALIDATE_UTOKEN,
  VALIDATE_UTOKEN_V2,
} from "../api";
import { 
  ReqPartnerVerifyData,
  ReqFetchLoanMatrix, 
  ReqValidateUToken,
  ReqValidateUTokenV2,
} from "../../types/request";
import { CODE } from '../../utilities/constants';
// import { mockAuth, mockCalculator } from "../../mock"

export class PartnerPageRequest {
  static validateUToken(data: ReqValidateUToken) {
    const promise = axiosInstance.post(`${VALIDATE_UTOKEN}`, data);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static validateUTokenV2(data: ReqValidateUTokenV2) {
    axiosInstance.setHeader({ sourceId: CODE.MCC });
    const promise = axiosInstance.post(`${VALIDATE_UTOKEN_V2}`, data, { baseURL: `${baseURL}/ms/v2` });
    const result:any = promise.then((response) => response.data)
    return result
  }

  static verifyPartnerId(data: ReqPartnerVerifyData) {
    const {isMcc, ...restData} = data;
    if(isMcc){
      axiosInstance.setHeader({ sourceId: CODE.MCC });
    }
    const promise = axiosInstance.post(`${AUTHENTICATION}`, restData);
    const result:any = promise.then((response) => response.data)
    return result
  }

  static fetchLoanMatrix(data?: ReqFetchLoanMatrix) {
    const promise = axiosInstance.get(`${LOAN_CALCULATOR}`, data);
    const result:any = promise.then((response) => response.data)
    return result

  }
}
