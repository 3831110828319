import { Fragment, useEffect, useState} from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { v4 as uuid } from "uuid"
import PartnerForm from "./subcomponents/PartnerForm"
import RepaymentForm from "./subcomponents/RepaymentForm"
import { ReqPartnerVerifyData, ReqValidateUToken } from "../../../types/request";
import { RespAppField, RespVerification} from "../../../types/response";
import { PartnerPageRequest } from "../../../services/requests/PartnerPageRequest";
import { CODE, BE_CODE_STATUS, QUICK_LINK, EWALLET_NOTE } from "../../../utilities/constants";
import { formatRepaymentValue, setMyInfoRedirectURL } from "../../../utilities/helper";
import {
    CHLOverlayLoading, 
    CHLButton, 
    CHLDialog,
    CHLHeaderBar
} from "../../../components"
import { RootState } from "../../../stores/rootReducer";
import { 
    AuthActions,
    PartnerActions, 
    PartnerRepaymentActions,
    ErrorAction, 
    MyInfoActions,
    ApplicationActions
} from "../../../stores/reducers";

import { catPartner } from "./../../../utilities/logConfig"


function PartnerPage() {
    const match = useRouteMatch<{ id: string }>("/partner/:id");
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        authenticatedData, 
        productData, 
        repaymentForm,
        partnerForm,
        placeholderData,
    } = useSelector((s: RootState) => s.partner);

    const token = window.sessionStorage.getItem(CODE.TOKEN)

    const [open, setOpen] = useState<boolean>(false);
    const [loanMatrixLoading, setLoanRepaymentLoading] = useState<boolean>(false);
    const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isManualFlow, setIsManualFlow] = useState<boolean>(false);
    const [helperTextObj, setHelperTextObj] = useState<any>()
    const [loanCalculatorForm, setLoanCalculatorForm] = useState<RespAppField[]>()
    const [respRepayment, setRepayment] = useState<any>();
    const [loanCalculatorFormData, setLoanCalcutorFormData] = useState<any>({});

    const queryParams = new URLSearchParams(location.search); 
    const callbackUrl = queryParams.get(CODE.CALLBACK_URL)
    const utoken = queryParams.get(CODE.UTOKEN)
    const productCode = queryParams.get(CODE.APPLIC_PRODUCT)
    const partnerType = queryParams.get(CODE.ENTITY_TYPE)
    const applicAmount = queryParams.get(CODE.APPLIC_AMOUNT)
    const orderid = queryParams.get(CODE.ORDERID)
    const customer = queryParams.get(CODE.CUSTOMER)
    const isRoadShow = queryParams.get(CODE.ROAD_SHOW)
    const agentCode = queryParams.get(CODE.AGENT_CODE)
    let progcode = queryParams.get(CODE.PROG_CODE)
    const partyCode = match?.params?.id;
    if(partyCode && !progcode){
        if(partyCode === "renoploan"){
            progcode = "CAMPAIGN004";
        }else{
            progcode = "CAMPAIGN001";
        }
    }
    sessionStorage.setItem(CODE.AGENT_CODE, agentCode != null ? agentCode: "")
    sessionStorage.setItem(CODE.ROAD_SHOW, isRoadShow != null ? isRoadShow : "false")

    useEffect(() => {
        catPartner.info("Start Partner")

        if(match){
            const partyCode = match.params.id

            if(partyCode && productCode && partnerType && applicAmount && callbackUrl) {
                let iAmount = parseFloat(applicAmount)

                const requestUToken: ReqValidateUToken = {
                    productCode, applicAmount, callbackUrl, utoken
                }

                const getSavedForms = async(reqUtoken: ReqValidateUToken) =>{
                    setIsLoading(true)
                    if(await validateUToken(reqUtoken)){
                        const product = authenticatedData.product

                        if(product){
                            const lcTemplate = authenticatedData.payload.loanCalculator.applicationTemplateTabFields
        
                            if(applicAmount){
                                const iAmount = parseFloat(applicAmount)
                                await fetchCalculator(
                                    iAmount, 
                                    product.minAmt, 
                                    product.maxAmt, 
                                    product.tenors, 
                                    lcTemplate
                                )
                            }else{
                                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
                            }
                        }else{
                            fetchForms(partyCode, productCode, partnerType, iAmount, requestUToken)
                        }
                    }
                    setIsLoading(false)
                }


                if(token && token != "undefined"){
                    if(verifyAuthCode(partyCode, productCode, partnerType, orderid, customer)){
                        const ssTabID = sessionStorage.getItem(CODE.TAB_ID);
        
                        if(ssTabID){
                            getSavedForms(requestUToken)
                        }else{
                            sessionStorage.setItem(CODE.TAB_ID, uuid()); //generate tab ID and save to session storage
                            fetchForms(partyCode, productCode, partnerType, iAmount, requestUToken)
                        }
                    }else{
                        sessionStorage.setItem(CODE.TAB_ID, uuid());
                        fetchForms(partyCode, productCode, partnerType, iAmount, requestUToken)                  
                    }
                }else{
                    sessionStorage.setItem(CODE.TAB_ID, uuid());
                    fetchForms(partyCode, productCode, partnerType, iAmount, requestUToken)                  
                }

                dispatch(PartnerActions.setCallbackURL(callbackUrl))
            }else{
                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
            }
        }else{
            dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
        }
    
        dispatch(MyInfoActions.setData({})) //clear
        dispatch(MyInfoActions.setMyInfoError(false)) //clear
        dispatch(ApplicationActions.setFaceVScore(undefined)) //clear Face Verification
        dispatch(ApplicationActions.setFaceVFlag(undefined)) //clear Face Verification
        dispatch(PartnerActions.setProgcode(progcode))
        dispatch(PartnerActions.setCrdeValue(undefined)) //clear 
    }, []);

    const validateUToken = async(requestUToken: ReqValidateUToken) =>{
        let validate = false
        
        const result = await PartnerPageRequest.validateUToken(requestUToken)
        if(result){
            validate = result.code === BE_CODE_STATUS.SUCCESS 
        }
        return validate
    }
    

    const verifyAuthCode = (
        partyCodes: string,
        productCode: string,
        partnerType: string, 
        orderID?: string | null,
        receivingAcc?: string | null,)  =>{

        let validate = true

        const ssPartyCode = sessionStorage.getItem(CODE.PARTY_CODE)
        const ssProductCode = sessionStorage.getItem(CODE.PRODUCT_CODE)
        const ssType = sessionStorage.getItem(CODE.ENTITY_TYPE)
        
        
        if(partnerType == CODE.MERCHANT){
            const ssOrderID = sessionStorage.getItem(CODE.ORDERID)
            if( orderID! == ssOrderID) validate = false
        }

        if(partnerType == CODE.EWALLET){
            const ssCustomer = sessionStorage.getItem(CODE.CUSTOMER)
            if( receivingAcc! == ssCustomer) validate = false
        }

        if(ssPartyCode && ssProductCode && ssType){
            if( ssPartyCode!== partyCodes) validate = false
            else if( ssProductCode !== productCode) validate = false
            else if( ssType !== partnerType) validate = false
        }
        
        return validate    
    }

    const fetchForms = async(
        partyCode: string,
        productCode: string,
        type: string,
        iAmount: number,
        requestUToken: ReqValidateUToken
        ) =>{
        const authReqPayload: ReqPartnerVerifyData = {
            partyCode, 
            productCode,
            type
        }
        setIsLoading(true)

        if(await validateUToken(requestUToken)){
            if(type === CODE.MERCHANT && !orderid){
                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
            }else if(type === CODE.EWALLET && !customer){
                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
            }else{
                    const result = await PartnerPageRequest.verifyPartnerId(authReqPayload)
                    if(result){
                        const authData: RespVerification = result.data
                        if(result.code === BE_CODE_STATUS.SUCCESS){
                            dispatch(PartnerActions.setPartnerVerifyData(authReqPayload))
    
                            //set partner data to session storage
                            sessionStorage.setItem(CODE.PARTY_CODE, partyCode)
                            sessionStorage.setItem(CODE.PRODUCT_CODE, productCode)
                            sessionStorage.setItem(CODE.ENTITY_TYPE, type)
    
                            if(type == CODE.MERCHANT){
                                if(orderid != null) sessionStorage.setItem(CODE.ORDERID, orderid)
                            }

                            if(type == CODE.EWALLET){
                                if(customer != null) sessionStorage.setItem(CODE.CUSTOMER, customer)
                            }
    
                            dispatch(PartnerActions.setAuthenticatedData(result))
    
                            const {minAmt, maxAmt, tenors, terms} = authData.product
                            const lcTemplateFields = authData.payload.loanCalculator.applicationTemplateTabFields
    
                            await fetchCalculator(iAmount, minAmt, maxAmt, tenors, lcTemplateFields)
                        }
                    }
            }
        }
        setIsLoading(false)
    }

    const fetchCalculator = async(
        iAmount: number,
        minAmt: number,
        maxAmt: number,
        tenors: number[],
        lcTemplateFields: RespAppField[]
    ) =>{
        if(!loanCalculatorForm){
            setLoanCalculatorForm(lcTemplateFields)
        }

        if(iAmount > 0){
            if(iAmount >= minAmt && iAmount <= maxAmt){
                if(partnerType == CODE.CORPORATE){
                    iAmount =  Math.floor(iAmount / 1000) * 1000;
                }
                if(partnerType == CODE.EWALLET){
                    // eWalle: incremental values in hundreds
                    iAmount =  Math.floor(iAmount / 100) * 100;
                }
                const lcformDefaultValues: any = {
                    [CODE.APPLIC_AMOUNT]: iAmount, 
                    [CODE.APPLIC_TENURE]: tenors[0].toString()
                };
                if(partnerType === CODE.MERCHANT){
                    lcformDefaultValues[CODE.ORDERID] = orderid;
                    lcformDefaultValues[CODE.APPLIC_AMOUNT] = applicAmount;
                }
                const lcData = setLoanCalculatorDefaultValues(
                lcformDefaultValues,
                lcTemplateFields)
                await calculateRepayment(lcData)
            }else{
                dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
               // setErrorPage(ERROR_PAGE.OFFER_EXPIRED)
            }
        }else{
            dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.OFFER_EXPIRED))
           // setErrorPage(ERROR_PAGE.OFFER_EXPIRED)
        }
    }

    const setLoanCalculatorDefaultValues = (d: any, template: RespAppField[]) =>{
        const form = template.map(({ field: { code, value } }: any) => {
            if(code === CODE.ORDERID){
                return {[code]: orderid}
            }else if(code === CODE.CUSTOMER){
                return {[code]: customer}
            }else{
                return  {[code]: d[code] ?? null}
            }
        })
        const formValues = Object.assign({}, ...form);
        return formValues
    }


    const handleSetOverlayLoader = () =>{
        setLoanRepaymentLoading(true)
    }
    
    const calculateRepayment = async (data: any) => {

        
        const { mincome = null } = data;
        const amount = data[CODE.APPLIC_AMOUNT]
        data[CODE.APPLIC_TENURE] = data[CODE.APPLIC_TENURE].toString()
        const tenure = data[CODE.APPLIC_TENURE]
        let income: number = 0; 
        if(mincome){
            // find before ploan My Monthly Income field support digital separator , & allow to cents
            if(mincome.includes(",")){     
                income = parseFloat(mincome.replace(/,/g, "")) * 12;
            }else{
                income = parseFloat(mincome) * 12;
            }
        }

        setLoanCalcutorFormData(data)
        // try{
            if(amount > 0){
                let result = await PartnerPageRequest.fetchLoanMatrix({
                    amount, 
                    tenure, 
                    income: income.toString(),
                    progcode
                });
                if(result){
                    if(result.code === BE_CODE_STATUS.SUCCESS){
                        setRepayment(result.data)
                    }else if(result.code === BE_CODE_STATUS.UNAUTHORIZED){
                        if(match && match.params.id && productCode && partnerType && callbackUrl && utoken ){

                            const requestUToken: ReqValidateUToken = {
                                productCode, applicAmount: amount.toString(), callbackUrl, utoken
                            }
        
                            dispatch(AuthActions.setToken(undefined))
                            fetchForms(match.params.id,productCode,partnerType, parseFloat(amount), requestUToken)
                        
                        }
                    }
                }else{
                    dispatch(AuthActions.setToken(undefined))
                    dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.UNAUTHORIZED))
                }
            }
            
        
            
        setLoanRepaymentLoading(false)
    };


    const setLoanRepaymentData = async (d: any, template: RespAppField[]) =>{
        let data: { [key: string]: any } = d
        
        const lcTemplate = partnerForm.applicationTemplateTabFields

        let loanInfo = lcTemplate.map((f)=>{
            const value = formatRepaymentValue(
                f.field.fieldType, 
                f.field.dataType, 
                loanCalculatorFormData[f.field.code], 
                productData.tenorUnit, 
                productData.currency
                ) ?? loanCalculatorFormData[f.field.code]
            return{
                label: f.name,
                code: f.field.code,
                value: value,
                answer: loanCalculatorFormData[f.field.code]
            }
        })

        let repayInfo = template.map((f)=>{
            const value = formatRepaymentValue(
                f.field.fieldType, 
                f.field.dataType, 
                data[f.field.code], 
                productData.tenorUnit, 
                productData.currency
                ) ?? data[f.field.code]
            return{
                label: f.name,
                code: f.field.code,
                value: value,
                answer: data[f.field.code]
            }
        })
        loanInfo.push(...repayInfo)


        const objRepayment = {
            repayment: loanInfo,
            entityType: partnerType,
            productCode,
            callbackUrl: callbackUrl,
            placeholder: placeholderData,
        }
    
        dispatch(PartnerRepaymentActions.setRepaymentData(objRepayment))
    }

    //if singpass way use this onclick redirect to singpass,
    //if manual way: useHistory push to callbackHompage, with the state(loanAmount,loanTenure info)
    const setSingpassRedirectURL = () =>{
        const state = token ?? ''
        const authoriseUrl = setMyInfoRedirectURL(state);
        return authoriseUrl;
    }


    const finalCallForSyncRepaymentResult = async () => {
        const { mincome, applicAmount, applicLoanTerm } = loanCalculatorFormData;
        const income: number = mincome ? mincome * 12 : 0;
        if(applicAmount && applicLoanTerm){
            const params = {
                amount: applicAmount,
                tenure: applicLoanTerm.toString(),
                income: income.toString(),
                progcode,
            }
            const result = await PartnerPageRequest.fetchLoanMatrix(params);
            if(result && result.code === BE_CODE_STATUS.SUCCESS){
                const repaymentData = result.data;
                const repayment = getFormatRepayment(loanCalculatorFormData, repaymentData);
                dispatch(PartnerRepaymentActions.setRepaymentData({
                    repayment,
                    entityType: partnerType,
                    productCode,
                    callbackUrl: callbackUrl,
                    placeholder: placeholderData,
                }))
            }
        }
    }

    const getFormatRepayment = (loanFormData: any, repaymentData:any) => {
        const loanTemplate = partnerForm.applicationTemplateTabFields;
        const repaymentTemplate = repaymentForm.applicationTemplateTabFields;

        const allTemplate = [...loanTemplate, ...repaymentTemplate];
        const allData = {...loanFormData, ...repaymentData};

        const ret = allTemplate.map(({field, name})=>{
            const {code, fieldType, dataType} = field;
            const value = formatRepaymentValue(
                fieldType, 
                dataType, 
                allData[code], 
                productData.tenorUnit, 
                productData.currency
                ) ?? allData[code]
            return{
                label: name,
                code,
                value,
                answer: allData[code]
            }
        })

        return ret
    }

    const onClickHandler = async (isManualFlow: boolean) => {
        window.dataLayer.push({'event': 'gtm.loancalculator'});
        setRedirectLoading(true)

        await finalCallForSyncRepaymentResult();

        const crdeValue = respRepayment?.crdeValue;
        dispatch(PartnerActions.setCrdeValue(crdeValue));
        dispatch(PartnerActions.setIsManualFlow(isManualFlow))

        let redirectUrl = ""
        if(!isManualFlow){
            redirectUrl = setSingpassRedirectURL()
            dispatch(PartnerRepaymentActions.setPartnerRedirectURL(redirectUrl))
        }else{
            redirectUrl = window._env.REACT_APP_MYINFO_APP_REDIRECT_URI
        }

        window.location.href = redirectUrl;
    };

    const applyOnClick = (isManualFlow: boolean) =>{
        const terms = authenticatedData.product.terms
        setIsManualFlow(isManualFlow)

        if(loanCalculatorForm){
            let count = 0
            loanCalculatorForm.map((f) =>{
                const {code, regex, validationMessage} = f.field
                if(!regex) return
                if(!validationMessage) return

                if(loanCalculatorFormData.hasOwnProperty([code])){
                    count++
                    const value = loanCalculatorFormData[code]

                    if(value === null){
                        // setValidationMessage(validationMessage)
                        // setFormValidateOpen(true)
                        setHelperTextObj({[code]: validationMessage})
                    }else{
                        const r = new RegExp(regex);

                        if(r.test(value)){
                            terms ? setOpen(true) : onClickHandler(isManualFlow)
                            setOpen(true)
                            // onClickHandler()
                        }else{
                            // setValidationMessage(validationMessage)
                            // setFormValidateOpen(true)
                            setHelperTextObj({[code]: validationMessage})
                        }
                    }
                    
                    
                }
            })
            if(count == 0){
                terms ? setOpen(true) : onClickHandler(isManualFlow)
                setOpen(true)
                // onClickHandler()
            }
        }
    }

    if(isLoading || redirectLoading){
        return (
        <Box display="flex" justifyContent="center" mt={6}>
            <CHLOverlayLoading/>
        </Box>
        );
    }else{
        return(
            (partnerType && Object.keys(loanCalculatorFormData).length > 0 && Object.keys(authenticatedData).length > 0)  ? (
                <Box position="relative" margin="auto" maxWidth={800}>
                    <PartnerForm
                        type={partnerType}
                        loanCalculatorFormData={loanCalculatorFormData}
                        // orderID={loanCalculatorFormData[CODE.ORDER]}
                        // amount={loanCalculatorFormData[CODE.APPLIC_AMOUNT]}
                        currency={productData.currency}
                        minAmt={productData.minAmt}
                        maxAmt={productData.maxAmt}
                        tenors={productData.tenors}
                        onChangeCommitted={calculateRepayment}
                        setOverlayLoader={handleSetOverlayLoader} 
                        helperTextObj={helperTextObj}
                    ></PartnerForm>
                    <Box mt={3} mb={5} position="relative">
                        {loanMatrixLoading && <CHLOverlayLoading/>} 
                        {respRepayment && 
                        <RepaymentForm
                            loanMatrix={respRepayment}
                            currency={productData.currency}
                            tenorUnit={productData.tenorUnit}
                        />}
                    </Box>
                    <Box my={2} />
                    <Typography variant="h4" className={classes.h4}>Quick Links</Typography>
                    {QUICK_LINK.map((l, i) => (
                        <Fragment key={i}>
                        <CHLHeaderBar title={l.title} hasLink={true} redirectLink={l.url} />
                        <Box my={2} />
                        </Fragment>
                    ))}
                    {partnerType == CODE.EWALLET && 
                        <Box my={2}>
                            <Typography className={classes.note}>
                                <b>Note:</b>
                                <Box>{EWALLET_NOTE}</Box>
                            </Typography>
                        </Box>
                    }
                    <Box mt={5} display="flex" justifyContent="center">
                        {/* <BtnRetrieveMyInfo
                            style={{cursor: "pointer"}}
                            onClick={() => applyOnClick(false)}
                        /> */}
                        <CHLButton
                            title="Retrieve Myinfo with Singpass"
                            fullWidth
                            color="primary"
                            onClick={() => applyOnClick(false)}
                            disabled={loanMatrixLoading}
                        />
                    </Box>
                    {/* <Box my={2}>
                        <Typography variant="caption">
                            <b>No Singpass account?</b> Don't worry, apply via online application form 
                            <a style={{cursor: "pointer"}} onClick={()=>applyOnClick(true)}> here</a>
                        </Typography>
                    </Box> */}
                    {/* <CHLDialog
                        open={formValidateOpen}
                        title=""
                        onClose={() => setFormValidateOpen(false)}
                        >
                        <Typography>{helperMessage}</Typography>
                    </CHLDialog> */}
                    <CHLDialog
                        open={open}
                        title="Terms & Conditions"
                        onClose={() => setOpen(false)}
                        actions={[
                        {
                            title: "Cancel",
                            color: "default",
                        },
                        {
                            title: "Agree",
                            color: "secondary",
                            onClick: () => onClickHandler(isManualFlow)
                        },
                        ]}>
                        <Typography className={classes.note}>{authenticatedData.product.terms}</Typography>
                    </CHLDialog>
                </Box>
            ): <div></div>
        )
    }
   
}

const useStyles = makeStyles((theme) => ({
    note: {
        fontSize: "16px",
        lineHeight: "22px",
    },
    h4:{
        color: theme.palette.secondary.main,
        fontSize: "22px",
        lineHeight: "28px",
        fontWeight: 700,
        marginBottom: "10px",
    }
}));

export default PartnerPage;