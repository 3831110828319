import { createContext, FC, Fragment, memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Typography,
  Box,
  Collapse,
  Grid,
  Hidden,
  Theme,
  useMediaQuery,
  makeStyles
} from "@material-ui/core";
import {
  dataURIToBlob,
  createYupSchema,
} from "../../../../utilities/helper";
import { selectParentsByIndex } from "../../../../stores/reducers/application";
import { 
  CAPTION, 
  CODE, 
  DEFAULT_VALUE, 
  FORM_TYPE, 
  PDPA_CONSENT_CONTENT
} from "../../../../utilities/constants";
import { CODE_LABEL } from "../../../../utilities/label";
import CollapseHeader from "./CollapseHeader";
import { RootState } from "../../../../stores/rootReducer";
import { RespField, RespAppField, RespPlaceholder } from "../../../../types/response";
import FormDataEntry from "./FormDataEntry";
import FormTable from "./FormTable";
import ClickableValueField  from "./ClickableValueField"
import PdpaConsentField from "./PdpaConsentField"
import ApplicationButton from "./ApplicationButton";
import { CHLButton } from "../../../../components";
import _ from "lodash"

interface IApplicationForm {
  activeIndex: number;
  tableTypeData?: any[];
  // values: { code: string; value: any }[];
  values: {[key: string]: any};
  onSubmit: () => void;
  onBack?: () => void;
}

interface IApplicationFormContext {
  register: any;
  control: any;
  defaultValues: any;
  // fields: RespField[];
  fields: RespAppField[];
  activeIndex: number;
  setValue?: any;
  getValues?: any;
  setFocus?: any;
  nric?: string;
  placeholders?: RespPlaceholder;
}

export const ApplicationFormContext = createContext<IApplicationFormContext | null>(
  null
);
const ApplicationForm: FC<IApplicationForm> = (props) => {
  const classes = useStyles();
  const { activeIndex, tableTypeData, values, onSubmit, onBack } = props;
  const { data: myinfo } = useSelector((s: RootState) => s.myinfo);
  const myInfoData:{[key:string]: any} = myinfo;
  // Myinfo Mode: my profile from singpass step1 is cancel,so step2 paynow->step1 paynow, values has no code.nric
  const nric = values[CODE.NRIC] && values[CODE.NRIC][0][0] || myInfoData[CODE.NRIC];

  const {
    parents: { length },
  } = useSelector((s: RootState) => s.application);
  const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const { isManualFlow, isAmericanFlag, uploadRelated, parents, documentUpload, names, isLendela } = useSelector((s: RootState) => s.application);

  const { placeholderData: placeholders  } = useSelector((s: RootState) => s.partner);

  const currentParent = useSelector(selectParentsByIndex(activeIndex));
  const [expands, setExpands] = useState<boolean[]>(()=>{
    if(isLendela){
      const expands = new Array(currentParent.length).fill(true);
      return expands;
    }
    return [true];
  });

  const template = currentParent.reduce<RespAppField[]>((prev, current) => {
    let tabFields = current.applicationTemplateTabFields;

    let fields = null;
    if (current.type === FORM_TYPE.TABLE) {
      // if (previousData != null) {
      //   fields = previousData
      //   // console.log(previousData)
      //   let currentTableData = previousData.filter((o1) =>
      //     currentParent.some((o2) => o1.id === o2.id && o1.id === current.id)
      //   );

      //   if (currentTableData.length != 0) {
      //     fields = currentTableData[0].fields.reduce(
      //       (obj: any, item: any) =>
      //         Object.assign(obj, { [item.code]: item.value }),
      //       {}
      //     );
      //   } else {
      //     fields = tabFields.reduce((prevValue, newValue) => {
      //       return { ...prevValue, [newValue.field.code]: null };
      //     }, {});
      //   }
      // } else {
        fields = tabFields.reduce((prevValue, newValue) => {
          const fieldType = newValue.field.fieldType 
          const hasRender = newValue.field.render
          let defValue = null
          
          if(fieldType == "RadioButton"){
            if(!hasRender){
              defValue = newValue.field.options[0].code
            }
          }
          return { ...prevValue, [newValue.field.code]: defValue };
        }, {});
      // }

      tabFields = [
        {
          field: { code: `${current.id}`, name: `${current.originalName}`, value: [fields], details: tabFields },
        },
      ] as any;
    }
    return prev.concat(tabFields);
  }, []);


  // Assigning default value by default to null or selected/input value
  let form = null;



  // console.log(template)

  // if(values && Object.keys(values).length > 0){
   
  //   form = template.map((t) => {
  //     let { code, value, details } = t.field;
  //     console.log(t)
  //     if(!details){ //dataEntryType
  //       value = values[code] && values[code][0][0]
  //     }
      
  //     return  {[code]: value ?? null}
  //   })
  // }else{
  //   form = template.map(({ field: { code, value } }: any) => {
  //     return  {[code]: value ?? null}
  //   })
  // }

  let myInfo: { [key: string]: any } = {}
  myInfo = Object.assign({}, myinfo)

  let tableForm = template.map((t) => {
    let { code, value, details, fieldType, options, render } = t.field;
    if(details){ //istabletype
      if(tableTypeData){
        value = tableTypeData
      }
    }else{//isdataentryc

      if(fieldType == "RadioButton"){
        if(!render){ 
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
            if(!value){
              value = options[0].code
            }
          }else{
            value = options[0].code
          } 
        }else{
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
          }
        }
      }else{
        if(fieldType === "ImageFile"){
          if(values && Object.keys(values).length > 0){
            const src = values[code] && values[code][0][0]

            if(src){
              const blob = dataURIToBlob(src);
              value = new File([blob], documentUpload[code], { type: blob.type });
            }
          }
        }else{
          if(values && Object.keys(values).length > 0){
            value = values[code] && values[code][0][0]
    
            if(!value){
              value = myInfo[code]
            }
          }else{
              value = myInfo[code]
          }
        }
      }
    }
    return  {[code]: value ?? null}
  })

  const formValues = Object.assign({}, ...tableForm);

  let defaultValues = formValues
  if(isAmericanFlag){
    defaultValues = {...formValues, [CODE.IS_FATCA_RESIDENCE]: DEFAULT_VALUE.FATCA_RESIDENCE_YES}
  }

  const { 
    register, 
    control, 
    handleSubmit, 
    setValue, 
    getValues, 
    setFocus,
    formState
  } = useForm({
    mode: "onChange",
    defaultValues,
    shouldFocusError: true,
    resolver: createYupSchema(template, isManualFlow, uploadRelated, myInfo),
  });



  let { errors } = formState

  let objErr:{ [key: string] : any} = {}

  if(errors.hasOwnProperty(CODE.FATCA_REASON)){ //force validation on FATCA Reason Field
    Object.keys(errors).map(k =>{ 
      if(k === CODE.FATCA_REASON){
        objErr[k] = errors[k]
      }
    })
  }else{
    objErr = errors
  }


  useEffect(() => {
    const firstError = Object.keys(objErr).reduce((field: any, a) => {
      return !!objErr[field] ? field : a;
    }, null);
    
    const fe = currentParent.filter(k =>{
      if(k.id === firstError){ //TableType
        return k
      }else{
        return k.applicationTemplateTabFields.some(e => e.field.code === firstError);
      }
    })

    const collapseIndex = currentParent.findIndex(arr =>JSON.stringify(arr)  === JSON.stringify(fe[0]))

    if(collapseIndex > -1){
      expands[collapseIndex] = true
      setExpands([...expands])
    }
  }, [errors, setFocus]);


  // Template
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {currentParent.map((parent, index) => {
        const { applicationTemplateTabFields, originalName } = parent;
        const caption = CAPTION.find((c) => c.NAME === originalName);
        const isCaptionExisted = caption?.TITLE;

        return (
          <Fragment key={parent.id}>
            <CollapseHeader
              name={originalName}
              expand={expands[index]}
              onClick={() => {
                expands[index] = !expands[index];
                setExpands([...expands]);
              }}
            />

            <Collapse in={isCaptionExisted ? expands[index] : true}>
              <Box mt={2} />
              {caption?.SUBTITLE && <p className={classes.darkText}>{caption?.SUBTITLE}</p>}
              {originalName == CODE.PDPA_CONSENT && <PdpaConsentField/>}
              {
                (isLendela && originalName == "Application Summary") &&
                (<Box borderTop={1} my={4} borderColor="#BDBDBD" width="100%">
                    <Box my={4} fontWeight={900} fontSize="18px">Acknowledgement</Box>
                </Box>)
              }
              {
                (isLendela && originalName == "Sign Your Application") &&
                (<Box my={4}></Box>)
              }

              <Grid container spacing={smAndUp ? 4 : 2}>
                <ApplicationFormContext.Provider
                  value={{
                    register,
                    control,
                    defaultValues,
                    fields: applicationTemplateTabFields,
                    activeIndex,
                    setValue,
                    getValues,
                    nric,
                    placeholders, 
                  }}>
                  {parent.type === FORM_TYPE.TABLE ? (
                    originalName === CODE.CPF_HISTORY || originalName === CODE.NOA  ? 
                    <Box p={1} style={{width: "100%"}}>
                      <ClickableValueField 
                        code={originalName}
                        label={CODE_LABEL[originalName]}
                      /> 
                    </Box>
                    :
                    <FormTable id={`${parent.id}`} />
                  ) : (
                    <FormDataEntry />
                  )}
                </ApplicationFormContext.Provider>
              </Grid>
            </Collapse>
          </Fragment>
        );
      })}
      {
        isLendela ? (
          <Grid container>
            <Grid item xs={12} sm={4}>
            </Grid> 
            <Grid item xs={12} sm={4}>
              <Box mt={2}>
                <ApplicationButton
                  activeIndex={activeIndex}
                  length={length}
                  control={control}
                  title="Next"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
            </Grid>
          </Grid>
        ) : (
          <Grid container item>
            {activeIndex != 0 && activeIndex != parents.length - 1 && (
              <Box clone order={{ xs: 3, sm: 1 }}>
                <Grid item xs={12} sm={3}>
                  <Box my={2}>
                    <CHLButton
                      type="button"
                      title="Back"
                      color="default"
                      fullWidth
                      onClick={onBack}
                    />
                  </Box>
                </Grid>
              </Box>
            )}
            <Hidden xsDown>
              <Box clone order={{ xs: 2, sm: 2 }}>
                <Grid
                  item
                  sm={activeIndex == 0 || activeIndex == parents.length - 1 ? 9 : 6}
                />
              </Box>
            </Hidden>
            <Box clone order={{ xs: 1, sm: 3 }}>
              <Grid item xs={12} sm={3}>
                <Box mt={2}>
                  <ApplicationButton
                    activeIndex={activeIndex}
                    length={length}
                    control={control}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        )
      }
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  darkText:{
    color: theme.palette.secondary.main,
    fontSize: "16px"
  },
}))

export default memo(ApplicationForm);
