import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPreChecked: false,
};

const preCheckSlice = createSlice({
  name: "precheck",
  initialState,
  reducers: {
    setIsPreCheck(state, action) {
      const { payload } = action;
      state.isPreChecked = payload;
    },
  },
});

const { actions, reducer } = preCheckSlice;

export const PreCheckAction = actions;
export default reducer;
