import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlertState, IState } from "../../types/state";

const initialState: IState<IAlertState | null> = {
  data: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<string | IAlertState>) => {
      const { payload } = action;
      if (typeof payload === "string") {
        state.data = {
          type: "success",
          message: payload,
        };
      } else state.data = payload;
    },
    removeAlert: (state) => {
      state.data = null;
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = alertSlice;
export const AlertActions = actions;
export default reducer;
