import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

const key = "RL+yllyzWGZtDrhWCEV0lg==" //key returned from CB

const lendelaKey = "1dee88d240fdc399";
const lendelaIV = "a5957c43b45bb40b";

export function lendelaEncrypt(data: object){
  const dataStr = JSON.stringify(data);
  const parsedKey = CryptoJS.enc.Base64.parse(lendelaKey)
  const parsedIV = CryptoJS.enc.Utf8.parse(lendelaIV)

  const encryptedData = AES.encrypt(dataStr, parsedKey, {
    iv: parsedIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const strEncrypted = encryptedData.toString()
  return strEncrypted
}

export function lendelaDecrypt(param: string){
  const parsedKey = CryptoJS.enc.Base64.parse(lendelaKey)
  const parsedIV = CryptoJS.enc.Utf8.parse(lendelaIV)

  let decrypted = AES.decrypt(param, parsedKey, {
    iv: parsedIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const strDecrypted = decrypted.toString(CryptoJS.enc.Utf8)
  const decryptedObj = JSON.parse(strDecrypted)
  
  return decryptedObj
}



export function dataEncryption(data: object, timestamp: number){
  const requestData = JSON.stringify(data)
  const parsedKey = CryptoJS.enc.Base64.parse(key)
  const parsedIV = CryptoJS.enc.Utf8.parse(set16BitIV(timestamp))

  const encryptedData = AES.encrypt(requestData, parsedKey, {
    iv: parsedIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const strEncrypted = encryptedData.toString()
  const toBase64 = btoa(strEncrypted)

  console.log(toBase64)

  // dataDecryption(toBase64, timestamp)

  return toBase64
}

export function dataDecryption(data: string, timestamp: number){
  const parsedKey = CryptoJS.enc.Base64.parse(key)
  const parsedIV = CryptoJS.enc.Utf8.parse(set16BitIV(timestamp))

  const fromBase64 = atob(data)

  let decrypted = AES.decrypt(fromBase64, parsedKey, {
    iv: parsedIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const strDecrypted = decrypted.toString(CryptoJS.enc.Utf8)
  const decryptedObj = JSON.parse(strDecrypted)
  console.log(decryptedObj)
  return decryptedObj
}

function set16BitIV(timestamp: number){
  let strIV = timestamp.toString()
  strIV = strIV.padEnd(16, "0")

  return strIV
}
