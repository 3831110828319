import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { FC } from "react";

export type ButtonColorProps = "default" | "primary" | "secondary";

interface ICHLButton extends ButtonProps {
  title: string;
  type?: "button" | "submit" | "reset";
  color?: ButtonColorProps;
  disabled?: boolean;
  loading?: boolean;
}

const CHLButton: FC<ICHLButton> = (props) => {
  const { loading, title, type, color = "default", onClick, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes[color]}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
      }}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {loading ? <CircularProgress size={25} /> : title}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "16px",
    minHeight: 50,
  },
  disabled: {
    backgroundImage: "none !important",
  },
  primary: {
    background: theme.palette.gradient?.main,
    color: theme.palette.gradient?.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.gradient?.light,
      boxShadow: "none",
    },
  },
  default: {
    background: theme.palette.grey[100],
    color: theme.palette.secondary.main,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      background: theme.palette.grey[100],
      boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.15)",
    },
  },
  secondary: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.main,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    },
  },
}));

export default CHLButton;
