import { FC, useContext } from "react";
import {
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
  Radio,
} from "@material-ui/core";
import clsx from "clsx";
import { ReactComponent as RadioButton } from "../../assets/images/radio.svg";
import { ReactComponent as RadioCheckedButton } from "../../assets/images/radio_checked.svg";
import { RadioContext } from ".";

const CHLRadioButton: FC<FormControlLabelProps> = (props) => {
  const { children, value, ...rest } = props;
  const classes = useStyles();
  const context = useContext(RadioContext);
  const checked = context?.value === value;
  const toggle = context?.toggle;

  return (
    <FormControlLabel
      {...rest}
      value={value}
      classes={{
        root: toggle
          ? clsx(classes.toggleRoot, checked && classes.toggleRootBg)
          : undefined,
        label: toggle
          ? clsx(classes.label, checked && classes.toggleLabelBold)
          : classes.label,
      }}
      label={children}
      disabled={context?.disabled}
      control={
        <Radio
          className={toggle ? classes.toggleRadio : undefined}
          icon={<RadioButton />}
          checkedIcon={<RadioCheckedButton />}
        />
      }
    ></FormControlLabel>
  );
};

const useStyles = makeStyles((theme) => ({
  toggleRoot: {
    flex: 1,
    marginLeft: 0,
    marginRight: 0,
    justifyContent: "center",
    minHeight: 44,
  },
  toggleRootBg: {
    backgroundColor: theme.palette.primary.main,
  },
  toggleRadio: {
    display: "none",
  },
  toggleLabelBold: {
    color: "#fff !important",
    fontWeight: 700,
  },
  label: {
    padding: "0 10px",
  },
}));

export default CHLRadioButton;
