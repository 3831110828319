import clsx from "clsx";
import { FC, useState } from "react";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  ListSubheader
} from "@material-ui/core";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import CHLLabel from "../CHLLabel";

interface ICHLSelectGroup extends SelectProps {
  label?: string;
  items?: { text: string; value: number | string }[];
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  description?: string;
  optionGroups?:{
    text: string;
    value: string;
    children: {
      text: string;
      value: string;
    }[]
  }[];
  value?: string[];
}
const DEFAULT_TEXT = "Please select";
const CHLSelectGroup: FC<ICHLSelectGroup> = (props) => {
  const {
    label,
    items = [],
    required,
    bold,
    helperText = " ",
    description,
    error,
    children,
    optionGroups,
    value,
    ...rest
  } = props;
  const classes = useStyles();

  const options = () =>{
    const items = []
    if(optionGroups){
      for (let parent of optionGroups) {
        items.push(<ListSubheader key={parent.value} className={classes.groupLabel}>{parent.text}</ListSubheader>);
        for (let child of parent.children) {
          items.push(
            <MenuItem key={child.value} value={[parent.value, child.value]} className={classes.optionsLabel}>{child.text}</MenuItem>
          );
        }
      }
    }
    return items;
  }

  const getSelectedOptionText = (parentValue: string, childValue: string) =>{
    let selectedText = DEFAULT_TEXT
    if(optionGroups){
      const parent = optionGroups.filter((key) => key.value === parentValue)
      if(parent){
        const children = parent[0].children.filter((key) => key.value === childValue)
        selectedText = children && children[0].text
      }
    }
    return selectedText
  }

  // Templates
  return (
    <FormControl classes={{ root: classes.root }} error={error}>
      <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
        component="div"
      />
      <Select
        classes={{
          disabled: classes.disabled,
        }}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
        }}
        displayEmpty
        IconComponent={({ className, ...rest }) => (
          <ArrowDownIcon className={clsx(className, classes.icon)} {...rest} />
        )}
        defaultValue=""
        renderValue={(value: any) => {
          return <div>{value ? getSelectedOptionText(value[0], value[1]) : DEFAULT_TEXT}</div>;
        }}
        {...rest}
        variant="standard"
      >
        <MenuItem style={{display: "none"}} value="">{DEFAULT_TEXT}</MenuItem>
        {options()}
      </Select>
      <FormHelperText error={true}>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiOutlinedInput-root": {
      // borderRadius: 2,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      borderWidth: 1,
    },
    "& .MuiSelect-select:focus":{
      backgroundColor: "transparent",
    }
  },
  paper: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  icon: {
    top: "unset",
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled,
    color: "#8A8A8A",
  },
  groupLabel: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A203D",
    position: "relative",
    padding:"10px 15px"
  },
  optionsLabel:{
    marginLeft: "10px",
    fontSize: "15px",
    lineHeight: "22px",
  }
}));

export default CHLSelectGroup;
