import { FC, useEffect, useState, useLayoutEffect } from 'react'
import { Controller, useForm } from "react-hook-form";
import { CODE } from "../../../../utilities/constants";
import { CODE_LABEL } from "../../../../utilities/label";
import { TOOLTIPS } from "../../../../utilities/tooltips";
import { FieldType, RespField, RespAppOptionField } from "../../../../types/response";
import { Box } from "@material-ui/core";

import WatchedComponent from "../../Callback/subcomponents/WatchedComponent"
import ComponentHeader from './ComponentHeader'
import {
    CHLCheckbox,
    CHLCheckboxButton,
    CHLDatePicker,
    CHLRadio,
    CHLRadioButton,
    CHLSelect,
    CHLTextField,
    CHLUploadField,
    CHLValueField,
    CHLSlider,
  } from "../../../../components";
  import { InputAdornment, MenuItem } from "@material-ui/core";


import { assignTargetProps, createYupSchema } from "../../../../utilities/helper";

import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducer";

interface PartnerProps{
  type: string,
  loanCalculatorFormData: any,
  // orderID?: string, 
  // amount: string, 
  currency?: string,
  minAmt: number,
  maxAmt: number, 
  tenors: number[],
  onChangeCommitted?:(data: any) => void,
  setOverlayLoader?:() => void
  helperTextObj?:{[key: string]: any}
}

const PartnerForm: FC<PartnerProps> = ({
  type,
  loanCalculatorFormData,
  // orderID, 
  // amount, 
  currency,
  minAmt,
  maxAmt,
  tenors,
  onChangeCommitted=()=>{},
  setOverlayLoader=()=>{},
  helperTextObj
}) => {
  const roundDownToNearestThousand = (val: number) =>{
    val = Math.floor(val / 1000) * 1000;
    return val > maxAmt ? maxAmt : val;
  }

  const roundDownToNearestHundred = (val: number) => {
    val = Math.floor(val / 100) * 100;
    return val > maxAmt ? maxAmt : val;  
  }

  const [amountOptions, setFormAmountOptions] = useState<RespAppOptionField[]>([]);
  const [tenorOptions, setFormTenorOptions] = useState<RespAppOptionField[]>([]);
  const [isTenorSliderDisable, setIsTenorSliderDisable] = useState<boolean>(false);
  const [isAmountSliderDisable, setIsAmountSliderDisable] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<any>(helperTextObj);

  const { partnerForm, placeholderData } = useSelector((s: RootState) => s.partner);
  const template = partnerForm.applicationTemplateTabFields;
  const form = template.map(({ field: { code, value } }: any) => {
    return  {[code]: value ?? ''}
  });
  const formValues = Object.assign({}, ...form);
  const defaultValues = assignTargetProps(formValues, loanCalculatorFormData);

  const amount = loanCalculatorFormData[CODE.APPLIC_AMOUNT];
  const partnerType = type;

  useLayoutEffect(() => {
    setHelperText(helperTextObj);
  }, [helperTextObj]);

  useEffect(()=>{
    let minLoanAmount = minAmt.toString();
    let maxLoanAmount = maxAmt.toString();
    let loanTenors = tenors;
    // Ploan 
    if(partnerType == CODE.CORPORATE){
      // CIMBSG-2729# Update minimum loan amount from 1000 to 2000
      minLoanAmount = (amount <= 1000 ? 0 : 2000).toString();
      maxLoanAmount = roundDownToNearestThousand(amount).toString(); 
    }
    // BNPL
    if(partnerType == CODE.MERCHANT){
      // BNPL的amount不可修改，所以tenor直接就可以确定了
      if(amount < 500){
        loanTenors = [3, 6, 9, 12];
      }else{
        loanTenors = [3, 6, 9, 12, 18];
      }
    }
    // E-Wallet
    if(partnerType == CODE.EWALLET){
      if(amount < 500){
        loanTenors = [3, 6, 9, 12];
      }else{
        loanTenors = [3, 6, 9, 12, 18, 24];
      }
    }
    const loanAmountOptions = [
      {name: minLoanAmount, code: minLoanAmount},
      {name: maxLoanAmount, code: maxLoanAmount}
    ];
    const loanTenorsOptions = loanTenors.map(tenor=>{
      return {
        code: tenor.toString(),
        name: tenor.toString()
      }
    })
    setFormAmountOptions(loanAmountOptions);
    setFormTenorOptions(loanTenorsOptions);
  }, [])

  

  const {
      handleSubmit,
      reset,
      control,
      getValues,
      setValue,
      formState: { errors},
      } = useForm({
      defaultValues,
      resolver: createYupSchema(template),
  });

  const handleOnChangeCommited = () =>{
      setOverlayLoader()
      const delayDebounceFn = setTimeout(() => {
        onChangeCommitted(getValues())
      }, 1000)
    
      return () => clearTimeout(delayDebounceFn)
  }

  const getComponentAttributes = (obj: {
      code: string;
      type: FieldType;
      options: [] | RespAppOptionField[];
      hasValue: boolean;
      maxLength?: number;
      placeholder?: string | Object;
    }) => {
      const { code, type, options, hasValue, maxLength, placeholder } = obj;
      
      if(code === CODE.APPLIC_AMOUNT){
        if(partnerType === CODE.CORPORATE || partnerType === CODE.EWALLET){
            return {
              component: CHLSlider,
              props: { 
                onChangeCommitted: handleOnChangeCommited,
                disabled: isAmountSliderDisable,
                partnerType,
              }
            }
        }else{
            return { component: CHLValueField,
            props: {
              // BNPL - amount cannot edit & need to allow up to cents
              value: currency +  " " + parseFloat(amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
              label: CODE_LABEL[code],
            }
          };
        }
      }else if(code === CODE.APPLIC_TENURE){
        return {
          component: CHLSlider,
          props: { 
            onChangeCommitted: handleOnChangeCommited,
            disabled: isTenorSliderDisable 
          }
        }
      } else if(hasValue){
        return { component: CHLValueField };
      }

      switch (type) {
        case "InputField":
        case "Address":
        case "Email":
          return { 
            component: CHLTextField,
            props:{
              placeholder
            } 
          };
        case "TextArea":
          return {
            component: CHLTextField,
            props: {
              placeholder,
              multiline: true,
              maxRows: 6,
            },
          };
        case "Phone":
          return {
            component: CHLTextField,
            props: {
              placeholder,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">+65</InputAdornment>
                ),
              },
            },
          };
        case "Amount":
          return {
            component: CHLTextField,
            props: {
              placeholder,
              maxLength,
              InputProps: {
                startAdornment: <InputAdornment position="start">SGD</InputAdornment>,
              },
            },
          };
        case "DatePicker":
          return {
            component: CHLDatePicker,
            props: { placeholder: "yyyy-mm-dd" },
          };
        case "Dropdown":
          return { component: CHLSelect, componentOption: MenuItem };
        case "ImageFile":
          return {
            component: CHLUploadField,
            props: { placeholder: "Tap here to upload file" },
          };
        case "RadioButton":
          return {
            component: CHLRadio,
            componentOption: CHLRadioButton,
            props: { bold: false, toggle: options.length === 2 },
          };
        case "Checkbox":
          return {
            component: CHLCheckbox,
            componentOption: CHLCheckboxButton,
          };
        default:
          return { 
            component: CHLTextField,
            props:{
              placeholder
            } 
          };
      }
    };

    // now only Ploan has My Monthly Income field, and Max loan amount calculation based on monthly income for corporate flow
    const handleChangeAmountOptions = (monthlyIncome: string | number) =>{
      let options: RespAppOptionField[] = []
      const mincome = typeof monthlyIncome === "string" ? parseFloat(monthlyIncome) : monthlyIncome

      let min = 2000
      let max = 1000

      if(mincome >= 0 && mincome <= 1665){
        min = 0
        max = 1000
      }else if(mincome >= 1666 && mincome <= 2499){
        max = mincome * 2
      }else if(mincome >= 2500 && mincome <= 9999){
        max = mincome * 4
      }else{
        max = mincome * 8
      }
      const maxLoanAmt = roundDownToNearestThousand(max);
      setValue(CODE.APPLIC_AMOUNT, min)
      options = [
        {name: min.toString(), code:min.toString()},
        {name: maxLoanAmt.toString(), code:maxLoanAmt.toString()},
      ]
      setFormAmountOptions(options)
    }

    const handleChangeTenorOptions = (tenors: number[]) => {
      if(tenors.length !== tenorOptions.length){
        const newOptions = tenors.map(tenor=>{
          return {
            code: tenor.toString(),
            name: tenor.toString()
          }
        })
        if(!tenors.includes(parseFloat(getValues(CODE.APPLIC_TENURE)))){
          setValue(CODE.APPLIC_TENURE, tenors[0].toString())
        }
        setFormTenorOptions(newOptions);
      }
    }

  

    const disableSlider = () =>{
      setValue(CODE.APPLIC_TENURE, tenors[0].toString())
      setIsTenorSliderDisable(true)
      setIsAmountSliderDisable(true)
    }

    const onChangeHandler = ( code: string, onChange?: any, regex?: string, validationMessage?: string) => (e?: any) => {
      let newValue = e?.target?.value ?? e;

      const cstNewValue = newValue

      // as before, only use field has regex as condition to judge current field is My Monthly Income[mincome]..
      if(partnerType === CODE.CORPORATE && code === CODE.APPLIC_INCOME && regex){
        const r = new RegExp(regex);
        if(r.test(newValue)){

          if(newValue.includes(",")){
            newValue = newValue.replace(/,/g, "")
          }
          const monthlyIncome = parseFloat(newValue)
          handleChangeAmountOptions(monthlyIncome)
          setIsTenorSliderDisable(false)
          setIsAmountSliderDisable(false)
          setHelperText(undefined)
        }else{
          setValue(CODE.APPLIC_AMOUNT, 0)
          disableSlider()
          setHelperText({[code]: validationMessage})
        }
        handleOnChangeCommited()
        // handleMonthlyIncomeChange()
        
      }

        if(partnerType === CODE.EWALLET && code === CODE.APPLIC_AMOUNT){
          const loanAmount = parseFloat(newValue);
          let loanTenors = tenors;
          if(loanAmount < 500){
            loanTenors = [3, 6, 9, 12];
          }else{
            loanTenors = [3, 6, 9, 12, 18, 24];
          }
          handleChangeTenorOptions(loanTenors);
        }
  
        onChange && onChange(cstNewValue)  
    };

  return(
      <>
      <Box mt={8}>
        <ComponentHeader 
          title="Loan Calculator" 
          subTitle={type === CODE.MERCHANT ? "Please confirm the amount and details" : "Choose your preferred loan amount" }/>
      </Box>
      
      {template && template.map(({ field, name, required }) => {
        const { code, fieldType, maxLength, options, regex, validationMessage, group } = field;
        let formOptions:RespAppOptionField[] = code === CODE.APPLIC_AMOUNT ? amountOptions : tenorOptions

        if(group?.code == CODE.HIDDEN_FIELD) return null

        if(partnerType !== CODE.MERCHANT && code === CODE.ORDERID) return null

        return (
            <Controller
              key={code}
              name={code}
              control={control}
              render={({ field: { value, onChange }, fieldState: { isDirty } }) => {

                  const placeholder = placeholderData[code]
                  const hasValue = !!value && !isDirty; 
                  const { component, componentOption, props } = getComponentAttributes({
                  code,
                  type: fieldType,
                  options,
                  hasValue,
                  maxLength, 
                  placeholder
                  });
          
                  const Component:any = component;
                  const ComponentOption:any = componentOption;
                  const componentProps =
                  { ...props,
                    description: (partnerType === CODE.MERCHANT && code === CODE.APPLIC_AMOUNT) ? 
                    undefined : TOOLTIPS[code] } || {};
                  
                  return (
                      <Box my={3} key={code}>
                          <Component
                              code={code}
                              label={name}
                              required={required}
                              items={formOptions.map((option) => ({
                                  text: option.name,
                                  value: option.code,
                              }))}
                              value={value ?? ''}
                              error={false}
                              helperText={helperText && helperText[code]}
                              onChange={onChangeHandler(code, onChange, regex, validationMessage)}
                              // onChange={handleOnChange}
                              {...componentProps}
                              >
                              {formOptions.map((option, i) => (
                                <WatchedComponent
                                  key={option.code}
                                  control={control}
                                  text={CODE_LABEL[option.code] ?? option.name}
                                  value={option.code}
                                  component={ComponentOption}
                                  index={i}
                                />
                              ))}
                              </Component>
                      </Box>)
                  }}
              />
          );
      })}
      </>
  )
}

export default PartnerForm;