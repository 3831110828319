import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RespVerification, RespAppMetaData, RespMyInfo} from "../../types/response";
import { IState } from "../../types/state";

const initialState = {
  repaymentData: <any>{},
  partnerRedirectURL: ""
}

const partnerRepaymentSlice = createSlice({
  name: "partnerRepaymentSlice",
  initialState,
  reducers: {
    setRepaymentData: (state, { payload }) => {
      state.repaymentData = payload;
    },
    setPartnerRedirectURL: (state, {payload}) =>{
      state.partnerRedirectURL = payload
    }
  },
});

const { actions, reducer } = partnerRepaymentSlice;

export const PartnerRepaymentActions = actions;
export default reducer;
