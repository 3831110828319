import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: null
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorCode(state, action) {
      const { payload } = action;
      state.code = payload
    }
  }
});

const { actions, reducer } = errorSlice;

export const ErrorAction = actions;
export default reducer;
