import { makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { AlertActions } from "../../stores/reducers/alert";
import { ErrorAction } from "../../stores/reducers/error";
import { RootState } from "../../stores/rootReducer";

const CHLAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((s: RootState) => s.alert.data);
  const errorCode = useSelector((s: RootState) => s.error.code);
  const classes = useStyles();

  const onCloseHandler = () => {
    dispatch(AlertActions.removeAlert());
  };

  return (
    <Snackbar
      open={!!alert}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onCloseHandler}
    >
      {alert ? (
        <Alert
          onClose={onCloseHandler}
          severity={alert.type}
          elevation={15}
          className={classes.root}
        >
          {alert.message + errorCode}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 200,
  },
}));

export default CHLAlert;
