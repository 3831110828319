import { BE_CODE_STATUS, CODE } from "./constants"

export const ERROR_PAGE = {
  [BE_CODE_STATUS.OFFER_EXPIRED]: {
    header: "Offer is expired",
    message: "We're sorry but it seems that the offer is invalid or has expired."
  },
  [BE_CODE_STATUS.CONNECTIVITY_ERROR]: {
    header: "Connectivity Error",
    message: "We're sorry but it seems that there has been an error in processing your application. Please try again later."
  },
  [BE_CODE_STATUS.SYSTEM_ERROR]:{
    header: "Processing Error",
    message: "We're sorry but it seems that there has been an error in processing your application. Please try again later."
  },
  [BE_CODE_STATUS.UNAUTHORIZED]: {
    header: "Application Time Out",
    message: "We're sorry but it seems that your application has timed out. Please try again later. "
  },
  [BE_CODE_STATUS.UNDER_MAINTENANCE]: {
    header: "Under Maintenance",
    message: "This service is currently under maintenance from 11:00PM to 2:00AM. Please try again later."
  },
  [BE_CODE_STATUS.PENDING_ORDER]: {
    header: "Application Rejected",
    message: "We're sorry to inform that your application has been rejected."
  },
  [BE_CODE_STATUS.FAILED]: {
    header: "Under Maintenance",
    message: "This service is currently under maintenance. Please try again later."
  },
  [BE_CODE_STATUS.AIP_EXPIRED]: {
    header: "Validation Unsuccessful",
    message: "The Application Reference Number provided is invalid. The Application Reference Number is provided in the email sent to you. Please check and try again."
  },
  [BE_CODE_STATUS.AIP_INVALID_NRIC]: {
    header: "Validation Unsuccessful",
    message: "We're sorry but we're unable to match the Application Reference Number against your records. Please validate your information and try again."
  },
  // [BE_CODE_STATUS.AIP_INVALID_NODE]: {
  //   header: "Validation Unsuccessful",
  // message: "The Application Reference Number provided is invalid. The Application Reference Number is provided in the email sent to you. Please check and try again."
  // },
}

export const ERROR_MESSAGE = {
  [CODE.LENGTH_OF_EMPLOYMENT]: "Please enter a valid length.",
  [CODE.APPLY_NO]: "Please enter Application reference number.",
  PAYNOW_FAILED_ATTEMPT_1: "To proceed with your CIMB Personal Loan application, we can only accept valid PayNow account in your name. Please note that you have ",
  PAYNOW_FAILED_ATTEMPT_2: " failed attempt(s) for this verification. Kindly enter a PayNow ID that is linked to a bank account in your name.",
  PAYNOW_EMPTY: "Paynow ID is empty. Kindly re-confirm again."
}

export const MYINFO_ACCESS_DENIED = "access_denied"
