import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ErrorPage from "./pages/Error";
import Public from "./pages/Public";
import NoMatch from "./pages/NoMatch";
import publicRoutes from "./routes/publicRoutes";
import { useSelector } from "react-redux";
import { RootState } from "./stores/rootReducer";
import { IRoute } from "./types/common";

declare global{
  interface Window{
    _env: any;
    _envVar: any;
    dataLayer: any;
  }
}

function App() {
  const publicPaths = flatten(publicRoutes).map(
    (publicRoute) => publicRoute.path
  );
  const errorCode = useSelector((s: RootState) => s.error.code);

  // window.onbeforeunload = () => {
  //   // Clear the local storage
  //   window.localStorage.clear()
  // }
  
  if(errorCode != null){
    return <ErrorPage/>
  }

  return (
    <Router>
      <Switch>
        <Route path={publicPaths} exact component={Public} />
        <Route path="*" component={NoMatch} />
      </Switch>
    </Router>
  );
}

function flatten(items: IRoute[]): IRoute[] {
  return items.concat.apply(
    items,
    items.map((item) => {
      return (item.children && flatten(item.children)) || [];
    })
  );
}

export default App;
