import { FC } from 'react'
import { RespLoanMatrix, RespAppMetaData, RespAppField } from "../../../../types/response";
import { Box, Typography, makeStyles } from "@material-ui/core";

import {CHLHeaderBar, CHLValueField} from '../../../../components/'

import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducer";
import { formatRepaymentValue } from "../../../../utilities/helper";

import { CODE_LABEL } from "../../../../utilities/label";
import { CODE } from "../../../../utilities/constants";

const RepaymentForm: FC<{loanMatrix: RespLoanMatrix, currency: string, tenorUnit: string, getLoanValue?:(loanValue: any, template: RespAppField[]) => void}> = ({
    loanMatrix,
    currency, 
    tenorUnit,
    getLoanValue=()=>{},
}) => {
    const classes = useStyles();

    const {repaymentForm} = useSelector((s: RootState) => s.partner);
    const template = repaymentForm.applicationTemplateTabFields

    const riskTextItem = template.find(item => item.field.code === CODE.RISK_BASED_TEXT);
    const isShowRiskText = riskTextItem ? true : false;
    const riskText = riskTextItem?.name;

    let data: { [key: string]: any } = {}
    data = Object.assign({}, loanMatrix)
    
    const repayForm = template.map((f)=>{
        return {
            ...f, 
            value: formatRepaymentValue(f.field.fieldType, f.field.dataType, data[f.field.code], tenorUnit, currency)
        }
    })

    const repayFormValue = repayForm.map((f) =>{
        return {[f.field.code]: f.value}
    })

    if(repayFormValue){
        getLoanValue(Object.assign({}, ...repayFormValue), template)
    }

    return (
        <Box position="relative">
            <CHLHeaderBar title="My Estimated Repayment" hasLink={false}/>
            <div className={classes.repaymentContainer}>
                {repayForm.map((item) =>{
                    const {code, group } = item.field
                    const {value, name} = item

                    if(group?.code == CODE.HIDDEN_FIELD) return null
                    
                    return (
                        <Box key={code} my={2}>
                            <CHLValueField
                            key={code}
                            label={CODE_LABEL[code] ?? name}
                            required={false}
                            value={value}
                            error={false}
                            helperText={""}/>
                        </Box>
                    )
                })}
            </div>
            {isShowRiskText && <Typography style={{margin: '10px 10px 0' }}>{riskText}</Typography>}
        </Box>
    )
}

const useStyles = makeStyles({
    repaymentContainer: {
        background: '#FAFBFF',
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        padding: "10px",
    },
})

export default RepaymentForm;