import { FC } from "react";
import { useFormState } from "react-hook-form";
import { CHLButton } from "../../../../components";

interface IApplicationButton {
  length: number;
  activeIndex: number;
  control: any;
}

const ApplicationButton: FC<IApplicationButton> = (props) => {
  const { activeIndex, length, control } = props;
  const { isSubmitting } = useFormState({ control });

  return (
    <CHLButton
      type="submit"
      title={activeIndex === length - 1 ? "Submit" : "Next"}
      color="secondary"
      fullWidth
      disabled={isSubmitting}
      loading={isSubmitting}
    />
  );
};

export default ApplicationButton;
