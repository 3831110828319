import {
  Box,
  Container,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useLayoutEffect } from "react";
import { ReactComponent as ErrorLogo } from "../../assets/images/404.svg";

function Forbidden() {
  const isWidthGreater500 = useMediaQuery("(min-width: 500px)");
  useLayoutEffect(() => {
    document.title = "Forbidden";
  }, []);

  return (
    <Box mt={10}>
      <Container>
        <Box mt={5}>
          <Typography component="div">Error 403 - Forbidden</Typography>
        </Box>
        <Typography variant="h4" component="div" color="primary">
          <Box fontWeight="bold">403</Box>
        </Typography>
        <Box my={2}>
          <Typography>
            Oops! We're sorry. The page you're looking for is no longer available.
          </Typography>
        </Box>
      </Container>
        <Box mt={-2} textAlign="center">
          <ErrorLogo width={!isWidthGreater500 ? "100%" : undefined} />
        </Box>
    </Box>
  );
}

export default Forbidden;
