import { createContext, FC, Fragment, useState } from "react"
import { useSelector } from "react-redux";
import {
    Box,
    Collapse,
    Grid,
    Hidden,
    Theme,
    Typography,
    useMediaQuery,
  } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { createYupSchema } from "../../../../utilities/helper";
import CollapseHeader from "./CollapseHeader";
import { RootState } from "../../../../stores/rootReducer";
import { CAPTION_SUMMARY, CODE, FORM_TYPE } from "../../../../utilities/constants";
import { CODE_LABEL, SUMMARY_CODE_LABEL} from "../../../../utilities/label";
import { CHLButton, CHLValueField, CHLCustomerService } from "../../../../components";
import { selectParentsByIndex } from "../../../../stores/reducers/application";
import { RespAppField, RespAppOptionField, RespPlaceholder, RespRender } from "../../../../types/response";
import FormDataEntry from "./FormDataEntry";
import ClickableValueField  from "./ClickableValueField"

const CUSTOMER_SERVICE_URL = window._env.REACT_APP_CIMB_CUSTOMER_SERVICE

interface IApplicationFormSummary {
    activeIndex: number;
    onSubmit: () => void;
    onBack?: () => void;
}
interface IApplicationSummaryContext {
    register: any;
    control: any;
    defaultValues: any;
    fields: RespAppField[];
    activeIndex: number;
    setValue?: any;
    getValues?: any;
    setFocus?: any;
    nric?: string;
    placeholders?: RespPlaceholder;
}

export const ApplicationSummaryContext = createContext<IApplicationSummaryContext | null>(
    null
);

const ApplicationSummary: FC<IApplicationFormSummary> = (props) =>{
    const { activeIndex, onSubmit, onBack } = props;
    const [expands, setExpands] = useState<boolean[]>([]);
    const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
    const { summaryData, documentUpload, isPloanHasUploadStep } = useSelector(
        (s: RootState) => s.application
    );
    const { partnerVerifyData } = useSelector((s: RootState) => s.partner)
    const { type: partyType } = partnerVerifyData
    const currentParent = useSelector(selectParentsByIndex(activeIndex));
    const template = currentParent.reduce<RespAppField[]>((prev, current) => {
        let tabFields = current.applicationTemplateTabFields;
        return prev.concat(tabFields);
    }, []);

    const form = template.map(({ field: { code, value } }: any) => ({
        [code]: value ?? null,
    }));

    const defaultValues = Object.assign({}, ...form);
    const { control, handleSubmit, register } = useForm({
        defaultValues,
        resolver: createYupSchema(template),
    });

    const setLengthOfEmploymentValue = (val: string) =>{
        const v = val.split("-")
        return v[0] + " Years " + v[1] + " Months"
    }


    return (
        <>
            {summaryData.map((d, i) => {
                const {fields, type, name} = d
                const caption = CAPTION_SUMMARY.find((c) => c.NAME === d.name);
                const isCaptionExisted = caption?.TITLE;

                if(!fields) return null
                if(!isPloanHasUploadStep && isCaptionExisted == CODE.DOC_UPLOAD){
                    return null 
                }
                if(isPloanHasUploadStep &&  isCaptionExisted == CODE.DOC_UPLOAD_CHECKBOX){
                    return null 
                }
                
                return(
                    <Fragment key={"summaryHeader"+i}>
                        <CollapseHeader
                            key={"collapseHeader"+i}
                            isSummary={true}
                            name={d.name}
                            expand={!!expands[i]}
                            onClick={() => {
                                expands[i] = !expands[i];
                                setExpands([...expands]);
                            }}
                        />
                        <Collapse in={isCaptionExisted ? expands[i] : true}>
                            <Box key={"Box"+i} mt={2} />
                            <Grid key={"Grid"+i} container spacing={smAndUp ? 4 : 2}>
                                {type === "dataEntry" && (
                                    fields.map((f, idx) => {
                                        if(f){
                                            if(f.code === CODE.PAYSLIP || f.code === CODE.LETTER_OF_ACCEPTANCE || f.code === CODE.W8_W9_FORM){
                                                const documentName = documentUpload[f.code]
                                                if(documentName || f.value){
                                                    return (
                                                    <Grid item xs={12} sm={6} key={idx}>
                                                        <CHLValueField
                                                            key={"CHLVal"+idx}
                                                            label={SUMMARY_CODE_LABEL[f.code] ?? f.label}
                                                            value={documentName ? documentName : f.value}
                                                        />
                                                    </Grid>
                                                    )
                                                }
                                            }
                                            if(f.code === CODE.ORDERID && partyType !== CODE.MERCHANT){
                                                return null
                                            }
                                            if(f.code=== "initialInterestRate" || f.code === "loanApprovedAmount"){
                                                return null
                                            }
                                            if(f.value && f.code !== "loanApprovedInterestRate"){
                                                return (
                                                <Grid item xs={12} sm={6} key={idx}>
                                                    <CHLValueField
                                                        key={"CHLVal"+idx}
                                                        label={SUMMARY_CODE_LABEL[f.code] ?? f.label}
                                                        value={f.code == CODE.LENGTH_OF_EMPLOYMENT ? setLengthOfEmploymentValue(f.value) : f.value }
                                                    />
                                                </Grid>)
                                            }
                                            if(f.code === "riskBasedTextSummary"){
                                                return (
                                                    <Box mx={smAndUp ? 2 : 1} mb={smAndUp ? 2 : 1}>
                                                        <Typography>{f.label}</Typography>
                                                    </Box> 
                                                )
                                            }
                                        }
                                        
                                    }) 
                                )}
                                
                                {type === FORM_TYPE.TABLE && (
                                name === CODE.NOA || name === CODE.CPF_HISTORY ? 
                                <Box key={i} p={2} style={{width: "100%"}}>
                                    <ClickableValueField 
                                        key={"CHLClickVal"+i}
                                        code={name}
                                        label={CODE_LABEL[name]}
                                    /> 
                                </Box>
                                :   
                                fields.map((f, idx) => {
                                    return(
                                        <Grid item xs={12} sm={6} key={idx}>
                                            <Typography key={idx} color={"primary"}>Tax Residence #{idx + 1}</Typography>
                                            {
                                                f.map((t:any, j: number) =>{
                                                    if(t.value){
                                                        return (
                                                            <Box my={2}>
                                                                <CHLValueField
                                                                    key={j}
                                                                    label={t.label}
                                                                    value={t.value}
                                                                />
                                                            </Box>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                    )
                                })
                            )}

                            {type === FORM_TYPE.ATTACHMENT && (
                                fields.map((f, idx) => {
                                    if(f){
                                        const documentName = documentUpload[f.code]
                                        if((documentName !== "undefined" && documentName) || f.value){
                                            return (
                                            <Grid item xs={12} sm={6} key={idx}>
                                                <CHLValueField
                                                    key={"CHLVal"+idx}
                                                    label={SUMMARY_CODE_LABEL[f.code] ?? f.label}
                                                    value={documentName ? documentName : f.value}
                                                />
                                            </Grid>
                                            )
                                        }
                                    }
                                    
                                }) 
                            )}


                                {/* {type === "dataEntry" && 
                                fields.map((f, idx) => {
                                    if(f.value){
                                        return (
                                        <Grid item xs={12} sm={6} key={idx}>
                                            <CHLValueField
                                                label={f.label}
                                                value={f.value}
                                            />
                                        </Grid>)
                                    }
                                })} */}
                                
                                {/* {type === "table" && 
                                
                                fields.map((f, idx) => {
                                    return(
                                        <Grid item xs={12} sm={6} key={idx}>
                                            <Typography color={"primary"}>Tax Residence #{idx + 1}</Typography>
                                            {
                                                f.map((t:any, j: number) =>{
                                                    if(t.value){
                                                        return (
                                                            <Box my={2}>
                                                                <CHLValueField
                                                                    label={t.label}
                                                                    value={t.value}
                                                                />
                                                            </Box>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                    )
                                })} */}
                            </Grid>
                        </Collapse>
                    </Fragment>
                )
            })}
            <Box borderTop={1} my={4} borderColor="#BDBDBD" width="100%">
                <Box my={4} fontWeight={900} fontSize="18px">Acknowledgement</Box>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                {currentParent.map((parent, index) =>{
                    const { applicationTemplateTabFields } = parent;
                    return(
                    <Grid key={index} container>
                        <Grid key={"n"+index} container spacing={smAndUp ? 4 : 2}>
                            <ApplicationSummaryContext.Provider
                                value={{
                                register,
                                activeIndex,
                                control,
                                defaultValues,
                                fields: applicationTemplateTabFields,
                                }}>
                                <FormDataEntry isSummary={true} />
                            </ApplicationSummaryContext.Provider>
                        </Grid>
                    </Grid>
                    )
                })}
                <Box mt={4} display="flex" justifyContent="start">
                    <CHLCustomerService/>
                </Box>

                <Grid container item>
                    {activeIndex != 0 && (
                    <Box clone order={{ xs: 3, sm: 1 }}>
                        <Grid item xs={12} sm={3}>
                        <Box my={2}>
                            <CHLButton
                            type="button"
                            title="Back"
                            color="default"
                            fullWidth
                            onClick={onBack}
                            />
                        </Box>
                        </Grid>
                    </Box>
                    )}
                    <Hidden xsDown>
                        <Box clone order={{ xs: 2, sm: 2 }}>
                            <Grid item sm={activeIndex == 0 ? 9 : 6} />
                        </Box>
                    </Hidden>
                    <Box clone order={{ xs: 1, sm: 3 }}>
                        <Grid item xs={12} sm={3}>
                            <Box mt={2}>
                            <CHLButton
                                type="submit"
                                title="Agree and Proceed"
                                color="secondary"
                                fullWidth
                            />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </>
    )
}

export default ApplicationSummary;