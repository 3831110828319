import { forwardRef, ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { RespAppField, RespRender } from "../../../../types/response";
import { CODE, DEFAULT_VALUE } from "../../../../utilities/constants"

type Field = Pick<RespAppField, "field">;
interface IWatchField extends Partial<Field> {
  control: any;
  render?: RespRender;
  onChange?: any;
  isDirty?: boolean;
  currentValue?: any;
  defaultValue?: any;
  controllercode?: string;

  // for field
  children?: ReactNode;

  // for option
  text?: string;
  value?: string | number;
  component?: any;
  index?: number;
  isManualFlow?: boolean;
}

const WatchedComponent = forwardRef<any, IWatchField>((props, ref) => {
  let { control, render } = props;
  const { 
    field, 
    controllercode, 
    currentValue, 
    onChange, 
    isManualFlow, 
    children, 
    text, 
    value, 
    component, 
    ...rest 
  } = props;

  let name: string[] = [];
  let dependValues: (string | number)[] = [];

  const hasRenderCondition = render && render.fields && render.fields.length;
  if (render && hasRenderCondition) {
    name = render.fields.map((field) => {
      // for table type
      if (controllercode)
        return controllercode.replace(/\.[^.]*$/, `.${field.code}`);
      // for data entry type
      else return field.code;
    });
    dependValues = render.fields.map((field) => field.value);
  }

  let values = useWatch({ control, name })
  if(name.includes(CODE.MYINFO_MODE)){
    values = isManualFlow ? [DEFAULT_VALUE.NON_MYINFO] : [DEFAULT_VALUE.MYINFO]
  }

  const isAllEqual = (a: any[], b: any[]) =>
    JSON.stringify(a) === JSON.stringify(b);

  if (isAllEqual(values, dependValues)) {
    if (!children) {
      const Component = component;
      return (
        <Component ref={ref} value={value} {...rest}>
          {text}
        </Component>
      );
    } else {
      return <>{children}</>;
    }
  } else {
    if(!(field?.code == CODE.PAYNOW_ID && currentValue)){
      setTimeout(() => onChange && onChange(null), 100);
    }

    return null;
    
    ;
  }
});

function setDefaultValue(
  { field }: Field,
  defaultValue: any,
  isDirty?: boolean,
  onChange?: any
) {
  if (defaultValue) return;

  const { dataType, fieldType, options } = field;
  if (fieldType === "RadioButton" && options && options.length && !isDirty) {
    let value: any;
    if (dataType === "Integer" || dataType === "Float")
      value = parseFloat(options[0].code);
    else value = options[0].code;
    
    setTimeout(() => onChange && onChange(value), 100);
  }
}

export default WatchedComponent;
