import { makeStyles, Typography } from "@material-ui/core";
import { FC } from "react";

const Header: FC<{ title: String, subTitle: String }> = (props) => {
    const { title, subTitle } = props;
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h4" className={classes.title}>{title}</Typography>
            {subTitle != '' && <Typography className={classes.subTitle} paragraph={true}>{subTitle}</Typography>}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '28px'
    }, 
    subTitle: {
        color: theme.palette.secondary.main,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px'
    }
}))

export default Header;

