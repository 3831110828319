import { FC, ReactNode, Fragment, useState } from "react";
import {
  Grid,
  Box,
  Link,
  FormControl,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import CHLLabel from "../../../../components/CHLLabel";
import CHLDialog from "../../../../components/CHLDialog";

const label =
  "Notice And Consent In Respect Of Products And/Or Services Offered By CIMB Bank Berhad";

const Title = () => {
  const classes = useStyles();
  return <Typography className={classes.titleLabel}>{label}</Typography>
}

const PdpaConsentField: FC = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const smAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Fragment>
      <Box pb={2} mb={2}>
        <Typography>
          I confirm my contact method preference and I accept the
          <a
            style={{ cursor: "pointer"}}
            onClick={() => {
              setOpen(true);
            }}
          >
            {" "}
            PDPA Consent
          </a>
        </Typography>
      </Box>
      <CHLDialog
        titleAlignment="left"
        hideClose={true}
        open={open}
        title={<Title/>}
        onClose={() => setOpen(false)}
        actions={[
          {
            title: "Close",
            color: "secondary",
            onClick: () => setOpen(false),
          },
        ]}
      >
        <Typography>
          I/We agree and consent that CIMB Bank Berhad and its related
          corporations (collectively “CIMB”) may collect, use, disclose and
          process my/our personal information provided by me/us to CIMB, for one
          or more purposes stated in CIMB’s Personal Data Protection Terms and
          Conditions including for the purposes of sending me/us marketing,
          advertising and promotional information (“Marketing Messages”) about
          other products/services that CIMB and its affiliates, related
          corporations and third party service providers may be offering or
          which CIMB believes maybe of interest or benefit to me/us (“Marketing
          Purpose”), by way of my/our selected contact method preference.
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography>
          In the event that no indication is made on the contact method
          preference, CIMB reserves its right to send Marketing
          Messages regarding your existing products/services with CIMB to your
          Singapore telephone number registered with CIMB (“Registered Number”)
          if you have an ongoing relationship with CIMB and if the Registered
          Number is not registered with the National Do Not Call Registry.
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography className={classes.greyText}>
          <Box style={{ textDecoration: "underline" }}>
            Opt Out for Marketing Messages via postal mail and/or electronic
            transmission to my email address(es):
          </Box>
          <Box>
            You have the right to opt out of receiving such messages. Please
            visit www.cimb.com.sg to find out how. If you do not exercise your
            right to opt out, you will be deemed to have consented to receiving
            such messages by CIMB.
          </Box>
        </Typography>
      </CHLDialog>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
    wordBreak: "normal",
    "& .MuiTableCell-head": {
      background: "#C4161C",
      color: "#fff",
      padding: "10px",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "bold",
    },
    "& .MuiTableCell-body": {
      background: "#F2F4FA",
      border: "1px solid #fff",
    },
  },
  noaContainer: {
    background: "#F2F4FA",
    padding: "10px",
  },
  header: {
    background: "#C4161C",
    color: "#fff",
    padding: "10px",
    fontSize: "16px",
    lineHeight: "22px",
  },
  label: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
  },
  value: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
  },
  greyText: {
    color: theme.palette.grey["900"],
  },
  titleLabel: {
    color: "#f00",
    fontWeight: 700,
  }
}));

export default PdpaConsentField;
