import { createAsyncThunk } from "@reduxjs/toolkit";
import MD5 from "crypto-js/md5";
import { AppDispatch } from "..";
import { store } from "../../stores";
import { CODE, AIP_SECRET_KEY } from "../../utilities/constants";
import { polishMyInfoData } from "../../utilities/helper";
import { MyInfoActions } from "../reducers/myinfo";
import { ApplicationPageRequest } from "../../services/requests/ApplicationPageRequest";
import { DocUploadRequest } from "../../services/requests/DocUploadRequest";
import {
  ReqInPrincipleApproval,
  ReqDocUploadApplication,
} from "../../types/request";
import { DocUploadActions } from "../reducers/docUpload";

export const docUploadApplication = createAsyncThunk<
  any,
  ReqDocUploadApplication,
  { dispatch: AppDispatch }
>("docUploadApplication/fetchForms", async (data, { dispatch }) => {
  try {
    dispatch(DocUploadActions.setPageLoading(true));

    let reqParams: ReqInPrincipleApproval;
    const applyNo = data.applyNo;

    const { myinfo } = store.getState();
    let myInfoData: { [key: string]: any } = myinfo.data;

    if (Object.keys(myInfoData).length == 0) {
      const myInfoResult = await ApplicationPageRequest.fetchMyInfoPerson(
        data.myInfo
      );
      myInfoData = myInfoResult.data;
      dispatch(MyInfoActions.setData(polishMyInfoData(myInfoData)));
    }

    const nric = myInfoData[CODE.NRIC];
    const hToken = MD5(data.applyNo + nric + AIP_SECRET_KEY).toString();
    reqParams = {
      applyNo,
      hToken,
      nric,
    };
    const results = await DocUploadRequest.fetchDocUploadApplication(reqParams);
    const resData = results.data;

    if (resData) {
      // console.log("data", resData);
      dispatch(DocUploadActions.setDocUploadApplication(resData));
    }
  } catch (err) {
    Promise.resolve(err);
    throw err;
  } finally {
    dispatch(DocUploadActions.setPageLoading(false));
  }
});
