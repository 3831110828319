import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { IState } from "../../types/state";
import { RespAppMetaData } from "../../types/response";
import { CODE, DEFAULT_VALUE } from "../../utilities/constants"

type Parents =
  | [{ [key: string]: (RespAppMetaData & { originalName: string })[] }]
  | [];

const initialState: IState<RespAppMetaData[]> & {
  originalParents: Parents;
  parents: Parents;
  originalNames:  string[];
  ploanSummaries: Parents;
  ploanSummariesNames: string[];
  ploanNoUploadStepSummaries: Parents;
  ploanNoUploadStepSummariesNames: string[];
  ploanParents: Parents;
  ploanNames: string[];
  ploanNoUploadStepParents: Parents;
  ploanNoUploadStepNames: string[];
  isPloanHasUploadStep: boolean;
  names: string[];
  summaries: Parents;
  summariesNames: [];
  isManualFlow: boolean;
  formData: any;
  summaryData: {
    id: string | number;
    name: string;
    type: string;
    fields: any[]
  }[];
  isSTP: string;
  isPayNowIDValidateFailed: boolean;
  partyType?: string;
  faceVScore?: number;
  faceVFlag?: string;
  applicationStatus: {
    code: string;
    message: string;
  },
  documentUpload: {
    [key: string]: any
  },
  obsFileMap: {
    [key: string]: any
  },
  isAmericanFlag: boolean;
  uploadRelated: {
    [key: string]: any
  },
  isLendela: boolean;
} = {
  data: [],
  names: [],
  originalNames: [],
  originalParents: [],
  parents: [], // For rendering tabs
  summaries: [], // For rendering summary
  summariesNames: [],
  ploanParents: [],
  ploanNames: [],
  ploanNoUploadStepParents: [],
  ploanNoUploadStepNames: [],
  ploanSummaries: [],
  ploanSummariesNames: [],
  ploanNoUploadStepSummaries: [],
  ploanNoUploadStepSummariesNames: [],
  isPloanHasUploadStep: true,
  isLoading: false,
  isManualFlow: false,
  formData: {},
  summaryData: [],
  isSTP: DEFAULT_VALUE.STP,
  isPayNowIDValidateFailed: true,
  faceVScore: undefined,
  faceVFlag: undefined,
  applicationStatus: {
    code: "",
    message: ""
  },
  documentUpload: {},
  obsFileMap: {},
  isAmericanFlag: false,
  uploadRelated: {},
  isLendela: false,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setIsPloanHasUploadStep: (state, { payload }) => {
      state.isPloanHasUploadStep = payload;
    },
    setParents: (state, { payload }) => {
      state.parents = payload;
    },
    setNames: (state, { payload }) => {
      state.names = payload;
    },
    setSummaries: (state, { payload }) => {
      state.summaries = payload;
    },
    setSummariesNames: (state, { payload }) => {
      state.summariesNames = payload;
    },
    setUploadRelated: (state, { payload }) => {
      state.uploadRelated = payload;
    },
    setIsAmericanFlag: (state, { payload }) => {
      state.isAmericanFlag = payload;
    },
    setDocumentUpload: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const stateDocumentUpload = state.documentUpload
      state.documentUpload = { ...stateDocumentUpload, ...payload };
    },
    removeDocumentByCode: (state, { payload }) => {
      const documentUpload = state.documentUpload;
      let newDocumentUpload = { ...documentUpload };
      delete newDocumentUpload[payload];
      state.documentUpload = newDocumentUpload;
    },
    setObsFileMap: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const obsFileMap = state.obsFileMap
      state.obsFileMap = { ...obsFileMap, ...payload };
    },
    removeObsFileMapByCode: (state, { payload }) => {
      const obsFileMap = state.obsFileMap;
      let newObsFileMap = { ...obsFileMap };
      delete newObsFileMap[payload];
      state.obsFileMap = newObsFileMap;
    },
    setPartyType: (state, { payload }) => {
      state.partyType = payload;
    },
    setFaceVScore: (state, { payload }) => {
      state.faceVScore = payload
    },
    setFaceVFlag: (state, { payload }) => {
      state.faceVFlag = payload
    },
    setApplicationStatus: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.applicationStatus = payload;
    },
    setSummaryData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const stateData = state.summaryData
      if (stateData.length == 0) {
        state.summaryData = [...state.summaryData, ...payload];
      } else {
        var filtered = stateData.filter(o1 => !payload.some((o2: any) => o1.id == o2.id));
        var result = filtered.concat(payload);

        state.summaryData = result
      }
    },
    setFormData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.formData = payload;
    },
    setIsSTP: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.isSTP = payload
    },
    setIsPayNowIDValidateFailed: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isPayNowIDValidateFailed = payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isLoading = payload;
    },
    setData: (
      state,
      action: PayloadAction<{ res: RespAppMetaData[]; isManualFlow: boolean; isLendela?: boolean }>
    ) => {
      const { res, isManualFlow, isLendela = false } = action.payload;
      if(isLendela){
        let results = res.sort((a, b) => a.sequence - b.sequence);
        let tempPayload = results.map((p: any)=>{
          return {
            ...p,
            originalName: p.name,
            name: "LendelaWholePage",
          }
        });
        let items: any = [];
        tempPayload.map((p) => {
          const index = items.findIndex((t: any) => t[p.name]);
          if (index < 0) {
            items.push({ [p.name]: [p] });
          } else {
            items[index][p.name].push(p);
          }
        });
        let names = Array.from(new Set(tempPayload.map((p) => p.name)));
        state.originalParents = items;
        state.originalNames = names;

        state.parents = items;  
        state.names = names;

        state.data = results; // purpose of storing original payload data
        state.summaries = [...items] as any;
        state.summariesNames = [...names] as any;

        state.isManualFlow = isManualFlow;
        state.isLendela = isLendela;

      }else{
      let results = res.sort((a, b) => a.sequence - b.sequence);
      results = results.filter(tab => tab.name !== 'awsmreservefieldlist' && tab.name!=='Partner Reserve Field List');

      // Slice tab name to find similar tab name
      const nameMap: any = {
        "About Me": CODE.PERSONAL_DETAILS,
        "Address Details": CODE.PERSONAL_DETAILS,
        "CPF Contributions": CODE.PERSONAL_DETAILS,
        "NOA History": CODE.PERSONAL_DETAILS,
        "Employment Details": CODE.PERSONAL_DETAILS,
        "PDPA": "Declaration",
        "FATCA": "Declaration",
        "Application Summary": 'Review',
        "Signature": "Sign Your Application",
        "Non-MyInfo Upload": CODE.DOCUMENT_UPLOAD
      };

      let tempPayload = results.map((p: any) => {
        let name = '';
        let applicationTemplateTabFields = p.applicationTemplateTabFields;
        if (state.partyType === CODE.MCC) {
          name = nameMap[p.name];
          if (p.name === CODE.ABOUT_ME) {
            // applicationTemplateTabFields = applicationTemplateTabFields.filter((f: any) => ![CODE.EMAIL, CODE.PRINCIPAL_NAME, CODE.ALIASNAME].includes(f?.field?.code))
            // 获取到 与Mobile Number相关的fields 的 sequence数组
            let mobileRelatedFieldSequenceArr: number[] = [];
            let areaCodeDropdownOptions;
            let secondContactRelatedFieldSequenceArr:  number[] = [];
            let secondAreaCodeOptions;
            applicationTemplateTabFields.map((item: any )=> {
              const { field, sequence } = item;
              if(field.code === CODE.MCC_MOBILE_AREA_CODE || field.code===CODE.MCC_MOBILE_NO){
                mobileRelatedFieldSequenceArr.push(sequence);
                if(field.code === CODE.MCC_MOBILE_AREA_CODE){
                  areaCodeDropdownOptions = field.options;
                }
              }
              if(field.code === CODE.MCC_SECOND_CONTACT_AREA_CODE || field.code===CODE.MCC_SECOND_CONTACT_NUMBER){
                secondContactRelatedFieldSequenceArr.push(sequence);
                if(field.code === CODE.MCC_SECOND_CONTACT_AREA_CODE){
                  secondAreaCodeOptions = field.options;
                }
              }
            });
            // 与Mobile Number相关的fields 有两个，那么就要渲染新的Mobile组件
            if(mobileRelatedFieldSequenceArr.length === 2){
              // 构建一个fake field，用于遍历渲染组件时可以识别
              const fakeMobileField = {
                field: {
                  code: CODE.MCC_FAKE_MOBILE,
                  name: "Mobile Number",
                  dataType: 'Text',
                  fieldType: 'InputField',
                  options: areaCodeDropdownOptions,
                  validationMessage: "Please provide your Mobile Number/area code",
                },
                name: "Mobile Number",
                required: true,
                sequence: mobileRelatedFieldSequenceArr[0],
              };
              // [可优化] 合并212的过滤逻辑，节省一次遍历
              // 把原来 与Mobile Number相关的两个 fields 过滤掉，
              applicationTemplateTabFields = applicationTemplateTabFields.filter((f: any) => ![CODE.MCC_MOBILE_AREA_CODE, CODE.MCC_MOBILE_NO].includes(f?.field?.code));
              // 再把这个fake field[Mobile Number] 加入到applicationTemplateTabFields里
              applicationTemplateTabFields.push(fakeMobileField);
              // 最后重排一下
              applicationTemplateTabFields = applicationTemplateTabFields.sort((a: any, b: any) => a.sequence - b.sequence)
            }
            if(secondContactRelatedFieldSequenceArr.length === 2){
              const fakeSecondaryContactField = {
                field: {
                  code: CODE.MCC_FAKE_SECONDARY_CONTACT,
                  name: "Secondary Contact Number",
                  dataType: 'Text',
                  fieldType: 'InputField',
                  options: secondAreaCodeOptions,
                  validationMessage: "Please provide your Secondary Contact Number",
                },
                name: "Secondary Contact Number",
                required: false,
                sequence: secondContactRelatedFieldSequenceArr[0],
              };
              applicationTemplateTabFields = applicationTemplateTabFields.filter((f: any) => ![CODE.MCC_SECOND_CONTACT_AREA_CODE, CODE.MCC_SECOND_CONTACT_NUMBER].includes(f?.field?.code));
              applicationTemplateTabFields.push(fakeSecondaryContactField);
              applicationTemplateTabFields = applicationTemplateTabFields.sort((a: any, b: any) => a.sequence - b.sequence)
            }
          }
        } else {
          name =
            p.name === CODE.CPF_HISTORY ||
            p.name === CODE.NOA ||
            p.name === CODE.ABOUT_ME
              ? CODE.MYINFO_PROFILE
              : p.name.includes(CODE.DOC_UPLOAD)
              ? CODE.DOC_UPLOAD
              : p.name.replace(/#\d+/, "");
        }
        return ({
          ...p,
          originalName: p.name,
          name,
          applicationTemplateTabFields,
        })
      });

      // fixme
      if (state.partyType === CODE.MCC) {
        const docTab = tempPayload.pop();
        tempPayload.splice(3, 0, docTab);
      }

      // Push payloads to a new item
      let items: any = [];
      tempPayload.map((p) => {
        const index = items.findIndex((t: any) => t[p.name]);
        if (isManualFlow && [CODE.CPF_HISTORY, CODE.MCC_CPF_HISTORY, CODE.NOA, CODE.MCC_NOA].includes(p.originalName)) return;
        if (index < 0) {
          items.push({ [p.name]: [p] });
        } else {
          items[index][p.name].push(p);
        }
      });

      let names = Array.from(new Set(tempPayload.map((p) => p.name)));

      if (!isManualFlow) {
        items = items.filter((e: any) => {
          return Object.keys(e)[0] != CODE.DOCUMENT_UPLOAD
        })
        names = names.filter((e: string) => e !== CODE.DOCUMENT_UPLOAD)
      }

      state.originalParents = items;
      state.originalNames = names;
      
      if(state.partyType !== CODE.MCC && !isManualFlow) {
        // ploan myinfo hidden step1:myinfo profile
        state.parents = items.filter((i: any, index: number) => index != 0);  
        state.names = names.filter((i: any, index: number) => index != 0); 
        state.ploanParents = items.filter((i: any, index: number) => index != 0);
        state.ploanNames = names.filter((i: any, index: number) => index != 0);
        state.ploanNoUploadStepParents = items.filter((i: any, index: number) => {
          const key = Object.keys(i)[0];
          return index != 0 && !key.includes(CODE.DOC_UPLOAD);
        });
        state.ploanNoUploadStepNames = names.filter((i: any, index: number) => {
          return i != CODE.DOC_UPLOAD && index != 0
        });
      } else {
        state.parents = items;  
        state.names = names;
      }
      state.data = results; // purpose of storing original payload data
      state.summaries = [...items] as any;
      state.summariesNames = [...names] as any;
      state.ploanSummaries = [...items] as any;
      state.ploanSummariesNames = [...names] as any;
      state.ploanNoUploadStepSummaries = items.filter((i: any) => {
        const key = Object.keys(i)[0];
        return !key.includes(CODE.DOC_UPLOAD);
      });
      state.ploanNoUploadStepSummariesNames = names.filter((i: any) => {
        return i != CODE.DOC_UPLOAD;
      });
      state.isManualFlow = isManualFlow;
      }
    },
  },
});

export const selectDataByIndex = (index: number) =>
  createSelector(
    (s: RootState) => s.application,
    ({ data }) => data[index]
  );

export const selectParentsByIndex = (index: number) =>
  createSelector(
    (s: RootState) => s.application,
    ({ parents, names }) => {
      return parents[index] && parents[index][names[index]];
    }
  );

export const selectMyProfileData = (index: number) =>
  createSelector(
    (s: RootState) => s.application,
    ({ originalParents, originalNames }) => {
      return originalParents[index] && originalParents[index][originalNames[index]];
    }
  );

const { actions, reducer } = applicationSlice;
export const ApplicationActions = actions;
export default reducer;
