import { CODE } from "./constants"

export const TOOLTIPS = {
    [CODE.PAYNOW_ID_TYPE]: "Please provide the ID (mobile number or NRIC) used to set up your PayNow. Please note that in order to process the disbursement of your loan application, we can only accept PayNow ID that is linked to a bank account in your name.",
    [CODE.APPLIC_AMOUNT]: "Please provide the loan amount you require. The final approved loan amount is still subject to the Bank's review.",
    [CODE.APPLIC_INCOME]: "Please provide your monthly fixed salary.",
    [CODE.PR_STATUS]: " Please provide the number of years that you obtained your Singapore Permanent Resident (PR) status.",
    // [CODE.MCC_FULLNAME]: "You may continue with a shortened name if your name is longer than the maximum allowable characters.",
    // [CODE.MCC_EMAIL_ADDRESS]: "E-Statement will be sent to this Email Address.",
    [CODE.MCC_MOBILE_NUMBER]: "6-digit Activation Code and a One Time Password for 2nd Factor Authentication will be sent to this number via SMS.",
    [CODE.MCC_FAKE_MOBILE]: "Mobile number should exclude country code selected. For example, to fill the mobile number +60123456789, select country dropdown of +60 and input the mobile number 123456789 without the +60.",
    [CODE.PASSPORT_NUMBER]: "Passport expiry date needs to have at least 6 months validity.",
};