import clsx from "clsx";
import { FC } from "react";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import CHLLabel from "../CHLLabel";

interface ICHLSelect extends SelectProps {
  label?: string;
  items?: { text: string; value: number | string }[];
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  description?: string;
}
const DEFAULT_TEXT = "Please select";
const CHLSelect: FC<ICHLSelect> = (props) => {
  const {
    label,
    items = [],
    required,
    bold,
    helperText = " ",
    description,
    error,
    children,
    ...rest
  } = props;
  const classes = useStyles();

  // Templates
  return (
    <FormControl classes={{ root: classes.root }} error={error}>
      <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
        component="div"
      />
      <Select
        classes={{
          disabled: classes.disabled,
        }}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
        }}
        displayEmpty
        IconComponent={({ className, ...rest }) => (
          <ArrowDownIcon className={clsx(className, classes.icon)} {...rest} />
        )}
        renderValue={(value: any) => {
          const item = items.find((item) => item.value === value);
          return <div>{value ? item?.text : DEFAULT_TEXT}</div>;
        }}
        {...rest}
        variant="standard"
      >
        <MenuItem>{DEFAULT_TEXT}</MenuItem>
        {children}
      </Select>
      <FormHelperText error={true}>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiOutlinedInput-root": {
      // borderRadius: 2,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      borderWidth: 1,
    },
  },
  paper: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  icon: {
    top: "unset",
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled,
    color: "#8A8A8A",
  },
}));

export default CHLSelect;
