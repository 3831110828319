//Authentication
export const AUTHENTICATION = "/identity/auth"
export const IDENTITY_AIP = "/identity/inPrincipleApproval" //Temporary endpoint for AIP via external URL
export const VALIDATE_UTOKEN = "/validate/utoken"
export const VALIDATE_UTOKEN_V2 = "/validate/utoken"

//Product
export const PRODUCT = "/product";
export const FORMS = "/forms";


// Application
export const APPLICATION_URL = "/application"
export const RESUME_APPLICATION = "/check"
export const APPLICATION_AIP = "/application/inPrincipleApproval" //MAS get AIP
export const OBS_UPLOAD = "/uploadFiles"

//Dictionary 
export const DICTIONARY_URL = "/dictionary"
export const DICTIONARY_ELEMENT = "/elements?label="

//External
export const LOAN_CALCULATOR = "/ext/calculator"
export const PAYNOW_VALIDATION = "/ext/paynow"
 
// Singpass MyInfo/Identiface
export const MYINFO = "/ext/myinfo"
export const FACE_VERIFICATION = "/ext/identiface"
export const FACE_V_GET_TOKEN = "/token"
export const FACE_V_VALIDATE_TOKEN = "/validate"

// Road Show Call
export const ROAD_SHOW_RESPONSE = "/roadShow/getCustomerDecision"
//Onemap
export const GET_ADDRESS = "/ext/onemap/search"

export const MCC_REDIRECT_URI = '/redirect/uri'

// for smart fill doc upload
export const IDENTITY_UPLOAD_FILES = "/identity/uploadFilesCheck"
export const UPLOAD_FORM = "/uploadFilesCheck"
// export const OBS_UPLOAD = "/uploadFiles"
export const UPLOAD_FILES = "/uploadFilesToSLP"