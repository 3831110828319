import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlertState, IState } from "../../types/state";

interface IAlertDialog {
    title: string;
    message: string;
    buttonText: string;
    buttonAction?:() => void
}

const initialState: IState<IAlertDialog | null> = {
  data: null,
};

const alertDialogSlice = createSlice({
  name: "alertDialog",
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<IAlertDialog>) => {
      const { payload } = action;
      state.data = payload;
    },
    removeAlert: (state) => {
      state.data = null;
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = alertDialogSlice;
export const AlertDialogActions = actions;
export default reducer;
