import axiosInstance from "../axios";
import { 
  APPLICATION_URL, 
  PAYNOW_VALIDATION, 
  PRODUCT, 
  FORMS, 
  MYINFO,
  OBS_UPLOAD, 
  GET_ADDRESS 
} from "../api";
import { 
  ReqApplicationPage,
  ReqApplicationData, 
  ReqValidatePayNowAccount, 
  ReqMyInfo,
  ReqOnemapSearchAddress 
} from "../../types/request";
import { CODE } from "../../utilities/constants";
import { mockPloanMyinfo, mockPloanMyinfoForm, mockMccMyinfo, mockMccMyinfoForm, mockPayNowIDValidation, mockObsUpload } from "../../mock"

export class ApplicationPageRequest {
  static fetchMyInfoPerson = (data: ReqMyInfo) => {
    const {isMcc, ...restData} = data;
    if (isMcc) {
      axiosInstance.setHeader({ sourceId: CODE.MCC });
    }
    const promise = axiosInstance.get(`${MYINFO}`, restData);
    const result:any = promise.then((response) => response.data);
    //const result:any = mockMccMyinfo; // mockPloanMyinfo;

    return result
  };

  static fetchForms = (data: ReqApplicationPage) => {
    const tids = { tids: data.tids };
    if (data.isMcc) {
      axiosInstance.setHeader({ sourceId: CODE.MCC });
    }
    const promise = axiosInstance.get(`${PRODUCT}/${data.productCode}${FORMS}`, tids);
    const result:any = promise.then((response) => {
      //response.data = mockMccMyinfoForm; //mockPloanMyinfoForm;
      return response.data
    })
    return result
  };

  static validatePayNowAccount = (data: ReqValidatePayNowAccount)=> {
    return axiosInstance.post(`${PAYNOW_VALIDATION}`, data);
    // const promise = axiosInstance.post(`${PAYNOW_VALIDATION}`, data);
    // const result:any = promise.then((response) => {
    //   response.data = {data: mockPayNowIDValidation} 
    //   return response.data
    // })
    // return result 
  };

  static uploadToObs = async (data: any) => {
    const promise = axiosInstance.post(`${OBS_UPLOAD}`, data);
    const result: any = promise.then((response) => response.data);
    // const promise = new Promise((resolve) => {
    //   resolve(mockObsUpload);
    // });
    // const result: any = promise.then((res) => res);
    return result;
  };

  static getOneMapAddress = (data: ReqOnemapSearchAddress)=> {
    const {isMcc, ...restData} = data;
    if (isMcc) {
      axiosInstance.setHeader({ sourceId: CODE.MCC });
    }
    return axiosInstance.post(`${GET_ADDRESS}`, restData);
  };

  static submitApplication = (data: ReqApplicationData)=> {
    const {isMcc = false, ...restData} = data;
    if(isMcc){
      axiosInstance.setHeader({ sourceId: CODE.MCC });
    }
    return axiosInstance.post(`${APPLICATION_URL}`, restData);
  };
}
