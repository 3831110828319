export const POST_MESSAGE = {
  D1: "D1", // "URL decryption failed",
  A1: "A1", // "Authentication API failed due to validation",
  A2: "A2", // "Authentication API failed due to system error",
  A3: "A3", // "Fetch AIP API failed due to ext token",
  A4: "A4", // "Fetch AIP API failed due to order validation",
  A5: "A5", // "Fetch AIP API failed due to system error",
  P4: "P4", // "PayNow API failed due to ext token/ authen token validation",
  P5: "P5", // "PayNow API failed due to system error",

  P1: "P1", // "PayNow Account Invalid",
  P2: "P2", // "PayNow Account Valid, Name Not Matched",
  P3: "P3", // "PayNow Account Valid, Name Matched",
  S1: "S1", // "Submit AIP Success",
  S2: "S2", // "Submit AIP Failed due to Connectivity Error",
  S3: "S3", // "Submit AIP Failed due to Authentication / Node Expire Error",
  F1: "F1", // "FATCA is No",
  F2: "F2", // "FATCA is Yes",
  C1: "C1", // "CRS only one record",
  C2: "C2", // "CRS more than 1 record",
  U1: "U1", // "Unknown error",
};

export const POPUP_MESSAGE = {
  // to do
  AUTH_ERROR: "Auth API failed due to system error",
  AIP_ERROR: "Fetch API failed due to system error",
  PAYNOW_VALIDATION_NOT_MATCH: "Paynow API success but result is AB",
  PAYNOW_ERROR: "Paynow API failed due to system error",
  SUBMIT_ERROR: "Submit API failed due to system error",
}
