import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  ReqApplicationData,
  ReqApplicationSubmission,
  ReqInPrincipleApproval,
} from "../../../types/request";
import { RespAppOptionField } from "../../../types/response";
import { ApplicationPageRequest } from "../../../services/requests/ApplicationPageRequest";
import { ResumeApplicationRequest } from "../../../services/requests/ResumeApplicationRequest";
import { RootState } from "../../../stores/rootReducer";
import { fetchMyInfo } from "../../../stores/thunks/myinfo.thunks";
import { fetchForms } from "../../../stores/thunks/application.thunks";
import { selectParentsByIndex } from "../../../stores/reducers/application";
import { ApplicationActions } from "../../../stores/reducers/application";
import { MyInfoActions, PartnerActions } from "../../../stores/reducers";
import { blobToBase64 } from "../../../utilities/helper";
import { PATH } from "../../../utilities/path";
import { MYINFO_ACCESS_DENIED } from "../../../utilities/error";
import {
  BE_CODE_STATUS,
  CODE,
  FORM_TYPE,
  DEFAULT_VALUE,
} from "../../../utilities/constants";

import { CHLOverlayLoading, CHLDialog } from "../../../components";
import ApplicationSummary from "./subcomponents/ApplicationSummary";
import FaceVerification from "../FaceVerificationPage"
import { ErrorAction } from "../../../stores/reducers";

const ApplicationForm = lazy(() => import("./subcomponents/ApplicationForm"));
const ApplicationHeader = lazy(() => import("./subcomponents/ApplicationHeader"));

const AIP_RETRY_INTERVAL = window._env.AIP_RETRY_INTERVAL
const AIP_RETRY_TIMEOUT = window._env.AIP_RETRY_TIMEOUT
const CIMB_HOME_URL = window._env.REACT_APP_CIMB_HOME
const HAS_FACEV = window._env.MCC_HAS_FACEV === 'true'

let applicationSubmissionData: ReqApplicationSubmission = {}

function MCCCallbackApplication() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  let [backLoading, setBackLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [tableTypeData, setTableTypeData] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useStore();
  const { parents, isLoading: isLoadingForm, isSTP, faceVScore, faceVFlag } = useSelector(
    (s: RootState) => s.application
  );
  const { isLoading: isLoadingMyInfo, data: myInfoData, myInfoError, errorType } = useSelector(
    (s: RootState) => s.myinfo
  );
  const { productData, isManualFlow, uid, landingUrl } = useSelector((s: RootState) => s.partner);
  const { repaymentData } = useSelector((s: RootState) => s.partnerRepayment);
  const currentTab = useSelector(selectParentsByIndex(activeIndex));
  const params = new URLSearchParams(location.search);
  const code = params.get(CODE.MYINFO_CODE);
  const state = params.get(CODE.MYINFO_STATE);
  const error = params.get(CODE.MYINFO_ERROR);

  const callbackURL = repaymentData.callbackUrl;

  useEffect(() => {
    applicationSubmissionData[CODE.MYINFO_MODE] = [[isManualFlow ? DEFAULT_VALUE.NON_MYINFO : DEFAULT_VALUE.MYINFO]]

    try {
      const productCode = repaymentData.productCode
      const templateID = productData.templateIdIn
      const strTemplateID = btoa(templateID.join(":"))

      const entityType = repaymentData.entityType;
      dispatch(ApplicationActions.setPartyType(entityType));
      //if (entityType && entityType === CODE.CORPORATE) {
      //  // if(processMode != DEFAULT_VALUE.STP){//local unit test workaround
      //  if (isSTP == DEFAULT_VALUE.STP) { //if faceV hit not NSTP
      //    dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.STP))//change to STP on 28/2
      //  }
      //}

      if (code && state) {
        dispatch(
          fetchForms({
            productCode,
            isManualFlow: isManualFlow,
            tids: strTemplateID,
            isMcc: true
          })
        )
        if (!myInfoData || Object.keys(myInfoData).length == 0) {
          dispatch(
            fetchMyInfo({ code, state: state, isMcc: true })
          )
        }
      } else {
        if (error) {
          if (error == MYINFO_ACCESS_DENIED) {
            // window.location.href = CIMB_HOME_URL;
            window.location.href = landingUrl;
          }
        }
        dispatch(
          fetchForms({
            productCode,
            isManualFlow: isManualFlow,
            tids: strTemplateID,
            isMcc: true
          })
        )
      }
    } catch {
      dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.SYSTEM_ERROR))
    }
    window.history.pushState(null, "", window.location.href);
  }, []);

  const onBackButtonEvent = useCallback((e: any) => {
    e.preventDefault();
    window.history.pushState(null, "", window.location.href);
    if (activeIndex > 0) {
      if (activeIndex == parents.length - 1) {
        dispatch(ErrorAction.setErrorCode(BE_CODE_STATUS.SYSTEM_ERROR))
      } else {
        onBack()
      }
    }
  }, [activeIndex, parents.length])

  window.addEventListener('popstate', onBackButtonEvent);

  // Methods
  const onSubmit = useCallback(
    async (app?: any) => {
      let proceed = true
      // polish data to submit
      if (app) await polishSubmissionData(app);
      if (activeIndex < parents.length - 1) {
        if (app) setSummaryData(app);
        window.scrollTo(0, 0);
        if (proceed) setActiveIndex((prev) => ++prev);
      } else {
        setSubmitLoading(true)
        submitAppication();
      }
    },
    [activeIndex, parents.length]
  );

  const setSummaryFieldValue = (
    value?: string,
    options?: RespAppOptionField[]
  ) => {
    let selectedValue: string | number | null | undefined
    if (options?.length) {
      const selectedOptions = options.filter(item => item.code === value)
      selectedOptions.length && (selectedValue = selectedOptions[0].name)
    } else {
      selectedValue = value && value
    }
    return selectedValue
  }

  const setSummaryData = (data: any) => {
    const parentData = Object.values(parents[activeIndex])[0]
    let summaryData = parentData.map(item => {
      let fields: any = []
      if (item.type === FORM_TYPE.ATTACHMENT) {
        fields = item.applicationTemplateTabFields.map(f => {
          if (f.field.group?.code === CODE.HIDDEN_FIELD) return
          return {
            label: f.name,
            code: f.field.code,
          }
        })
      } else if (item.type === FORM_TYPE.DATAENTRY) {
        fields = item.applicationTemplateTabFields.map(f => {
          if (f.field.group?.code === CODE.HIDDEN_FIELD) return
          let value = ""
          if (f.field.options) {
            const selectedOptions = f.field.options.filter(o => o.code === data[f.field.code])
            selectedOptions.length && (value = selectedOptions[0].name)
          } else {
            value = data[f.field.code]
          }
          return {
            label: f.name,
            code: f.field.code,
            value: f.field.code === CODE.MCC_FAKE_MOBILE || f.field.code === CODE.MCC_FAKE_SECONDARY_CONTACT ? data[f.field.code] : setSummaryFieldValue(data[f.field.code], f.field.options)
          }
        })
      } else {
        const tableData: any[] = data[item.id]
        let tableFields: any = []
        tableData.map((d, i) => {
          let obj = item.applicationTemplateTabFields.map((f) => {
            return {
              label: f.name,
              value: setSummaryFieldValue(tableData[i][f.field.code], f.field.options)
            }
          })
          tableFields.push(obj)
        })
        fields = tableFields
      }

      return {
        id: item.id,
        name: item.originalName,
        type: item.type,
        fields: fields
      }
    })

    const res = summaryData.reduce((a: any[], b) => {
      const found = a.find((e: any) => e.name == `${CODE.MCC_DOCUMENT_UPLOAD}`);
      return found ? found.fields.push(...b.fields) : a.push({ ...b }), a;
    }, [])
    summaryData = res
    dispatch(ApplicationActions.setSummaryData(summaryData))
  }

  const polishSubmissionData = async (data: any) => {
    await Promise.all(
      currentTab.map(async (tab) => {
        let sData: { [key: string]: any[] } = {}
        const { applicationTemplateTabFields, id, type, originalName } = tab;
        if (type === FORM_TYPE.TABLE) {
          // hardcode
          if (originalName != CODE.MCC_CPF_HISTORY && originalName != CODE.MCC_NOA) {
            applicationTemplateTabFields.map(async (field) => {
              const { code } = field.field
              const tableData = data[id]

              setTableTypeData(tableData)

              let td = tableData.map((d: any) => {
                return d[code] ? [d[code]] : [null]
              });
              sData[code] = td
            })
            Object.assign(applicationSubmissionData, sData)
          } else {
            const myInfo = store.getState().myinfo.data
            if (Object.keys(myInfo).length == 0) {
              applicationTemplateTabFields.map(async (field) => {
                const { code } = field.field
                const tableData = data[id]
                let td = tableData.map((d: any) => {
                  return d[code] ? [d[code]] : [null]
                });
                sData[code] = td
              })
              Object.assign(applicationSubmissionData, sData)
            } else {
              const mData: { [key: string]: any[] } = myInfo
              const mdata = mData[originalName]
              const res = applicationTemplateTabFields.reduce((acc, el) => {
                const { code } = el.field
                let values: any = [[null]]
                if (code.includes("_")) {
                  const key = code.split("_")[1]
                  if (mdata) {
                    values = mdata.map((item) => {
                      return [item[key]]
                    })
                  }
                }
                return { ...acc, [code]: values }
              }, {})
              Object.assign(applicationSubmissionData, res)
            }
          }

        } else {
          const fieldV = await applicationTemplateTabFields.reduce(
            async (prevValue, newValue) => {
              const { code, maxLength }: any = newValue.field;
              let value = data[code];
              // hardcode
              if (code === CODE.MCC_FATCA_RESIDENCE && value === DEFAULT_VALUE.FATCA_RESIDENCE_YES) dispatch(ApplicationActions.setIsSTP(DEFAULT_VALUE.NSTP))
              if (value instanceof Blob || value instanceof File) {
                if (value instanceof File) {
                  dispatch(ApplicationActions.setDocumentUpload({
                    [code]: value.name
                  }))
                }
                value = await blobToBase64(value);
              }
              if (value == " ") value = ""
              value = [[value]];

              const prev = await prevValue;
              return { ...prev, [code]: value };
            },
            Promise.resolve({})
          );
          Object.assign(applicationSubmissionData, fieldV)
        }
      })
    )
  }

  const submitAppication = async () => {
    applicationSubmissionData[CODE.PROCESS_MODE] = [[isSTP]]
    applicationSubmissionData[CODE.ENTITY_TYPE] = [[repaymentData.entityType]]
    applicationSubmissionData[CODE.CALLBACK_URL] = [[repaymentData.callbackUrl]]
    applicationSubmissionData[CODE.UID] = [[uid]];
    applicationSubmissionData[CODE.MCC_BY_PASS_DC] = window._env.MCC_BY_PASS_DC_INTEGRATION === 'Y' ? [['Y']] : [['N']];
    // applicationSubmissionData[CODE.EMAIL] = applicationSubmissionData[CODE.MCC_EMAIL_ADDRESS];
    // applicationSubmissionData[CODE.PRINCIPAL_NAME] = applicationSubmissionData[CODE.MCC_FULLNAME];
    // applicationSubmissionData[CODE.ALIASNAME] = applicationSubmissionData[CODE.MCC_ALIASNAME];
    const isLocalAddress = applicationSubmissionData[CODE.MCC_ADDR_TYPE]?.[0]?.[0] === 'local';
    if(isLocalAddress) {
      applicationSubmissionData[CODE.MCC_ADDR_COUNTRY] = [[DEFAULT_VALUE.COUNTRY_SINGAPORE]];
    }
    const mobile = applicationSubmissionData[CODE.MCC_FAKE_MOBILE]?.[0]?.[0];
    if (typeof mobile === 'string' && (mobile as string).includes("#")) {
      const mArr = (mobile as string).split("#");
      const m_areaCode = mArr[0];
      const m_numbr = mArr[1];
      applicationSubmissionData[CODE.MCC_MOBILE_AREA_CODE] = [[m_areaCode]];
      applicationSubmissionData[CODE.MCC_MOBILE_NO] = [[m_numbr]];
      delete applicationSubmissionData[CODE.MCC_FAKE_MOBILE];
    }
    const secondaryContact = applicationSubmissionData[CODE.MCC_FAKE_SECONDARY_CONTACT]?.[0]?.[0];
    if (typeof secondaryContact === 'string' && (secondaryContact as string).includes("#")) {
      const mArr = (secondaryContact as string).split("#");
      const m_areaCode = mArr[0];
      const m_numbr = mArr[1];
      applicationSubmissionData[CODE.MCC_SECOND_CONTACT_AREA_CODE] = [[m_areaCode]];
      applicationSubmissionData[CODE.MCC_SECOND_CONTACT_NUMBER] = [[m_numbr]];
      delete applicationSubmissionData[CODE.MCC_FAKE_SECONDARY_CONTACT];
    } else {
      applicationSubmissionData[CODE.MCC_SECOND_CONTACT_AREA_CODE] = [[null]];
      applicationSubmissionData[CODE.MCC_SECOND_CONTACT_NUMBER] = [[null]];
      delete applicationSubmissionData[CODE.MCC_FAKE_SECONDARY_CONTACT];
    }

    if (HAS_FACEV) {
      applicationSubmissionData[CODE.FACEV_SCORE] = [[faceVScore]]
      applicationSubmissionData[CODE.FACEV_FLAG] = [[faceVFlag]]
    }

    const payload: ReqApplicationData = {
      answers: applicationSubmissionData,
      isMcc: true
    }

    const { submitApplication } = ApplicationPageRequest;
    const { data }: any = await submitApplication(payload);

    if (data.code == BE_CODE_STATUS.SUCCESS) {
      const applyNo = data.data?.applyNo
      proceedToAIP(applyNo);
    }
  }

  const proceedToAIP = (applyNo: string) => {
    const reqAIP: ReqInPrincipleApproval = { applyNo }

    let referenceNo = '';
    let getAIPResponse = setInterval(async () => {
      try {
        const checkResult = await ResumeApplicationRequest.fetchResumeApplication(reqAIP)
        const { data, code } = checkResult;
        let decisionStr = '';
        if (data) {
          const { decision, applicationNo } = data;
          if (applicationNo) { referenceNo = applicationNo; }
          decisionStr = decision;
        }
        if (code === BE_CODE_STATUS.SUCCESS) {
          clearInterval(getAIPResponse)
          clearTimeout(redirectComplete)
          if (decisionStr) {
            setTimeout(() => {
              history.replace({
                pathname: PATH.MCC_CALLBACK_COMPLETE,
                state: {
                  code: code,
                  applyNo: referenceNo,
                  decision: decisionStr
                },
              });
            }, 1000)
          }
        } else {
          if (code != BE_CODE_STATUS.RETRIGGER_AIP) {
            clearInterval(getAIPResponse)
            clearTimeout(redirectComplete)
            // inprogress ?? fixme
            history.replace({
              pathname: PATH.MCC_CALLBACK_COMPLETE,
              state: {
                code: BE_CODE_STATUS.SUCCESS,
                applyNo: referenceNo,
              },
            });
          }
        }
      } catch (err) {
        clearInterval(getAIPResponse)
        clearTimeout(redirectComplete)

        history.replace({
          pathname: PATH.MCC_CALLBACK_COMPLETE,
          state: {
            code: BE_CODE_STATUS.SUCCESS,
            applyNo: referenceNo,
          },
        });
      }
    }, parseInt(AIP_RETRY_INTERVAL))

    let redirectComplete = setTimeout(() => {
      clearInterval(getAIPResponse)
      history.replace({
        pathname: PATH.MCC_CALLBACK_COMPLETE,
        state: {
          code: BE_CODE_STATUS.SUCCESS,
          applyNo: referenceNo,
        },
      });
      // }, 60 * 1000); // fixme
    }, parseInt(AIP_RETRY_TIMEOUT))
  }

  const onBack = () => {
    if (activeIndex === 0) {
      // fixme to landing page
      const redirectToPartner: any = sessionStorage.getItem(CODE.MCC_LANDING_URL);
      window.location.href = redirectToPartner;
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const goToManulFlow = () => {
    dispatch(PartnerActions.setIsManualFlow(true));
    dispatch(MyInfoActions.setMyInfoError(false))
    dispatch(MyInfoActions.setErrorType(''));
    setIsError(true);
    if(errorType){
      window.location.href = CIMB_HOME_URL;
    }else{
      window.location.href = window._env.MCC_REACT_APP_MYINFO_APP_REDIRECT_URI;
    }
  }

  // Template
  const condition = (code ? isLoadingForm || isLoadingMyInfo : isLoadingForm) || submitLoading;
  if (condition) {
    return (
      <Box display="flex" justifyContent="center" mt={6}>
        <CHLOverlayLoading />
      </Box>
    );
  }

  if (HAS_FACEV) {
    if (faceVScore == undefined && Object.keys(myInfoData).length > 0) {
      let myInfo: { [key: string]: any } = {}
      myInfo = Object.assign({}, myInfoData)

      const bearer = state ?? ''
      const userID: string = myInfo[CODE.NRIC]
      return (
        <FaceVerification
          userID={userID}
          state={bearer}
        />
      )
    }
  }

  if (myInfoError || isError) {
    return (
      <CHLDialog
        hideClose={true}
        open={true}
        title="Notice"
        onClose={() => { }}
        actions={[
          {
            title: "OK",
            color: "secondary",
            onClick: goToManulFlow
          }]}
      >
        <Typography>
          {errorType ? "You do not meet the minimum age of this product." :
            "We are unable to proceed through myInfo details, please complete the application form with Ok button."}
        </Typography>
      </CHLDialog>
    )
  }

  return (
    <Box position="relative">
      {backLoading && <CHLOverlayLoading />}
      <Suspense fallback={<h1 />}>
        <ApplicationHeader activeIndex={activeIndex} />
        {activeIndex !== parents.length - 2 ?
          <ApplicationForm
            key={activeIndex}
            activeIndex={activeIndex}
            tableTypeData={tableTypeData}
            values={applicationSubmissionData}
            onSubmit={onSubmit}
            onBack={onBack}
          />
          :
          <ApplicationSummary
            activeIndex={activeIndex}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      </Suspense>
    </Box>
  )
}

export default MCCCallbackApplication;
