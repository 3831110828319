import { useState, forwardRef } from "react";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  Box,
  TextField,
  SelectProps,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import CHLLabel from "../CHLLabel";

interface ICHLSelect extends SelectProps {
  label?: string;
  items?: { text: string; value: number | string }[];
  required?: boolean;
  bold?: boolean;
  helperText?: string;
  description?: string;
  onChange: any;
  value?: any;
  error?: any;
  disabled?: boolean;
}
const DEFAULT_TEXT = "Please select";
const CHLSelect = forwardRef<any, ICHLSelect>((props, ref) => {
  const {
    label,
    items = [],
    required,
    bold,
    helperText,
    description,
    error,
    children,
    onChange,
    value,
    disabled = false,
    ...rest
  } = props;
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
  });

  const onChangeHandler = () => (e: any, val: any) => {
    onChange && onChange(val.code);
  }


  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <FormControl classes={{ root: classes.root }}>
        <CHLLabel
          bold={bold}
          description={description}
          label={label}
          required={required}
          component="div"
        />
        <Autocomplete
          filterOptions={filterOptions}
          value={value}
          disabled={disabled}
          disableClearable
          autoHighlight
          fullWidth
          options={items}
          getOptionLabel={(option) => option.name || ""}
          onChange={onChangeHandler()}
          renderInput={(params) => <TextField inputRef={ref} {...params} placeholder={DEFAULT_TEXT} variant="standard" />}
          popupIcon={<ArrowDownIcon />}
        />
        <FormHelperText error={true}>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiOutlinedInput-root": {
      // borderRadius: 2,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      borderWidth: 1,
    },
  },
  paper: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  icon: {
    top: "unset",
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled,
    color: "#8A8A8A",
  },
}));

export default CHLSelect;
