import { FC } from "react";
import clsx from "clsx";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/images/arrow_down.svg";
import { MCC_CAPTION, MCC_CAPTION_SUMMARY } from "../../../../utilities/constants";

interface ICollapseHeader {
  name: string;
  expand: boolean;
  isSummary?: boolean;
  onClick: () => void;
}

const CollapseHeader: FC<ICollapseHeader> = (props) => {
  const { name, expand, isSummary = false, onClick } = props;
  const classes = useStyles();
  const cap = isSummary ? MCC_CAPTION_SUMMARY : MCC_CAPTION;
  const caption = cap.find((c) => c.NAME === name);

  if (!caption?.TITLE) return null;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          bgcolor="#F3F4F9"
          mx={-1}
          mt={2}
          px={1}
          py={2}
          borderRadius={4}
          style={{ cursor: "pointer" }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          onClick={onClick}
        >
          <Typography variant="h6" component="div">
            <Box className={classes.headerText} fontWeight="bold">{caption?.TITLE}</Box>
          </Typography>
          <Box
            className={clsx(classes.expand, {
              [classes.expandOpen]: expand,
            })}
          >
            <ArrowDownIcon />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: "#33373A",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));
export default CollapseHeader;
