import { FC, useEffect, useState } from "react"
import { ReqFaceVerificationGetToken, ReqFaceVerificationValidateToken } from "../../../types/request";
import { FaceVerificationRequest } from "../../../services/requests/FaceVerificationRequest";
import { Box, Typography } from "@material-ui/core";
import { CHLButton, CHLOverlayLoading } from "../../../components";
import { ReactComponent as Success } from "../../../assets/images/success.svg";
import { ReactComponent as Fail } from "../../../assets/images/fail.svg";
import ErrorPage from "../../Error";
import { CHLDialog } from "../../../components";
import { RootState } from "../../../stores/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationActions } from "../../../stores/reducers/application"
import { CODE, DEFAULT_VALUE, REDIRECT_COUNT_DOWN, FACEV_RETRY_TIMES } from "../../../utilities/constants"
import { setProcessMode } from "../../../utilities/helper"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sp-face': SPFaceProps;
        }
    }
}
interface SPFaceProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    token: string,
    logo?: string,
    base_url?: string,
    show_countdown?: string,
    debug?: string,
}

interface IFaceVerification {
    userID: string;
    state: string;
    // returnCallbackHome:(status: false) => void;
}

// const FV_BASE_URL = window._env.FACEV_STREAMING_BASE_URL
const FV_SINGPASS_LOGO = "https://stg-bio-resources.singpass.gov.sg/html5sdk/images/singpass-white.svg"

const FaceVerification: FC<IFaceVerification> = (props) => {
    const { userID, state } = props
    const dispatch = useDispatch();
    // const userID = "G2957839M"
    // const userID = "G2834561K" //always failed

    const { callbackURL, partyType }: any = useSelector((s: RootState) => s.partner);
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const [retryTimes, setRetryTimes] = useState<number>(0);
    const [isFvError, setIsFvError] = useState<boolean>(false);
    const [fvToken, setFVToken] = useState<string>();
    //let [fvStatus, setFVStatus] = useState<string>();
    let [doneValidate, setDoneValidate] = useState<string>();
    //let [isError, setIsError] = useState<boolean>(false);
    let [isLoading, setIsLoading] = useState<boolean>(false);
    let [timer, setTimer] = useState<number>(REDIRECT_COUNT_DOWN);
    const [processMode, setFvProcessMode] = useState<string>()
    let FV_BASE_URL;
    if(partyType === CODE.MCC) {
        FV_BASE_URL = window._env.MCC_FACEV_STREAMING_BASE_URL;
    } else {
        FV_BASE_URL = window._env.FACEV_STREAMING_BASE_URL;
    }

    useEffect(() => {
        getFaceVerification()
    }, [])

    useEffect(() => {
        if (fvToken && !isLoading) {
            handleSpfaceEle();
        }
    }, [isLoading, fvToken])

    const getFaceVerification = async () => {
        let payload: ReqFaceVerificationGetToken = {
            userId: userID
        }
        try {
            setIsLoading(true)
            const res: any = await FaceVerificationRequest.getToken(payload)
            const result = res.data.data

            if (result) {
                setFVToken(result.token)
            }
            setIsLoading(false)
        } catch (err) { }
    }

    const validateResult = async (token: string, type: string) => {
        sessionStorage.setItem(CODE.TOKEN, state)
        let payload: ReqFaceVerificationValidateToken = { token }

        try {
            console.log('validateResult,get', sessionStorage.getItem(CODE.TOKEN))
            const res: any = await FaceVerificationRequest.validateToken(userID, payload)
            const result = res.data.data
            const score = result.score
            const faceVFlag = result.facevFlag
            const retriesRemaining = result.retriesRemaining || 0;

            if (retriesRemaining > 0 && type === 'failed') {
                setRetryTimes(retriesRemaining);
                setIsRetry(true);
            } else {
                setDoneValidate("success");
                const processMode = setProcessMode(score, partyType);
                setFvProcessMode(processMode);

                if (processMode != DEFAULT_VALUE.REJECT) {
                    setTimeout(() => {
                        dispatch(ApplicationActions.setIsSTP(processMode))
                        dispatch(ApplicationActions.setFaceVScore(score))
                        dispatch(ApplicationActions.setFaceVFlag(faceVFlag.toString()))
                    }, 2000)
                }
            }
        } catch (err) {
            //setIsError(true)
        }
    }

    const handleSpfaceEle = () => {
        let spFaceEl = document.querySelector("sp-face");
        ["cancelled", "passed", "failed", "error"].forEach((eventName: string) => {
            (spFaceEl as Element).addEventListener(eventName, e => {
                spFaceHandler(e);
            });
        })
    }

    const spFaceHandler = (e: any) => {
        console.log(e.type);
        switch (e.type) {
            case "cancelled":
                setDoneValidate(e.type);
                setFvProcessMode(DEFAULT_VALUE.REJECT);
                break;

            case "passed":
            case "failed":
                if (fvToken) {
                    validateResult(fvToken, e.type);
                }
                break;

            case "error":
                setIsFvError(true);
                (document.getElementsByTagName("sp-face")[0] as HTMLElement).style.display = "none";
                break;

            default:
                break;
        }
    }

    const handleRetry = () => {
        setIsRetry(false);
        window.location.reload();
    }

    const redirectToMerchant = () => {
        window.location.href = callbackURL;
        window.localStorage.clear()
    }

    if (processMode == DEFAULT_VALUE.REJECT) {
        const countDownTimer = setInterval(() => {
            timer--;
            setTimer(timer)
            if (timer === 0) {
                redirectToMerchant()
                clearInterval(countDownTimer)
            }
        }, 1000)
    }

    if (isLoading) return <Box display="flex" justifyContent="center" mt={6}><CHLOverlayLoading /></Box>

    if (doneValidate) {
        return (
            <Box mt={6} p={1}>
                <Box margin="auto" maxWidth={800} textAlign="center">
                    {processMode && processMode != DEFAULT_VALUE.REJECT ?
                        <Box my={5} display="block">
                            <Success />
                            <Typography>Successfully verified your identity!</Typography>
                        </Box>
                        :
                        <Box my={5} display="block">
                            <Fail />
                            <Typography>Face Verification Failed.</Typography>
                            <Box my={2}>
                                <CHLButton
                                    title={"Back (" + timer + "s)"}
                                    color="secondary"
                                    onClick={() => redirectToMerchant()}
                                />
                            </Box>
                        </Box>}
                </Box>
            </Box>
        )
    }

    return (
        <Box mt={6} p={1}>
            <Box margin="auto" maxWidth={800} textAlign="center">
                <div id="sp-face-here">
                    {fvToken && (
                        <>
                            <sp-face
                                // local mock token
                                //token="8727a3101aa6b7ed487db388f931925f0ec93adbdd83b6034b3e679f1801vs02"
                                //base_url="https://developer.bio-api.singpass.gov.sg/api/face/verify/token"
                                //debug="true"
                                token={fvToken}
                                base_url={FV_BASE_URL}
                                logo={FV_SINGPASS_LOGO}
                                show_countdown="true">
                            </sp-face>

                        </>)}

                    <CHLDialog
                        hideClose={true}
                        open={isRetry}
                        title="Oops..."
                        onClose={() => handleRetry()}
                        actions={[
                            {
                                title: "Try Again",
                                color: "secondary",
                                onClick: () => handleRetry()
                            }]}
                    >
                        <Typography>Singpass Face Verification unsuccessful. Sorry, ambiguous outcome. You can try again in a well-lit environment and make sure that your whole face and eyes are clearly visible. Take note that you have only {retryTimes} tries remaining.</Typography>
                    </CHLDialog>
                    {isFvError && <ErrorPage />}
                </div>
            </Box>
        </Box>
    )
}

export default FaceVerification;