import { FC, useContext } from "react";
import { ApplicationFormContext } from "./ApplicationForm";
import { ApplicationSummaryContext } from "./ApplicationSummary";
import ApplicationController from "./ApplicationController";
import { CODE, BYPASS_VALIDATION } from "../../../../utilities/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducer";

interface IFormDataEntry {
  isSummary?: boolean;
}

const FormDataEntry: FC<IFormDataEntry> = (props) => {
  const { isSummary } = props;
  const context = useContext(
    isSummary ? ApplicationSummaryContext : ApplicationFormContext
  );
  const fields = context?.fields;
  const { isManualFlow } = useSelector((s: RootState) => s.application);
  const { data: myInfoData } = useSelector((s: RootState) => s.myinfo);
  let myInfo: { [key: string]: any } = myInfoData;

  return (
    <>
      {fields &&
        fields.map(({ field, name, required }) => {
          const { code, group } = field;
          if (group?.code === CODE.HIDDEN_FIELD) return null;
          if ([CODE.APPLIC_AMOUNT, CODE.ADDRESS].includes(code)) return null;

          if (isManualFlow) {
            if (BYPASS_VALIDATION.includes(field.code)) {
              return null
            }
          } else {
            //Myinfo has value then hidden the field
            if (![CODE.MCC_EMAIL_ADDRESS, CODE.MCC_FAKE_MOBILE, CODE.MCC_SECOND_CONTACT_NUMBER, CODE.MCC_SECOND_CONTACT_AREA_CODE, CODE.MCC_MARITAL_STATUS].includes(code)) {
              if (!["", null, undefined].includes(myInfo[code])) {
                return null;
              }
            }

            if ([CODE.MCC_ALIASNAME, CODE.MCC_HYPY_NAME].includes(code)) return null;
          }

          return (
            <ApplicationController
              key={code}
              isSummary={isSummary}
              length={fields.length}
              field={field}
              required={required}
              name={name}
            />
          );
        })}
    </>
  );
};

export default FormDataEntry;
