import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReqMyInfo } from "../../types/request"
import { ApplicationPageRequest } from "../../services/requests/ApplicationPageRequest";
import { MyInfoActions } from "../reducers/myinfo";
import {
  polishMyInfoData,
  isValidNRIC,
  isValidAgeRange,
  isValidDOB,
  isNRICMatchWithDOB,
  isAlgoCheckPass,
  getApplicantType,
  isExpiryDateValid,
  isPassTypeValid,
  isPassStatusValid,
} from "../../utilities/helper";
import { CODE, DEFAULT_VALUE, PR_STATUS_VALUE } from '../../utilities/constants';

// const specialCharSet = ["@", ".", "-", "/", "\\", "\"", "\'", ","];
const nameRegex = /^[a-zA-Z @.\-\/'\\\\",]+$/;
const dateRegex = /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/;

export const fetchMyInfo = createAsyncThunk(
  "myinfo/fetchMyInfo",
  async (req: ReqMyInfo, { dispatch }) => {
    try {
      dispatch(MyInfoActions.setIsLoading(true));
      const result = await ApplicationPageRequest.fetchMyInfoPerson(req);
      const data: any = result.data;

      if (window.sessionStorage.getItem("productType") === "ploan") {
        const {
          personIDNo,
          nationality,
          passtype,
          passstatus,
          passexpirydate,
        } = data;
        if (nationality === DEFAULT_VALUE.COUNTRY_SINGAPORE) {
          data[CODE.PR_STATUS] = PR_STATUS_VALUE.YEAR0;
        }     
        if (personIDNo && nationality) {
          // myinfo-flow 
          const { isSGPR } = getApplicantType(personIDNo, nationality);  
          const checkMyinfo = () => {
            if (!passtype || (passtype && !isPassTypeValid(passtype))) {
              return {
                continuable: false,
                errorType: "foreigner_passtype_invalid"
              }
            }
            if (!passstatus || (passstatus && !isPassStatusValid(passstatus))) {
              return {
                continuable: false,
                errorType: "foreigner_passstatus_invalid"
              }
            }
            if (
              !passexpirydate ||
              (passexpirydate && !isExpiryDateValid(passexpirydate))
            ) {
              return {
                continuable: false,
                errorType: "foreigner_passexpirydate_invalid"
              }
            }
            return {
              continuable: true,
              errorType: "foreigner_nyinfo_valid"
            }
          }
          const {continuable, errorType} = checkMyinfo();
          if (!isSGPR && !continuable) {
            dispatch(MyInfoActions.setErrorType(errorType));
            dispatch(MyInfoActions.setMyInfoError(true));
          } else {
            dispatch(MyInfoActions.setData(polishMyInfoData(data)));
            dispatch(MyInfoActions.setMyInfoError(false));
          }
        } else {
          dispatch(MyInfoActions.setData(polishMyInfoData(data)));
          dispatch(MyInfoActions.setMyInfoError(false));
        }
      } else {
        const regaddObj = data?.regadd;
        const { type, country, postal, block, street } = regaddObj;
        const awsmForeignAddLine1 = data?.awsmForeignAddLine1;

        const getValidationResult: () => {
          validate: boolean;
          errorType?: string | undefined;
        } = () => {
          const requiredFields = [
            CODE.MCC_FULLNAME,
            CODE.NRIC,
            CODE.MCC_GENDER,
            CODE.RACE,
            CODE.MCC_DOB,
            CODE.MCC_NATIONALITY,
            CODE.BIRTH_COUNTRY,
          ];
          if (requiredFields.some((field) => !data[field])) {
            return { validate: false };
          }

          if (!regaddObj) {
            return { validate: false };
          }
          if (
            (type === "SG" && !(country && postal && block && street)) ||
            (type !== "SG" && !(country && awsmForeignAddLine1))
          ) {
            return { validate: false };
          }

          const gender = data[CODE.MCC_GENDER];
          if (typeof gender === "string" && gender === "U") {
            return { validate: false };
          }

          const dob = data[CODE.MCC_DOB];
          if (typeof dob === "string" && !isValidDOB(dob)) {
            return { validate: false };
          }
          const nric = data[CODE.NRIC];
          if (
            !isValidNRIC(nric) ||
            !isNRICMatchWithDOB(nric, dob) ||
            !isAlgoCheckPass(nric)
          ) {
            return { validate: false };
          }
          if (
            isValidNRIC(nric) &&
            isNRICMatchWithDOB(nric, dob) &&
            !isValidAgeRange(dob)
          ) {
            return { validate: false, errorType: "ageRangeError" };
          }

          const needValidateNames = [
            data[CODE.MCC_FULLNAME],
            data[CODE.MCC_ALIASNAME],
            data[CODE.MCC_HYPY_NAME],
          ];
          if (
            needValidateNames.some(
              (name) => name && typeof name === "string" && !nameRegex.test(name)
            )
          ) {
            return { validate: false };
          }

          return { validate: true };
        };

        const { validate, errorType } = getValidationResult();

        if (!validate) {
          dispatch(MyInfoActions.setMyInfoError(true));
          if (errorType) {
            dispatch(MyInfoActions.setErrorType(errorType));
          }
        } else {
          if (
            data.hasOwnProperty(CODE.MCC_MOBILE_AREA_CODE) &&
            data.hasOwnProperty(CODE.MCC_MOBILE_NO)
          ) {
            const areaCode = data[CODE.MCC_MOBILE_AREA_CODE];
            const number = data[CODE.MCC_MOBILE_NO];
            data[CODE.MCC_FAKE_MOBILE] = `${areaCode}#${number}`;
          }
          if (type !== "SG" && awsmForeignAddLine1) {
            data[CODE.MCC_ADDR_TYPE] = CODE.MCC_ADDR_TYPE_FOREIGN;
          }
          if (type === "SG" && country && postal && block && street) {
            data[CODE.MCC_ADDR_TYPE] = CODE.MCC_ADDR_TYPE_LOCAL;
          }
          dispatch(MyInfoActions.setData(polishMyInfoData(data)));
          dispatch(MyInfoActions.setMyInfoError(false));
        }
      }
    } catch (err) {
      dispatch(MyInfoActions.setMyInfoError(true))
      throw err;
    } finally {
      dispatch(MyInfoActions.setIsLoading(false));
    }
  }
);


