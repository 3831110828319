import { Typography } from "@material-ui/core";
import { FC } from "react";

const CHLCustomerService: FC = () => {

  return (
    <Typography component="div">
       For any enquires regarding your application, please contact CIMB At-Your-Service at (+65) 6333 7777 (9am to 7pm daily).
    </Typography>
  )
};

export default CHLCustomerService;
