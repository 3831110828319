import { FC, ReactNode, Fragment, useState } from "react";
import { Box, Link, FormControl, Typography, makeStyles,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import CHLLabel from "../../../../../components/CHLLabel";
import CHLDialog from "../../../../../components/CHLDialog";
import { CODE, CPF_HISTORY_TABLE } from "../../../../../utilities/constants"
import { CODE_LABEL } from "../../../../../utilities/label"
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores/rootReducer";
import moment from "moment"

interface IClickableValueField {
  label?: string;
  error?: boolean;
  helperText?: string;
  description?: string;
  code: string;
}

interface INoaDataItem {
  // 重命名用以适配
  // noahistory_yearofassessment: string;
  // noahistory_category: string;
  // noahistory_taxclearance: string;
  // noahistory_amount: number;
  // noahistory_employment: number;
  // noahistory_trade: number;
  // noahistory_rent: number;
  // noahistory_interest: number;
  yearofassessment: string;
  category: string;
  taxclearance: string;
  amount: number;
  employment: number;
  trade: number;
  rent: number;
  interest: number;
}

interface ICpfDataItem {
  // 重命名用以适配
  // cpfcontributions_month: string;
  // cpfcontributions_date: string;
  // cpfcontributions_amount: number;
  // cpfcontributions_employer: string;
  month: string;
  date: string;
  amount: number;
  employer: string;
}

const ClickableValueField: FC<IClickableValueField> = (props) => {
  const {
    label,
    error,
    description,
    code
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles()

  const { data: myinfo } = useSelector((s: RootState) => s.myinfo);
  const myInfoData: { [key: string]: any } = myinfo
  let value = myInfoData[code]

  if(!value){
    value = []
  }

  let unit:string = ""
  if(code){
    // hardcode
    if(code === CODE.MCC_CPF_HISTORY) unit = " Months"
    else if (code === CODE.MCC_NOA) unit = " Years"
  }

  const handleOnClick = () =>{
    setOpen(true)
  }
  
  return (
    <Fragment>
      <FormControl error={error} fullWidth style={{marginTop: '10px'}}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleOnClick}
        style={{cursor: "pointer"}}
      > 
        <CHLLabel
          bold={true}
          description={description}
          label={label}
          required={false}
        />
        {value.length != 0 && <Link>View Details</Link>}
      </Box>
      <Typography component="div">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={32}
        >
        <Box color="#9A9A9A">{value  && value.length + unit}</Box>
        </Box>
      </Typography>
    </FormControl>
      <CHLDialog
        open={open}
        title={CODE_LABEL[code]}
        onClose={() => setOpen(false)}
        actions={[
        {
          title: "Close",
          color: "default",
          onClick: () => setOpen(false)
        }]}
      >
      {(code === CODE.MCC_NOA && value) && value.map((obj: INoaDataItem, i: number) => {
        // 重命名用以适配
        const {
          // noahistory_yearofassessment: yearofassessment,
          // noahistory_category: category,
          // noahistory_taxclearance: taxclearance,
          // noahistory_amount: amount,
          // noahistory_employment: employment,
          // noahistory_trade: trade,
          // noahistory_rent: rent,
          // noahistory_interest: interest,
          yearofassessment,
          category,
          taxclearance,
          amount,
          employment,
          trade,
          rent,
          interest,
        } = obj;
        return (
          <Fragment key={i}>
            <Box fontWeight="bold" className={classes.header}>
              Year of {yearofassessment}
            </Box>
            <Box className={classes.noaContainer}>
              <Box>
                <Typography component="span" className={classes.label}>Type: </Typography>
                <Typography component="span" className={classes.value}> 
                  {category + (taxclearance != "N" ? " (Clearance)" : "")}
                </Typography>
              </Box>
              <Box my={3}>
                <Typography className={classes.label}>Assessable Income: </Typography>
                <Typography className={classes.value}>$ {amount}</Typography>
              </Box>
              <Box mt={3}>
                <Typography className={classes.label}>Income Breakdown: </Typography>
                <Box className={classes.value}>
                  <Typography>Employment: $ {employment}</Typography>
                  <Typography>Trade: $ {trade}</Typography>
                  <Typography>Rent: $ {rent}</Typography>
                  <Typography>Interest: $ {interest}</Typography>
                </Box>
              </Box>   
            </Box> 
          </Fragment>  
        )})
      }
      {code === CODE.MCC_CPF_HISTORY && 
         <Table classes={{ root: classes.root }}>
          <TableHead>
            <TableRow>
              {CPF_HISTORY_TABLE.map((header, i) =><TableCell key={i}>{header}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {value && value.map((obj: ICpfDataItem, i: number) => {
              // 重命名用以适配
              const {
                // cpfcontributions_month: month,
                // cpfcontributions_date: date,
                // cpfcontributions_amount: amount,
                // cpfcontributions_employer: employer,
                month,
                date,
                amount,
                employer,
              } = obj;
              return (
                <TableRow key={i}>
                  <TableCell>{moment(month).format("MMM YYYY")}</TableCell>
                  <TableCell>{moment(date).format("DD MMM YYYY")}</TableCell>
                  <TableCell>{amount}</TableCell>
                  <TableCell>{employer}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>}
      </CHLDialog>
    </Fragment>
  );
};


const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    wordBreak: "normal",
    "& .MuiTableCell-head": {
      background: "#C4161C",
      color: "#fff",
      padding: "10px",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "bold",
    },
    "& .MuiTableCell-body":{
      background: "#F2F4FA",
      border: "1px solid #fff"
    }
  },
  noaContainer:{
    background: "#F2F4FA",
    padding: "10px",
  },
  header: {
    background: "#C4161C",
    color: "#fff",
    padding: "10px",
    fontSize: "16px",
    lineHeight: "22px",
  },
  label: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
  },
  value: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
  },
})

export default ClickableValueField;
