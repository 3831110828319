import { FC, useState, forwardRef } from "react";
import {
  Box,
  makeStyles,
  TextField,
  StandardTextFieldProps,
  FormHelperText
} from "@material-ui/core";
import clsx from "clsx";
import CHLLabel from "../CHLLabel";
import { ReactComponent as FileIcon } from "../../assets/images/file.svg";
import { ReactComponent as HasFileIcon } from "../../assets/images/hasFile.svg";

export interface ICHLTextField extends StandardTextFieldProps {
  code?: string;
  file?: boolean;
  bold?: boolean;
  required?: boolean;
  label?: string;
  description?: string;
  hideHelperText?: boolean;
  placeholder?: string;
  maxLength?: number;
  value?: any;
  upperFormat?: boolean;
  numberOnly?: boolean;
  spaceFormat?: boolean;
  alphanumericFormat?: boolean;
  isMYIC?: boolean;
  tips?: any;
}

// const CHLTextField: FC<ICHLTextField> = (props) => {
const CHLTextField = forwardRef<any, ICHLTextField>((props, ref) => {
  const {
    label,
    file,
    bold = true,
    required,
    InputProps,
    inputProps,
    helperText = "",
    description,
    hideHelperText,
    placeholder,
    maxLength,
    upperFormat = false,
    numberOnly = false,
    spaceFormat = false,
    alphanumericFormat = false,
    isMYIC = false,
    tips = null,
    ...rest
  } = props;
  const classes = useStyles();

  const value:any = rest.value
  const charLength = maxLength ? (value ? (maxLength - value.length) : maxLength) : 0

  const [charRemaining, setCharRemaining] = useState<number>(charLength);

  const handleInput = (e: any) =>{
    if(rest.maxRows && maxLength){
      const targetValue = e.target.value.length
      setCharRemaining(maxLength - targetValue)
    }
    if(upperFormat){
      e.target.value = e.target.value.toUpperCase();
    }
    if(numberOnly){
      e.target.value = e.target.value.replace(/[^0-9]/, '');
    }
    if(spaceFormat){
      if(e.target.value == " "){
        e.target.value = "";
      }
      e.target.value = e.target.value.replaceAll(/\s+/g, " ");
    }
    if(alphanumericFormat){
      e.target.value = e.target.value.replaceAll(/[^0-9a-zA-Z]/g, '');
    }
    if (isMYIC) {
      let prevValue = value;
      let newValue = e.target.value;
      const operation =
        newValue.length > value.length ? "addChar" : "deleteChar";
      const regx = /[0-9]/g;
      const focusPosition = e.nativeEvent.srcElement.selectionStart;

      if (operation == "addChar") {
        const { char, index } = getAddCharInfo(prevValue, newValue, focusPosition);
        if (!regx.test(char)) {
          e.target.value = prevValue;
          e.nativeEvent.srcElement.selectionStart = index - 1;
          e.nativeEvent.srcElement.selectionEnd = index - 1;
        } else {
          e.target.value = formatStr(newValue);
          if (focusPosition === 6 || focusPosition === 9) {
            e.nativeEvent.srcElement.selectionStart = focusPosition + 1;
            e.nativeEvent.srcElement.selectionEnd = focusPosition + 1;
          } else {
            e.nativeEvent.srcElement.selectionStart = index;
            e.nativeEvent.srcElement.selectionEnd = index;
          }
        }
      }
      if (operation == "deleteChar") {
        const { char, index } = getDeleteCharInfo(
          prevValue,
          newValue,
          focusPosition
        );
        if (char == "-") {
          if ((index === 6 || index === 9) && index === newValue.length) {
            e.target.value = formatStr(
              newValue.substring(0, newValue.length - 1)
            );
          } else {
            let front = newValue.substring(0, index - 1);
            let end = newValue.substring(index, newValue.length);
            e.target.value = formatStr(front + end);
          }
          e.nativeEvent.srcElement.selectionStart = index - 1;
          e.nativeEvent.srcElement.selectionEnd = index - 1;
        } else {
          e.target.value = formatStr(newValue);
          e.nativeEvent.srcElement.selectionStart = index;
          e.nativeEvent.srcElement.selectionEnd = index;
        }
      }
    }
  }

  const formatStr = (str: string) => {
    const arr = str.split("").filter((char) => char !== "-");
    let formatted: string = "";
    if (arr.length < 6) {
      formatted = arr.join("");
    } else if (arr.length === 6) {
      formatted = arr.join("") + "-";
    } else if (arr.length === 7) {
      arr.splice(6, 0, "-");
      formatted = arr.join("");
    } else if (arr.length === 8) {
      arr.splice(6, 0, "-");
      formatted = arr.join("") + "-";
    } else {
      arr.splice(6, 0, "-");
      arr.splice(9, 0, "-");
      formatted = arr.join("");
    }
    return formatted;
  };

  const getAddCharInfo = (prevStr: string, curStr: string, idx: number) => {
    const ret = {
      index: idx,
      char: curStr.charAt(idx - 1),
    };
    return ret;
  };

  const getDeleteCharInfo = (prevStr: string, curStr: string, idx: number) => {
    const ret = {
      index: idx,
      char: prevStr.charAt(idx),
    }
    return ret;
  }

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <CHLLabel
        bold={bold}
        description={description}
        label={label}
        required={required}
      />

      <TextField
        inputRef={ref}
        placeholder={placeholder}
        variant={file ? "outlined" : "standard"}
        FormHelperTextProps={{
          classes: {
            root: classes.helperTextRoot,
          },
        }}
        inputProps={{
          maxLength,
          onInput: handleInput,
          ...inputProps,
        }}
        InputProps={{
          classes: {
            root: clsx(classes.root, file && classes.file, (file && value && (helperText == "")) && classes.hasFile),
            disabled: classes.disabled,
          },
          startAdornment: (
            <Box mr={1}>
              {file && (
                (value && (helperText == "")) ? <HasFileIcon/> : <FileIcon/>
              )}
            </Box>
          ),
          ...InputProps,
        }}
        {...rest}
        // helperText={hideHelperText ? undefined : helperText}
      />
      {(rest.maxRows && maxLength) &&
        <FormHelperText>
          {charRemaining + " characters remaining" }
        </FormHelperText>
      }
      {tips}
      <FormHelperText error={true}>{hideHelperText ? undefined : helperText}</FormHelperText>
    </Box>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    // "&.MuiInput-underline:after":{
    //   borderBottom: "none",
    // },
    "&.MuiInput-underline:not(.Mui-focused):after":{
      borderBottom: "none",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: 1,
    },
  },
  file: {
    background: "#FCFCFC",
    cursor: "default",
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "dashed",
    },
    "& input": {
      cursor: "default",
    },
  },
  hasFile:{
    color: "#36B37E",
    background: "#F2FFFA",
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "solid",
      borderColor: "#36B37E",
    },
  },
  helperTextRoot: {
    margin: "3px 0 0",
    position: "absolute",
    top: "30px"
  },
  disabled: {
    borderRadius: 8,
    backgroundColor: theme.palette.action.disabled,
    color: "#8A8A8A",
  },
  hasError: {
    color: "#f44336"
  }
}));

export default CHLTextField;
