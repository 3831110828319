import { makeStyles } from "@material-ui/core";
import loadingGif from "../../assets/images/flipball.gif";
import { FC } from "react";
import zIndex from "@material-ui/core/styles/zIndex";

const CHLOverlayLoading: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.overlayLoader}>
      <img className={classes.loading} src={loadingGif} alt="loading..." />
    </div>
  );
};

const useStyles = makeStyles({
  loading:{
    width: "50px",
    height: "50px",
    zIndex: 100,
  },
  overlayLoader: {
    position: "absolute",
    top: "0px",
    // right: "0px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(236, 234, 234, .3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
});

export default CHLOverlayLoading;
